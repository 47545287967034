import { splitSku, logError } from '../../../../../utils/Utils';
import { generateSku } from '../ProductHelpers';

export const MATERIAL_KEY = 'material';
export const COLOR_KEY = 'color';

export function sanitizeFormValue(value) {
  if (value === 'true') {
    return true;
  }

  return value;
}

export function calculateSelectedFilters(formValues = {}) {
  return Object.values(formValues).reduce(
    (acc, value) => acc + ((value && value !== 'all') ? 1 : 0),
    0,
  );
}

export function getProductCollectionItemsCount(productCollections = []) {
  return productCollections.reduce((acc, collection) => acc + collection.productCollectionItems.length, 0);
}

export function filterCollectionItemsByConfigurations(collections = [], configurations = []) {
  return collections.map((collection) => ({
    ...collection,
    productCollectionItems: collection.productCollectionItems.filter(
      ({ filterCategories }) => filterCategories.some(
        (filterCategory) => configurations.includes(filterCategory),
      ),
    ),
  }));
}

export function collectionItemsMatchAnySKUMatcher(collections = [], skuMatchers = []) {
  return collections.some(
    ({ productCollectionItems }) => productCollectionItems.some(
      ({ sku }) => skuMatchers.some((skuMatcher) => sku.includes(skuMatcher)),
    ),
  );
}

export function filterCollectionItemsBySKUMatchers(collections = [], skuMatchers = []) {
  return collections.map((collection) => ({
    ...collection,
    productCollectionItems: collection.productCollectionItems.filter(
      ({ sku }) => skuMatchers.find((skuMatcher) => sku?.includes(skuMatcher)),
    ),
  }));
}

export function filterCollectionItemsByColors(collections = [], colors = []) {
  return collections.map((collection) => ({
    ...collection,
    productCollectionItems: collection?.productCollectionItems.filter(
      (productCollectionItem) => {
        if (!productCollectionItem?.product?.modifiers) {
          if (productCollectionItem.type === 'ProductCard') {
            logError(`[filterCollectionItemsByColors] Found a productCollection item without product or modifier: ${productCollectionItem.id}`);
          }
          return false;
        }

        const { product: { modifiers } } = productCollectionItem;

        return modifiers.some(
          ({ options }) => options.some(
            ({ value }) => colors.some(
              (color) => value === color,
            ),
          ),
        );
      },
    ),
  }));
}

export function filterCollectionItemsByCollections(collections = [], collectionFilters = []) {
  return filterCollectionItemsBySKUMatchers(
    collections,
    collectionFilters.map(({ skuMatchers }) => skuMatchers).flat(),
  );
}

export function findColorBucketByID(id = '', colorBuckets = []) {
  return colorBuckets.find(({ name }) => name === id);
}

export function findAppropriateColorInBucket(colorBuckets = [], colorSelection = '', productColors = []) {
  const colorsInBucket = colorBuckets.find(
    ({ name }) => name === colorSelection,
  ).colors;

  return colorsInBucket.find(
    (colorInBucket) => productColors.find(
      (materialColor) => materialColor === colorInBucket,
    ),
  );
}

export const getAllColorOptions = (colorBuckets) => {
  const uniqueColorBuckets = colorBuckets
    .reduce((acc, colorData) => ({
      ...acc,
      [colorData.name]: colorData,
    }), {});
  const allColorsOption = { label: 'All Colors', id: 'all' };
  const colorOptions = [
    allColorsOption,
    ...Object
      .values(uniqueColorBuckets)
      .map((colorData) => ({ id: colorData.name, ...colorData })),
  ];

  colorOptions.sort(({ order: order1 }, { order: order2 }) => order2 - order1);

  return colorOptions;
};

export function getUpdatedProductSKUs(collectionItems = [], colorSelection = '', colorBuckets = []) {
  return collectionItems.map(({ sku, product }) => {
    if (colorSelection === 'all') {
      return sku;
    }

    const skuSplit = splitSku(sku, product?.skuParse);
    let [modifier] = product.modifiers;
    if (product.modifiers.length >= 2) {
      const idx = product.modifiers.findIndex(({ options }) => options.find(({ value }) => value.startsWith(skuSplit.color)));
      ([modifier] = product.modifiers.splice(idx, 1));
    }
    const productColors = modifier.options.map(({ value }) => value);
    const setColor = findAppropriateColorInBucket(colorBuckets, colorSelection, productColors);
    let setMaterialColor = '';

    if (!setColor && product.modifiers.length && modifier.onSelectFunction === 'SetMaterialColor') {
      product.modifiers.some(({ options, subTitle }) => {
        const color = findAppropriateColorInBucket(colorBuckets, colorSelection, options.map(({ value }) => value));
        if (color) {
          setMaterialColor = `${subTitle.toLowerCase()}-${color}`;
        }

        return color;
      });
    }
    return generateSku({ setColor, setMaterialColor }, skuSplit, product?.skuParse);
  });
}

export function getUnselectedConfigurations(formValues = {}, availableConfigurations = []) {
  return availableConfigurations.filter((configuration) => !formValues[configuration]);
}

export function getSelectedConfigurations(formValues = {}, availableConfigurations = []) {
  return Object.keys(formValues).filter(
    (key) => (formValues[key] && availableConfigurations.includes(key)),
  );
}

export function getUnselectedCollections(formValues = {}, collectionFilters = []) {
  return collectionFilters
    .map(({ slug }) => slug)
    .filter((collection) => !formValues[collection]);
}

export function getSelectedCollections(formValues = {}, collectionFilters = []) {
  return collectionFilters
    .map(({ slug }) => slug)
    .filter((slug) => !!formValues?.[slug])
    .map((collectionFilterKey) => (
      collectionFilters.find(({ slug }) => slug === collectionFilterKey)
    ));
}

export function getFilterResults(
  formValues = {},
  productCollections = [],
  availableMaterials = [],
  colorBuckets = [],
  availableConfigurations = [],
  collectionFilters = [],
) {
  const colorSelection = formValues[COLOR_KEY] || 'all';
  const materialSelection = formValues[MATERIAL_KEY] || 'all';
  let collectionsFiltered = productCollections;

  const unselectedConfigurations = getUnselectedConfigurations(formValues, availableConfigurations);
  const isEveryConfigurationUnselected = unselectedConfigurations.length === availableConfigurations.length;
  if (!isEveryConfigurationUnselected) {
    const selectedConfigurations = getSelectedConfigurations(formValues, availableConfigurations);
    collectionsFiltered = filterCollectionItemsByConfigurations(
      productCollections,
      selectedConfigurations,
    );
  }

  if (materialSelection !== 'all') {
    const selectedMaterial = availableMaterials.find(({ name }) => name === materialSelection);
    const selectedMaterialSKUMatchers = selectedMaterial?.skuMatchers || [];

    collectionsFiltered = filterCollectionItemsBySKUMatchers(collectionsFiltered, selectedMaterialSKUMatchers);
  }

  if (colorSelection !== 'all') {
    collectionsFiltered = filterCollectionItemsByColors(
      collectionsFiltered,
      colorBuckets.find(
        ({ name }) => name === colorSelection,
      ).colors,
    );
  }

  const unselectedCollections = getUnselectedCollections(formValues, collectionFilters);
  const isEveryCollectionUnselected = collectionFilters.length === unselectedCollections.length;
  if (!isEveryCollectionUnselected) {
    const selectedCollections = getSelectedCollections(formValues, collectionFilters);
    collectionsFiltered = filterCollectionItemsByCollections(
      collectionsFiltered,
      selectedCollections,
    );
  }
  // nonEmptyCollections
  return collectionsFiltered.filter(
    (collection) => collection.productCollectionItems.length > 0,
  );
}

export function getRelevantCollectionSlugs(
  materialSelection = '',
  availableMaterials = [],
  productCollections = [],
  collectionFilters = [],
) {
  if (materialSelection === 'all') {
    return collectionFilters.map(({ slug }) => slug);
  }

  const { skuMatchers } = availableMaterials.find(({ name }) => name === materialSelection) || [];
  const collectionsFilteredByMaterial = filterCollectionItemsBySKUMatchers(productCollections, skuMatchers);

  return collectionFilters
    .map(({ slug }) => slug)
    .filter(
      (collectionFilterKey) => collectionItemsMatchAnySKUMatcher(
        collectionsFilteredByMaterial,
        collectionFilters.find(({ slug }) => slug === collectionFilterKey).skuMatchers,
      ),
    );
}

export function getSanitizedFilterQueryParams(queryParams = {}) {
  const sanitizedQueryParams = Object.entries(queryParams).reduce((acc, [key, value]) => ({
    ...acc,
    [key]: sanitizeFormValue(value),
  }), {});
  sanitizedQueryParams.withFilters = false;

  return sanitizedQueryParams;
}

export function getFilterRedirect(formValues = {}, filterRedirects = [], collectionFilters = []) {
  const foundMaterialRedirect = filterRedirects.find(
    (filterRedirect) => formValues[MATERIAL_KEY] === filterRedirect?.material?.name,
  );
  if (foundMaterialRedirect) {
    return foundMaterialRedirect;
  }

  const selectedCollections = getSelectedCollections(formValues, collectionFilters);
  if (selectedCollections.length === 1) {
    const foundCollectionRedirect = filterRedirects.find(
      (filterRedirect) => formValues[filterRedirect?.collectionFilter?.slug],
    );

    if (foundCollectionRedirect) {
      return foundCollectionRedirect;
    }
  }

  return filterRedirects?.[filterRedirects.length - 1];
}

export function getFilterRedirectFromCurrentPathname(pathname = '', filterRedirects = []) {
  return filterRedirects.find(({ page }) => page?.url === pathname);
}
