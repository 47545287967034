const prefix = '[PAGE]';

export const FETCH_PAGE_CONTENT = `${prefix} FETCH_PAGE_CONTENT`;
export const FETCH_PAGE_CONTENT__REQUEST = `${prefix} FETCH_PAGE_CONTENT__REQUEST`;
export const FETCH_PAGE_CONTENT__SUCCESS = `${prefix} FETCH_PAGE_CONTENT__SUCCESS`;
export const FETCH_PAGE_CONTENT__FAILURE = `${prefix} FETCH_PAGE_CONTENT__FAILURE`;
export const SET_PAGE_CONTENT = `${prefix} SET_PAGE_CONTENT`;
export const SET_PAGE_COMPONENTS = `${prefix} SET_PAGE_COMPONENTS`;
export const SET_FILTER_DATA = `${prefix} SET_FILTER_DATA`;
export const SET_FILTERS_SELECTED = `${prefix} SET_FILTERS_SELECTED`;

export const fetchPageContent = (pathname) => ({
  type: FETCH_PAGE_CONTENT,
  pathname,
});

export const fetchPageContentRequest = () => ({
  type: FETCH_PAGE_CONTENT__REQUEST,
});

export const fetchPageContentSuccess = (payload) => ({
  type: FETCH_PAGE_CONTENT__SUCCESS,
  payload,
});

export const fetchPageContentFailure = (error) => ({
  type: FETCH_PAGE_CONTENT__FAILURE,
  error,
});

export const setPageContent = (payload) => ({
  type: SET_PAGE_CONTENT,
  payload,
});

export const setFiltersSelected = (filtersSelected) => ({
  type: SET_FILTERS_SELECTED,
  payload: filtersSelected,
});
