import { useEffect, useState } from 'react';

import afterDomReady from '../../../../../digioh-custom-js/2-step-modal/after_dom_ready';
import {
  IdentifyUserAndTrackEvent,
  FetchUserIdByEmailAndTrack,
} from '../../../../../digioh-custom-js/2-step-modal/after_dom_ready.types';

declare global {
  interface Window {
    braze?: {
      requestInAppMessageRefresh: () => void;
      showInAppMessage?: (inAppMessage: unknown) => void;
      subscribeToInAppMessage: (callback: (inAppMessage: unknown) => void) => void;
    };
  }
}

const api: {
  identifyUserAndTrackEvent?: IdentifyUserAndTrackEvent;
  fetchUserIdByEmailAndTrack?: FetchUserIdByEmailAndTrack;
} = {};
afterDomReady(fetch)(api);

type FormSubmitData = {
  email?: string;
  phone?: string;
  campaignId: string;
  campaignName: string;
};

// Methods placed outside the component but within the same file
const handleFormSubmit = async (data: FormSubmitData) => {
  const {
    email,
    phone,
    campaignId,
    campaignName,
  } = data;
  if (email) {
    await api.fetchUserIdByEmailAndTrack?.({
      email,
      phone,
      analytics: window.parent.analytics,
      s: {
        lightbox_name: campaignName,
        lightbox_short_id: campaignId,
      },
    }).catch((error) => {
      // eslint-disable-next-line no-console
      console.error('Error during fetchUserIdByEmailAndTrack call: ', error.message);
    });
  } else {
    const userId = window.parent.analytics.user().id()
      || window.parent.analytics.user().anonymousId();

    api.identifyUserAndTrackEvent?.({
      phone,
      userId,
      lightboxId: campaignId,
      analytics: window.parent.analytics,
      extraProperties: {
        lightbox_name: campaignName,
        lightbox_short_id: campaignId,
      },
    });
  }

  const iframeElement = document.querySelector('.ab-iam-root iframe') as HTMLIFrameElement;
  if (iframeElement && iframeElement.contentWindow) {
    iframeElement.contentWindow.postMessage({ action: 'braze:formSubmissionSuccess' }, '*');
  }
};

const BrazeInAppListener = () => {
  const [brazeReady, setBrazeReady] = useState(false);

  useEffect(() => {
    if (brazeReady) {
      window.braze?.subscribeToInAppMessage((inAppMessage) => (
        window.braze?.showInAppMessage?.(inAppMessage)
      ));
    }
  }, [brazeReady]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    const checkBrazeLoaded = () => {
      if (window.braze && typeof window.braze.subscribeToInAppMessage === 'function') {
        setBrazeReady(true);
      } else {
        timer = setTimeout(checkBrazeLoaded, 1000);
      }
    };

    checkBrazeLoaded();

    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    // Add message listener for braze iframe communication
    const messageHandler = (event: MessageEvent) => {
      if (event.origin !== window.origin) {
        return;
      }

      if (event.data?.action === 'braze:submitForm') {
        const {
          phone,
          email,
          campaignId,
          campaignName,
        } = event.data.data;
        handleFormSubmit({
          phone,
          email,
          campaignId,
          campaignName,
        });
      } else if (event.data?.action === 'braze:modalClose') {
        window.parent.dispatchEvent(new CustomEvent('customDigiohBannerClosed'));
      }
    };

    // Attach the message listener
    window.addEventListener('message', messageHandler);

    // Cleanup the message listener when the component unmounts
    return () => {
      window.removeEventListener('message', messageHandler);
    };
  }, []);

  return null;
};

export default BrazeInAppListener;
