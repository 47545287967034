import { DocumentNode } from 'graphql';
import { OperationVariables } from '@apollo/client';
import ApolloClient from '../../../../Apollo/ApolloClient';

const permissibleErrors = {
  SESSION_LOCALE_MISMATCH: 'Sorry, that page is unavailable in your region. Please select a different region.',
  INSUFFICIENT_STOCK: 'Sorry, that product is out of stock. Please select a different product',
  UNAVAILABLE_IN_CHANNEL: 'Sorry, that product is unavailable in your region.',
  INVALID_CREDENTIALS: 'The account credentials you entered are incorrect',
  NOT_LOGGED_IN: 'You are not logged in. Please log in and try again.',
  CHECKOUT_CART_EMPTY: 'Error checkout because the cart is empty, please refresh the page.',
  NO_PROMO_MATCH: 'The coupon code you entered couldn\'t be applied to any items in your order.',
  USER_NOT_FOUND: 'No user with this email address was found',
  CUSTOMER_NOT_FOUND: 'No user with this email address was found',
  USER_EXISTS: 'The supplied email address is already in use, taking you to password reset.',
  EMPTY_CART_CHECKOUT: 'You cannot check out with an empty cart.',
  NEED_TO_RESET_PASSWORD: 'We need you to update your password, taking you to password reset.',
  POS_LOW_BATTERY: 'The POS terminal battery is low, please recharge or select another terminal.',
  MISSING_OR_INVALID_DATA: 'The order cannot be processed because of missing required fields or invalid data. Please review the address data and cart items.',
  forgot: 'If the e-mail you entered is associated with an existing customer account, you will receive an e-mail from us with reset instructions.',
  CART_MIN: 'The cart minimum for this promotional code has not been met. Please check the restrictions for this promotional code and try again.',
};
const errorMessages = {
  ...permissibleErrors,
  generic: 'Sorry something went wrong, please try again.',
  getInventory: 'Sorry something went wrong, please refresh the page.',
  getProducts: 'Sorry something went wrong, please refresh the page.',
  getProduct: 'Sorry something went wrong, please refresh the page.',
  getJobs: 'Sorry something went wrong, please refresh the page.',
  getCheckout: 'There was a problem checking out, please refresh and try again',
  noProduct: 'No such product found.',
  createUser: 'Something went wrong creating an account, please try again.',
  orderCreate: 'Something went wrong while processing your order, please refresh and try again.',
  GET_CART: 'Error getting cart, please refresh the page.',
  ADD_TO_CART: 'Adding item to cart failed. Please refresh the page and try again.',
  CART_ACTION: 'Error updating cart, please refresh the page.',
  DELETE_CART: 'Error deleting cart.',
  ACCOUNT_NOT_FOUND: 'Something went wrong, please refresh the page.',
  REMOVE_COUPON: 'There was an errorr removing this promotion from your cart. Please refresh the page and try again.',
  POS_UNAVAILABLE: 'The POS terminal you selected cannot be reached, please confirm that it is on and connected to the internet',
  POS_UNKNOWN: 'Unknown POS terminal, please reach out to engineering',
  POS_NO_RESPONSE: 'The POS terminal did not respond in time, please try the payment again',
  POS_CANCELLED: 'The POS terminal either timed out or was cancelled',
};

export default {
  permissibleErrors,
  errorMessages,
  handleErrorMessage({ message }: { message: keyof typeof errorMessages }) {
    if (message in errorMessages) {
      return errorMessages[message];
    }
    return errorMessages.generic;
  },

  async apolloGqlMutate<T>(
    mutation: DocumentNode,
    variables?: OperationVariables,
  ) {
    const { data } = await ApolloClient.mutate<T>({
      mutation,
      variables,
      fetchPolicy: 'no-cache',
    });

    return data;
  },

  async apolloGqlQuery<T = Record<string, unknown>>(
    query: DocumentNode,
    variables?: OperationVariables,
  ) {
    const { data } = await ApolloClient.query<T>({
      query,
      variables,
      fetchPolicy: 'no-cache',
    });

    return data;
  },

  async fetchPost<R = unknown>({
    url,
    body,
  }: { url: string; body: unknown; }) {
    return fetch(url, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }).then((r): Promise<R> => r.json());
  },
};
