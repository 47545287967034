import { createSelector } from 'reselect';

import { getPageContent } from './page.selectors';
import { Component, ProductCollectionDto, ProductCollectionItemDto } from '../../types/schema';
import { RootState } from '../store.types';

export const getFavoritesFrom = (group: string) => (
  ({ favorites }: RootState) => Object.values(favorites.data[group] || {})
);

export const getLoading = ({ favorites }: RootState) => favorites.loading;

export const isFavoriteItemSelected = ({ sku, group }: { sku: string, group: string }) => (
  ({ favorites }: RootState) => !!(favorites.data[group] && favorites.data[group][sku])
);

export const isFavoritesBucketVisible = createSelector(
  getFavoritesFrom('swatches'),
  getPageContent,
  (favoriteItems, { components }: { components: Component[] }) => {
    const showFavoritesBucket = components
      .reduce<ProductCollectionDto[]>((allCollections, component) => {
      if (component.type === 'ProductCollection') {
        return [...allCollections, ...component.collections];
      }

      return allCollections;
    }, [])
      .reduce<ProductCollectionItemDto[]>((allProductCollectionItems, collection) => (
      [...allProductCollectionItems, ...(collection.productCollectionItems || [])]
    ), [])
      .some((i) => i.type === 'FavoriteCard');

    return showFavoritesBucket && favoriteItems.length > 0;
  },
);
