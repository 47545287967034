import { splitSku, typedObjectKeys } from '../../../utils/Utils';
import SkuSplit, { SkuPart, SkuParts } from '../../../shared/skuSplit';

interface HandlerParams {
  materialColor: string;
  rules: Rules;
  skuSplit: SkuSplit;
}

interface Rules {
  materialLocation: SkuPart;
  materialValues: Record<string, string>;
  materialDefaultModifiers?: Record<string, SkuParts>;
}

type HandledTypes = 'fieldSeating' | 'nomadSofa';

export const allRules: Record<string, Rules> = {
  fieldSeating: {
    materialLocation: 'collection',
    materialValues: {
      fabric: 'FD',
      leather: 'FDL',
    },
  },
  nomadSofa: {
    materialLocation: 'type',
    materialValues: {
      fabric: 'NSF',
      leather: 'NLSF',
      velvet: 'NVSF',
    },
    materialDefaultModifiers: {
      velvet: {
        arms: 'MD',
      },
    },
  },
};

export const getMaterialFromSku = (
  sku: string | SkuSplit,
  rules: string | Rules,
) => {
  const skuSplit = typeof sku === 'string' ? splitSku(sku) : sku;
  const {
    materialLocation,
    materialValues,
  } = typeof rules === 'string' ? allRules[rules] : rules;
  const skuMaterial = skuSplit[materialLocation];

  return Object.entries(materialValues).find(
    ([, value]) => value === skuMaterial,
  )?.[0];
};

const getColorAndMaterial = ({ materialColor, rules, skuSplit }: HandlerParams) => {
  let [material, color]: (string | undefined)[] = materialColor.split('-');

  // Ideally we should be checking this against colors.constants.ts,
  // but since the list seems to be incomplete, we will check using a regex
  if (/^[A-Z]{2,4}$/.test(material)) {
    color = material;
    material = getMaterialFromSku(skuSplit, rules);
  } else if (!color) {
    color = skuSplit.color;
  }

  return { material, color };
};

/**
 * This is the base handler, it should be used to create new handlers
 * @param materialColor
 * @param rules
 * @param skuSplit
 */
export const baseHandler = ({ materialColor, rules, skuSplit }: HandlerParams) => {
  const { material, color } = getColorAndMaterial({ materialColor, rules, skuSplit });

  const { materialLocation, materialValues, materialDefaultModifiers } = rules;
  const defaultModifier = (material && materialDefaultModifiers?.[material]) || null;

  const materialValue = (material && materialValues[material]) || skuSplit[materialLocation];

  const modifiersValue = {
    color,
    [materialLocation]: materialValue,
  };

  if (defaultModifier) {
    typedObjectKeys(defaultModifier).forEach((modifier) => {
      if (skuSplit[modifier] !== defaultModifier[modifier]) {
        modifiersValue[modifier] = defaultModifier[modifier];
      }
    });
  }

  return modifiersValue;
};

/**
 * This HOF is just to reduce the amount of code duplication when defining new handlers
 * @param rules
 */
const withBaseHandler = (rules: Rules) => (
  (materialColor: string, skuSplit: SkuSplit) => (
    baseHandler({
      materialColor,
      rules,
      skuSplit,
    })
  )
);

type ExtendedHandler = (
  materialColor: HandlerParams['materialColor'],
  skuSplit: HandlerParams['skuSplit']
) => ReturnType<typeof baseHandler>;

const handleSetMaterialColor: Record<HandledTypes, ExtendedHandler> = {
  fieldSeating: withBaseHandler(allRules.fieldSeating),
  nomadSofa: withBaseHandler(allRules.nomadSofa),
};

export default handleSetMaterialColor;
