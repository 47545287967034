import type { ReactEventHandler } from 'react';
import type { Asset } from './header/header.types';

import { withCssModulesClassNames } from '../../common/nextMigrationHelpers';

// eslint-disable-next-line import/no-named-default -- non-issue
import { default as styles } from '../../scss/components/VideoTag.module.scss';

const cssModulesClassNames = withCssModulesClassNames(styles);

interface VideoTagControlsProps {
  asset: Asset;
  isPlaying: boolean;
  togglePlay: ReactEventHandler<HTMLDivElement | HTMLButtonElement>;
}

function VideoTagControls({
  asset,
  isPlaying,
  togglePlay,
}: VideoTagControlsProps) {
  return (
    <div
      className={cssModulesClassNames('video-tag-container__controls')}
      aria-controls={`video-tag-${asset.handle}`}
    >
      <button
        type="button"
        className={cssModulesClassNames('video-controls__toggle-play')}
        onClick={togglePlay}
        title={isPlaying ? 'Pause video' : 'Play video'}
      >
        <i className={`icon icon-${isPlaying ? 'pause' : 'play'}`} aria-hidden={true} />
      </button>
    </div>
  );
}

export default VideoTagControls;
