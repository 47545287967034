enum AspectRatios {
  all_16x10 = 'all_16x10',
  all_1x1 = 'all_1x1',
  all_2x1 = 'all_2x1',
  all_3x1 = 'all_3x1',
  all_388x100 = 'all_388x100',
  all_3x2 = 'all_3x2',
  all_5x2 = 'all_5x2',
  all_4x3 = 'all_4x3',
  all_7x3 = 'all_7x3',
  all_8x10 = 'all_8x10',
  desktop_16x10 = 'desktop_16x10',
  desktop_1x1 = 'desktop_1x1',
  desktop_2x1 = 'desktop_2x1',
  desktop_3x1 = 'desktop_3x1',
  desktop_3x2 = 'desktop_3x2',
  desktop_5x2 = 'desktop_5x2',
  desktop_4x3 = 'desktop_4x3',
  desktop_7x3 = 'desktop_7x3',
  desktop_8x10 = 'desktop_8x10',
  tablet_16x10 = 'tablet_16x10',
  tablet_1x1 = 'tablet_1x1',
  tablet_2x1 = 'tablet_2x1',
  tablet_3x1 = 'tablet_3x1',
  tablet_3x2 = 'tablet_3x2',
  tablet_5x2 = 'tablet_5x2',
  tablet_4x3 = 'tablet_4x3',
  tablet_7x3 = 'tablet_7x3',
  tablet_8x10 = 'tablet_8x10',
}

export default AspectRatios;
