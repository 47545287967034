import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Component } from '../../types/schema';
import { RootState } from '../../store/store.types';

declare global {
  interface Window {
    ReviewsWidget: (id: string, options: Record<string, unknown>) => void;
  }
}

export const REVIEWS_WIDGET_ID = 'ReviewsWidget';
export const getReviewsWidgetOptions = (sku: string) => ({
  store: 'Burrow.com',
  widget: 'polaris',
  options: {
    types: 'product_review',
    lang: 'en',
    layout: '',
    per_page: 10,
    store_review: {
      hide_if_no_results: true,
    },
    third_party_review: {
      hide_if_no_results: true,
    },
    product_review: {
      sku,
      hide_if_no_results: false,
    },
    questions: {
      grouping: sku,
      hide_if_no_results: true,
      enable_ask_question: false,
      show_dates: true,
    },
    header: {
      enable_summary: true,
      enable_ratings: true,
      enable_attributes: true,
      enable_image_gallery: true,
      enable_percent_recommended: false,
      enable_write_review: false,
      enable_ask_question: false,
      enable_sub_header: true,
      rating_decimal_places: 1,
      use_write_review_button: false,
    },
    filtering: {
      enable: true,
      enable_text_search: true,
      enable_sorting: true,
      enable_product_filter: false,
      enable_media_filter: true,
      enable_overall_rating_filter: true,
      enable_language_filter: false,
      enable_language_filter_language_change: false,
      enable_ratings_filters: true,
      enable_attributes_filters: true,
      enable_expanded_filters: false,
    },
    reviews: {
      default_sort: 'highest_rating',
      enable_avatar: true,
      enable_reviewer_name: true,
      enable_reviewer_address: false,
      reviewer_address_format: 'city, country',
      enable_verified_badge: true,
      enable_subscriber_badge: true,
      review_content_filter: 'undefined',
      enable_reviewer_recommends: true,
      enable_attributes: false,
      enable_product_name: true,
      enable_review_title: undefined,
      enable_replies: true,
      enable_images: true,
      enable_ratings: true,
      enable_share: false,
      enable_helpful_vote: false,
      enable_helpful_display: false,
      enable_report: false,
      enable_date: true,
      enable_third_party_source: true,
      enable_duplicate_reviews: undefined,
    },
  },
  translations: {
    'Verified Customer': 'Verified Customer',
  },
  styles: {
    '--base-font-size': '16px',
    '--common-button-font-family': 'inherit',
    '--common-button-font-size': '16px',
    '--common-button-font-weight': '500',
    '--common-button-letter-spacing': '0',
    '--common-button-text-transform': 'none',
    '--common-button-vertical-padding': '10px',
    '--common-button-horizontal-padding': '20px',
    '--common-button-border-width': '2px',
    '--common-button-border-radius': '0px',
    '--primary-button-bg-color': '#383633',
    '--primary-button-border-color': '#383633',
    '--primary-button-text-color': '#ffffff',
    '--secondary-button-bg-color': 'transparent',
    '--secondary-button-border-color': '#383633',
    '--secondary-button-text-color': '#383633',
    '--common-star-color': '#383633',
    '--common-star-disabled-color': '#3836333B',
    '--medium-star-size': '22px',
    '--small-star-size': '19px',
    '--heading-text-color': '#383633',
    '--heading-text-font-weight': '600',
    '--heading-text-font-family': 'inherit',
    '--heading-text-line-height': '1.4',
    '--heading-text-letter-spacing': '0',
    '--heading-text-transform': 'none',
    '--body-text-color': '#383633',
    '--body-text-font-weight': '400',
    '--body-text-font-family': 'inherit',
    '--body-text-line-height': '1.4',
    '--body-text-letter-spacing': '0',
    '--body-text-transform': 'none',
    '--inputfield-text-font-family': 'inherit',
    '--input-text-font-size': '13px',
    '--inputfield-text-font-weight': '400',
    '--inputfield-text-color': '#383633',
    '--inputfield-border-color': '#A3A2A1',
    '--inputfield-background-color': 'transparent',
    '--inputfield-border-width': '1px',
    '--inputfield-border-radius': '0px',
    '--common-border-color': 'rgba(0,0,0,0.15)',
    '--common-border-width': '1px',
    '--common-sidebar-width': '190px',
    '--filters-panel-bg-color': 'transparent',
    '--filters-panel-font-size': '16px',
    '--filters-panel-text-color': '16px',
    '--filters-panel-horizontal-padding': '0',
    '--filters-panel-vertical-padding': '0',
    '--slider-indicator-bg-color': 'rgba(0,0,0,0.1)',
    '--slider-indicator-button-color': '#0E1311',
    '--slider-indicator-width': '190px',
    '--badge-icon-color': '#767472',
    '--badge-icon-font-size': 'inherit',
    '--badge-text-color': '#767472',
    '--badge-text-font-size': 'inherit',
    '--badge-text-letter-spacing': 'inherit',
    '--badge-text-transform': 'inherit',
    '--author-font-size': 'inherit',
    '--author-text-transform': 'none',
    '--avatar-thumbnail-size': '48px',
    '--avatar-thumbnail-border-radius': '100px',
    '--avatar-thumbnail-text-color': '#383633',
    '--avatar-thumbnail-bg-color': 'rgba(0,0,0,0.1)',
    '--photo-video-thumbnail-size': '80px',
    '--photo-video-thumbnail-border-radius': '0px',
    '--mediaslider-scroll-button-icon-color': '#383633',
    '--mediaslider-scroll-button-bg-color': 'rgba(255, 255, 255, 0.85)',
    '--mediaslider-overlay-text-color': '#ffffff',
    '--mediaslider-overlay-bg-color': 'rgba(0, 0, 0, 0.75))',
    '--mediaslider-item-size': '110px',
    '--pagination-tab-text-color': '#383633',
    '--pagination-tab-text-transform': 'none',
    '--pagination-tab-text-letter-spacing': '0',
    '--pagination-tab-text-font-size': '16px',
    '--pagination-tab-text-font-weight': '600',
    '--pagination-tab-active-text-color': '#383633',
    '--pagination-tab-active-text-font-weight': '600',
    '--pagination-tab-active-border-color': '#383633',
    '--pagination-tab-border-width': '3px',
  },
});

const ReviewsWidget = ({ anchor }: { anchor?: Component['anchor'] }) => {
  const productDetails = useSelector((state: RootState) => state.product.data.details);

  // eslint-disable-next-line prefer-arrow-callback
  useEffect(function loadWidget() {
    let timer: NodeJS.Timeout | null = null;
    const checkAndLoadReviewsWidget = () => {
      if (typeof window.ReviewsWidget === 'function') {
        window.ReviewsWidget(`#${REVIEWS_WIDGET_ID}`, getReviewsWidgetOptions(productDetails?.sku ?? ''));
      } else {
        timer = setTimeout(checkAndLoadReviewsWidget, 50);
      }
    };
    checkAndLoadReviewsWidget();

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [productDetails?.sku]);

  return (
    <div
      id={anchor || undefined}
      className="reviews"
    >
      {productDetails?.sku && <h2>Reviews</h2>}
      <div data-testid={REVIEWS_WIDGET_ID} id={REVIEWS_WIDGET_ID} />
    </div>
  );
};

export default ReviewsWidget;
