import {
  call, put, race, select, take, takeLatest,
} from 'redux-saga/effects';
import { gql } from '@apollo/client/core';
import RequestHelpers from '../../components/global/RequestHelpers';
import CartHelpers from '../../components/global/Cart/CartHelpers';
import {
  cartFields, getCXOperatingHoursQuery, getPromotionFields, getVariantQuery,
} from '../../../../apis/QueryBuilder';
import { getIsUserDataPending, getUserDetails } from '../selectors/user.selectors';
import {
  ADD_COUPON_TO_CART,
  ADD_TO_CART,
  ADD_TO_CART__SUCCESS,
  addCouponToCartFailure,
  addCouponToCartRequest,
  addCouponToCartSuccess,
  addToCartFailure,
  addToCartRequest,
  addToCartSuccess,
  APPLY_AUTOMATIC_DISCOUNT,
  GET_RECOMMENDED_CART_ITEMS,
  getRecommendedCartItemsRequest,
  getRecommendedCartItemsSuccess,
  getRecommendedCartItemsFailure,
  DELETE_CART,
  deleteCartFailure,
  deleteCartRequest,
  deleteCartSuccess,
  FETCH_CART,
  FETCH_CHECKOUT_URL_AND_REDIRECT,
  fetchCartFailure,
  fetchCartRequest,
  fetchCartSuccess,
  fetchCheckoutUrlAndRedirectFailure,
  fetchCheckoutUrlAndRedirectRequest,
  fetchCheckoutUrlAndRedirectSuccess,
  REMOVE_COUPON_FROM_CART,
  REMOVE_COUPON_FROM_CART__SUCCESS,
  removeCouponFromCartFailure,
  removeCouponFromCartRequest,
  removeCouponFromCartSuccess,
  SAVE_CART_FOR_LATER,
  saveCartForLaterFailure,
  saveCartForLaterRequest,
  saveCartForLaterSuccess,
  SET_CART_DETAILS,
  setCartDetails,
  setIsProductEditModalVisible,
  setIsPromoInputVisible,
  setPromotions,
  UPDATE_CART_ITEM,
  UPDATE_CART_ITEM__SUCCESS,
  UPDATE_CART_ITEM_MODIFIERS,
  updateCartItemFailure,
  updateCartItemRequest,
  updateCartItemSuccess,
} from '../actions/cart.actions';
import { createOrUpdateConsignment } from '../actions/checkout.actions';
import {
  OPEN_CART, openCart, setErrorPopupMessage, updateCXHours, setIsSearchDropdownOpen,
} from '../actions/ui.actions';
import { getShippingConsignmentAddress } from '../selectors/checkout.selectors';
import { getQueryParams } from '../selectors/router.selectors';
import { getIsCartOpen } from '../selectors/ui.selectors';
import Analytics, { AnalyticsFactory } from '../../components/global/Analytics';
import { logError, capitalize } from '../../../../utils/Utils';
import { getCartItemsDataForShareCart, getShareCartUrl } from '../../components/global/ProductHelpers';
import {
  getAppliedCoupons,
  getAutomaticallyAppliedPromotion,
  getCartDetails,
  getCartError,
} from '../selectors/cart.selectors';
import { FETCH_LOGGED_IN_USER__FAILURE, FETCH_LOGGED_IN_USER__SUCCESS } from '../actions/user.actions';
import { closeFiltersPopout, closeMobileMenu } from './ui.sagas';
import { getHrefLang } from '../selectors/general.selectors';
import { trackOnServer } from '../../../../server/3rd-party/Segment.client-side';

// FIXME: [products] after products implementing
// TODO: we need to get description of the item by getting the product itself
export function* fetchCart() {
  yield put(fetchCartRequest());

  try {
    const query = gql`query GetCartWithPromotionsAndCXHours {
        promotions: graphCMSCouponPromotionLookup {
          ${getPromotionFields(true)}
        }
        cxHours: getCXHours {
          ${getCXOperatingHoursQuery()}
        }
        cartDetails: getCart {
          ${cartFields}
          email
        }
      }`;
    const {
      cartDetails,
      promotions,
      cxHours,
    }  = yield call(RequestHelpers.apolloGqlQuery, query);
    yield put(updateCXHours(cxHours));
    yield put(setPromotions(promotions));
    const {
      id = null,
      email = '',
    } = yield select(getUserDetails);

    if (cartDetails && cartDetails.id) {
      yield put(setCartDetails(cartDetails));
    }

    yield call([Analytics, Analytics.updateUserIdentityAndIdentify], { email });

    if (id) {
      try {
        Analytics.enqueue({
          method: 'pinterest',
          params: {
            email,
          },
        });
      } catch (e) { /* nothing to do */ }
    }

    yield put(fetchCartSuccess(cartDetails));
  } catch (error) {
    yield call([this, logError], error, fetchCart.name);
    yield put(setErrorPopupMessage(RequestHelpers.errorMessages.GET_CART));
    yield put(fetchCartFailure({ error }));
  }
}

export function* addToCart(action) {
  yield put(addToCartRequest());

  const {
    item,
    allOptions,
    quantity,
    source,
    addOnItems,
    overwrite = false,
    quantityMultiplier,
    modifiers,
    openCartOnSuccess = true,
    onAdded,
  } = action;

  try {
    const label = CartHelpers.getCartLabel(source);
    const lineItems = action.lineItems || CartHelpers.generateMultipleAddToCartLineItems(item, allOptions, quantity, addOnItems, quantityMultiplier);
    const cartFunction = overwrite ? 'createCart' : 'addOrCreateCart';
    const mutation = gql`mutation ${capitalize(cartFunction)} {
        cartDetails: ${cartFunction}(lineItems: ${lineItems}) {
          ${cartFields}
        }
      }`;
    let {
      /** @type {{ items: CartLineItem[] }} */
      cartDetails,
    } = yield call(RequestHelpers.apolloGqlMutate, mutation);
    if (typeof window.mulberryBurrow?.hasSelectedPlan === 'function' && window.mulberryBurrow.hasSelectedPlan()) {
      yield call(window.mulberryBurrow.addPlanToCart, cartDetails.id);
      yield call(fetchCart);
      cartDetails = yield select(getCartDetails);
    } else {
      yield put(setCartDetails(cartDetails));
    }

    if (openCartOnSuccess) {
      const isCartOpen = yield select(getIsCartOpen);
      if (!isCartOpen) {
        yield put(openCart(true));
        yield put(setIsSearchDropdownOpen(false));
      }
    }

    const {
      email = '',
    } = yield select(getUserDetails);

    const products = CartHelpers.generateProductsForAnalytics(cartDetails);

    yield call([Analytics, Analytics.updateUserIdentityAndIdentify], { email });

    const events = [];

    try {
      const isSwatchSample = item.sku.startsWith('SAMP');

      if (!isSwatchSample) {
        const itemForAnalytics = AnalyticsFactory.prepareItemProperties({
          ...item,
          quantity,
        });

        const properties = Analytics.prepareEventPayload({
          properties: {
            ...itemForAnalytics,
            products,
            source: label,
            email,
          },
        });
        events.push({
          event: 'Product Added',
          ...properties,
        });
      }

      modifiers.forEach((modifier) => {
        const item = AnalyticsFactory.prepareItemProperties({
          productID: modifier?.productId,
          pricing: {
            price: {
              raw: modifier.optionPrice,
            },
          },
          quantity: modifier.quantity * quantity,
          name: modifier.name,
          optionId: modifier.optionId,
        });
        const properties = Analytics.prepareEventPayload({
          properties: {
            ...item,
            label: `${label} Upgrades`,
            email,
          },
        });

        events.push({
          event: 'Product Added',
          ...properties,
        });
      });

      addOnItems.forEach((addOnItem) => {
        const item = AnalyticsFactory.prepareItemProperties({
          productID: addOnItem?.product?.skuID || addOnItem?.product?.id,
          sku: addOnItem.product && addOnItem.product.sku,
          pricing: {
            price: {
              raw: addOnItem.priceAdjust,
            },
          },
          quantity: addOnItem.quantity * quantity,
          name: addOnItem.label,
        });

        const properties = Analytics.prepareEventPayload({
          properties: {
            ...item,
            label: `${label} Upgrades`,
            email,
          },
        });

        events.push({
          event: 'Product Added',
          ...properties,
        });
      });
    } catch (e) { /* nothing to do */ }

    if (events.length && window?.analytics?.track) {
      const { sent } = yield call(trackOnServer, {
        events,
        include: ['event', 'properties', 'context', 'messageId'],
      });

      sent.forEach((eventPayload) => {
        const {
          event,
          properties,
          ...options
        } = eventPayload;

        // Call event again, with same messageId, on client-side
        window.analytics.track(
          event,
          properties,
          options,
        );
      });
    }

    yield put(addToCartSuccess(cartDetails));
    if (typeof onAdded === 'function') {
      onAdded();
    }
  } catch (error) {
    yield call([this, logError], error, addToCart.name);
    yield put(setErrorPopupMessage(
      RequestHelpers.errorMessages[error.message]
        ? RequestHelpers.errorMessages[error.message]
        : RequestHelpers.errorMessages.ADD_TO_CART,
    ));
    yield put(addToCartFailure({ error }));
  }
}

export function* updateCartItem(action) {
  yield put(updateCartItemRequest());

  const {
    item,
    sign,
    action: eventAction,
    label,
    source,
  } = action;

  try {
    /** @type {{ items: CartLineItem[] }} */
    let cartDetails;
    const cartQuery = CartHelpers.generateUpdateCartQuery(item, sign);
    const mutation = gql`mutation UpdateOrRemoveItem {
        cartDetails: ${cartQuery} {
          ${cartFields}
        }
      }`;

    ({ cartDetails } = yield call(RequestHelpers.apolloGqlMutate, mutation));
    const mulberryWarrantyItemByItemName = cartDetails?.items?.find((i) => i.name.includes(`Mulberry Protection for ${item.name}`));
    if (mulberryWarrantyItemByItemName && (!sign || sign === -1)) {
      const removeMulberryItemQuery = CartHelpers.generateUpdateCartQuery(mulberryWarrantyItemByItemName);
      ({ cartDetails } = yield call(RequestHelpers.apolloGqlMutate,  gql`mutation UpdateOrRemoveItem {
        cartDetails: ${removeMulberryItemQuery} {
          ${cartFields}
        }
      }`));
    }

    yield put(setCartDetails(cartDetails));

    try {
      const {
        email = '',
      } = yield select(getUserDetails);

      const products = CartHelpers.generateProductsForAnalytics(cartDetails);

      yield call([Analytics, Analytics.updateUserIdentityAndIdentify], { email });

      Analytics.enqueue({
        method: 'item',
        params: {
          event: `Product ${eventAction}`,
          action: eventAction,
          products,
          item,
          label,
          source,
          email,
        },
      });
    } catch (e) { /* nothing to do */ }

    yield put(updateCartItemSuccess(cartDetails));
    yield put(setIsProductEditModalVisible(false));
  } catch (error) {
    yield call([this, logError], error, updateCartItem.name);
    yield put(setErrorPopupMessage(RequestHelpers.errorMessages.CART_ACTION));
    yield put(updateCartItemFailure({ error }));
  }
}

function* getUserDetailsFromStore() {
  const isUserDataPending = yield select(getIsUserDataPending);
  if (isUserDataPending) {
    yield race([
      take(FETCH_LOGGED_IN_USER__SUCCESS),
      take(FETCH_LOGGED_IN_USER__FAILURE),
    ]);
  }

  return yield select(getUserDetails);
}

export function* updateCartItemModifiers(action) {
  const { item, optionValues, quantity } = action;

  yield call(addToCart, {
    item: { ...item, id: item.productId },
    allOptions: optionValues,
    quantity,
    openCartOnSuccess: false,
  });

  const hasErrorAddToCart = yield select(getCartError);
  if (!hasErrorAddToCart) {
    // remove the original item
    yield call(updateCartItem, { item, sign: null });
  }
}

export function* applyAutomaticDiscount() {
  const appliedCoupons = yield select(getAppliedCoupons);
  const { disableAutoPromo } = yield select(getQueryParams);

  if (disableAutoPromo === 'true' || (Array.isArray(appliedCoupons) && appliedCoupons.length > 0)) {
    return;
  }

  const userDetails = yield getUserDetailsFromStore();
  const automaticallyAppliedPromotion = yield select(getAutomaticallyAppliedPromotion);

  if (userDetails?.tradePartner || !automaticallyAppliedPromotion?.couponCode) {
    return;
  }

  try {
    yield put(addCouponToCartRequest());
    const code = automaticallyAppliedPromotion.couponCode.toUpperCase();
    const mutation = gql`mutation AddPromotion {
        cartDetails: addPromotion(promotionCode: "${code}") {
          ${cartFields}
        }
      }`;
    const { cartDetails } = yield call(RequestHelpers.apolloGqlMutate, mutation);
    yield put(setCartDetails(cartDetails));
    yield put(addCouponToCartSuccess(cartDetails));
  } catch (error) {
    yield call([this, logError], error, applyAutomaticDiscount.name);
    yield put(addCouponToCartFailure({ error }));
  }
}

export function* addCouponToCart(action) {
  yield put(addCouponToCartRequest());

  const {
    cartId,
    couponCode,
  } = action;

  const {
    email = '',
  } = yield select(getUserDetails);

  yield call([Analytics, Analytics.updateUserIdentityAndIdentify], { email });

  let code = couponCode;
  if (code && typeof code === 'string') {
    code = code.toUpperCase();
  }

  try {
    if (!code) {
      throw new Error('No Coupon Code Found');
    }

    try {
      Analytics.enqueue({
        method: 'track',
        params: {
          event: 'Coupon',
          properties: {
            action: 'Entered',
            coupon_id: code,
            cart_id: cartId,
            email,
          },
        },
      });
    } catch (e) { /* nothing to do */ }

    const mutation = gql`mutation AddPromotion {
        cartDetails: addPromotion(promotionCode: "${code}") {
          ${cartFields}
        }
      }`;
    const { cartDetails } = yield call(RequestHelpers.apolloGqlMutate, mutation);

    yield put(setCartDetails(cartDetails));

    if (cartDetails.coupons.length > 0) {
      try {
        Analytics.enqueue({
          method: 'track',
          params: {
            event: 'Coupon',
            properties: {
              action: 'Applied',
              coupon_id: cartDetails.coupons[0].id,
              coupon_name: cartDetails.coupons[0].code.toUpperCase(),
              cart_id: cartDetails.id,
              discount: cartDetails.coupons[0].discountedAmount,
              email,
            },
          },
        });
      } catch (e) { /* nothing to do */ }
    }

    yield put(addCouponToCartSuccess(cartDetails));
    yield put(setIsPromoInputVisible(false));
  } catch (error) {
    yield call([this, logError], error, addCouponToCart.name);
    const errorMessage = error.message && error.message.includes('Your order does not meet the minimum total for this coupon code to be applied')
      ? RequestHelpers.errorMessages.CART_MIN
      : RequestHelpers.errorMessages.NO_PROMO_MATCH;
    Analytics.enqueue({
      method: 'track',
      params: {
        event: 'Coupon',
        properties: {
          action: 'Denied',
          coupon_id: code,
          cart_id: cartId,
          reason: errorMessage,
          email,
        },
      },
    });
    yield put(setErrorPopupMessage(errorMessage));
    yield put(addCouponToCartFailure({ error }));
  }
}

export function* removeCouponFromCart(action) {
  yield put(removeCouponFromCartRequest());

  const { couponCode } = action;

  try {
    const mutation = gql`mutation RemovePromotion {
        cartDetails: removePromotion(promotionCode: "${couponCode}") {
          ${cartFields}
        }
      }`;
    const { cartDetails } = yield call(RequestHelpers.apolloGqlMutate, mutation);

    yield put(setCartDetails(cartDetails));

    const {
      email = '',
    } = yield select(getUserDetails);

    yield call([Analytics, Analytics.updateUserIdentityAndIdentify], { email });

    try {
      Analytics.enqueue({
        method: 'track',
        params: {
          event: 'Coupon',
          properties: {
            action: 'Removed',
            coupon_id: couponCode,
            cart_id: cartDetails.id,
            email,
          },
        },
      });
    } catch (e) { /* nothing to do */ }

    yield put(removeCouponFromCartSuccess(cartDetails));
  } catch (error) {
    yield call([this, logError], error, removeCouponFromCart.name);
    yield put(setErrorPopupMessage(RequestHelpers.errorMessages.REMOVE_COUPON));
    yield put(removeCouponFromCartFailure({ error }));
  }
}

export function* fetchCheckoutUrlAndRedirect() {
  yield put(fetchCheckoutUrlAndRedirectRequest());

  try {
    const query = gql`query GetCheckoutRedirectURL {
        redirectURLs: getRedirectURL {
          checkoutURL
        }
      }`;

    const { redirectURLs: { checkoutURL } } = yield call(RequestHelpers.apolloGqlQuery, query);

    yield put(fetchCheckoutUrlAndRedirectSuccess(checkoutURL));
    window.location.href = checkoutURL;
  } catch (error) {
    yield call([this, logError], error, fetchCheckoutUrlAndRedirect.name);
    const msg = RequestHelpers.handleErrorMessage(error);
    yield put(setErrorPopupMessage(msg));
    yield put(fetchCheckoutUrlAndRedirectFailure({ error }));
  }
}

export function* saveCartForLater(action) {
  try {
    yield put(saveCartForLaterRequest());
    const {
      payload: {
        email,
        note = '',
        subscribe = false,
      },
    } = action;
    const { items } = yield select(getCartDetails);
    const hrefLang = yield select(getHrefLang);
    const shareCartUrl = getShareCartUrl(items, hrefLang);
    const cartItems = getCartItemsDataForShareCart(items, hrefLang);

    yield call([Analytics, Analytics.updateUserIdentityAndIdentify], { email });

    // This event triggers a workflow that will email the user
    const event = {
      method: 'track',
      params: {
        event: 'Save Cart For Later',
        properties: {
          email,
          note,
          subscribe,
          shareCartUrl,
          cartItems,
        },
      },
    };
    // since Analytics is a Queue, the enqueue function needs Analytics context
    yield call([Analytics, Analytics.enqueue], event);
    yield put(saveCartForLaterSuccess());
  } catch (error) {
    yield call([this, logError], error, saveCartForLater.name);
    const msg = RequestHelpers.handleErrorMessage(error);
    yield put(setErrorPopupMessage(msg));
    yield put(saveCartForLaterFailure());
  }
}

export function* setCartDetailsSaga(action) {
  const {
    details = {},
    localStorage,
  } = action;

  if (localStorage) {
    try {
      yield call([window.localStorage, window.localStorage.setItem], 'cart', JSON.stringify(details));
      yield call([window.localStorage, window.localStorage.setItem], 'cart_contents', JSON.stringify(details.items));
    } catch (error) {
      yield call([this, logError], error, setCartDetailsSaga.name);
    }
  }

  const shippingAddress = yield select(getShippingConsignmentAddress);
  if (shippingAddress) {
    yield put(createOrUpdateConsignment(shippingAddress));
  }
}

export function* deleteCart() {
  yield put(deleteCartRequest());

  try {
    const mutation = gql`mutation DeleteCart {
      deleteCart
    }`;
    yield call(RequestHelpers.apolloGqlMutate, mutation);
    yield put(deleteCartSuccess());
  } catch (error) {
    yield call([this, logError], error, deleteCart.name);

    yield put(setErrorPopupMessage(RequestHelpers.errorMessages.DELETE_CART));
    yield put(deleteCartFailure({ error }));
  }
}

export function* handleGetRecommendedCartItems() {
  yield put(getRecommendedCartItemsRequest());
  const hrefLang = yield select(getHrefLang);
  const cartDetails = yield select(getCartDetails);
  const skusArray = cartDetails.items.map((item) => item.sku.toString());
  try {
    const query = gql`
    query GetRecommendedCartItems($skus: [String!]!, $locale: String!) {
      getRecommendedCartItems(skus: $skus, locale: $locale)
    }
  `;
    const response = yield call(RequestHelpers.apolloGqlQuery, query, {
      skus: skusArray,
      locale: hrefLang,
    });
    const recommendedItems = response.getRecommendedCartItems;
    if (!recommendedItems?.length) {
      yield put(getRecommendedCartItemsFailure({ error: 'No recommendedProducts' }));
      return;
    }
    const bigCommerceInfo = [];
    for (let i = 0; i < recommendedItems.length; i += 1) {
      // If the returnScore is greater than 0.02
      if (recommendedItems[i][1] > 0.02) {
        const queryBigCommerce = gql`query GetRecommendedVariantBySKUProduct {
          product: variantBySKU(sku: "${recommendedItems[i][0]}") {
            ${getVariantQuery(true)}
          }
        }`;
        const { product } = yield call(RequestHelpers.apolloGqlQuery, queryBigCommerce);
        if (product) {
          bigCommerceInfo.push(product);
        }
      }
    }
    if (bigCommerceInfo.length === 0) {
      yield put(getRecommendedCartItemsFailure({ error: 'All BigCommerce queries for variantBySKU failed.' }));
    } else {
      yield put(getRecommendedCartItemsSuccess(bigCommerceInfo));
    }
  } catch (error) {
    yield put(getRecommendedCartItemsFailure({ error }));
  }
}

const cartSagas = [
  takeLatest(FETCH_CART, fetchCart),
  takeLatest(ADD_TO_CART, addToCart),
  takeLatest(UPDATE_CART_ITEM, updateCartItem),
  takeLatest(UPDATE_CART_ITEM_MODIFIERS, updateCartItemModifiers),
  takeLatest(ADD_COUPON_TO_CART, addCouponToCart),
  takeLatest(APPLY_AUTOMATIC_DISCOUNT, applyAutomaticDiscount),
  takeLatest(REMOVE_COUPON_FROM_CART__SUCCESS, applyAutomaticDiscount),
  takeLatest(REMOVE_COUPON_FROM_CART, removeCouponFromCart),
  takeLatest(FETCH_CHECKOUT_URL_AND_REDIRECT, fetchCheckoutUrlAndRedirect),
  takeLatest(SET_CART_DETAILS, setCartDetailsSaga),
  takeLatest(SAVE_CART_FOR_LATER, saveCartForLater),
  takeLatest(GET_RECOMMENDED_CART_ITEMS, handleGetRecommendedCartItems),
  takeLatest(DELETE_CART, deleteCart),
  takeLatest(UPDATE_CART_ITEM__SUCCESS, applyAutomaticDiscount),
  takeLatest(ADD_TO_CART__SUCCESS, applyAutomaticDiscount),
  takeLatest(OPEN_CART, closeMobileMenu),
  takeLatest(OPEN_CART, closeFiltersPopout),
];

export default cartSagas;
