import {
  LOAD_PRODUCT_VARIANT,
  FETCH_PRODUCT_DETAILS__REQUEST,
  FETCH_PRODUCT_DETAILS__SUCCESS,
  FETCH_PRODUCT_DETAILS__FAILURE,
  FETCH_EDIT_MODAL_PRODUCT_DETAILS__REQUEST,
  FETCH_EDIT_MODAL_PRODUCT_DETAILS__SUCCESS,
  FETCH_EDIT_MODAL_PRODUCT_DETAILS__FAILURE,
  SET_SELECTED_MODIFIERS,
  SET_LAST_MODIFIER_SELECTION,
  SET_ADD_ON_MODIFIERS,
  SET_TUFTED,
  FETCH_PRODUCT_AVAILABILITY__REQUEST,
  FETCH_PRODUCT_AVAILABILITY__SUCCESS,
  FETCH_PRODUCT_AVAILABILITY__FAILURE,
  FETCH_PRODUCT_SHIPPING_ESTIMATE__SUCCESS,
  SET_SELECTED_QUANTITY,
  TOGGLE_MODIFIER_OPTION,
  FETCH_PRODUCT_PROMOTIONS__SUCCESS,
  FETCH_PRODUCT_PROMOTIONS__FAILURE,
  FETCH_ADDON_MODIFIER_OPTION__INVENTORY__SUCCESS,
  FETCH_ADDON_MODIFIER_OPTION__INVENTORY_REQUEST,
  FETCH_ADDON_MODIFIER_OPTION__INVENTORY__FAILURE,
} from '../actions/product.actions';
import { reapplySelectedModifiers } from '../../components/global/ProductHelpers';

/** @type {ProductState} */
export const initialState = {
  error: null,
  isPending: false,
  addonModifierOptionInventoryError: null,
  isAddonModifierOptionInventoryPending: false,
  isFetchProductAvailabilityPending: true,
  data: {
    details: null,
    selectedModifiers: {},
    addOnModifiers: {},
    selectedQty: 1,
    promotionsLookup: null,
    shippingEstimates: {},
  },
};

const fetchProductDetailsRequest = (state) => ({
  ...state,
  isPending: true,
  error: null,
  data: {
    ...state.data,
    promotionsLookup: null,
  },
});

const fetchProductDetailsSuccess = (state, payload) => {
  const {
    data: {
      selectedModifiers,
      lastModifierSelection = {},
      addOnModifiers,
    },
  } = state;
  const {
    product,
  } = payload;
  return {
    ...state,
    isPending: false,
    data: {
      ...state.data,
      details: product,
      lastModifierSelection: product.sku.includes(lastModifierSelection.value) ? lastModifierSelection : {},
      selectedModifiers: reapplySelectedModifiers(selectedModifiers, product.productModifiers),
      addOnModifiers,
      promotionsLookup: null,
    },
  };
};
const fetchProductDetailsFailure = (state, payload) => ({
  ...state,
  isPending: false,
  data: { ...initialState.data },
  error: payload,
});

const fetchAddonModifierOptionsInventoryRequest = (state) => ({
  ...state,
  addonModifierOptionInventoryError: null,
  isAddonModifierOptionInventoryPending: true,
});

const fetchAddonModifierOptionsInventoryFailure = (state, payload) => ({
  ...state,
  addonModifierOptionInventoryError: payload,
  isAddonModifierOptionInventoryPending: false,
});

const fetchAddonModifierOptionsInventorySuccess = (state, payload) => ({
  ...state,
  isAddonModifierOptionInventoryPending: false,
  data: {
    ...state.data,
    details: {
      ...state.data.details,
      productModifiers: payload,
    },
  },
});

const loadProductVariant = (state, { productDetails }) => ({
  ...state,
  isPending: false,
  data: {
    ...state.data,
    details: productDetails,
  },
});
const setSelectedModifiers = (state, payload) => ({
  ...state,
  data: {
    ...state.data,
    selectedModifiers: payload,
  },
});

const setLastModifierSelection = (state, payload) => {
  const {
    lastModifierSelection,
  } = payload;
  return {
    ...state,
    data: {
      ...state.data,
      lastModifierSelection,
    },
  };
};
const setAddOnModifiers = (state, payload) => ({
  ...state,
  data: {
    ...state.data,
    addOnModifiers: payload,
  },
});
const setTufted = (state, payload) => ({
  ...state,
  data: {
    ...state.data,
    tufted: payload,
  },
});
const setSelectedQty = (state, payload) => ({
  ...state,
  data: {
    ...state.data,
    selectedQty: payload,
  },
});
const toggleModifierOption = (state, { modifier, option }) => ({
  ...state,
  data: {
    ...state.data,
    lastModifierSelection: {
      slug: modifier.slug,
      value: option.value,
      index: option.changeGalleryIndex,
    },
  },
});
const fetchProductShippingEstimateSuccess = (state, {
  sku,
  shippingMessage,
}) => ({
  ...state,
  data: {
    ...state.data,
    shippingEstimates: {
      ...state.data.shippingEstimates,
      [sku]: shippingMessage,
    },
  },
});
const fetchProductPromotionsSuccess = (state, promotionsLookup) => ({
  ...state,
  data: {
    ...state.data,
    promotionsLookup,
  },
});
const fetchProductPromotionsFailure = (state) => ({
  ...state,
  data: {
    ...state.data,
    promotionsLookup: null,
  },
});

/**
 * channelAvailability type
 * @typedef {Object} ChannelAvailability
 * @property {number} channelId
 * @property {number} productId
 * @property {boolean} channelAvailability
 */

/**
 * Generates an object from a list of skus and channelAvailability
 * @param {string[]} skus
 * @param {ChannelAvailability[]} availability
 * @returns {{[p: string]: ChannelAvailability}}
 */
const availabilityFromEntries = (skus, availability) => Object.fromEntries(availability.map(({
  channelId,
  productId,
  channelAvailability,
}, idx) => [skus[idx], {
  channelId,
  productId,
  channelAvailability,
}]));

const fetchProductAvailabilityRequest = (state) => ({
  ...state,
  fetchProductAvailabilityError: null,
  isFetchProductAvailabilityPending: true,
});

const fetchProductAvailabilityFailure = (state, payload) => ({
  ...state,
  fetchProductAvailabilityError: payload,
  isFetchProductAvailabilityPending: false,
});

const fetchProductAvailabilitySuccess = (state, {
  inventory,
  channelAvailability,
  skus,
}) => ({
  ...state,
  isFetchProductAvailabilityPending: false,
  data: {
    ...state.data,
    details: {
      ...state.data.details,
      inventory,
    },
    channelAvailability: {
      ...state.data.channelAvailability,
      ...availabilityFromEntries(skus, channelAvailability),
    },
  },
});
export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRODUCT_DETAILS__REQUEST:
    case FETCH_EDIT_MODAL_PRODUCT_DETAILS__REQUEST:
      return fetchProductDetailsRequest(state, action.payload);

    case FETCH_PRODUCT_DETAILS__SUCCESS:
    case FETCH_EDIT_MODAL_PRODUCT_DETAILS__SUCCESS:
      return fetchProductDetailsSuccess(state, action.payload);

    case FETCH_PRODUCT_DETAILS__FAILURE:
    case FETCH_EDIT_MODAL_PRODUCT_DETAILS__FAILURE:
      return fetchProductDetailsFailure(state, action.payload);

    case LOAD_PRODUCT_VARIANT:
      return loadProductVariant(state, action.payload);

    case SET_LAST_MODIFIER_SELECTION:
      return setLastModifierSelection(state, action.payload);

    case SET_ADD_ON_MODIFIERS:
      return setAddOnModifiers(state, action.payload);

    case SET_SELECTED_MODIFIERS:
      return setSelectedModifiers(state, action.payload);

    case SET_TUFTED:
      return setTufted(state, action.payload);

    case SET_SELECTED_QUANTITY:
      return setSelectedQty(state, action.payload);

    case TOGGLE_MODIFIER_OPTION:
      return toggleModifierOption(state, action.payload);

    case FETCH_PRODUCT_AVAILABILITY__REQUEST:
      return fetchProductAvailabilityRequest(state, action.payload);

    case FETCH_PRODUCT_AVAILABILITY__SUCCESS:
      return fetchProductAvailabilitySuccess(state, action.payload);

    case FETCH_PRODUCT_AVAILABILITY__FAILURE:
      return fetchProductAvailabilityFailure(state, action.payload);

    case FETCH_PRODUCT_SHIPPING_ESTIMATE__SUCCESS:
      return fetchProductShippingEstimateSuccess(state, action.payload);

    case FETCH_PRODUCT_PROMOTIONS__SUCCESS:
      return fetchProductPromotionsSuccess(state, action.payload);

    case FETCH_PRODUCT_PROMOTIONS__FAILURE:
      return fetchProductPromotionsFailure(state, action.payload);

    case FETCH_ADDON_MODIFIER_OPTION__INVENTORY_REQUEST:
      return fetchAddonModifierOptionsInventoryRequest(state, action.payload);

    case FETCH_ADDON_MODIFIER_OPTION__INVENTORY__SUCCESS:
      return fetchAddonModifierOptionsInventorySuccess(state, action.payload);

    case FETCH_ADDON_MODIFIER_OPTION__INVENTORY__FAILURE:
      return fetchAddonModifierOptionsInventoryFailure(state, action.payload);

    default:
      return state;
  }
};
