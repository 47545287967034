import HrefLang from '../../../shared/localization/hrefLang';
import Currency from '../../../shared/currency';

const getCurrencyPrefix = ({ hrefLang, verbose }:{ hrefLang: HrefLang, verbose?: boolean }): string => {
  switch (hrefLang) {
    case HrefLang.EN_US:
      return '$';
    case HrefLang.EN_CA:
      return `${verbose ? `${Currency.CAD} ` : ''}$`;
    default:
      // eslint-disable-next-line no-case-declarations
      const unsupportedHrefLang: never = hrefLang;
      console.warn(`Unsupported hrefLang: ${unsupportedHrefLang}`);
      return '';
  }
};

export default getCurrencyPrefix;
