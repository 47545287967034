/* eslint no-underscore-dangle: ["warn", { "allow": ["__NEXT_DATA__"] }] */
import { useEffect, useState } from 'react';
import { useSearchParams } from 'next/navigation';
import { useDispatch } from 'react-redux';
import { gql } from '@apollo/client';
import { captureException } from '@sentry/browser';
import { setAnalyticsInfo } from '../store/actions/user.actions';
import { AppDispatch } from '../store/store.types';
import RequestHelpers from '../components/global/RequestHelpers';
import { updateExperimentsFlags, updateHeaderNavigation } from '../store/actions/ui.actions';
import type { SessionPayloadDto } from '../../../shared/sessionManager';
import NavigationItemDto from '../../../shared/graphCms/navigationItem.dto';
import { availableFlags, isFeatureEnabled } from '../../../utils/featureFlag';

type CookieObject = {
  _ga_15NMTV2PDM?: string;
  _ga?: string;
  _fbc?: string;
  _fbp?: string;
};

export const extractGaInfoFromCookieObject = (cookies: CookieObject) => {
  const {
    _ga_15NMTV2PDM: gaSessionCookie,
    _ga: gaClientCookie,
    _fbc: fbc,
    _fbp: fbp,
  } = cookies;

  const [, , gaSessionID, gaSessionNumber] = gaSessionCookie?.split('.') || [];
  const gaClientId = gaClientCookie?.split('.').splice(2).join().replace(',', '.') || '';

  return {
    gaSessionID,
    gaClientId,
    gaSessionNumber,
    fbc,
    fbp,
  };
};

export const setUserAnalyticsFromCookies = (dispatch?: AppDispatch) => {
  const cookies = document.cookie.split(';').reduce((acc, cookie) => {
    const [key, value] = cookie.trim().split('=');
    if (key) acc[key] = value; // eslint-disable-line no-param-reassign
    return acc;
  }, {} as Record<string, string>);

  const analyticsInfoPayload = extractGaInfoFromCookieObject(cookies);

  if (dispatch) dispatch(setAnalyticsInfo(analyticsInfoPayload));

  return analyticsInfoPayload;
};

const GET_EXPERIMENTS = gql`
  query GetExperiments {
    getExperiments {
      experiments
    }
  }
`;

type Experiments = SessionPayloadDto['experiments'];
export interface GetExperimentsResponse {
  getExperiments: {
    experiments: Experiments;
  };
}

export const setExperiments = async (dispatch: AppDispatch) => {
  const experiments = await RequestHelpers.apolloGqlQuery<GetExperimentsResponse>(GET_EXPERIMENTS)
    .then(({ getExperiments: { experiments: _experiments } }) => _experiments)
    .catch((error) => {
      captureException(error);
      return {};
    });

  dispatch(updateExperimentsFlags(experiments));

  return experiments;
};

export interface GetHeaderNavigationResponse {
  getHeaderNavigation: {
    headerNavigation: NavigationItemDto[];
  };
}
const GET_HEADER_NAVIGATION = gql`
  query GetHeaderNavigation($aBTestVersion: String) {
    getHeaderNavigation(aBTestVersion: $aBTestVersion) {
      headerNavigation
    }
  }
`;

export const setHeaderNavigation = async (
  dispatch: AppDispatch,
  experiments: Experiments,
  searchParamsObj: Record<string, string> = {},
) => {
  const aBTestVersion = isFeatureEnabled(
    availableFlags.HeaderNavWithCategories,
    experiments,
    searchParamsObj,
  ) ? 'Variation1' : 'Original';

  const headerNavigation = await RequestHelpers.apolloGqlQuery<GetHeaderNavigationResponse>(
    GET_HEADER_NAVIGATION,
    { aBTestVersion },
  )
    .then((r) => r.getHeaderNavigation.headerNavigation)
    .catch((error) => {
      captureException(error);
      return [] as NavigationItemDto[];
    });

  dispatch(updateHeaderNavigation(headerNavigation));
};

type UserAnalytics = ReturnType<typeof setUserAnalyticsFromCookies>;

interface Data {
  userAnalytics?: UserAnalytics;
  experiments?: Experiments;
}

export default function useDynamicData() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<Data>({});
  const dispatch: AppDispatch = useDispatch();
  const searchParams = useSearchParams();

  useEffect(() => {
    const analytics = setUserAnalyticsFromCookies(dispatch);

    // The app router doesn't provide __NEXT_DATA__ on the first load,
    // so we need to create it manually to use it correctly in the Analytics.js file.
    if (!window.__NEXT_DATA__) {
      // related to: https://github.com/rse/graphql-query-compress/issues/10
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.__NEXT_DATA__ = { props: { pageProps: {} } };
    }
    window.__NEXT_DATA__.props.pageProps.analytics = analytics;

    setExperiments(dispatch)
      .then(async (experiments) => {
        await setHeaderNavigation(
          dispatch,
          experiments,
          searchParams ? Object.fromEntries(searchParams) : {},
        );
        setLoading(false);
        setData({ userAnalytics: analytics, experiments });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- should only run once
  }, []);

  return {
    data,
    loading,
  };
}
