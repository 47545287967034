import { createSelector } from 'reselect';
import { getPageComponents } from './page.selectors';
import { getQueryParams } from './router.selectors';
import { RootState } from '../store.types';
import { Breakpoints, ComponentType } from '../../types/schema';

export const getWindowWidth = ({ ui }: RootState) => ui.windowWidth;
export const getIsMobile = ({ ui }: RootState) => ui.windowWidth <= Breakpoints.Phone;
export const getIsMediumDevice = ({ ui }: RootState) => ui.windowWidth <= Breakpoints.Medium;
export const getIsTablet = ({ ui }: RootState) => ui.windowWidth <= Breakpoints.Tablet;
export const getIsLargeTablet = ({ ui }: RootState) => ui.windowWidth < Breakpoints.Large;
export const getIsXLWidth = ({ ui }: RootState) => ui.windowWidth >= Breakpoints.XL;
export const getHeaderTextColor = ({ ui }: RootState) => ui.headerTextColor;
export const getErrorPopupMessage = ({ ui }: RootState) => ui.errorPopupMessage;
export const getExperiments = ({ ui }: RootState) => ui.experiments;
export const getPopupRedirect = ({ ui }: RootState) => ui.popupRedirect;
export const getPopupOnClose = ({ ui }: RootState) => ui.popupOnClose;
export const getFooterNavigation = ({ ui }: RootState) => ui.footerNavigation;
export const getHeaderNavigation = ({ ui }: RootState) => ui.headerNavigation;
export const getCXHours = ({ ui }: RootState) => ui.cxHours;
export const getIsCartOpen = ({ ui }: RootState) => ui.isCartOpen;
export const getIsFiltersPopoutOpen = ({ ui }: RootState) => ui.isFiltersPopoutOpen;
export const getIsGoogleMapsActive = ({ ui }: RootState) => ui.isGoogleMapsActive;
export const getNavHeight = ({ ui }: RootState) => ui.navHeight;
export const getDisplayProductPreview = ({ ui }: RootState) => ui.displayProductPreview;
export const getIsBXActive = ({ ui }: RootState) => ui.bxActive;
export const getNavPinned = ({ ui }: RootState) => ui.navPinned;
export const getInjectedScripts = ({ ui }: RootState) => ui.injectedScripts;
export const getIsOnline = ({ ui }: RootState) => ui.isOnline;
export const getNavItems = ({ ui }: RootState) => ui?.navItems || [];
export const getIsMobileMenuOpen = ({ ui }: RootState) => ui?.isMobileMenuOpen || false;
export const getIsSearchDropdownOpen = ({ ui }: RootState) => ui?.isSearchDropDownOpen || false;
export const getSearchLayout = ({ ui }: RootState) => ui?.searchLayout;
export const getPlpSiteWideText = ({ ui }: RootState) => ui?.plpSiteWideText;
export const isNextApp = ({ ui }: RootState) => ui?.nextApp;
export const isSSG = ({ ui }: RootState) => ui?.ssg;

/**
 * @todo this type can be removed once the `page` state is typed.
 *       This is related to the `getPageComponents` selector ReturnType.
 */
type PageComponent = {
  type: ComponentType;
  anchor: string;
};

export const getScrollHandlingComponentsAnchor = createSelector(
  getQueryParams,
  getPageComponents,
  (queryParams, components: PageComponent[]) => {
    const scrollToComponent = components
      .find(({ type, anchor }) => type === 'Accordion' && queryParams[anchor]); // Components implementing their own scroll to functionality

    return scrollToComponent?.anchor;
  },
);

export const getAvailableLocale = createSelector(
  getHeaderNavigation,
  (headerNavigation = []) => headerNavigation.find(({ tier }) => tier === 'Locale')?.features ?? [],
);
