import PropTypes from 'prop-types';

import Asset from '../global/Asset';
import UniversalLinkContainer from '../global/UniversalLink';
import { assetPropTypes } from '../../common/asset.propTypes';
import { linkPropTypes } from '../../common/point.propTypes';
import { Nullable } from '../../types/common';
import { LinkDto, AssetDto, Align } from '../../types/schema';
import { withCssModulesClassNames } from '../../common/nextMigrationHelpers';
// eslint-disable-next-line import/no-named-default
import { default as styles } from '../../scss/components/ContentCard.module.scss';

const withCssModulesClassNamesHandler = withCssModulesClassNames(styles);
export interface ContentCardProps {
  link?: Nullable<LinkDto>;
  copy?: Nullable<string>;
  header?: Nullable<string>;
  icon?: Nullable<AssetDto>;
  tabIndex?: number;
  asset?: Nullable<AssetDto>;
  columnSpan?: Nullable<string>;
  alignHorizontally?: Nullable<Align.Left | Align.Right>;
  backgroundCopyColor?: Nullable<string>;
  visibilityClassNames?: string;
  productCarouselLifestyleCard?: boolean;
}

const ContentCard = ({
  copy,
  icon,
  link,
  asset,
  tabIndex,
  alignHorizontally,
  backgroundCopyColor,
  columnSpan = 'span1',
  visibilityClassNames,
  productCarouselLifestyleCard,
}: ContentCardProps) => {
  const Wrapper = link ? UniversalLinkContainer : 'div';
  const wrapperProps = link ? { ...link, tabIndex } : {};

  return (
    <div
      className={withCssModulesClassNamesHandler(
        'content-card',
        columnSpan,
        visibilityClassNames,
        {
          'content-card__carousel-wrapper': productCarouselLifestyleCard,
        },
      )}
    >
      <Wrapper
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...wrapperProps}
        className={withCssModulesClassNamesHandler('content-card__wrapper', {
          'content-card__carousel': productCarouselLifestyleCard,
        })}
      >
        {
          icon?.width || copy ? (
            <div
              className={withCssModulesClassNamesHandler('content-card__text-wrapper', {
                'content-card__text-wrapper_right': alignHorizontally === 'Right',
                'content-card__text-wrapper_left': alignHorizontally === 'Left',
              })}
            >
              <div
                className={withCssModulesClassNamesHandler(
                  'content-card__text',
                  (
                    backgroundCopyColor ? {
                      [`bg-color-${backgroundCopyColor.toLowerCase()}`]: backgroundCopyColor,
                    } : null
                  ),
                )}
              >
                {icon?.width && (
                  <Asset
                    imageProps={{
                      outerWrapperClassName: withCssModulesClassNamesHandler(
                        'content-card__text-icon',
                      ),
                    }}
                    asset={icon}
                  />
                )}
                {copy && <h4>{copy}</h4>}
              </div>
            </div>
          ) : null
        }
        {
          asset && (
            <Asset
              imageProps={{
                outerWrapperClassName: withCssModulesClassNamesHandler(
                  'content-card__wrapper-image',
                ),
              }}
              asset={asset}
            />
          )
        }
      </Wrapper>
    </div>
  );
};

ContentCard.defaultProps = {
  link: undefined,
  copy: undefined,
  icon: undefined,
  asset: undefined,
  tabIndex: undefined,
  columnSpan: undefined,
  alignHorizontally: undefined,
  backgroundCopyColor: undefined,
  visibilityClassNames: undefined,
  productCarouselLifestyleCard: false,
};

ContentCard.propTypes = {
  copy: PropTypes.string,
  tabIndex: PropTypes.number,
  columnSpan: PropTypes.string,
  link: PropTypes.shape(linkPropTypes),
  backgroundCopyColor: PropTypes.string,
  icon: PropTypes.shape(assetPropTypes),
  visibilityClassNames: PropTypes.string,
  asset: PropTypes.shape(assetPropTypes),
  productCarouselLifestyleCard: PropTypes.bool,
  alignHorizontally: PropTypes.oneOf(['Left', 'Right']),
};

export default ContentCard;
