import { useState } from 'react';
import { withCssModulesClassNames } from '../../../common/nextMigrationHelpers';
import CallToAction from '../CallToAction';
import { default as styles } from '../../../scss/components/Header.module.scss';
import { Asset } from './header.types';
import AssetComponent from '../Asset';
import { CallToAction as CallToActionType } from '../../../types/common';

const withCssModulesClassNamesHandler = withCssModulesClassNames(styles);

export type FeatureBoxProps = {
  asset?: Asset,
  callToAction?: CallToActionType | null,
  color?: string,
  hoverColor?: string,
  sectionHeader?: string,
  text?: string | null,
};

const FeatureBox = ({
  asset,
  callToAction,
  sectionHeader,
  text,
  color,
  hoverColor,
}: FeatureBoxProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <div
      className={withCssModulesClassNamesHandler('header-menu-link-container')}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ color: isHovered ? hoverColor : color }}
    >
      {sectionHeader && <div>{sectionHeader}</div>}
      {asset && (
        <AssetComponent
          asset={asset}
          outerWrapperClassName={withCssModulesClassNamesHandler('graphcms-image-outer-wrapper')}
          imageClassName={withCssModulesClassNamesHandler('link-img', 'images')}
        />
      )}
      {text}
      {callToAction && (
        <CallToAction
          callToAction={callToAction}
          additionalClassName={withCssModulesClassNamesHandler('link-subtitle', 'call-to-action')}
        />
      )}
    </div>
  );
};

export default FeatureBox;
