/* eslint-disable max-classes-per-file */
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Asset from './Asset';
import UniversalLink from './UniversalLink';

import { fetchProductDetails } from '../../store/actions/product.actions';
import withModal from '../../common/WithModal';
import { withCssModulesClassNames } from '../../common/nextMigrationHelpers';
import { default as styles } from '../../scss/components/Modal.module.scss';

const withCssModulesClassNamesHandler = withCssModulesClassNames(styles);

class Details extends Component {
  constructor(props) {
    super(props);

    this.modal = {
      containerClassName: withCssModulesClassNamesHandler('modal-details', props.containerClassName),
      containerStyle: {
        backgroundColor: props.backgroundColor && props.backgroundColor.css
          ? props.backgroundColor.css
          : '#F1DBBC',
      },
    };
  }

  static imageWrapper({ children }) {
    return (
      <div>
        {children}
      </div>
    );
  }

  static videoWrapper({ children }) {
    return (
      <div className={withCssModulesClassNamesHandler('modal-video')}>
        {children}
      </div>
    );
  }

  render() {
    const {
      links,
      asset,
      componentToRender,
      onClose,
      open,
    } = this.props;

    return componentToRender
      ? componentToRender(onClose)
      : (
        <>
          <Asset
            asset={asset}
            imageWrapper={Details.imageWrapper}
            videoWrapper={Details.videoWrapper}
            autoPlay={open}
          />
          <div className={withCssModulesClassNamesHandler('modal-msg')}>
            <h4>{this.props.header}</h4>
            <p className={withCssModulesClassNamesHandler('copy', 'short-description')}>{this.props.popUpCopy}</p>
            <p className={withCssModulesClassNamesHandler('footnote')}>{this.props.footnote}</p>
            {links.map((link, i) => (link.type?.toLowerCase() === 'product' ? (
              <div
                key={i}
                onClick={() => {
                  this.props.fetchProductDetailsAction({
                    ...this.props,
                    sku: link?.destinationPage?.product?.sku,
                  });
                }}
                className={withCssModulesClassNamesHandler(
                  'pointer',
                  { [link.textColor?.toLowerCase()]: link.textColor },
                )}
              >
                {link.text}
              </div>
            ) : (
              <UniversalLink
                type={link.type}
                id={link.id}
                key={i}
                to={`${link.destinationPage.url}`}
                className={withCssModulesClassNamesHandler(
                  { [link.textColor?.toLowerCase()]: link.textColor },
                )}
              >
                {link.text}
              </UniversalLink>
            )))}
          </div>
        </>
      );
  }
}
Details.defaultProps = {
  links: [],
  open: false,
  shippingEstimate: '',
};

Details.propTypes = {
  open: PropTypes.bool,
  teaserCopy: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  teaserIcon: PropTypes.object,
  shippingEstimate: PropTypes.string,
  popUpCopy: PropTypes.string,
  footnote: PropTypes.string,
  componentToRender: PropTypes.func,
  header: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      textColor: PropTypes.string,
      destinationPage: PropTypes.shape({
        url: PropTypes.string,
      }),
    }).isRequired,
  ),
};

const mapDispatchToProps = {
  fetchProductDetailsAction: fetchProductDetails,
};

const DetailsModal = withModal(Details);

class DetailsModalWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: props.open,
    };

    this.togglePopUp = this.togglePopUp.bind(this);
    this.renderTeaser = this.renderTeaser.bind(this);
  }

  togglePopUp() {
    this.setState({
      open: !this.state.open,
    });
    const { onClose } = this.props;
    if (typeof onClose === 'function') {
      onClose();
    }
  }

  renderTeaser() {
    const {
      teaserCopy,
      teaserRenderer,
      teaserIcon,
      shippingEstimate,
      teaserColor,
      couponCode,
    } = this.props;
    const styles = {};
    if (teaserColor) {
      styles.color = teaserColor.css;
    }
    if (shippingEstimate) {
      return (
        <div
          aria-label={teaserCopy}
          onClick={this.togglePopUp}
        >
          {shippingEstimate}
        </div>
      );
    }
    if (teaserIcon && teaserIcon.url) {
      return (
        <div
          className={withCssModulesClassNamesHandler('modal-icon')}
          style={{ backgroundImage: `url(${teaserIcon.url})` }}
          aria-label={teaserCopy}
          onClick={this.togglePopUp}
        />
      );
    }
    if (typeof teaserCopy === 'string') {
      return (
        <span
          className={withCssModulesClassNamesHandler('modal-text', {
            'modal-text--sale-link': couponCode,
          })}
          style={styles}
          onClick={this.togglePopUp}
          dangerouslySetInnerHTML={{
            __html: couponCode
              ? teaserCopy.replace(couponCode, `<span class="modal-text__coupon-code">${couponCode}</span>`)
              : teaserCopy,
          }}
        />
      );
    }
    if (teaserRenderer) {
      return teaserRenderer(this.togglePopUp);
    }
    return (
      <span
        className={withCssModulesClassNamesHandler('modal-text')}
        style={styles}
        onClick={this.togglePopUp}
      >
        {teaserCopy}
      </span>
    );
  }

  render() {
    const {
      teaserCopy,
      teaserRenderer,
      teaserIcon,
    } = this.props;
    const {
      open,
    } = this.state;

    return (
      <React.Fragment>
        {(teaserCopy || teaserRenderer || teaserIcon?.url) && this.renderTeaser()}
        <DetailsModal
          {...this.props}
          open={open}
          onClose={this.togglePopUp}
        />
      </React.Fragment>
    );
  }
}

export default connect(null, mapDispatchToProps)(DetailsModalWrapper);
