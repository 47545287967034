const prefix = '[ORDER]';

export const FETCH_ORDER_HISTORY = `${prefix} FETCH_ORDER_HISTORY`;
export const FETCH_ORDER_HISTORY__REQUEST = `${prefix} FETCH_ORDER_HISTORY__REQUEST`;
export const FETCH_ORDER_HISTORY__SUCCESS = `${prefix} FETCH_ORDER_HISTORY__SUCCESS`;
export const FETCH_ORDER_HISTORY__FAILURE = `${prefix} FETCH_ORDER_HISTORY__FAILURE`;

export const fetchOrderHistory = () => ({
  type: FETCH_ORDER_HISTORY,
});

export const fetchOrderHistoryRequest = () => ({
  type: FETCH_ORDER_HISTORY__REQUEST,
});

export const fetchOrderHistorySuccess = (payload) => ({
  type: FETCH_ORDER_HISTORY__SUCCESS,
  payload,
});

export const fetchOrderHistoryFailure = (payload) => ({
  type: FETCH_ORDER_HISTORY__FAILURE,
  payload,
});
