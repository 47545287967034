import React, { Component } from 'react';
import { withFormsy } from 'formsy-react';

export class Checkbox extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    if (this.props.checked) {
      this.props.setValue(this.props.checked);
    }
  }

  onChange(event) {
    const val = event.currentTarget.checked;
    this.props.setValue(val);
    if (this.props.onChange) {
      this.props.onChange(val);
    }
  }

  render() {
    const {
      showRequired,
      showError,
      errorMessage,
      value: checked = false,
      name,
      extraClass,
      id,
      label,
      withinAnExternalLabel,
      disabled,
    } = this.props;
    let className = null;

    if (showRequired) {
      className = 'required';
    } else if (showError) {
      className = 'error';
    }

    const Wrapper = withinAnExternalLabel ? 'div' : 'label';

    return (
      <div className={`${className || ''} checkbox form-fancy ${checked ? 'checked' : 'not-checked'}`}>
        <Wrapper>
          <input
            type="checkbox"
            name={name}
            onChange={this.onChange}
            className={extraClass}
            checked={checked}
            data-checked={checked}
            id={id}
            disabled={disabled}
          />
          <span className="checkbox-icon-wrapper">
            <svg viewBox="0 0 100 100" version="1.1">
              <path className="st0" d="M42.4,86.2c-0.9,0-1.8-0.4-2.5-1L18.4,63.6c-1.4-1.4-1.4-3.6,0-4.9c1.4-1.4,3.6-1.4,5,0L41.6,77l34-61 c0.9-1.7,3.1-2.3,4.8-1.4c1.7,0.9,2.3,3.1,1.4,4.8L45.4,84.4c-0.5,1-1.5,1.6-2.6,1.8C42.7,86.2,42.6,86.2,42.4,86.2z"/>
            </svg>
          </span>
          {errorMessage || label}
        </Wrapper>
      </div>
    );
  }
}

export default withFormsy(Checkbox);
