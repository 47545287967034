import {
  shape,
  number,
  string,
  arrayOf,
  oneOf,
} from 'prop-types';

/* eslint-disable import/prefer-default-export */

export const ColorProps = shape({
  css: string,
  hex: string,
  rgba: shape({
    r: number,
    g: number,
    b: number,
    a: number,
  }),
});

const AssetSourceType = shape({
  order: number,
  source: string,
  type: string,
  maxWidth: number,
});

const AssetSourcesType = arrayOf(AssetSourceType);

export const AssetProps = shape({
  alt: string,
  fileName: string,
  url: string,
  handle: string,
  mimeType: string,
  width: number,
  height: number,
  externalAssetUrl: string,
  screenSize: string,
  assetSources: AssetSourcesType,
});

export const StyleProps = shape({
  value: string,
  property: string,
  selector: string,
  breakPoint: number,
});

export const RichTextProps = shape({
  html: string,
  markdown: string,
  text: string,
});

export const LinkProps = shape({
  id: string,
  asset: shape(AssetProps),
  buttonColor: ColorProps,
  color: ColorProps,
  text: string,
  order: number,
  href: string,
  richText: shape(RichTextProps),
  className: string,
  type: string,
  anchor: string,
  queryParams: string,
  destinationPage: shape({
    url: string,
  }),
  horizontalAlign: string,
});

export const GalleryItemProps = shape({
  sort: number,
  type: oneOf(['Asset', 'Video']),
  images: arrayOf(AssetProps),
});

const AddOnProductOptionProps = shape({
  optionId: number,
  optionValue: number,
  type: string,
  value: string,
});

const AddOnProductProps = shape({
  id: number,
  sku: string,
  skuID: number,
  optionValues: arrayOf(AddOnProductOptionProps),
});

export const AddOnProps = shape({
  label: string,
  nameAppend: string,
  priceAdjust: number,
  quantity: number,
  product: AddOnProductProps,
});
