import { gql, useQuery } from '@apollo/client';

import ApolloClient from '../../../../../Apollo/ApolloClient';
import { SearchDropdownPopularLinksProps } from './SearchDropdownPopularLinks';

export const POPULAR_LINKS_QUERY = gql`
  query popularLinks {
    popularLinks {
      id
      text
      destinationPage {
        url
      }
    }
  }
`;

const usePopularLinks = () => {
  const {
    data,
    loading,
  } = useQuery<{ popularLinks: SearchDropdownPopularLinksProps['popularLinks'] }>(POPULAR_LINKS_QUERY, {
    client: ApolloClient,
  });

  return {
    isLoading: loading,
    popularLinks: data?.popularLinks ?? [],
  };
};

export default usePopularLinks;
