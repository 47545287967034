import React, { useEffect } from 'react';
import throttle from 'lodash/throttle';
import dynamic from 'next/dynamic';
import { useDispatch } from 'react-redux';

import { setNavState } from '../../../store/actions/ui.actions';
import { withCssModulesClassNames } from '../../../common/nextMigrationHelpers';
import ParsedMarkdown from '../ParsedMarkdown';
import HeaderLink from './HeaderLink';
import { default as styles } from '../../../scss/components/Header.module.scss';
import FreeShippingIcon from './FreeShippingIcon';
import { CloseMenuParams, NavigationItem } from './header.types';

const withCssModulesClassNamesHandler = withCssModulesClassNames(styles);

// fix with TS stuff later
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const UtilityDropdownSection = dynamic(() => import('./UtilityDropdownSection'), { ssr: false });

export type UtilityNavigationProps = {
  navigation: NavigationItem[],
  isCartOpen: boolean,
  isFiltersPopoutOpen: boolean,
  isMobileMenuOpen: boolean,
  bannerContent?: string
  pinned: boolean,
  isDropdownOpen: boolean,
  dropdownStates: { [key: string]: boolean },
  closeMenu: (config?: CloseMenuParams) => void,
  toggleCurrentDropdown: (id: string, header: string, neighbors: string[]) => void,
  closeUtilityDropDown: () => void,
};

const UtilityNavigation = ({
  closeMenu,
  dropdownStates,
  pinned,
  toggleCurrentDropdown,
  navigation,
  isCartOpen,
  isFiltersPopoutOpen,
  isMobileMenuOpen,
  closeUtilityDropDown,
  bannerContent = '',
}: UtilityNavigationProps) => {
  const dispatch = useDispatch();
  const utilityLinks = navigation.filter(({ tier }) => tier === 'Utility');

  useEffect(() => {
    const actualSite = window.document.querySelector<HTMLDivElement>('.actual-site');
    const onScrollThrottled = throttle(() => {
      if (!actualSite || typeof actualSite.getBoundingClientRect !== 'function') return;

      const { top } = actualSite.getBoundingClientRect();

      if (pinned && top <= 0) {
        dispatch(setNavState({ pinned: false }));
        closeUtilityDropDown();
      } else if (!pinned && top > 0) {
        dispatch(setNavState({ pinned: true }));
      }
    }, 500);

    window.addEventListener('scroll', onScrollThrottled);

    return () => {
      window.removeEventListener('scroll', onScrollThrottled);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pinned]);

  return (
    <div
    data-testid="header-utility-navigation"
      className={withCssModulesClassNamesHandler(
        'header-utility-navigation',
        pinned ? 'pinned' : 'unpinned',
        {
          'with-popout': isCartOpen || isFiltersPopoutOpen || isMobileMenuOpen,
        },
      )}
      data-cy={`${pinned ? 'pinned' : 'unpinned'}-header-utility-navigation`}
    >
      <div className={withCssModulesClassNamesHandler('header-utility-navigation__banner')}>
        <FreeShippingIcon />
        <ParsedMarkdown options={{ forceBlock: true }}>
          {bannerContent || 'Furniture designed for modern life at home'}
        </ParsedMarkdown>
      </div>
      <div className={withCssModulesClassNamesHandler('header-utility-menu')}>
        {utilityLinks.map((link) => (link.navigationType === 'Dropdown' ? (
          <UtilityDropdownSection
            key={link.navigationId}
            link={link}
            toggleCurrentDropdown={toggleCurrentDropdown}
            pinned={pinned}
            navigation={navigation}
            dropdownStates={dropdownStates}
            closeMenu={closeMenu}
          />
        ) : (
          <HeaderLink
            closeMenu={closeMenu}
            link={link}
            key={link.navigationId}
            baseClass={withCssModulesClassNamesHandler('links-section-item')}
          />
        )))}
      </div>
    </div>
  );
};

export default UtilityNavigation;
