import HrefLang from '../shared/localization/hrefLang';

/**
 * TODO: https://hiburrow.atlassian.net/browse/BTR-1101
 * @deprecated
 * @description
 * This util class should be replaced with a service responsible to handle the localization.
 * URLs that are being pushed via FE should not be using BE services.
 * This class is only used in order to reduce the bundle size of the FE files.
 */
export default class LocalizationUtils {
  static readonly production: boolean = process.env.STORE === 'production';

  static anyHrefLangRegExp = /^\/([a-zA-Z]{2}-[a-zA-Z]{2})/;

  static readonly I18N_CONFIG_LOOKUP = {
    [HrefLang.EN_US]: {
      currencyCode: 'USD',
    },
    [HrefLang.EN_CA]: {
      currencyCode: 'CAD',
    },
  };

  static getI18nConfig(hrefLang: HrefLang) {
    return this.I18N_CONFIG_LOOKUP[hrefLang];
  }

  static isLocalized(hrefLang: HrefLang) {
    if (hrefLang === HrefLang.EN_US) return false;
    return Boolean(this.getI18nConfig(hrefLang));
  }

  static getAnyHrefLangFromUrl(url: string) {
    const [, urlLocale] = this.anyHrefLangRegExp.exec(url) ?? [];
    return urlLocale;
  }
}
