import HrefLang from '../../../shared/localization/hrefLang';

const SLASH_SIGN = '/';
const DOUBLE_SLASH_REGEX = /\/\//;

/**
 * @description
 * In some specific edge cases, endpoint might return a double slash
 * That is the reason why we have to replace doubled slash signs before returning the value
 * @param hrefLang
 * @param endpoint
 */
function generateUrlWithHrefLang({ hrefLang, endpoint }: { hrefLang: HrefLang, endpoint: string }): string {
  const prependedHrefLangValue = hrefLang === HrefLang.EN_US ? '' : hrefLang;
  const endpointSplit = endpoint.split(SLASH_SIGN).filter(Boolean);

  const urlWithoutPrecedingBackslash = [prependedHrefLangValue, ...endpointSplit].join(SLASH_SIGN);
  const urlWithDuplicatedBackslash = `${SLASH_SIGN}${urlWithoutPrecedingBackslash}`;

  return urlWithDuplicatedBackslash.replace(DOUBLE_SLASH_REGEX, SLASH_SIGN);
}

export default generateUrlWithHrefLang;
