import qs from 'qs';
import {
  ModifierOption,
  ModifierOptionDto,
  OnSelectFunction,
  ProductModifierDto,
} from '../types/schema';
import { splitSku } from '../../../utils/Utils';
import {
  getOptionRelatedProduct,
  isAttachAddonItemModifier,
  isUpdateAddonItemVariantModifier,
} from '../components/global/ProductHelpers';
import CMSModifierDto, { ModifierOptionDto as CMSModifierOptionDto } from '../../../Product/dto/modifier.dto';
import BaseVariant from '../../../Product/Variant';
import FormattedBigCommerceRelatedProductDto from '../../../shared/bigCommerce/formattedBigCommerceRelatedProduct.dto';
import PricingObjectDto from '../../../Product/dto/pricingObject.dto';
import { Nullable } from '../types/common';

/**
 * extract selected modifier option value and its count from query params
 * @param queryParams query params object
 * @param modifier product modifier
 * @returns selected option value and its count if specified
 */
export const getSelectedModifierValueAndCountFromQueryParams = (
  queryParams: ReturnType<typeof qs.parse>,
  modifier?: ProductModifierDto,
): { value: string; count?: number; } => {
  const queryParamValue: string | undefined = modifier && typeof queryParams[modifier.slug] === 'string'
    ? queryParams[modifier.slug] as string
    : undefined;

  if (modifier?.modifierOperation === 'AttachAddonItem' && modifier.modifierStyle === 'Counter') {
    const [countString, ...restArray] = queryParamValue?.split('-') || [];

    return {
      value: restArray.join('-') || undefined,
      count: +(countString || 0),
    };
  }

  return {
    value: queryParamValue || '',
  };
};

const parentOfAddOnVariantModifierMap: Record<string, string> = {
  'round-tray-color': 'round-tray',
  'table-finish': 'table-add-on',
  'headboard-color': 'headboard-add-on',
  'headboard-3-color': 'headboard-3-add-on',
};

const getAddOnVariantModifierValueFromParentQueryParam = (slug: string, optionValue: string, parentQueryParamValue: string): string | undefined => {
  switch (slug) {
    case 'round-tray-color':
      return parentQueryParamValue?.slice(-2)?.toLowerCase();
    case 'table-finish':
      return parentQueryParamValue?.slice(-4, -2)?.toLowerCase();
    case 'headboard-color':
    case 'headboard-3-color':
      return parentQueryParamValue?.slice(optionValue.length * -1)?.toLowerCase();
    default:
      return undefined;
  }
};

export const getIsOptionSelected = (
  slug: string,
  productModifiers: ProductModifierDto[],
  queryParams: ReturnType<typeof qs.parse>,
  option: ModifierOption,
  selectedModifiers: { [key: string]: string },
  skuSplit: Record<string, any>,
): boolean => {
  const modifier = productModifiers?.find((productModifier) => productModifier.slug === slug);
  const { value } = getSelectedModifierValueAndCountFromQueryParams(queryParams, modifier);
  const queryParamsValue = value?.toLowerCase();
  const optionValue = option.value?.toLowerCase() || '';
  const optionLabel = option.label?.toLowerCase() || '';
  const selectedModifier = selectedModifiers?.[slug]?.toLowerCase() || '';

  if ((queryParamsValue && queryParamsValue === optionValue)
    || (selectedModifier && selectedModifier === optionLabel)) {
    return true;
  }

  switch (slug) {
    case 'tufted':
      return !queryParamsValue && optionValue === 'tf';
    case 'door':
      return !queryParamsValue && optionValue === 'std';
    case 'round-tray':
    case 'table-add-on':
    case 'headboard-add-on':
    case 'headboard-3-add-on':
      return queryParamsValue?.includes(optionValue);
    case 'round-tray-color':
    case 'table-finish':
    case 'headboard-color':
    case 'headboard-3-color': {
      const { value: parentQueryParamValue } = getSelectedModifierValueAndCountFromQueryParams(
        queryParams,
        productModifiers.find((productModifier) => productModifier.slug === parentOfAddOnVariantModifierMap[slug]),
      );

      return getAddOnVariantModifierValueFromParentQueryParam(slug, optionValue, parentQueryParamValue) === optionValue;
    }
    case 'cover': {
      const currentCover = skuSplit.cover?.toLowerCase() || '';
      // if cover is not cv0, then it is selected
      return currentCover !== 'cv0' && currentCover.includes(optionValue);
    }
    default:
      return false;
  }
};

export const getOptionCount = (
  slug: string,
  productModifiers: ProductModifierDto[],
  queryParams: ReturnType<typeof qs.parse>,
) => {
  const modifier = productModifiers?.find((productModifier) => productModifier.slug === (parentOfAddOnVariantModifierMap[slug] || slug));
  const { count } = getSelectedModifierValueAndCountFromQueryParams(queryParams, modifier);

  return count;
};

export const handleDunesScoutColorParsing = (productSku: string, setColorCode: string) => {
  const skuParts = productSku.split('-');
  skuParts[skuParts.length - 2] = setColorCode;
  return skuParts.join('-');
};

// TODO: Think about a better way to solve this color discrepancy.
export const setProductSkuColorParser = (setSku: string, productSku: string) => {
  const setColorCode = setSku.split('-').slice(-1)[0].slice(0, 3);
  const productColorCode = productSku.split('-').slice(-1)[0];

  if (['SAL', 'SHL'].indexOf(productColorCode.slice(0, 3)) === -1) {
    return handleDunesScoutColorParsing(productSku, setColorCode);
  }

  return productSku.replace(productColorCode, `${setColorCode}${productColorCode.slice(3)}`);
};

const getModifierSlugFromSelectFn = (selectFn?: OnSelectFunction) => {
  switch (selectFn) {
    case 'SetMaterialColor':
      return 'color';
    case 'SetColor':
      return 'color';
    case 'SetLegs':
      return 'legs';
    case 'SetArms':
      return 'arms';
    case 'SetSize':
      return 'size';
    default:
      return null;
  }
};

export const getSelectedProductModifierOptionsCb = (
  productModifiers: ProductModifierDto[],
  skuSplit: ReturnType<typeof splitSku>,
) => {
  if (!(productModifiers && Array.isArray(productModifiers))) return [];
  const skuArr = Object.values(skuSplit || []);
  const result: ModifierOptionDto[] = [];
  productModifiers.forEach((modifier) => {
    const { options } = modifier;
    const selectedModifierOptions = options.filter((option) => {
      const modifierSlug = getModifierSlugFromSelectFn(
        modifier.onSelectFunction,
      );
      const includesCondition = modifierSlug
        ? skuSplit[modifierSlug] === option.value
        : skuArr.includes(option.value);

      return option.selected === true || includesCondition;
    });
    if (selectedModifierOptions.length) result.push(...selectedModifierOptions);
  });
  return result;
};

export interface AddOnOption extends CMSModifierOptionDto {
  slug: CMSModifierDto['slug'];
  requiredActiveModifierOptions: CMSModifierDto['requiredActiveModifierOptions'];
}

export const getAddOnOptionsHandler = (productModifiers: Nullable<CMSModifierDto[]>) => {
  const addOnOptions: AddOnOption[] = [];
  if (!productModifiers) {
    return addOnOptions;
  }

  productModifiers
    .filter((modifier) => (
      isAttachAddonItemModifier(modifier)
      || isUpdateAddonItemVariantModifier(modifier)
    ))
    .forEach(({ options, slug, requiredActiveModifierOptions }) => {
      options.forEach((option) => {
        addOnOptions.push({ ...option, slug, requiredActiveModifierOptions });
      });
    });

  return addOnOptions;
};

const addRelatedProductToOption = ({
  relatedProducts,
  skuSplit,
}: Pick<BaseVariant, 'relatedProducts' | 'skuSplit'>) => (
  option: AddOnOption,
): AddOnOption & { product?: FormattedBigCommerceRelatedProductDto } => ({
  ...option,
  product: getOptionRelatedProduct({
    slug: option.slug,
    relatedProducts,
    value: option.value,
    activeColor: skuSplit.color,
    activeLegs: skuSplit.legs,
    activeLegStyle: skuSplit.legStyle,
    activeSize: skuSplit.size,
    activeMaterial: skuSplit.material,
  }),
});

const generateAddOnPricing = (
  variantPrice: Nullable<number>,
  pricing: Nullable<PricingObjectDto>,
  discount: Nullable<number>,
  quantity: Nullable<number>,
) => {
  const qty = quantity || 1;
  const price = pricing?.price?.raw || variantPrice;
  const originalPrice = pricing?.originalPrice?.raw || price;
  const calculatedPrice = discount && originalPrice ? originalPrice - discount : price;

  return originalPrice && calculatedPrice ? {
    pricing: {
      originalPrice: originalPrice * qty,
      price: calculatedPrice * qty,
    },
  } : {};
};

export const addProductPricingToOption = (
  (getVariantBySKUCb: (sku: string) => Promise<BaseVariant>) => (
    async ({
      product,
      value,
      addOnBundleDiscount,
      priceAdjust,
      quantity,
    }: ReturnType<ReturnType<typeof addRelatedProductToOption>>) => {
      let variantPrice = priceAdjust;
      let pricing: Nullable<PricingObjectDto> = null;

      if (product && typeof addOnBundleDiscount === 'number') {
        ({ price: variantPrice, pricing } = await getVariantBySKUCb(product.sku).catch((e) => {
          if (e.message?.match(/Variant not found with SKU/)) {
            return { price: priceAdjust, pricing: null };
          }
          throw e;
        }));
      }

      const addOnPricing = generateAddOnPricing(variantPrice, pricing, addOnBundleDiscount, quantity);

      return {
        value,
        ...addOnPricing,
      };
    }
  )
);

export const reducerAddOnOptionsWithPricing = (
  {
    relatedProducts,
    skuSplit,
    getVariantBySKUCb,
  }: Pick<BaseVariant, 'relatedProducts' | 'skuSplit'> & {
    getVariantBySKUCb: (sku: string) => Promise<BaseVariant>;
  },
) => (
  acc: ReturnType<ReturnType<typeof addProductPricingToOption>>[],
  option: AddOnOption,
) => {
  const newOption = addRelatedProductToOption({
    relatedProducts,
    skuSplit,
  })(option);

  acc.push(addProductPricingToOption(getVariantBySKUCb)(newOption));

  return acc;
};

export const updateModifierOptionWithPricing = (
  (optionsWithPrice: Pick<ModifierOptionDto, 'value' | 'pricing'>[]) => (
    (option: CMSModifierOptionDto) => {
      const matchingOption = optionsWithPrice.find(
        ({ value }) => value === option.value,
      );
      const pricing = matchingOption && matchingOption.pricing;
      return {
        ...option,
        ...(pricing && { pricing }),
      };
    }
  )
);
