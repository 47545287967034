import { call, put, takeLatest } from 'redux-saga/effects';
import { gql } from '@apollo/client/core';
import {
  FETCH_REVIEWS_AVERAGE_RATING,
  fetchReviewsAverageRatingFailure,
  fetchReviewsAverageRatingRequest,
  fetchReviewsAverageRatingSuccess,
} from '../actions/reviews.actions';
import RequestHelpers from '../../components/global/RequestHelpers';
import { logError } from '../../../../utils/Utils';

export function* fetchReviewsAverageRating() {
  yield put(fetchReviewsAverageRatingRequest());

  try {
    const query = gql`query GetReviewsAverage {
        reviewsAverage,
      }`;
    const { reviewsAverage } = yield call(() => RequestHelpers.apolloGqlQuery(query));

    yield put(fetchReviewsAverageRatingSuccess(reviewsAverage));
  } catch (error) {
    yield call([this, logError], error, fetchReviewsAverageRating.name);
    yield put(fetchReviewsAverageRatingFailure({ error }));
  }
}

const reviewsSagas = [
  takeLatest(FETCH_REVIEWS_AVERAGE_RATING, fetchReviewsAverageRating),
];

export default reviewsSagas;
