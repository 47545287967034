import {
  INITIALIZE_COLLECTIONS_DATA,
  FETCH_COLLECTIONS_REQUEST,
  FETCH_COLLECTIONS_SUCCESS,
  FETCH_COLLECTIONS_FAILURE,
  SET_ACTIVE_FILTER,
  SET_AVAILABLE_FILTERS,
  SET_SELECTED_LAYOUT,
} from '../actions/collections.actions';

export const initialState = {
  isPending: false,
  error: null,
  activeFilters: [],
  availableFilters: {},
  selectedLayout: null,
};

const initializeCollectionsData = (state, data) => ({
  ...state,
  data,
});

const fetchCollectionsRequest = (state, path) => ({
  ...state,
  path,
  isPending: true,
  error: null,
});

const fetchCollectionsSuccess = (state, collections) => ({
  ...state,
  data: [...collections],
  isPending: false,
  error: null,
});

const fetchCollectionsFailure = (state, error) => ({
  ...state,
  error,
  isPending: false,
});

const setActiveFilter = (state, selectedFilter) => {
  if (!selectedFilter) {
    return {
      ...state,
      activeFilters: [],
    };
  }
  const { activeFilters = [] } = state;

  return {
    ...state,
    activeFilters: activeFilters.includes(selectedFilter)
      ? activeFilters.filter((filter) => filter !== selectedFilter)
      // FIXME: Re-enable activeFilters + selectedFilter when we want to filter on multiple categories
      // https://app.hive.com/workspace/BPQTWhi9hPh6N6usf?projectId=tyfaBvJGy5gZbfXz4&actionId=mALKQYnCMtPpFHc7o
      : [selectedFilter],
  };
};

const setAvailableFilters = (state, newAvailableFilters) => {
  const {
    availableFilters,
    activeFilters = [],
  } = state;

  if (typeof newAvailableFilters === 'string') {
    if (availableFilters?.[newAvailableFilters]) delete availableFilters[newAvailableFilters];

    return {
      ...state,
      availableFilters,
    };
  }

  const mergedFilters = {
    ...availableFilters,
    ...newAvailableFilters,
  };

  return {
    ...state,
    availableFilters: !newAvailableFilters && !activeFilters.length ? {} : mergedFilters,
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case INITIALIZE_COLLECTIONS_DATA:
      return initializeCollectionsData(state, action.payload);

    case FETCH_COLLECTIONS_REQUEST:
      return fetchCollectionsRequest(state, action.payload);

    case FETCH_COLLECTIONS_SUCCESS:
      return fetchCollectionsSuccess(state, action.payload);

    case FETCH_COLLECTIONS_FAILURE:
      return fetchCollectionsFailure(state, action.payload);

    case SET_ACTIVE_FILTER:
      return setActiveFilter(state, action.payload);

    case SET_AVAILABLE_FILTERS:
      return setAvailableFilters(state, action.payload);

    case SET_SELECTED_LAYOUT:
      return { ...state, selectedLayout: action.payload };

    default:
      return state;
  }
};
