import { Dispatch, SetStateAction } from 'react';
import { SkuParseDto } from '../../../../shared/graphCms/skuParse.dto';
import { getVariantStateParams, ProductCardState, ProductCardProps } from './ProductCard';
import { generateSku, getVariantBySKU } from '../global/ProductHelpers';
import { splitSku } from '../../../../utils/Utils';

type ProductCardSetState = Dispatch<SetStateAction<ProductCardState>>;
type ProductParams = Pick<ProductCardProps, 'sku' | 'addOnItems' | 'flipSetting' | 'tuftedSetting'>;

export const setupProduct = (
  {
    sku,
    addOnItems,
    flipSetting,
    tuftedSetting,
  }: ProductParams,
  callback: ProductCardSetState,
) => {
  getVariantBySKU(sku, addOnItems)
    .then((variant) => {
      if (variant) {
        callback((prevState) => ({
          ...prevState,
          ...getVariantStateParams({
            item: variant,
            sku,
            flipSetting,
            tuftedSetting,
            addOnItems,
          }),
        }));
      }
    })
    .catch((e) => {
      console.error(e, setupProduct.name);
    });
};

export const updateAddOnsSkus = (
  addOnItems: ProductCardProps['addOnItems'],
  value: string,
  onSelect?: ProductCardState['onSelect'],
  skuParse?: SkuParseDto,
) => {
  if (!onSelect || !addOnItems) {
    return addOnItems;
  }

  return addOnItems.map((addOnSku) => {
    if (addOnSku.includes('BRBD-HB-CI3-QN-')) {
      return generateSku({
        [onSelect]: value.slice(-2),
      }, splitSku(addOnSku), skuParse) || addOnSku;
    }
    return addOnSku;
  });
};

export const onChooseColorOption = (
  state: ProductCardState,
  setState: ProductCardSetState,
  productCardOptionSelected: (action: string) => void,
  {
    addOnItems,
    flipSetting,
    tuftedSetting,
  }: Pick<ProductCardProps, 'addOnItems' | 'flipSetting' | 'tuftedSetting'>,
  skuParse?: SkuParseDto,
) => async (value: string) => {
  const {
    onSelect,
    item: {
      skuSplit,
    },
  } = state;
  const skuToSetup = generateSku({
    [onSelect ?? '']: value,
  }, skuSplit, skuParse);

  if (skuToSetup) {
    const addOnsToSetup = updateAddOnsSkus(addOnItems, value, onSelect, skuParse);
    await setupProduct({
      sku: skuToSetup,
      addOnItems: addOnsToSetup,
      flipSetting,
      tuftedSetting,
    }, setState);
    productCardOptionSelected('Option');
  }
};
