import { ModalDto } from '../types/schema';

export const ignoreErrors = [
  'Failed to fetch',
  'cancelled',
  'cannot parse response',
  'The operation couldn’t be completed. Protocol error',
  'Could not connect to the server.',
  'SecurityError: The operation is insecure.',
  'Maximum call stack size exceeded.',
  // eslint-disable-next-line quotes
  "SecurityError: Failed to read the 'localStorage' property from 'Window': Access is denied for this document.",
  'InvalidStateError: The object is in an invalid state.',
  'Non-Error promise rejection',
  'SyntaxError: The string did not match the expected pattern',
  'NS_ERROR',
  'showCollectionLink',
  'isErrorEventCalled',
  'Could not connect to the server.',
  'Unexpected token < in JSON at position 0',
  'ResizeObserver loop limit exceeded',
  /Failed to load https:\/\/www\.googletagmanager\.com\/gtag/,
  /Loading CSS chunk/,
];

export const blacklistUrls = [
  // Facebook flakiness
  /graph\.facebook\.com/i,
  /assets\.bounceexchange\.com/i,
  /cdn\.kustomerapp\.com/i,
  // Facebook blocked
  /connect\.facebook\.net\/en_US\/all\.js/i,
  // Woopra flakiness
  /eatdifferent\.com\.woopra-ns\.com/i,
  /static\.woopra\.com\/js\/woopra\.js/i,
  // Chrome extensions
  /extensions\//i,
  /^chrome:\/\//i,
  // Other plugins
  /127\.0\.0\.1:4001\/isrunning/i,  // Cacaoweb
  /webappstoolbarba\.texthelp\.com\//i,
  /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
];

export const general = {
  detectedIE:
    'It looks like you\'re using Internet Explorer, which we no longer support. For stronger security and a better experience, we recommend updating to a modern browser like Microsoft Edge, Mozilla Firefox, or Google Chrome.',
};

export const auxillary = {
  faq: {
    header: 'FAQ',
    subHeader: 'Don\'t hesitate to {contact}. We\'re here to help.',
  },
  returns: {
    header: 'Returns',
  },
  terms: {
    header: 'Terms & Conditions',
    subHeader:
      'Please take a moment to familiarize yourself with our terms and {contact} if you have any questions.',
  },
  termsReferral: {
    header: 'Refer-a-Friend Terms & Conditions',
    subHeader:
      'Please take a moment to familiarize yourself with our terms and {contact} if you have any questions.',
  },
  accessibility: {
    header: 'Accessibility',
    subHeader: '',
  },
  reviews: {
    header: 'Reviews',
    subHeader:
      'These verified reviews all come from people who actually purchased a Burrow product.',
    copy: ' of 5 Stars',
  },
  careers: {
    positions: {
      apply: 'Apply here',
    },
  },
};

export const PLPNav = {
  filter: 'Filter:',
};

export const accountHistory = {
  shippingAddress: {
    header: 'Shipping Address',
  },
  billingAddress: {
    header: 'Billing Information',
  },
};
export const swatches = {
  success: 'Thanks, your swatch request is being processed!',
};

export const orderStatus = {
  pulling: {
    header: 'Pulling up your order!',
    message: 'Just one moment while we get your order status.',
  },
};

export const cartErrors = {
  NO_PROMO_CODE: 'You did not enter a promo code.',
};

export const orderErrors = {
  EMAIL_ADDRESS_DOES_NOT_MATCH:
    'The information you provided does not match our records.',
  NOT_FOUND: 'Sorry, we were unable to find that order.',
  ERROR:
    'There was a problem looking up this order, please contact support@burrow.com.',
  MOBILE_CANCELED: 'You canceled, please select another payment method.',
};

export const filters = {
  toggleCopy: 'Filters',
  header: 'Filters',
  configurationsSectionHeader: 'Configurations',
  colorSectionHeader: 'Color',
  clearButtonCopy: 'Clear',
  selectColorSubheader: 'Select a color option',
  seatingCollectionsHeader: 'Seating Collections',
  applyCopy: 'Apply',
  resultsCopy: 'Results',
};

export const materials = {
  all: 'All Materials',
  fabric: 'Fabric',
  velvet: 'Velvet',
  leather: 'Leather',
};

export const configurations = {
  armchairs: 'Accent Chairs',
  chaiseSectionals: 'Chaise Sectionals',
  sofas: 'Sofas',
  loveseats: 'Loveseats',
  cornerSectionals: 'Corner Sectionals',
  ottomans:'Ottomans',
  uSectionals: 'U Sectionals',
  sleepers: 'Sleepers',
};

export const cart = {
  savings_cart: 'Savings shown in cart',
  extra_savings_cart: 'Extra savings shown in cart',
  your_cart: 'Your Cart',
  empty: 'Your cart is currently empty',
  calculating: 'Calculating...',
  total: 'Total',
  discountTypes: [
    {
      type: 'bundleSaving',
      title: 'Bundle Savings',
      class: 'bundle-savings',
    },
    {
      type: 'cartLevel',
      title: 'Cart Savings',
      class: 'cart-savings',
    },
  ],
  shipping: 'Shipping',
  free_shipping: 'FREE',
  saleSavings: 'Sale Savings',
  subtotal: 'Subtotal',
  apply_promo: 'Apply promo code',
  invalid_promo: 'Promo code is invalid or expired.',
  valid_promo: 'Promo Applied',
  promo_input_apply: 'Apply',
  recommendation: 'You might like:',
  tax: 'Tax',
};

export const footer = {
  address: '15 W 27th Street, 10th Floor New York, NY, 10001',
};

export const cartThermometer = {
  tierDistanceCopy: 'You are $distance away from $nextTierSavings savings!',
  maxSavingsCopy: 'Congratulations $savings off, you\'ve maximized savings!',
};

export const productModifiers = {
  shipping_message: {
    text: 'Avg. delivery time 5-7 business days',
    url: '/free-shipping',
  },
};

export const orderDetails = {
  your_order_details: 'Your order details',
  shipping_status: 'Status',
  status: {
    paid: 'Processing',
    Paid: 'Processing',
    refunded: 'Refunded',
    partial: 'Awaiting Fulfillment',
    partially_refunded: 'Partially Refunded',
    cancelled: 'Cancelled',
    partially_paid: 'Partially Paid',
    'Partially Shipped': 'Awaiting Fulfillment',
    InProduction: 'In production',
    Pending: 'Ready to send',
    InfoReceived: 'Ready to send',
    InTransit: 'In-transit',
    OutForDelivery: 'Out for delivery',
    AttemptFail: 'Failed attempt',
    Delivered: 'Delivered',
    AvailableForPickup: 'Available for pickup',
    Exception: 'Exception',
    Expired: 'Shipment expired',
  },
  send_paylink: 'Resend payment link',
  paylink_sent: 'Payment link sent',
};

export const popup = {
  offline_message: 'You\'re offline.',
};

export const restockModals: ModalDto[] = [
  {
    header: 'Get the good word when we restock',
    copy:
      'Enter your email below to be notified when this item is back in stock:',
    footnote: 'You can unsubscribe at any time.',
    buttonCopy: [
      'Also subscribe to news about new products, exclusive sales, and more.',
      'Continue',
    ],
    order: 1,
    assets: [],
  },
  {
    header: 'Want restock notifications via text?',
    copy: 'N/A',
    footnote:
      'You can unsubscribe at any time. Standard text message rates apply.\n',
    buttonCopy: ['TEXT ME', 'EMAIL IS FINE'],
    order: 2,
    assets: [
      {
        fileName: 'OOS-Text-Capture.png',
        url: 'https://media.graphassets.com/oXYzU3wWReidtm9qkMs3',
        handle: 'oXYzU3wWReidtm9qkMs3',
        mimeType: 'image/png',
        width: 261,
        height: 261,
        externalAssetUrl: null,
        videoSources: [],
      },
    ],
  },
  {
    header: 'We\'ll let you know as soon as that\'s back in stock',
    copy: 'N/A',
    footnote: null,
    buttonCopy: ['Continue shopping'],
    order: 3,
    assets: [
      {
        fileName: 'OOS-Email-Capture.png',
        url: 'https://media.graphassets.com/xE9PTmMOTaKxKXoRTTGa',
        handle: 'xE9PTmMOTaKxKXoRTTGa',
        mimeType: 'image/png',
        width: 455,
        height: 455,
        externalAssetUrl: null,
        videoSources: [],
      },
    ],
  },
];

export const restockButtonData = {
  __typename: 'Link',
  components: [
    {
      backgroundColor: '#383633',
      __typeName: 'Component',
      type: 'RestockNotificationsButton',
    },
  ],
  destinationPage: {
    __typeName: 'Page',
    url: '/free-shipping',
  },
  text: 'Get Notified',
};

export const shippingMessageData = {
  asset: {
    __typename: 'Asset',
    alt: 'Man moving a Burrow box into his home',
    assetSources: [],
    externalAssetUrl: null,
    fileName: 'Pilot-Modal-Free-Shipping.jpg',
    handle: 'E0DYyfgMTRaeKeMCrVBD',
    height: 667,
    mimeType: 'image/jpeg',
    screenSize: null,
    url: 'https://media.graphassets.com/E0DYyfgMTRaeKeMCrVBD',
    width: 1000,
  },
  teaserIcon: {
    __typename: 'Asset',
    alt: 'Question mark, clicking opens up free shipping modal',
    assetSources: [],
    externalAssetUrl: null,
    fileName: 'ICON-Question-Tooltip (1).svg',
    handle: 'Odi39FaSCUzgXJvnPslA',
    height: 0,
    mimeType: 'image/svg+xml',
    screenSize: null,
    url: 'https://media.graphassets.com/Odi39FaSCUzgXJvnPslA',
    width: 0,
  },
  popUpCopy: 'Unlike made-to-order furniture brands, we’re able to keep inventory on hand thanks to our modular design, which means your order gets to your door way faster (and in a normal shipping box, not expensive special freight).',
  header: 'Fast, affordable shipping',
};

export const productCollection = {
  show_more: 'Show More',
};

export const legColorsToWoodTones = {
  LW: 'OK',
  DW: 'WN',
};

export const woodTonesToLegColors = {
  FROK: 'LW',
  OK: 'LW',
  FRWN: 'DW',
  WN: 'DW',
};

export const haikuSeatsToWoodTones = {
  SG: 'WN',
  MG: 'WN',
  TN: 'OK',
  PY: 'OK',
};

export const altoSeatsToWoodTones = {
  SG: 'BM',
  MG: 'WN',
  TN: 'BM',
  PY: 'WN',
};

export const circaHeadboardToMaterial = {
  OK: 'WD',
  WN: 'WD',
  UTHC: 'UH',
  UTPY: 'UH',
  UTSG: 'UH',
};

export const circaFrameToHeadboard = {
  FROK: 'OK',
  FRWN: 'WN',
  FRHC: 'UTHC',
  FRPY: 'UTPY',
  FRSG: 'UTSG',
};

export const chorusColorToMaterial = {
  FROK: 'WD',
  FRWN: 'WD',
  FRSG: 'UH',
  FRHC: 'UH',
  FRPY: 'UH',
  OK: 'WD',
  WN: 'WD',
  SG: 'UH',
  HC: 'UH',
  PY: 'UH',
};

export const chorusFrameColorToHeadboardColor = {
  FROK: 'OK',
  FRWN: 'WN',
  FRSG: 'SG',
  FRHC: 'HC',
  FRPY: 'PY',
};

export const chorusOptionToSetVariable = {
  QN: 'setSize',
  KG: 'setSize',
  FRWN: 'setColor',
  FROK: 'setColor',
  FRSG: 'setColor',
  FRHC: 'setColor',
  FRPY: 'setColor',
  ST: 'setLegStyle',
  TP: 'setLegStyle',
  WN: 'setLegs',
  OK: 'setLegs',
};

export const filterCategoryToOrder = {
  sofas: 1,
  chaiseSectionals: 2,
  cornerSectionals: 3,
  uSectionals: 4,
  loveseats: 5,
  armchairs: 6,
  ottomans: 7,
  rugs: 8,
  tables: 9,
  pillows: 10,
  throws: 11,
  bundles: 12,
};

export const vesperMaterialToColor = {
  'UHW-L': 'SLWN',
  'UH-F': 'HC',
  'UHW-F': 'HCWN',
};

export const picaColorToMaterial = {
  JDV: 'V',
  MNV: 'V',
  IVB: 'B',
};

export const lodgeColorToMaterial = {
  IVF: 'F',
  NBF: 'F',
  SGF: 'F',
  SLL: 'L',
  TNL: 'L',
};

export const vesperColorToPillow = {
  SGWN: 'SG',
  HCWN: 'HC',
  PYOK: 'PY',
  SLWN: 'SL',
  TNOK: 'TN',
  MGWN: 'MG',
  NBWN: 'NB',
  IVWN: 'IV',
  SLBLK: 'SL',
};

export const checkout = {
  step1: 'Step 1. Shipping Address',
  step2: 'Step 2. Sales Channel',
  step3: 'Step 3. Terminal',
  dropdownLabels: {
    terminal: 'Select your sales terminal*',
    salesChannel: 'Select your sales channel*',
    paymentMethod: 'Select your payment method*',
  },
  signatureOnDelivery: 'Request signature on delivery',
  textUpdates: 'Text updates',
  cart: {
    edit: 'Edit',
    remove: 'Remove',
    update: 'Update',
  },
  sendPayLink: 'Send the paylink via e-mail',
};

export const productConstants = {
  sizes: {
    1: 'Armchair',
    2: 'Loveseat',
    3: 'Sofa',
    4: 'King Sofa',
    5: 'Sectional',
    6: 'Sectional',
    7: 'Sectional',
    8: 'Sectional',
  },
  arms: {
    LO: 'Low Arms',
    HI: 'High Arms',
  },
  colors: {
    CG: 'Crushed Gravel Fabric',
    BR: 'Brick Red Fabric',
    NB: 'Navy Blue Fabric',
    CH: 'Charcoal Fabric',
    BG: 'Beige Fabric',
    IV: 'Ivory Fabric',
    CN: 'Chestnut Leather',
    SL: 'Slate Leather',
  },
  legs: {
    MB: 'Matte Black Legs',
    DW: 'Walnut Legs',
    LW: 'Oak Legs',
    BW: 'Ebony Legs',
  },
  tableColors: {
    OK: 'Oak',
    WN: 'Walnut',
  },
  rugSizes: {
    SM: 'Small',
    MD: 'Medium',
  },
};

interface CountryCodeToCountryName {
  [key:string] : string
}

export const countryCodeToCountryName: CountryCodeToCountryName = {
  US: 'United States',
  CA: 'Canada',
};

export const s3ProductUrlBase = 'https://burrow-products.s3.amazonaws.com/16_9_SKU/';

export const productCardColors = 'Colors';
