import PropTypes from 'prop-types';
import { withCssModulesClassNames } from '../../../common/nextMigrationHelpers';
import { default as styles } from '../../../scss/components/Header.module.scss';

const withCssModulesClassNamesHandler = withCssModulesClassNames(styles);

export type HamburgerProps = {
  toggleMobileMenu: () => void,
  isMobileMenuOpen: boolean,
};

const Hamburger = ({
  isMobileMenuOpen,
  toggleMobileMenu,
}: HamburgerProps) => {
  const classes = withCssModulesClassNamesHandler('icon', {
    'icon-close': isMobileMenuOpen,
    'icon-bars': !isMobileMenuOpen,
  });
  return (
    <i
      className={classes}
      tabIndex={0}
      role="button"
      aria-label="Open menu"
      aria-haspopup="true"
      onClick={toggleMobileMenu}
    />
  );
};

Hamburger.propTypes = {
  isMobileMenuOpen: PropTypes.bool.isRequired,
  toggleMobileMenu: PropTypes.func.isRequired,
};

export default Hamburger;
