import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { default as styles } from '../../../scss/components/Header.module.scss';
// FIXME: extract necessary styles

class CartButton extends Component {
  render() {
    const {
      cartNumber,
      openCart,
      experiments,
    } = this.props;
    const hasItemsInCart = cartNumber > 0;
    const ButtonComponent = experiments?.newNavigation ? 'button' : 'div';

    return (
      <ButtonComponent
        className={classNames(
          'header-menu-account',
          styles['header-menu-account'],
          'mobile-cart-link',
          styles['mobile-cart-link'],
          'links-section-item',
          styles['links-section-item'],
        )}
        onClick={openCart}
      >
        <div className={classNames('header-cart-wrapper', styles['header-cart-wrapper'])}>
          {hasItemsInCart && (
            <span
              data-testid="header-cart-num"
              className={classNames(
                'header-cart-num',
                styles['header-cart-num'],
              )}
            >{cartNumber}</span>
          )}
          <i className="icon icon-cart" data-testid="icon-cart-main"/>
        </div>
        <span className={classNames('mobile-link-span', styles['mobile-link-span'])}>
          Your Cart
        </span>
      </ButtonComponent>
    );
  }
}

CartButton.propTypes = {
  cartNumber: PropTypes.number,
  openCart: PropTypes.func,
};

export default CartButton;
