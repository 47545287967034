import React, { Component, createRef } from 'react';
import classNames from 'classnames';
import RecommendedAddToCart from './RecommendedAddToCart';
import Asset from '../Asset';

import { generateImageAltTag, getInventory } from '../ProductHelpers';
import { checkout as checkoutGlobalAPI } from '../../../mock/globalAPI';

export default class Item extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shippingMessage: {},
      imageContainerWidth: null,
      shouldRenderImages: false,
      isEditModalOpen: false,
    };

    this.imageContainerRef = createRef();
  }

  async componentDidMount() {
    const {
      item,
      isCartOpen,
      isCheckoutCart,
    } = this.props;

    this.mounted = true;
    this.setState({
      imageContainerWidth: this.imageContainerRef.current?.clientWidth || null,
      shouldRenderImages: isCartOpen || isCheckoutCart,
    });

    if (typeof this.props.fetchProductShippingEstimate === 'function' && !this.isCustomItem()) {
      const { shippingMessage } = await getInventory(item.sku);
      this.props.fetchProductShippingEstimate({
        sku: item.sku,
        shippingMessage,
      });
    }
  }

  componentDidUpdate() {
    if ((this.props.isCartOpen || this.props.isCheckoutCart) && !this.state.shouldRenderImages) {
      this.setState({ shouldRenderImages: true });
    }
  }

  handleItemClick = () => {
    const { item, loadAndCloseCart  } = this.props;
    if (this.isCustomItem()) {
      return;
    }

    loadAndCloseCart(item);
  };

  isCustomItem = () => {
    const { item } = this.props;
    return !item.productId && !item.variantId;
  };

  renderShippingMessage() {
    const shippingMessage = this.props.shippingEstimates?.[this.props.item?.sku] || '';
    return (
      <div className="cart-item__shipping">
        <span className="caption cart-item__info-copy" >{shippingMessage}</span>
      </div>
    );
  }

  renderEdit(calculating) {
    const { openEditModal, item } = this.props;

    return (
      <button
        className={classNames(
          'body-small',
          'cart-item__edit',
          'cart-item__button',
          calculating ? 'disabled' : 'clickable',
        )}
        disabled={calculating}
        onClick={() => openEditModal(item)}
      >
        {checkoutGlobalAPI.cart.edit}
      </button>
    );
  }

  renderRemove(calculating) {
    const {
      remove,
      recommended,
    } = this.props;

    return (!recommended && (
      <button
        className={classNames(
          'body-small',
          'cart-item__remove',
          'cart-item__button',
          calculating ? 'disabled' : 'clickable',
        )}
        disabled={calculating}
        onClick={remove}
      >
        {checkoutGlobalAPI.cart.remove}
      </button>
    ));
  }

  renderStepper(calculating, quantity, price) {
    const {
      recommended,
    } = this.props;

    const disabled = !price || calculating || this.isCustomItem();

    return (!recommended && (
      <div className="cart-item-quantity caption">
        <div
          className={classNames(
            'cart-item-quantity__circle',
            'cart-item-quantity__circle-decrease',
            { disabled },
          )}
          onClick={disabled ? null : this.props.decreaseQty}
        />
        <span className="cart-item-quantity__number">{quantity}</span>
        <div
          className={classNames(
            'cart-item-quantity__circle',
            'cart-item-quantity__circle-increase',
            { disabled },
          )}
          onClick={disabled ? null : this.props.increaseQty}
        />
      </div>
    ));
  }

  render() {
    const {
      calculating,
      item,
      isCheckoutCart,
      recommended,
    } = this.props;
    const {
      imageContainerWidth,
      shouldRenderImages,
    }                           = this.state;
    const productName           = item.name;
    const quantity              = this.props.quantity || item.quantity;
    const altTag                = generateImageAltTag(item);
    let price                   = item.listPrice || item.pricing?.price?.formatted;
    const included              = item.parentId;
    const canEditItem           = !item.parentId && !isCheckoutCart;
    price                       = item.parentId ? 'Included' : price;
    let originalPricetoDisplay;
    if (!recommended) {
      originalPricetoDisplay = item.originalPrice !== price ? item.originalPrice : null;
    } else {
      originalPricetoDisplay = item.pricing?.originalPrice?.formatted  !== price ? item.pricing?.originalPrice?.formatted  : null;
    }
    const cartItemClassName = recommended ? 'recommended__cart-item' : 'cart-item';
    const cartItemImageClassName = recommended ? 'recommended__cart-item__image' : 'cart-item__image';
    const cartItemPriceClassName = recommended ? 'recommended__cart-item__price'  : 'cart-item__price';
    const recommendedProductInfo = recommended ? this.props.recommendedItems : null;
    const dataTestId = recommended ? 'cart__recommended-item' : null;

    return (
      <div className={cartItemClassName} data-testid={dataTestId}>
        <div
          className={cartItemImageClassName}
          ref={this.imageContainerRef}
        >
          {!recommended && item.productCardImage && imageContainerWidth && shouldRenderImages && (
            <a
              onClick={this.handleItemClick}
            >
              {
                item.productCardImage.handle ? (
                  <Asset
                    asset={item.productCardImage}
                    maxWidth={imageContainerWidth}
                    alt={altTag}
                    imageClassName="cart-item__image"
                  />
                ) : (
                  // eslint-disable-next-line @next/next/no-img-element
                  <img
                    src={item.productCardImage.url}
                    alt={generateImageAltTag(item)}
                    style={{
                      position: 'relative',
                      top: 0,
                      left: 0,
                      transition: 'opacity 0.5s',
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      objectPosition: 'center',
                    }}
                  />
                )
              }
            </a>
          )}
          {recommended && item.productCardImage && shouldRenderImages && (
            <a
              onClick={this.handleItemClick}
            >
              <img // eslint-disable-line @next/next/no-img-element
                src={item.productCardImage.url}
                alt={generateImageAltTag(item)}
                style={{
                  position: 'relative',
                  top: 0,
                  left: 0,
                  transition: 'opacity 0.5s',
                  width: '120px',
                  height: '75px',
                  objectFit: 'cover',
                  objectPosition: 'center',
                }}
              />
            </a>
          )}
          {canEditItem ? (
            this.renderStepper(calculating, quantity, price)
          ) : (
            <span className="cart-item__quantity-wrapper">
              Qty: <span className="cart-item-quantity__number">{quantity}</span>
            </span>
          )}
        </div>
        <span
          className="cart-item__info-title clickable"
          onClick={this.handleItemClick}
        >{productName}</span>
        <div className="cart-item__description">
          <div className="cart-item__info-copy">
            <p className={classNames(
              {
                'body-small': !isCheckoutCart,
                'body-medium': isCheckoutCart,
              },
            )}
            >
              By {item.brand || 'Burrow'}
            </p>
            {item.description && (
              <p className={classNames(
                {
                  'body-small': !isCheckoutCart,
                  'body-medium': isCheckoutCart,
                },
              )}
              >
                {item.description}
              </p>
            )}
          </div>
        </div>
        {isCheckoutCart && this.renderEdit(calculating)}
        {!included && this.renderRemove(calculating)}
        <span className={cartItemPriceClassName}>
          {originalPricetoDisplay ? <span className="strikethrough">{originalPricetoDisplay}</span> : null}<span>{price}</span>
        </span>
        {recommended && <RecommendedAddToCart recommendedProductInfo={recommendedProductInfo}/>}
        {!recommended && this.renderShippingMessage()}
      </div>
    );
  }
}
