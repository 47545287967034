import { Countries, HrefLang  } from '../types/schema';

export type State = {
  title: string,
  value: string
};

export interface Country extends State {
  fullName?: string
  hrefLang?: string
}

export type AllStates = {
  US: State[],
  CA: State[]
};

export const countries: Country[] = [
  {
    title: 'US',
    value: 'US',
    fullName: Countries.US,
    hrefLang: HrefLang.EN_US,
  },
  {
    title: 'CA',
    value: 'CA',
    fullName: Countries.Canada,
    hrefLang: HrefLang.EN_CA,
  },
];

export const states: State[] = [
  { value: 'AL', title: 'Alabama' },
  { value: 'AK', title: 'Alaska' },
  { value: 'AZ', title: 'Arizona' },
  { value: 'AR', title: 'Arkansas' },
  { value: 'CA', title: 'California' },
  { value: 'CO', title: 'Colorado' },
  { value: 'CT', title: 'Connecticut' },
  { value: 'DE', title: 'Delaware' },
  { value: 'DC', title: 'District of Columbia' },
  { value: 'FL', title: 'Florida' },
  { value: 'GA', title: 'Georgia' },
  { value: 'HI', title: 'Hawaii' },
  { value: 'ID', title: 'Idaho' },
  { value: 'IL', title: 'Illinois' },
  { value: 'IN', title: 'Indiana' },
  { value: 'IA', title: 'Iowa' },
  { value: 'KS', title: 'Kansas' },
  { value: 'KY', title: 'Kentucky' },
  { value: 'LA', title: 'Louisiana' },
  { value: 'ME', title: 'Maine' },
  { value: 'MT', title: 'Montana' },
  { value: 'NE', title: 'Nebraska' },
  { value: 'NV', title: 'Nevada' },
  { value: 'NH', title: 'New Hampshire' },
  { value: 'NJ', title: 'New Jersey' },
  { value: 'NM', title: 'New Mexico' },
  { value: 'NY', title: 'New York' },
  { value: 'NC', title: 'North Carolina' },
  { value: 'ND', title: 'North Dakota' },
  { value: 'OH', title: 'Ohio' },
  { value: 'OK', title: 'Oklahoma' },
  { value: 'OR', title: 'Oregon' },
  { value: 'MD', title: 'Maryland' },
  { value: 'MA', title: 'Massachusetts' },
  { value: 'MI', title: 'Michigan' },
  { value: 'MN', title: 'Minnesota' },
  { value: 'MS', title: 'Mississippi' },
  { value: 'MO', title: 'Missouri' },
  { value: 'PA', title: 'Pennsylvania' },
  { value: 'RI', title: 'Rhode Island' },
  { value: 'SC', title: 'South Carolina' },
  { value: 'SD', title: 'South Dakota' },
  { value: 'TN', title: 'Tennessee' },
  { value: 'TX', title: 'Texas' },
  { value: 'UT', title: 'Utah' },
  { value: 'VT', title: 'Vermont' },
  { value: 'VA', title: 'Virginia' },
  { value: 'WA', title: 'Washington' },
  { value: 'WV', title: 'West Virginia' },
  { value: 'WI', title: 'Wisconsin' },
  { value: 'WY', title: 'Wyoming' },
];

export const canadianStates: State[] = [
  { value: 'AB', title: 'Alberta' },
  { value: 'BC', title: 'British Columbia' },
  { value: 'MB', title: 'Manitoba' },
  { value: 'NB', title: 'New Brunswick' },
  { value: 'NL', title: 'Newfoundland and Labrador' },
  { value: 'NT', title: 'Northwest Territories' },
  { value: 'NS', title: 'Nova Scotia' },
  { value: 'NU', title: 'Nunavut' },
  { value: 'ON', title: 'Ontario' },
  { value: 'PE', title: 'Prince Edward Island' },
  { value: 'QC', title: 'Quebec' },
  { value: 'SK', title: 'Saskatchewan' },
  { value: 'YT', title: 'Yukon' },
];

export const stateMap: Record<string, string> = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District of Columbia',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};

export const allStates: AllStates = {
  US: states,
  CA: canadianStates,
};
