import { initialState } from '../reducers/page.reducer';

export const getIsPageDataPending = ({ page }) => page.isPending;
export const getPageError = ({ page }) => page.error;
export const getPageContent = ({ page }) => page?.data?.content;
export const getPageComponents = ({ page }) => page?.data?.content?.components || [];
export const getPageProduct = ({ page }) => page?.data?.content?.product || null;
export const getFooter = ({ page }) => page.data.footer;
export const getBackgroundColor = ({ page }) => page?.data?.content?.backgroundColor
  || initialState.data.content.backgroundColor;
export const getPageUsesPageTemplate = ({ page }) => page?.data?.content?.components?.length > 0;
export const getProductCollectionItemsCount = ({ page }) => page
  ?.data
  ?.content
  ?.components
  ?.filter(({ type }) => type === 'ProductCollection')
  ?.map((component) => component?.collections[0]?.productCollectionItems)
  ?.flat()
  ?.filter((productCollectionItem) => productCollectionItem?.type === 'ProductCard')
  ?.length || 0;

export const getFilterID = ({ page }) => page?.data?.content?.filter?.id || '';
export const getAvailableConfigurations = ({ page }) => page?.data?.content?.filter?.configurations || [];
export const getAvailableMaterials = ({ page }) => page?.data?.content?.filter?.materials || [];
export const getIsLoading = ({ page }) => page?.data?.content?.filter?.isLoading || false;
export const getCollectionFilters = ({ page }) => page?.data?.content?.filter?.collectionFilters || [];
export const getProductCollections = ({ page }) => page?.data?.content?.filter?.productCollections || [];
export const getNumberOfAppliedFilters = ({ page }) => page?.data?.content?.filter?.filtersSelected || 0;
export const getFilterRedirects = ({ page }) => page?.data?.content?.filter?.filterRedirects || [];
export const getIsFiltersFetched = ({ page }) => page?.data?.content?.filter?.isFetched || false;
export const getColorBuckets = ({ page }) => getAvailableMaterials({ page })
  .map(({ colorBuckets }) => colorBuckets)
  .flat();
export const getAvailablePages = ({ page }) => page?.data?.content?.availablePages?.map(({ url }) => url);
