import { useDispatch } from 'react-redux';
import { setIsSearchDropdownOpen } from '../../../store/actions/ui.actions';
import useSearchProducts from './useSearchProducts';
import SearchDivider from './SearchDivider';
import SearchTermInput from './SearchTermInput';
import SearchDropdownResults from './SearchDropdownResults';
import SearchDropdownNoResults from './SearchDropdownNoResults';
import SearchDropdownPopularLinks from './SearchDropdownPopularLinks';
import SearchDropdownResultsSkeleton from './SearchDropdownResultsSkeleton';
import { withCssModulesClassNames } from '../../../common/nextMigrationHelpers';
import { default as searchStyles } from '../../../scss/components/Search.module.scss';
import { default as dropDownStyles } from '../../../scss/components/SearchDropdown.module.scss';

import usePopularLinks from './usePopularLinks';

const withCssModulesClassNamesHandler = withCssModulesClassNames(searchStyles);
const withCssModulesClassNamesHandlerSearchDropdown = withCssModulesClassNames(dropDownStyles);

export const SEARCH_DROPDOWN_CONTAINER_TEST_ID = 'search-dropdown-container';

const SearchDropdown = () => {
  const { popularLinks } = usePopularLinks();
  const {
    isLoading,
    totalProducts,
    searchProducts,
    searchTermInputValue,
    onChangeSearchTermInputValue,
  } = useSearchProducts();

  const dispatch = useDispatch();

  return (
    <div
      data-testid={SEARCH_DROPDOWN_CONTAINER_TEST_ID}
      className={withCssModulesClassNamesHandlerSearchDropdown('search-dropdown-container')}
    >
      <div className={withCssModulesClassNamesHandlerSearchDropdown('search-dropdown')} data-testid="search-dropdown">
        <div className={withCssModulesClassNamesHandler('search-content')}>
          <SearchTermInput
            autoFocus
            value={searchTermInputValue}
            onChange={onChangeSearchTermInputValue}
            onCloseClick={() => dispatch(setIsSearchDropdownOpen(false))}
          />
          <SearchDivider />
          {
            isLoading && (
              <SearchDropdownResultsSkeleton />
            )
          }
          {
            !isLoading && searchTermInputValue.length >= 3 && searchProducts.length === 0 && (
              <SearchDropdownNoResults searchTermInputValue={searchTermInputValue} />
            )
          }
          {
            searchTermInputValue.length < 3 && (
              <SearchDropdownPopularLinks popularLinks={popularLinks} />
            )
          }
          {
            searchProducts.length > 0 && (
              <SearchDropdownResults
                totalProducts={totalProducts}
                searchTermInputValue={searchTermInputValue}
                searchProducts={searchProducts.slice(0, 5)}
              />
            )
          }
        </div>
      </div>
    </div>
  );
};

export default SearchDropdown;
