import {
  FETCH_CART__FAILURE,
  FETCH_CART__REQUEST,
  SET_IS_PROMO_INPUT_VISIBLE,
  SET_IS_PRODUCT_EDIT_MODAL_VISIBLE,
  SET_IS_UPDATING_PRODUCT_DETAILS,
  FETCH_CART__SUCCESS,
  ADD_TO_CART__FAILURE,
  ADD_TO_CART__REQUEST,
  ADD_TO_CART__SUCCESS,
  UPDATE_CART_ITEM__FAILURE,
  UPDATE_CART_ITEM__REQUEST,
  UPDATE_CART_ITEM__SUCCESS,
  ADD_COUPON_TO_CART__FAILURE,
  ADD_COUPON_TO_CART__REQUEST,
  ADD_COUPON_TO_CART__SUCCESS,
  REMOVE_COUPON_FROM_CART__FAILURE,
  REMOVE_COUPON_FROM_CART__REQUEST,
  REMOVE_COUPON_FROM_CART__SUCCESS,
  SET_CART_DETAILS,
  SET_TIERED_SALES,
  SAVE_CART_FOR_LATER_SUCCESS,
  SET_SAVE_CART_MODAL_OPEN,
  SET_SALES_TIERS_MODAL_OPEN,
  DELETE_CART__SUCCESS,
  GET_RECOMMENDED_CART_ITEMS__REQUEST,
  GET_RECOMMENDED_CART_ITEMS__SUCCESS,
  GET_RECOMMENDED_CART_ITEMS__FAILURE,
} from '../actions/cart.actions';

export const initialState = {
  isPromoInputVisible: false,
  isItemEditModalVisible: false,
  isPending: false,
  isUpdatingProductDetails: false,
  error: null,
  data: {
    details: {},
    tieredCoupons: null,
    savedForLater: false,
    saveCartModalOpen: false,
    salesTiersModalOpen: false,
    recommendedItems: null,
  },
};

const updateCartDetailsRequest = (state) => ({
  ...state,
  isPending: true,
});

const updateCartDetailsSuccess = (state, payload) => ({
  ...state,
  isPending: false,
  error: null,
  data: {
    ...state.data,
    details: payload,
    savedForLater: false,
  },
});

const updateCartDetailsFailure = (state, payload) => ({
  ...state,
  isPending: false,
  error: payload.error,
});

const setCartDetails = (state, payload) => ({
  ...state,
  data: {
    ...state.data,
    details: payload,
    savedForLater: false,
  },
});

const setPromotions = (state, payload) => ({
  ...state,
  data: {
    ...state.data,
    tieredCoupons: payload,
  },
});

const saveCartForLaterSuccess = (state) => ({
  ...state,
  data: {
    ...state.data,
    savedForLater: true,
  },
});

const setSaveCartModalOpen = (state, payload) => ({
  ...state,
  data: {
    ...state.data,
    saveCartModalOpen: payload,
  },
});

const setSalesTiersModalOpen = (state, payload) => ({
  ...state,
  data: {
    ...state.data,
    salesTiersModalOpen: payload,
  },
});

const updateIsPromoInputVisible = (state, payload) => ({
  ...state,
  isPromoInputVisible: payload.isPromoInputVisible,
});

const setIsProductEditModalVisible = (state, payload) => ({
  ...state,
  isItemEditModalVisible: payload.isItemEditModalVisible,
});

const setIsUpdatingProductDetails = (state, payload) => ({
  ...state,
  isUpdatingProductDetails: payload.isUpdatingProductDetails,
});

const getRecommendedCartItemsRequest = (state) => ({
  ...state,
});

const getRecommendedCartItemsSuccess = (state, payload) => ({
  ...state,
  isPending: false,
  error: null,
  data: {
    ...state.data,
    details: {
      ...state.data.details,
      recommendedItems: payload,
    },
  },
});

const getRecommendedCartItemsFailure = (state, payload) => ({
  ...state,
  isPending: false,
  error: payload.error,
});

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CART__REQUEST:
    case ADD_TO_CART__REQUEST:
    case UPDATE_CART_ITEM__REQUEST:
    case ADD_COUPON_TO_CART__REQUEST:
    case REMOVE_COUPON_FROM_CART__REQUEST:
      return updateCartDetailsRequest(state);

    case FETCH_CART__SUCCESS:
    case ADD_TO_CART__SUCCESS:
    case UPDATE_CART_ITEM__SUCCESS:
    case ADD_COUPON_TO_CART__SUCCESS:
    case REMOVE_COUPON_FROM_CART__SUCCESS:
      return updateCartDetailsSuccess(state, action.payload);

    case FETCH_CART__FAILURE:
    case ADD_TO_CART__FAILURE:
    case UPDATE_CART_ITEM__FAILURE:
    case ADD_COUPON_TO_CART__FAILURE:
    case REMOVE_COUPON_FROM_CART__FAILURE:
      return updateCartDetailsFailure(state, action.payload);

    case SET_CART_DETAILS:
    case DELETE_CART__SUCCESS:
      return setCartDetails(state, action.details);

    case SET_TIERED_SALES:
      return setPromotions(state, action.payload);

    case SAVE_CART_FOR_LATER_SUCCESS:
      return saveCartForLaterSuccess(state);

    case SET_SAVE_CART_MODAL_OPEN:
      return setSaveCartModalOpen(state, action.payload);
    case SET_SALES_TIERS_MODAL_OPEN:
      return setSalesTiersModalOpen(state, action.payload);
    case SET_IS_PROMO_INPUT_VISIBLE:
      return updateIsPromoInputVisible(state, action.payload);
    case SET_IS_PRODUCT_EDIT_MODAL_VISIBLE:
      return setIsProductEditModalVisible(state, action.payload);
    case SET_IS_UPDATING_PRODUCT_DETAILS:
      return setIsUpdatingProductDetails(state, action.payload);

    case GET_RECOMMENDED_CART_ITEMS__REQUEST:
      return getRecommendedCartItemsRequest(state);
    case GET_RECOMMENDED_CART_ITEMS__SUCCESS:
      return getRecommendedCartItemsSuccess(state, action.payload);
    case GET_RECOMMENDED_CART_ITEMS__FAILURE:
      return getRecommendedCartItemsFailure(state, action.payload);

    default:
      return state;
  }
};
