import React from 'react';
import lowerFirst from 'lodash/lowerFirst';
import { default as styles } from '../../scss/components/CallToAction.module.scss';
// FIXME: extract necessary styles
import { getContainerStyles } from '../../../../utils/Utils';
import { withCssModulesClassNames } from '../../common/nextMigrationHelpers';
import { CallToActionDto } from '../../types/schema/models';

const withCssModulesClassNamesHandler = withCssModulesClassNames(styles);

export type CallToActionProps = {
  additionalClassName?: string;
  callToAction: CallToActionDto;
};

const CallToAction = ({ callToAction, additionalClassName }: CallToActionProps) => {
  const {
    align,
    background,
    badgeCopy,
    className,
    color,
    verticalAlign,
  } = callToAction;

  return (
    <span
      style={getContainerStyles(background, color)}
      className={withCssModulesClassNamesHandler(
        'call-to-action',
        additionalClassName,
        styles['call-to-action'],
        align ? `align-${align.toLowerCase()}` : 'align-center',
        styles[align ? `align-${align.toLowerCase()}` : 'align-center'],
        className,
        verticalAlign ? `vertical-align-${lowerFirst(verticalAlign)}` : '',
      )}
    >
      {badgeCopy}
    </span>
  );
};

export default CallToAction;
