import React, { Component } from 'react';
import { connect } from 'react-redux';
import { cart as cartGlobalAPI } from '../../../mock/globalAPI';
import Discounts from './Discounts';
import PromoInputContainer from '../../checkout/partials/PromoInput';
import { formatStringToDollar } from '../../../../../utils/Utils';
import { getHrefLang } from '../../../store/selectors/general.selectors';
import PromoDisplayContainer from '../../checkout/partials/PromoDisplay';

class CartFooter extends Component {
  renderTotal() {
    const {
      cartDetails,
      checkoutTotal,
      consignmentTax,
      calculating,
    } = this.props;
    let total = cartDetails.cartTotalAmount;
    if (consignmentTax && checkoutTotal) {
      total = checkoutTotal;
    }
    return (
      <div className="cart-footer__item total cart-footer__item-bold cart-footer__item-padded">
        <span>{cartGlobalAPI.total}</span>
        <span className="amount">
          {calculating ? cartGlobalAPI.calculating : total}
        </span>
      </div>
    );
  }

  render() {
    const {
      addCouponToCartAction,
      applyAutomaticDiscountAction,
      automaticallyAppliedPromotion,
      calculating,
      cartDetails,
      consignmentTax,
      isCartDataPending,
      isConsignmentPending,
      removeCouponFromCartAction,
      subtotalNumber,
      hrefLang,
    } = this.props;

    const saleSavings = cartDetails?.saleSavings !== '-$0.00' ? cartDetails.saleSavings : null;

    return (
      <>
        <div className="cart-footer__item subtotal">
          <span>{cartGlobalAPI.subtotal}</span>
          <span className="amount">
            {calculating ? cartGlobalAPI.calculating : formatStringToDollar({
              value: subtotalNumber, minimumFractionDigits: 2, haveComma: true, hrefLang,
            })}
          </span>
        </div>
        <Discounts
          discounts={cartDetails?.discounts}
          isCartDataPending={isCartDataPending}
        />
        <PromoDisplayContainer
          applyDiscount={addCouponToCartAction}
          applyAutomaticDiscount={applyAutomaticDiscountAction}
          cartId={cartDetails.id}
          coupons={cartDetails.coupons}
          removeDiscount={removeCouponFromCartAction}
          discounts={cartDetails.discounts}
          automaticallyAppliedPromotion={automaticallyAppliedPromotion}
          calculating={calculating}
        />
        {saleSavings ?  (
          <div className="cart-footer__item sale-savings">
            <span>{cartGlobalAPI.saleSavings}</span>
            <span className="amount">{saleSavings}</span>
          </div>
        ) : null }
        {consignmentTax && (
          <div className="cart-footer__item tax">
            <span>{cartGlobalAPI.tax}</span>
            <span className="amount">{isConsignmentPending ? cartGlobalAPI.calculating : consignmentTax}</span>
          </div>
        )}
        {
          cartDetails.shippingAmount && (
            <div className="cart-footer__item shipping">
              <span>{cartGlobalAPI.shipping}</span>
              <span className="amount">{cartDetails.shippingAmount}</span>
            </div>
          )
        }
        {cartDetails.taxAmount && cartDetails.taxAmount !== '$0.00' && (
          <div className="cart-footer__item tax">
            <span>{cartGlobalAPI.tax}</span>
            <span className="amount">{cartDetails.taxAmount}</span>
          </div>
        )}
        <PromoInputContainer
          applyDiscount={addCouponToCartAction}
          applyAutomaticDiscount={applyAutomaticDiscountAction}
          cartId={cartDetails.id}
          coupons={cartDetails.coupons}
          removeDiscount={removeCouponFromCartAction}
          discounts={cartDetails.discounts}
          automaticallyAppliedPromotion={automaticallyAppliedPromotion}
          calculating={calculating}
        />
        {this.renderTotal()}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  hrefLang: getHrefLang(state),
});

export default connect(mapStateToProps, null)(CartFooter);
