/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import loadable from '@loadable/component';
import LoadingContainer from '../Loading';
import { getProductDetails } from '../../../store/selectors/product.selectors';
import PopoutUnderlay from '../PopoutUnderlay';
import {
  getNavPinned,
  getIsSearchDropdownOpen,
  getIsXLWidth,
} from '../../../store/selectors/ui.selectors';
import MobileMenu from './MobileMenu';
import ShoppingNavigation from './ShoppingNavigation';
import UtilityNavigation from './UtilityNavigation';
import { setNavState, setIsMobileMenuOpen, setIsSearchDropdownOpen } from '../../../store/actions/ui.actions';
import Analytics from '../Analytics';
import ErrorBoundary from '../ErrorBoundary';
import { default as styles } from '../../../scss/components/Header.module.scss';
import { withCssModulesClassNames } from '../../../common/nextMigrationHelpers';
import {
  NavigationItem, FeaturedItem, Link, CloseMenuParams,
} from './header.types';
import Nullable from '../../../../../shared/nullable';

const fallback = <LoadingContainer />;
const ProductPreviewLoadable = loadable(() => import('../../shopping/ProductPreview'), { fallback });
const withCssModulesClassNamesHandler = withCssModulesClassNames(styles);

export interface HeaderProps {
  bannerContent?: string,
  cartNumber: number,
  headerColor: string,
  isCartOpen: boolean,
  experiments?: Record<string, boolean>,
  isFiltersPopoutOpen: boolean,
  isLoggedIn: boolean,
  isMobileMenuOpen: boolean,
  navigation: NavigationItem[],
  openCart: (booleanValue: boolean) => void,
  path: string,
  setIsFiltersPopoutOpen: (booleanValue: boolean) => void,
}

const Header = ({
  isMobileMenuOpen,
  isCartOpen,
  isFiltersPopoutOpen,
  path,
  experiments,
  openCart: setOpenCart,
  setIsFiltersPopoutOpen,
  cartNumber,
  isLoggedIn,
  navigation = [],
  bannerContent = '',
}: HeaderProps) => {
  const [dropdownStates, setDropdownStates] = useState<{ [key: string]: boolean }>({});
  const pinned = useSelector(getNavPinned);
  const productDetails = useSelector(getProductDetails);
  const isDesktopDevice = useSelector(getIsXLWidth);
  const isSearchDropdownOpen = useSelector(getIsSearchDropdownOpen);
  const dispatch = useDispatch();
  const bxRef = useRef<HTMLDivElement | null>(null);
  const bxMutationHandler = () => {
    dispatch(setNavState({ bxActive: bxRef?.current?.offsetHeight }));
  };

  const closeMenu = (config?: CloseMenuParams) => {
    let preventMobile;
    if (config && 'preventMobile' in config) {
      preventMobile = config.preventMobile;
    }

    if (!preventMobile) {
      dispatch(setIsMobileMenuOpen(false));
    }

    setDropdownStates({});
  };

  useEffect(() => {
    let bxMutationObserver: MutationObserver;
    if (bxRef?.current) {
      bxMutationObserver = new MutationObserver(bxMutationHandler);
      bxMutationObserver.observe(bxRef.current, {
        attributes: true,
        childList: true,
        subtree: true,
      });
    }

    return () => {
      if (bxMutationObserver) {
        bxMutationObserver.disconnect();
      }
    };
  }, []);

  const [isBannerVisible, setIsBannerVisible] = useState(false);
  const unbounceBannerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const onBannerVisible = () => {
      setIsBannerVisible(true);
    };

    const onBannerClosed = () => {
      setIsBannerVisible(false);
    };

    window.addEventListener('customDigiohBannerVisible', onBannerVisible);
    window.addEventListener('customDigiohBannerClosed', onBannerClosed);

    const messageHandler = (event: MessageEvent) => {
      if (event.data.type === 'customDigiohBannerVisible' && !isBannerVisible) {
        const unbounceBanner = window.document.querySelector<HTMLDivElement>('.ub-emb-container');
        if (unbounceBanner && unbounceBannerRef.current) {
          const closeBtn = unbounceBanner.querySelector<HTMLDivElement>('.ub-emb-close');
          const unbounceBannerHeight = unbounceBanner.querySelector('.ub-emb-iframe')?.clientHeight || 30;

          if (closeBtn) {
            // Add a click listener to the close button
            closeBtn.addEventListener('click', () => {
              onBannerClosed();

              if (unbounceBanner && unbounceBannerRef.current) {
                unbounceBannerRef.current?.removeChild(unbounceBanner);
              }
            }, { once: true });
          }

          // Style and position the banner
          unbounceBanner.style.top = '0';
          unbounceBanner.style.zIndex = '9000';
          unbounceBanner.style.position = 'relative';
          unbounceBanner.style.minHeight = `${unbounceBannerHeight}px`;

          // Overrides some unbounce non-editable css rules
          const body = window.document.querySelector<HTMLBodyElement>('body');
          if (body) {
            body.style.setProperty('margin-top', '0px', 'important');
          }

          const frame = unbounceBanner.querySelector<HTMLDivElement>('.ub-emb-bar-frame');
          if (frame) {
            frame.style.setProperty('position', 'relative');
          }
          // ---

          unbounceBannerRef.current.appendChild(unbounceBanner);
        }

        onBannerVisible();
      }
    };

    window.addEventListener('message', messageHandler);

    return () => {
      window.removeEventListener('customDigiohBannerVisible', onBannerVisible);
      window.removeEventListener('customDigiohBannerClosed', onBannerClosed);
      window.removeEventListener('message', messageHandler);
    };
  }, [isBannerVisible]);

  const getIsShoppingMenuOpen = () => {
    const utilityDropdownIds = navigation
      .filter(({ tier }) => tier === 'Utility')
      .map(({ navigationId }) => navigationId);

    return Object
      .entries(dropdownStates)
      .filter(([key, value]) => value && !utilityDropdownIds.includes(key))
      .length > 0;
  };

  useEffect(() => {
    const bodyClasses = document.getElementsByTagName('body')[0].classList;
    if (isMobileMenuOpen || isCartOpen || isFiltersPopoutOpen || isSearchDropdownOpen) {
      bodyClasses.add('noscroll');
    } else {
      bodyClasses.remove('noscroll');
    }
  }, [isMobileMenuOpen, isCartOpen, isFiltersPopoutOpen, isSearchDropdownOpen]);

  useEffect(() => {
    closeMenu();
  }, [path]);

  const openCart = () => {
    setOpenCart(true);
    setDropdownStates({});
    Analytics.enqueue({
      method: 'track',
      params: {
        event: 'Cart Button',
        properties: {
          action: 'Clicked',
          effect: 'Open',
        },
      },
    });
  };

  const closePopouts = () => {
    if (isCartOpen) {
      setOpenCart(false);
    }
    if (isMobileMenuOpen) {
      closeMenu();
    }
    if (isFiltersPopoutOpen) {
      setIsFiltersPopoutOpen(false);
    }
    if (isSearchDropdownOpen) {
      dispatch(setIsSearchDropdownOpen(false));
    }
  };

  const toggleMobileMenu = () => {
    if (isMobileMenuOpen) {
      setDropdownStates({});
    }
    dispatch(setIsMobileMenuOpen(!isMobileMenuOpen));
    dispatch(setIsSearchDropdownOpen(false));

    Analytics.enqueue({
      method: 'track',
      params: {
        event: 'Mobile Menu',
        properties: {
          action: 'Clicked',
          effect: isMobileMenuOpen ? 'Open' : 'Close',
        },
      },
    });
  };

  const getOpenedDropdownIDs = () => Object
    .entries(dropdownStates)
    .filter(([, value]) => value)
    .map(([key]) => key);

  const isSectionManuallyOpened = ({ navigationItem = {}, openedDropdownIDs }: { navigationItem: NavigationItem | FeaturedItem | Link | Record<string, never>, openedDropdownIDs: string[] }) => {
    if ('expandedMobileSection' in navigationItem) {
      return !navigationItem?.expandedMobileSection && openedDropdownIDs.includes(navigationItem?.navigationId);
    }
    return null;
  };

  const getMainOpenedSection = () => {
    const openedDropdownIDs = getOpenedDropdownIDs();

    return navigation.find((navigationItem) => isSectionManuallyOpened({ navigationItem, openedDropdownIDs }));
  };

  const hasGallery = ({ navigationItem = {} }: { navigationItem: NavigationItem | FeaturedItem | Record<string, never> }) => {
    const productCollectionItemsExist = navigationItem?.featuredProductCollection?.productCollectionItems?.length > 0;

    if (productCollectionItemsExist) {
      return true;
    }

    if ('points' in navigationItem) {
      return navigationItem.points?.length > 0;
    }
    return false;
  };

  // This function recurrently searches for the deepest open section that has a gallery
  const findDeepestVisibleGallery = (navigationItem: NavigationItem | FeaturedItem, openedDropdownIDs: string[]): Nullable<NavigationItem | FeaturedItem> => {
    const navItemHasGallery = hasGallery({ navigationItem });
    const isNavItemManuallyOpened = isSectionManuallyOpened({ navigationItem, openedDropdownIDs });
    const navItemWithGallery = isNavItemManuallyOpened && navItemHasGallery
      ? navigationItem
      : null;

    const manuallyOpenedChildNavItem = (navigationItem?.features as (FeaturedItem | Link)[])?.find((feature) => isSectionManuallyOpened({ navigationItem: feature, openedDropdownIDs }));
    if (manuallyOpenedChildNavItem) {
      const deeperNavItem = findDeepestVisibleGallery(manuallyOpenedChildNavItem as FeaturedItem, openedDropdownIDs);
      const deeperNavItemHasGallery: Nullable<boolean> = deeperNavItem && hasGallery({ navigationItem: deeperNavItem });
      const isDeeperNavItemManuallyOpened: Nullable<boolean> = deeperNavItem && isSectionManuallyOpened({ navigationItem: deeperNavItem, openedDropdownIDs });

      return isDeeperNavItemManuallyOpened && deeperNavItemHasGallery
        ? deeperNavItem
        : navItemWithGallery;
    }

    return navItemWithGallery;
  };

  const closeUtilityDropDown = () => {
    setDropdownStates({});
  };

  const toggleCurrentDropdown = (id: string, header: string, neighbors: string[]) => {
    const isDropdownOpen = dropdownStates[id];
    const analyticsEffect = isDropdownOpen ? 'Close' : 'Open';

    const closedNeigbours = neighbors.reduce((a, v) => ({ ...a, [v]: false }), {});
    setDropdownStates((prevState) => ({
      ...prevState,
      ...closedNeigbours,
      [id]: !isDropdownOpen,
    }));

    if (isSearchDropdownOpen) {
      dispatch(setIsSearchDropdownOpen(false));
    }

    Analytics.enqueue({
      method: 'track',
      params: {
        event: 'Dropdown',
        properties: {
          action: 'Clicked',
          effect: analyticsEffect,
          section: header,
          location: 'header',
        },
      },
    });
  };

  const isShoppingMenuOpen = getIsShoppingMenuOpen();
  const mainOpenedSection = getMainOpenedSection();
  const openedDropdownIDs = getOpenedDropdownIDs();
  const deepestNavItemWithGallery = mainOpenedSection
    ? findDeepestVisibleGallery(mainOpenedSection, openedDropdownIDs)
    : null;

  return (
    <>
      <header className={withCssModulesClassNamesHandler('header-navigation')}>
        <div className={withCssModulesClassNamesHandler(
          'header-navigation-bar',
          { 'header-navigation-bar--pinned': pinned },
          { 'header-navigation-bar--has-banner': isBannerVisible },
        )}
        >
          <div id="lightbox-inline-form-48c82c84-68dd-4ae0-bac3-c6ca343b15fe" />
          <div ref={unbounceBannerRef} id="lightbox-inline-form-08f3ecc5-eb0a-47f2-8448-409181bba022" />
          <div className={withCssModulesClassNamesHandler('header-nav-bx-utility-wrapper')} >
            <div className={withCssModulesClassNamesHandler('header-nav__bx')} ref={bxRef} />
            <ErrorBoundary errorMessage="Could not load the navigation, try again.">
              <UtilityNavigation
                bannerContent={bannerContent}
                closeMenu={closeMenu}
                closeUtilityDropDown={closeUtilityDropDown}
                navigation={navigation}
                pinned={pinned}
                toggleCurrentDropdown={toggleCurrentDropdown}
                isDropdownOpen={isShoppingMenuOpen}
                isCartOpen={isCartOpen}
                isFiltersPopoutOpen={isFiltersPopoutOpen}
                isMobileMenuOpen={isMobileMenuOpen}
                dropdownStates={dropdownStates}
              />
            </ErrorBoundary>
          </div>
          <div
            onMouseLeave={() => {
              if (!isMobileMenuOpen) {
                setTimeout(() => closeMenu({ preventMobile: true }), 0);
              }
            }}
            data-testid="shopping-navigation"
            className={withCssModulesClassNamesHandler(
              'header-navigation-container',
              {
                'with-popout': isFiltersPopoutOpen || isCartOpen || isMobileMenuOpen,
                open: isShoppingMenuOpen,
                'search-open': isSearchDropdownOpen,
              },
            )}
          >
            <div className={withCssModulesClassNamesHandler('header-navigation-content')}>
              <ErrorBoundary errorMessage="Could not load the navigation, try again.">
                <ShoppingNavigation
                  cartNumber={cartNumber}
                  closeMenu={closeMenu}
                  isLoggedIn={isLoggedIn}
                  experiments={experiments}
                  isDesktop={isDesktopDevice}
                  isMobileMenuOpen={isMobileMenuOpen}
                  navigation={navigation}
                  openCart={openCart}
                  dropdownStates={dropdownStates}
                  toggleCurrentDropdown={toggleCurrentDropdown}
                  toggleMobileMenu={toggleMobileMenu}
                  isDropdownOpen={isShoppingMenuOpen}
                  isSearchDropdownOpen={isSearchDropdownOpen}
                />
              </ErrorBoundary>
            </div>
            <div
              className={withCssModulesClassNamesHandler(
                'header-navigation-portal',
                {
                  'header-navigation-portal--hidden': isMobileMenuOpen || isFiltersPopoutOpen || isCartOpen,
                },
              )}
            />
          </div>
        </div>
        {
          // don't render the mobile menu if the window width is greater than 1024
          !isDesktopDevice && (
            <ErrorBoundary errorMessage="Could not load the navigation, try again.">
              <MobileMenu
                closeMenu={closeMenu}
                isLoggedIn={isLoggedIn}
                isMobileMenuOpen={isMobileMenuOpen}
                experiments={experiments}
                navigation={navigation}
                openCart={openCart}
                dropdownStates={dropdownStates}
                toggleCurrentDropdown={toggleCurrentDropdown}
                visibleGalleryID={deepestNavItemWithGallery?.navigationId}
              />
            </ErrorBoundary>
          )
        }
        {productDetails ? <ProductPreviewLoadable /> : null}
        <PopoutUnderlay
          closePopouts={closePopouts}
          isPopoutOpen={isCartOpen || isFiltersPopoutOpen}
          isMobileMenuOpen={isMobileMenuOpen}
          isDropdownOpen={isShoppingMenuOpen}
          isSearchDropdownOpen={isSearchDropdownOpen}
        />
      </header>
    </>
  );
};

export default Header;
