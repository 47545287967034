import {
  all, call, fork, put, select, takeEvery, takeLatest,
} from 'redux-saga/effects';
import qs from 'qs';
import Router from 'next/router';
import lowerFirst from 'lodash/lowerFirst';
import camelCase from 'lodash/camelCase';

import { gql } from '@apollo/client/core';
import {
  FETCH_EDIT_MODAL_PRODUCT_DETAILS,
  FETCH_PRODUCT_DETAILS,
  FETCH_PRODUCT_DETAILS__SUCCESS,
  fetchEditModalProductDetailsFailure,
  fetchEditModalProductDetailsRequest,
  fetchEditModalProductDetailsSuccess,
  fetchProductAvailability as fetchProductAvailabilityAction,
  fetchProductAvailabilityRequest,
  fetchProductAvailabilitySuccess,
  fetchProductAvailabilityFailure,
  FETCH_PRODUCT_AVAILABILITY,
  fetchProductDetails,
  fetchProductDetailsFailure,
  fetchProductDetailsRequest,
  fetchProductDetailsSuccess,
  fetchProductPromotionsFailure,
  fetchProductPromotionsRequest,
  fetchProductPromotionsSuccess,
  fetchProductShippingEstimateSuccess,
  LOAD_PRODUCT_VARIANT,
  setAddOnModifiers,
  setSelectedModifiers,
  setSelectedQty,
  TOGGLE_MODIFIER_OPTION,
  fetchAddonModifierOptionsInventory as fetchAddonModifierOptionsInventoryAction,
  fetchAddonModifierOptionsInventoryRequest,
  fetchAddonModifierOptionsInventorySuccess,
  fetchAddonModifierOptionsInventoryFailure,
  FETCH_ADDON_MODIFIER_OPTION__INVENTORY,
} from '../actions/product.actions';
import { setErrorPopupMessage, replace, push } from '../actions/ui.actions';

import {
  getProductId,
  getProductSku,
  getProductDetails,
  getProductPromotions,
  getSelectedModifiers,
  getSelectedQty,
  getaddOnOptionsWithMatchingRelatedProducts,
  getProductModifiers,
  getIsSetProduct,
  getSetProductsIds,
  getSetProductsSkus,
} from '../selectors/product.selectors';
import { getLocation, getQueryParams, getQueryParamsAsString } from '../selectors/router.selectors';

import {
  attachInventoryToAddOnOptions,
  generateSku,
  getAddToCartModifierParams,
  getCircaHeadboardAddonSKU,
  getChorusHeadboardAddonSKU,
  getInventory,
  getPromotion,
  getRangeTableAddonSKU,
  reapplyQueryParams as reapplyQueryParamsHelper,
} from '../../components/global/ProductHelpers';
import RequestHelpers from '../../components/global/RequestHelpers';
import { getVariantQuery } from '../../../../apis/QueryBuilder';
import { getAddOnsParentSkusFromQueryParams, logError } from '../../../../utils/Utils';
import Analytics from '../../components/global/Analytics';
import { getUserDetails } from '../selectors/user.selectors';
import { getCartItems } from '../selectors/cart.selectors';
import { getHrefLang } from '../selectors/general.selectors';
import { getPageError } from '../selectors/page.selectors';
import { isNextApp, isSSG } from '../selectors/ui.selectors';
import LocalizationUtils from '../../../../utils/localization.utils';
import { filterSleeperAddonItems } from '../../../../utils/sleepkit.utils';
import HrefLang from '../../../../shared/localization/hrefLang';

/**
 * @param {boolean} nextSSG
 * @param {string} url
 * @param {'push' | 'replace'} method
 */
// eslint-disable-next-line consistent-return
function* nextClientRedirect({
  nextSSG,
  url,
  method = 'push',
}) {
  if (nextSSG) {
    yield call(Router[method], Router, url, { shallow: true });
    return;
  }
  window.location.href = url;
}

export function* fetchProductPromotionsSaga() {
  try {
    const promotions = yield select(getProductPromotions);
    yield put(fetchProductPromotionsRequest());
    const fetchedPromotions = yield all(
      promotions.map(({ id }) => call(getPromotion, id)),
    );
    yield put(fetchProductPromotionsSuccess(fetchedPromotions));
  } catch ({ message }) {
    yield call([this, logError], `[product.sagas:fetchProductPromotionsSaga] Error: "${message}"`, fetchProductPromotionsSaga.name);
    yield put(fetchProductPromotionsFailure(message));
  }
}

export function* loadProductVariantSaga({ payload = {} }) {
  const { url } = payload;
  yield put(fetchAddonModifierOptionsInventoryAction());
  yield put(fetchProductAvailabilityAction());
  yield fork(fetchProductPromotionsSaga);

  if (url) {
    const hrefLang = yield select(getHrefLang);
    const isLocalized = yield call(() => LocalizationUtils.isLocalized(hrefLang));
    let pageUrl = url;
    if (isLocalized && !url.includes(hrefLang)) {
      pageUrl = `/${hrefLang}${url}`;
    }
    const currentLocation = yield select(getLocation);
    const queryParamsAsString = yield select(getQueryParamsAsString);
    if (pageUrl !== `${currentLocation.pathname}${queryParamsAsString ? `?${queryParamsAsString}` : ''}`) {
      const nextApp = yield select(isNextApp);
      const nextSSG = yield select(isSSG);
      if (nextApp) {
        yield nextClientRedirect({
          nextSSG,
          url: pageUrl,
        });
        return;
      }
      yield put(push(pageUrl));
    }
  }
}

export function* fetchAddonModifierOptionsInventory() {
  try {
    yield put(fetchAddonModifierOptionsInventoryRequest());
    const addOnOptionsWithMatchingRelatedProducts = yield select(getaddOnOptionsWithMatchingRelatedProducts);
    const productModifiers =  yield select(getProductModifiers);
    const productModifiersWithInventory = yield call(attachInventoryToAddOnOptions, ({ addOnOptionsWithMatchingRelatedProducts,  productModifiers }));
    yield put(fetchAddonModifierOptionsInventorySuccess(productModifiersWithInventory));
  } catch (error) {
    yield put(fetchAddonModifierOptionsInventoryFailure(error.message));
    yield call([this, logError], error, fetchAddonModifierOptionsInventory.name);
  }
}

export function* fetchProductAvailability() {
  try {
    yield put(fetchProductAvailabilityRequest());
    const isSetProduct = yield select(getIsSetProduct);
    const skus = isSetProduct ? yield select(getSetProductsSkus) : [yield select(getProductSku)];
    const productIds = isSetProduct ? yield select(getSetProductsIds) : [yield select(getProductId)];
    const { inventory, channelAvailability = [], shippingMessage } = yield call(getInventory, skus.join(','), productIds);
    yield put(fetchProductAvailabilitySuccess({
      inventory,
      channelAvailability,
      skus,
    }));
    yield put(fetchProductShippingEstimateSuccess({
      sku: skus[0],
      shippingMessage,
    }));
  } catch (error) {
    yield call([this, logError], error, fetchProductAvailability.name);
    yield put(fetchProductAvailabilityFailure(error));
  }
}

export function* fetchEditModalProductDetailsSaga(action) {
  try {
    yield put(fetchEditModalProductDetailsRequest());
    const { sku } = action.payload;
    const query = gql`query GetProductDetailsAndInventory {
      inventory(sku: "${sku}"){
        availableInventory
      },
      product: variantBySKU(sku: "${sku}") {
        ${getVariantQuery(true)}
      }
    }`;
    const { product, inventory: { availableInventory } } = yield call(RequestHelpers.apolloGqlQuery, query);

    product.inventory = availableInventory;
    product.parent = product.product;

    yield put(fetchEditModalProductDetailsSuccess(product));
    const cartItems = yield select(getCartItems) || [];
    const cartItem = cartItems.find(
      ({ variantId }) => parseInt(variantId, 10) === parseInt(product.id, 10),
    );
    if (cartItem.quantity) {
      yield put(setSelectedQty(cartItem.quantity));
    }

    try {
      Analytics.enqueue({
        method: 'item',
        params: {
          event: 'Product',
          action: 'Viewed for Edit',
          item: product,
          label: 'New Product Loaded for Edit',
        },
      });
    } catch (e) { /* nothing to do */ }
  } catch (error) {
    yield call([this, logError], error, fetchEditModalProductDetailsSaga.name);
    yield put(setErrorPopupMessage(error.message));
    yield put(fetchEditModalProductDetailsFailure(error));
  }
}

export const genVariantBySKUQuery = (querySku, addOnItems, isClearCacheFlagPresent) => gql`query GetVariantBySKU {
  product: variantBySKU(sku: "${querySku}"${addOnItems?.length ? `, addOnItems: "${addOnItems}"` : ''}${isClearCacheFlagPresent ? ',cache:false' : ''}) {
    ${getVariantQuery(true)}
  }
}`;

function* queryProductDetailsWithFallback(payload, pathname, queryParams) {
  const {
    sku,
    fallbackSku,
    addOnItems = [],
  } = payload;

  const addOnItemsFiltered = filterSleeperAddonItems(pathname, addOnItems);

  const isClearCacheFlagPresent = queryParams.useCache === 'false';

  try {
    const { product } = yield call(RequestHelpers.apolloGqlQuery, genVariantBySKUQuery(sku, addOnItemsFiltered, isClearCacheFlagPresent));

    return { product, sku };
  } catch (e) {
    if (fallbackSku) {
      const { product } = yield call(RequestHelpers.apolloGqlQuery, genVariantBySKUQuery(fallbackSku, [], isClearCacheFlagPresent));
      return { product, sku: fallbackSku };
    }
    throw e;
  }
}

export function* fetchProductDetailsSaga(action) {
  try {
    yield put(fetchProductDetailsRequest());
    const {
      reapplyQueryParams,
      selectedModifierOptionValue,
    } = action.payload;
    const nextApp = yield select(isNextApp);
    const { pathname } = yield select(getLocation);
    const queryParams = yield select(getQueryParams);
    const hrefLang = yield select(getHrefLang);
    const isClearCacheFlagPresent = queryParams.useCache === 'false';

    const { product, sku } = yield call(queryProductDetailsWithFallback, action.payload, pathname, queryParams);

    product.parent = product.product;

    let url = product?.seo?.url;

    if (hrefLang && hrefLang !== HrefLang.EN_US && !url?.includes(hrefLang)) {
      url = `/${hrefLang}${url}`;
    }

    const skuParse = product?.skuParse;

    if (action.withRedirect) {
      if (reapplyQueryParams) {
        const reappliedQueryParams = reapplyQueryParamsHelper(queryParams, sku, product.productModifiers, product.skuSplit,  selectedModifierOptionValue, skuParse);
        yield put(fetchProductDetailsSuccess(product));
        const urlToReplace = `${url}?${qs.stringify(reappliedQueryParams)}${isClearCacheFlagPresent ? '&useCache=false' : ''}`;
        const nextSSG = yield select(isSSG);
        if (nextApp) {
          yield nextClientRedirect({
            nextSSG,
            url: urlToReplace,
            method: 'replace',
          });
          return;
        }

        yield put(replace(urlToReplace));
        return;
      }
      if (url !== pathname && sku !== queryParams?.sku) {
        const urlToPush = `${url}?${qs.stringify({ sku })}`;
        const nextSSG = yield select(isSSG);
        if (nextApp) {
          yield nextClientRedirect({
            nextSSG,
            url: urlToPush,
          });
        } else {
          yield put(push(urlToPush));
        }
      }
    }

    yield put(fetchProductDetailsSuccess(product));
  } catch (error) {
    // if page was not found, do not show error popup
    const pageContentError = yield select(getPageError);
    if (pageContentError?.message === 'Page not found') {
      return;
    }

    yield call([this, logError], error, fetchProductDetailsSaga.name);
    yield put(setErrorPopupMessage(error.message));
    yield put(fetchProductDetailsFailure(error));
  }
}

export function* handleUpdateAddonItemVariant(action, pathname, queryParams, skuSplit) {
  const {
    modifier: {
      slug,
    },
    option,
  } = action.payload;

  const {
    updateAddOnQueryParam,
    addOnParams,
  } = getAddToCartModifierParams(slug, option, skuSplit);
  const nextApp = yield select(isNextApp);
  const nextSSG = yield select(isSSG);

  if (updateAddOnQueryParam) {
    const queryParameters = qs.stringify({ ...queryParams, ...addOnParams });
    if (nextApp) {
      const urlToReplace = `${pathname}${queryParameters.length > 0 ? `?${queryParameters}` : ''}`;
      yield nextClientRedirect({
        nextSSG,
        url: urlToReplace,
        method: 'replace',
      });
      return;
    }
    yield put(replace({ pathname, search: queryParameters }));
  } else {
    yield put(setAddOnModifiers(addOnParams));
  }
}

export function getSkuReplace(modifier, parsedOptionValue) {
  const { onSelectFunction } = modifier;
  return {
    [lowerFirst(onSelectFunction)]:
      onSelectFunction === 'SetMaterialColor'
        ? `${modifier.subTitle.toLowerCase()}-${parsedOptionValue}`
        : parsedOptionValue,
  };
}

export function* handleUpdateVariant(action, pathname, queryParams, skuSplit, productType, parsedOptionValue, skuParse = {}) {
  const {
    modifier,
    option: {
      value: optionValue,
    },
  } = action.payload;

  const skuReplace = getSkuReplace(modifier, parsedOptionValue);
  const sku = yield call(generateSku, skuReplace, skuSplit, skuParse);

  if (queryParams.sku !== sku) {
    const addOnItems = getAddOnsParentSkusFromQueryParams(queryParams, pathname);
    yield put(fetchProductDetails(
      {
        sku,
        productType,
        addOnItems,
        reapplyQueryParams: true,
        selectedModifierOptionValue: optionValue,
      },
      action.withRedirect,
    ));
  }
}

export function* handleAttachAddonItem(action, pathname, queryParams, pickerBehaviour, parsedOptionValue, optionLabel) {
  const {
    modifier: {
      slug,
      shouldUpdateQueryParam,
    },
    operation,
  } = action.payload;
  const queryValue = queryParams[slug];

  if (shouldUpdateQueryParam) {
    const nextApp = yield select(isNextApp);
    const nextSSG = yield select(isSSG);
    const queryParameters = qs.stringify({
      ...queryParams,
      [slug]: (() => {
        switch (pickerBehaviour) {
          case 'CHECKBOX':
            return queryValue ? undefined : parsedOptionValue;
          case 'RADIO':
            return parsedOptionValue;
          case 'COUNTER': {
            const count = +(queryValue?.split('-')[0] || 0) + ((operation === 'ADD' && 1) || (operation === 'SUBTRACT' && -1) || 0);
            return count > 0 ? `${count}-${parsedOptionValue}` : undefined;
          }
          default:
            return undefined;
        }
      })(),
    });

    const urlToReplace = `${pathname}${queryParameters.length > 0 ? `?${queryParameters}` : ''}`;
    if (nextApp) {
      yield nextClientRedirect({
        nextSSG,
        url: urlToReplace,
        method: 'replace',
      });
    } else {
      yield put(replace({
        pathname,
        search: queryParameters,
      }));
    }

    return !!queryValue;
  }

  const selectedModifiers = yield select(getSelectedModifiers);
  yield put(setSelectedModifiers({
    ...selectedModifiers,
    [slug]: (() => {
      switch (pickerBehaviour) {
        case 'CHECKBOX':
          return selectedModifiers[slug] === optionLabel ? undefined : optionLabel;
        case 'RADIO':
          return optionLabel;
        default:
          return undefined;
      }
    })(),
  }));
  yield put(setAddOnModifiers({}));
  return selectedModifiers[slug] === optionLabel;
}

export function* toggleModifierOptionSaga(action) {
  try {
    const {
      skuSplit,
      productType,
      sku: currentProductSKU,
      name,
      skuParse,
    } = yield select(getProductDetails);
    const { pathname } = yield select(getLocation);
    const queryParams = yield select(getQueryParams);

    const {
      modifier: {
        modifierStyle,
        modifierOperation,
        type,
        title: modifierTitle,
        slug,
        onSelectFunction,
      },
      option: {
        value: optionValue,
        label: optionLabel,
      },
      option,
    } = action.payload;

    const pickerBehaviour = {
      List: 'RADIO',
      ColorPicker: 'RADIO',
      PickList: 'CHECKBOX',
      AddToCart: 'CHECKBOX',
      AddToCartModifier: 'RADIO',
      Checkbox: 'CHECKBOX',
      RadioDials: 'RADIO',
      Icons: 'RADIO',
      Buttons: 'RADIO',
      Counter: 'COUNTER',
    }[modifierStyle || type];

    const parsedOptionValue = (() => {
      switch (slug) {
        case 'round-tray':
          return `${optionValue}-${skuSplit.color}`;
        case 'table-add-on':
          return getRangeTableAddonSKU(skuSplit, option);
        case 'headboard-add-on':
          return getCircaHeadboardAddonSKU(skuSplit, option);
        case 'headboard-3-add-on':
          return getChorusHeadboardAddonSKU(skuSplit, option);
        default:
          return optionValue;
      }
    })();

    const analytics = {};

    const getModifierOperation = () => {
      if (modifierOperation === 'UpdateAddonItemVariant' && skuSplit[camelCase(slug)]) {
        return '';
      }

      return modifierOperation;
    };

    if (modifierOperation) {
      switch (getModifierOperation()) {
        case 'UpdateAddonItemVariant':
          yield call(handleUpdateAddonItemVariant, action, pathname, queryParams, skuSplit);
          break;
        case 'UpdateVariant':
          yield call(handleUpdateVariant, action, pathname, queryParams, skuSplit, productType, parsedOptionValue, skuParse);
          break;
        case 'AttachAddonItem':
        case 'UpdateGalleryImage':
          analytics.modifierOptionUnselected = yield call(handleAttachAddonItem, action, pathname, queryParams, pickerBehaviour, parsedOptionValue, optionLabel);
          break;
        default:
          break;
      }
      // Scenario 1,2 and 3 have to be deleted once the type field is retired and all modifier operation fields are filled
    } else if (type === 'AddToCartModifier' && !skuSplit[camelCase(slug)]) {
      // Scenario #1 handles updates to add on items
      yield call(handleUpdateAddonItemVariant, action, pathname, queryParams, skuSplit);
    } else if (onSelectFunction) {
      // Scenario #2 handles the updating of product variants
      yield call(handleUpdateVariant, action, pathname, queryParams, skuSplit, productType, parsedOptionValue, skuParse);
    } else  {
      // Scenario #3 handles the attaching of add on items when shouldUpdateQueryParam is set or not
      analytics.modifierOptionUnselected = yield call(handleAttachAddonItem, action, pathname, queryParams, pickerBehaviour, parsedOptionValue, optionLabel);
    }

    // Analytics
    const userDetails = yield select(getUserDetails);
    const quantity = yield select(getSelectedQty);
    const {
      email = '',
    } = userDetails;

    yield call([Analytics, Analytics.updateUserIdentityAndIdentify], { email });

    Analytics.enqueue({
      method: 'PDPAction',
      params: {
        event: 'PDP Modifier',
        item: {
          name,
          sku: currentProductSKU,
        },
        label: modifierTitle,
        email,
        quantity,
        action: `${analytics.modifierOptionUnselected ? 'Removed' : 'Added'}`,
        modifierName: optionLabel || modifierTitle,
        modifierChange: optionValue,
      },
      location: Analytics.getLocation(),
    });
  } catch (error) {
    yield call([this, logError], error, toggleModifierOptionSaga.name);
  }
}

const productSagas = [
  takeLatest([
    LOAD_PRODUCT_VARIANT,
    FETCH_PRODUCT_DETAILS__SUCCESS,
  ], loadProductVariantSaga),
  takeLatest(FETCH_ADDON_MODIFIER_OPTION__INVENTORY, fetchAddonModifierOptionsInventory),
  takeLatest(FETCH_PRODUCT_AVAILABILITY, fetchProductAvailability),
  takeLatest(FETCH_PRODUCT_DETAILS, fetchProductDetailsSaga),
  takeLatest(FETCH_EDIT_MODAL_PRODUCT_DETAILS, fetchEditModalProductDetailsSaga),
  takeEvery(TOGGLE_MODIFIER_OPTION, toggleModifierOptionSaga),
];
export default productSagas;
