import SliderContainer, { Slide } from '../../global/Slider';
import { withCssModulesClassNames } from '../../../common/nextMigrationHelpers';
import {
  AssetDto,
  ModalDto,
  ProductCollectionDto,
} from '../../../types/schema';
import { Nullable } from '../../../types/common';
// eslint-disable-next-line import/no-named-default
import { default as styles } from '../../../scss/components/FavoriteCard.module.scss';
import Asset from '../../global/Asset';

const withCssModulesClassNamesHandler = withCssModulesClassNames(styles);

export interface FavoriteCardDetailsModalProps {
  modal: ModalDto;
  collectionHeader: ProductCollectionDto['header'];
}

const DOUBLE_LINE_BREAK = '\n\n';
export const FAVORITE_CARD_DETAILS_MODAL_CONTAINER_TEST_ID = 'favorite-card-details-modal-container';

export const splitTextByTitleAndContent = (text?: Nullable<string>) => {
  const textSplitByDoubleLineBreak = text?.split(DOUBLE_LINE_BREAK) ?? [];
  const title = textSplitByDoubleLineBreak.shift();

  return {
    content: textSplitByDoubleLineBreak.join(DOUBLE_LINE_BREAK),
    title: `${title ?? ''}${(title && DOUBLE_LINE_BREAK) ?? ''}`,
  };
};

const FavoriteCardDetailsModal = ({
  modal,
  collectionHeader,
}: FavoriteCardDetailsModalProps) => {
  const {
    title: copyTitle,
    content: copyContent,
  } = splitTextByTitleAndContent(modal.copy);
  const {
    title: footnoteTitle,
    content: footnoteContent,
  } = splitTextByTitleAndContent(modal.footnote);

  return (
    <div
      data-testid={FAVORITE_CARD_DETAILS_MODAL_CONTAINER_TEST_ID}
      className={withCssModulesClassNamesHandler('favorite-card-details-modal-container')}
    >
      <p className={withCssModulesClassNamesHandler('favorite-card-details-modal-title')}>
        {collectionHeader}
      </p>
      <p className={withCssModulesClassNamesHandler('favorite-card-details-modal-subtitle')}>
        {modal.header}
      </p>
      <SliderContainer
        arrows={false}
        className={withCssModulesClassNamesHandler('favorite-card-details-modal-slider-container')}
        paginationClassName={withCssModulesClassNamesHandler('favorite-card-details-modal-slider-pagination')}
      >
        {
          modal.assets && modal.assets.map((img: AssetDto) => (
            <Slide
              data-testid="galleryImageRenderer"
              key={img.handle}
            >
              <Asset
                asset={{
                  handle: img.handle,
                  width: img.width || 0,
                  height: img.height || 0,
                }}
              />
            </Slide>
          ))
        }
      </SliderContainer>
      <p className={withCssModulesClassNamesHandler('favorite-card-details-modal-copy')}>
        <span>{copyTitle}</span>
        <span>{copyContent}</span>
      </p>
      <p className={withCssModulesClassNamesHandler('favorite-card-details-modal-footnote')}>
        <span>{footnoteTitle}</span>
        <span>{footnoteContent}</span>
      </p>
    </div>
  );
};

export default FavoriteCardDetailsModal;
