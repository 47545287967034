import UniversalLink from '../UniversalLink';
import ProductCardContainer, { DEFAULT_ITEM_STATE } from '../../shopping/ProductCard';
import { SearchProductsResponse } from '../../../../../Product/dto/search.dto';
import { withCssModulesClassNames } from '../../../common/nextMigrationHelpers';
import { default as searchDropdownStyles } from '../../../scss/components/SearchDropdown.module.scss';

interface SearchDropdownResultsProps {
  totalProducts: number;
  searchTermInputValue: string;
  searchProducts: SearchProductsResponse['products'];
}

const withCssModulesClassNamesHandler = withCssModulesClassNames(searchDropdownStyles);

export const SEARCH_DROPDOWN_RESULTS_CONTAINER_TEST_ID = 'search-dropdown-results-container';

const SearchDropdownResults = ({
  totalProducts,
  searchProducts,
  searchTermInputValue,
}: SearchDropdownResultsProps) => (
  <div
    data-testid={SEARCH_DROPDOWN_RESULTS_CONTAINER_TEST_ID}
    className={withCssModulesClassNamesHandler('search-dropdown-products-grid-container')}
  >
    {
      searchProducts.map(({
        sku, path, name, pricing, defaultImageUrl,
      }) => (
        <ProductCardContainer
          key={`product-card-${sku}`}
          visibilityClassNames={withCssModulesClassNamesHandler('product-card-dropdown')}
          item={{
            ...DEFAULT_ITEM_STATE,
            name,
            sku,
            id: sku,
            url: path,
            pricing: {
              ...pricing,
              addOnSavings: null,
              variantSaleRange: null,
              variantPriceRange: null,
            },
            productCardImage: [
              {
                sort: 1,
                type: 'Asset',
                images: [
                  {
                    alt: name,
                    url: defaultImageUrl,
                    fileName: defaultImageUrl.split('/').pop() ?? '',
                    handle: '',
                    width: 0,
                    height: 0,
                    mimeType: '',
                    assetSources: [],
                    screenSize: null,
                    externalAssetUrl: null,
                  },
                ],
              },
            ],
          }}
          source="search"
          hideColorPicker
          hideDetails={false}
          waypointProps={{}}
        />
      ))
    }
    {
      totalProducts > 5 && (
        <div className={withCssModulesClassNamesHandler('search-dropdown-products-view-all')}>
          <UniversalLink
            type="Page"
            to={`/search?term=${searchTermInputValue}`}
          >
            {`View all (${totalProducts}) results →`}
          </UniversalLink>
        </div>
      )
    }
  </div>
);

export default SearchDropdownResults;
