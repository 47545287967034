import { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Waypoint } from 'react-waypoint';

import {
  loadProductVariant as loadProductVariantAction,
} from '../../store/actions/product.actions';
import { getUserDetails } from '../../store/selectors/user.selectors';
import {
  getActiveLayout,
  getActiveLayoutGroup,
  getActiveFilters,
} from '../../store/selectors/collections.selectors';
import { getIsMobile, getExperiments } from '../../store/selectors/ui.selectors';
import { getHrefLang, getIsUsLang } from '../../store/selectors/general.selectors';
import { useTypedSelector } from '../../store/hooks';

import {
  applyModifiersToProductName,
  getRangeTableFinishFromAddOns,
  getAppropriateGalleryAsset,
  getAddOnItems,
  getVariantBySKU,
  getProductURL,
} from '../global/ProductHelpers';
import ProductCardColorPicker from './ProductCardColorPicker';
import ReviewsRatingWidget from './ReviewsRatingWidget';
import ProductCardImage from './ProductCardImage';
import Analytics from '../global/Analytics';
import UniversalLinkContainer from '../global/UniversalLink';
import { generateLocalizedPriceObject, lowerFirst } from '../../../../utils/Utils';
import { withCssModulesClassNames } from '../../common/nextMigrationHelpers';
import { default as styles } from '../../scss/components/ProductCard.module.scss';
import { Nullable } from '../../types/common';
import {
  ProductDto,
  CallToActionDto,
  ProductModifierDto,
  OnSelectFunction,
  ModifierActiveStateProp,
  HrefLang,
  PricingDto,
} from '../../types/schema';
import { onChooseColorOption, setupProduct } from './ProductCard.helpers';
import type { RootState } from '../../store/store.types';

const withCssModulesClassNamesHandler = withCssModulesClassNames(styles);
/**
 * The Product Card component shows product information in a succinct card.
 */

export interface ProductCardProps {
  sku?: Nullable<string>;
  source?: string;
  position?: number;
  tabIndex?: number;
  hrefLang?: string;
  className?: string;
  underline?: boolean;
  hideImage?: boolean;
  hideAffirm?: boolean;
  trackingName?: string;
  hideDetails?: boolean;
  addOnItems?: string[];
  showroomCard?: boolean;
  item?: ProductDto;
  hideAffirmFlag?: boolean;
  salesCopy?: Nullable<string>;
  flipSetting?: Nullable<string>;
  callToActions?: CallToActionDto[];
  tuftedSetting?: Nullable<string>;
  hideColorPicker?: Nullable<boolean>;
  waypointProps?: Waypoint.WaypointProps;
  visibilityClassNames?: Nullable<string>;
  setItemViewedCallback?: (cb: () => void) => void;
  plpSiteWideText: RootState['ui']['plpSiteWideText'];
}

export const getActiveModifiers = (item: ProductDto) => {
  const {
    productModifiers,
    skuSplit,
  } = item;

  const productModifier = productModifiers.find(
    ({ onSelectFunction, subTitle }) => onSelectFunction === 'SetColor'
      || (onSelectFunction === 'SetMaterialColor'
        && subTitle?.toLowerCase() === skuSplit.fabricMaterial),
  );
  const onSelect = productModifier?.onSelectFunction
    && lowerFirst<OnSelectFunction>(productModifier.onSelectFunction);
  const activeVariant = productModifier?.activeStateProp
    && lowerFirst<ModifierActiveStateProp>(productModifier.activeStateProp);
  const variantOptions = productModifier && productModifier.options
    .map(({
      borderColor,
      colors,
      description,
      value,
    }) => ({
      activeVariant,
      borderColor,
      colors,
      description,
      value,
    }));

  return {
    activeArms: null,
    activeSize: null,
    activeTufted: null,
    activeMaterial: null,
    activeTableTop: null,
    activeCoverOrientation: null,
    activeColor: skuSplit.color,
    activeArm: skuSplit.arms,
    activeLegs: skuSplit.legs,
    activeSeat: skuSplit.size,
    activeModel: skuSplit.model,
    activeCollection: skuSplit.collection,
    activeLegStyle: skuSplit.legStyle,
    activeCushionColor: skuSplit.cushionColor,
    activeFurnitureType: skuSplit.furnitureType,
    activeTableFinish: skuSplit.tableFinish,
    activeVariant,
    product: skuSplit.type,
    onSelect,
    variations: variantOptions || [],
  };
};

const getProductCardSelectedModifiers = (
  productModifiers: ProductModifierDto[],
  addOns: ReturnType<typeof getAddOnItems>,
) => (
  productModifiers.map((modifier) => {
    const selectedOptions = modifier.options.map((opt) => ({
      ...opt,
      selected: addOns.some((addOn) => addOn.value?.includes(opt.value)),
    }));
    return {
      ...modifier,
      options: selectedOptions,
    };
  })
);

export const getVariantStateParams = ({
  sku,
  item,
  flipSetting,
  tuftedSetting,
  addOnItems = [],
}: {
  item: ProductDto;
  sku?: Nullable<string>;
  addOnItems?: string[];
  flipSetting?: Nullable<string>;
  tuftedSetting?: Nullable<string>;
}) => {
  const {
    name,
    productModifiers,
  } = item;
  const options = {
    tufted: tuftedSetting,
    door: flipSetting,
  };
  const url = getProductURL(item, sku, addOnItems, options);
  const activeModifiers = getActiveModifiers(item);

  const addOns = getAddOnItems(addOnItems, productModifiers);
  const productName = applyModifiersToProductName(name, addOns);
  const activeTableFinish = getRangeTableFinishFromAddOns({
    tableFinish: activeModifiers.activeTableFinish,
    legs: activeModifiers.activeLegs,
  }, addOns);
  const selectedProductModifiers = getProductCardSelectedModifiers(productModifiers, addOns);

  return {
    ...activeModifiers,
    url,
    item,
    addOns,
    productName,
    activeTableFinish,
    productModifiers: selectedProductModifiers,
  };
};

export const DEFAULT_ITEM_STATE: ProductDto = {
  id: '',
  sku: '',
  name: '',
  url: '/',
  pricing: {
    addOnSavings: null,
    variantSaleRange: null,
    variantPriceRange: null,
    originalPrice: null,
    salePrice: null,
    price: {
      formatted: '~',
      raw: 0,
      inCents: 0,
    },
  },
  price: 0,
  type: '',
  skuID: 0,
  image: '',
  thumb: '',
  brand: '',
  gallery: [],
  category: '',
  filter: null,
  productID: 0,
  inventory: 0,
  skuSplit: {},
  metaImage: '',
  modifiers: [],
  parentSku: '',
  breadcrumbs: {},
  dimensions: [],
  seatType: null,
  collection: '',
  productType: '',
  setProducts: [],
  addOnSavings: 0,
  optionValues: [],
  promotions: null,
  description: null,
  dimensionImages: [],
  inferredAddOn: null,
  relatedProducts: [],
  activeModifier: null,
  productModifiers: [],
  addOnSavingsCopy: null,
  optionsDescription: '',
  sellWithoutInventory: null,
  seo: {
    url: '/',
  },
  productCardImage: [],
};

const DEFAULT_ACTIVE_MODIFIERS_STATE: Partial<ReturnType<typeof getActiveModifiers>> = {};

const DEFAULT_STATE = {
  url: '/',
  position: 1,
  addOns: [] as ReturnType<typeof getAddOnItems>,
  productName: '' as ReturnType<typeof applyModifiersToProductName>,
  variations: [] as ReturnType<typeof getActiveModifiers>['variations'],
  productModifiers: [] as ReturnType<typeof getProductCardSelectedModifiers>,
  item: {
    ...DEFAULT_ITEM_STATE,
  },
  ...DEFAULT_ACTIVE_MODIFIERS_STATE,
};

export type ProductCardState = typeof DEFAULT_STATE;

export const generateLocalizedPrices = ({
  addOns,
  hrefLang,
  pricing,
}: {
  addOns: ReturnType<typeof getAddOnItems>;
  hrefLang: HrefLang;
  pricing: Pick<PricingDto, 'price' | 'originalPrice' | 'salePrice'>;
}) => {
  let { price, salePrice, originalPrice } = pricing;
  const addOnPrice = addOns.length ? addOns.reduce(
    (acc, addOn) => acc + ((addOn.pricing?.price ?? addOn.priceAdjust) || 0),
    0,
  ) : 0;

  price = price?.raw ? generateLocalizedPriceObject({
    price: price.raw + addOnPrice,
    hrefLang,
  }) : null;
  salePrice = salePrice?.raw ? generateLocalizedPriceObject({
    price: salePrice.raw + addOnPrice,
    hrefLang,
  }) : null;
  originalPrice = originalPrice?.raw ? generateLocalizedPriceObject({
    price: originalPrice.raw + addOnPrice,
    hrefLang,
  }) : null;

  return {
    price: price || {
      ...DEFAULT_ITEM_STATE.pricing.price,
    },
    salePrice,
    originalPrice,
  };
};

const ProductCard = ({
  sku,
  item,
  tabIndex,
  hideImage,
  underline,
  className,
  salesCopy,
  flipSetting,
  showroomCard,
  tuftedSetting,
  setItemViewedCallback,
  addOnItems = [],
  source = 'plp',
  hideAffirm = false,
  callToActions = [],
  waypointProps = {},
  hideDetails = false,
  hideColorPicker = false,
  visibilityClassNames = null,
  trackingName = 'Product Card',
  position = DEFAULT_STATE.position,
  plpSiteWideText = null,
}: ProductCardProps) => {
  const dispatch = useDispatch();
  const isMobile = useSelector(getIsMobile);
  const hrefLang = useSelector(getHrefLang);
  const isUsLang = useSelector(getIsUsLang);
  const userDetails = useSelector(getUserDetails);
  const activeLayout = useSelector(getActiveLayout);
  const activeFilters = useSelector(getActiveFilters);
  const activeLayoutGroup = useSelector(getActiveLayoutGroup);
  const hideAffirmFlag = useTypedSelector((state) => (
    !!getExperiments(state)?.hideDynamicAffirm
  ));

  const productCardRef = useRef<HTMLDivElement>(null);
  const [isViewed, setIsViewed] = useState(false);
  const [
    state,
    setState,
  ] = useState(
    () => {
      if (item) {
        return {
          ...DEFAULT_STATE,
          position,
          ...(getVariantStateParams({
            item,
            sku,
            addOnItems,
            flipSetting,
            tuftedSetting,
          })),
        };
      }

      return {
        ...DEFAULT_STATE,
        position,
      };
    },
  );
  const [scrollableAncestor, setScrollableAncestor] = useState<Window | null>(null);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const selectProduct = async (label: string) => {
    const variant = await getVariantBySKU(state.item.sku, addOnItems);
    dispatch(loadProductVariantAction({ productDetails: variant }));

    try {
      Analytics.enqueue({
        method: 'item',
        params: {
          event: `${trackingName} Clicked`,
          item,
          label,
          source,
          activeFilters,
          activeLayoutGroup,
          email: userDetails.email,
        },
        location: Analytics.getLocation(),
      });
    } catch (e) { /* nothing to do */ }
  };

  const onProductCardClick = (label: string) => {
    selectProduct(label);
  };

  const productCardViewed = () => {
    try {
      if (state.item && !isViewed) {
        setIsViewed(true);
        Analytics.enqueue({
          method: 'item',
          params: {
            event: `${trackingName} Viewed`,
            item: state.item,
            label: `${trackingName} Viewed`,
            email: userDetails.email,
            activeLayoutGroup,
            activeFilters,
            source,
          },
          location: Analytics.getLocation(),
        });
      }
    } catch (e) { /* nothing to do */ }
  };

  const productCardOptionSelected = (action: string) => {
    try {
      Analytics.enqueue({
        method: 'item',
        params: {
          event: `${trackingName} Option Changed`,
          item: state.item,
          label: `${trackingName} ${action} Selected`,
          email: userDetails.email,
          activeLayoutGroup,
          activeFilters,
          source,
        },
        location: Analytics.getLocation(),
      });
    } catch (e) { /* nothing to do */ }
  };

  const renderNameAndDescription = () => {
    const { url } = state;

    const detailsCopyClassName = withCssModulesClassNamesHandler(
      'product-card__details-copy',
      underline && 'product-card__details-copy--underline',
    );

    return (
      <>
        <UniversalLinkContainer
          to={url}
          href={url}
          type="Page"
          data-id="name"
          className={detailsCopyClassName}
          clickHandler={() => onProductCardClick('name')}
        >
          {state.productName}
        </UniversalLinkContainer>
        {showroomCard && (
          <div
            data-id="name-breakdown"
            className={detailsCopyClassName}
          >
            {state.item.optionsDescription}
          </div>
        )}
      </>
    );
  };

  const renderPricing = () => {
    const {
      addOns,
      item: {
        addOnSavings,
        pricing: {
          variantPriceRange,
          variantSaleRange,
        },
      },
    } = state;
    const { price, salePrice, originalPrice } = generateLocalizedPrices({
      addOns,
      hrefLang,
      pricing: state.item.pricing,
    });

    // Show saving on any card except for bundle savings
    return salePrice && !addOnSavings
      ? (
        <span
          data-id="price"
          className={withCssModulesClassNamesHandler('product-card__details-copy', 'price')}
        >
          <span className={withCssModulesClassNamesHandler('strikethrough')}>
            {variantPriceRange || originalPrice?.formatted}
          </span>
          <span className={withCssModulesClassNamesHandler('product-card__details-copy-sale')}>
            <span className={withCssModulesClassNamesHandler('product-card__details-copy--heavy')}>
              {variantSaleRange || salePrice?.formatted}
            </span>
            {isMounted && !hideAffirm && !hideAffirmFlag && isUsLang && (
              <>
                &nbsp;
                <p
                  className={withCssModulesClassNamesHandler('affirm-as-low-as')}
                  data-page-type="marketplace"
                  data-affirm-type="text"
                  data-amount={salePrice.inCents}
                  data-learnmore-show="false"
                  data-testid="affirm-text"
                />
              </>
            )}
          </span>
          {plpSiteWideText && (
            <div className={withCssModulesClassNamesHandler('site-wide-sale-text')}>
              {plpSiteWideText}
            </div>
          )}
        </span>
      ) : (
        <div
          data-id="price"
          className={withCssModulesClassNamesHandler('product-card__details-copy', 'price')}
        >
          <span className={withCssModulesClassNamesHandler('product-card__details-copy--heavy')}>
            {variantPriceRange || price.formatted}
          </span>
          &nbsp;
          {isMounted && !hideAffirm && !hideAffirmFlag && isUsLang && (
            <p
              className={withCssModulesClassNamesHandler('affirm-as-low-as')}
              data-page-type="marketplace"
              data-affirm-type="text"
              data-amount={price.inCents}
              data-learnmore-show="false"
              data-testid="affirm-text"
            />
          )}
        </div>
      );
  };

  const renderImage = () => {
    const {
      url,
      addOns,
      productName,
      productModifiers,
      activeTableFinish,
      activeFurnitureType,
    } = state;

    const images = state.item.productCardImage
      .map((image) => getAppropriateGalleryAsset(
        image,
        state.item.sku,
        state.item.collection ?? '',
        { tufted: tuftedSetting, door: flipSetting },
        addOns,
        productModifiers,
        activeTableFinish,
        activeFurnitureType,
        state.item?.skuParse,
      )) || [];

    return (
      <ProductCardImage
        onProductCardClick={onProductCardClick}
        activeLayout={activeLayout}
        url={url}
        productName={productName}
        className={className}
        salesCopy={salesCopy}
        tabIndex={tabIndex}
        images={images}
        productCardRef={productCardRef}
        item={state.item}
        callToActions={callToActions}
        source={source}
        isMobile={isMobile}
      />
    );
  };

  const renderContent = () => (
    <div
      className={withCssModulesClassNamesHandler(
        'product-card',
        visibilityClassNames,
        {
          'product-card__showroom--two-col': showroomCard,
        },
      )}
      ref={productCardRef}
      onMouseOver={productCardViewed}
      data-testid="product-card"
    >
      <div className={withCssModulesClassNamesHandler('product-card__image-wrapper')}>
        {!hideImage && renderImage()}
      </div>
      {!hideColorPicker && (
        <ProductCardColorPicker
          variations={state.variations}
          moreOptions={{
            url: state.url,
            onClick: () => selectProduct('more-color-options'),
          }}
          activeVariant={
            (state.activeVariant && state[state.activeVariant])
            || null
          }
          displayColorPicker={!hideColorPicker}
          chooseOption={onChooseColorOption(
            state,
            setState,
            productCardOptionSelected,
            { addOnItems, flipSetting, tuftedSetting },
            item?.skuParse ?? undefined,
          )}
        />
      )}
      {!hideDetails && (
        <div className={withCssModulesClassNamesHandler('product-card__details')}>
          {renderNameAndDescription()}
          {renderPricing()}
        </div>
      )}
      {source === 'plp' && <ReviewsRatingWidget sku={sku} isPlp={true} />}
    </div>
  );

  useEffect(() => {
    setScrollableAncestor(window);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- expected to run once
  }, []);

  const renderWaypointWrapper = () => (
      <Waypoint
        onEnter={productCardViewed}
        scrollableAncestor={scrollableAncestor}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...waypointProps}
      >
        {renderContent()}
      </Waypoint>
  );

  useEffect(() => {
    let task: NodeJS.Timeout;
    if (!item && sku) {
      // Using setTimeout to defer the processing of the fetched variant data.
      // This is helpful for running multiple getVariantBySKU calls in different tasks
      // to optimize performance. By breaking down the tasks and scheduling them with
      // setTimeout, we avoid blocking the main thread, ensuring the application remains
      // responsive.
      task = setTimeout(() => {
        setupProduct({
          sku,
          addOnItems,
          flipSetting,
          tuftedSetting,
        }, setState);
      }, 0);
    }

    if (setItemViewedCallback) {
      setItemViewedCallback(productCardViewed);
    }

    return () => {
      if (task) {
        clearTimeout(task);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- expected to run once
  }, []);

  const withWaypoint = waypointProps?.horizontal;
  if (withWaypoint) {
    return renderWaypointWrapper();
  }

  return renderContent();
};

export default ProductCard;
