import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import LocalizationUtils from '../../utils/localization.utils';
import { generateLdJson } from './components/global/Metas.helpers';

export default class Meta extends Component {
  constructor(host) {
    super(host);
    this.hostname = host.host;
  }

  render() {
    const {
      isPDP,
      url = '/',
      productDetails,
      isProductSoldOut,
      availablePages,
    } = this.props;
    const pageDescription = this.props.pageDescription || 'The most clever, comfortable sofa designed for your ever-changing life and living room. Enjoy a cozy 30-day risk-free trial';
    const pageTitle = this.props.pageTitle || 'The Luxury Couch for Real Life | Burrow';
    let pageImage;
    if (productDetails?.metaImage && isPDP) {
      pageImage = productDetails.metaImage;
    } else {
      pageImage = this.props.pageImage || 'https://d3eewycu8m2p19.cloudfront.net/burrow-com-meta-image.jpg';
    }

    const alternatePages = availablePages?.map(({ url: alternatePageUrl }) => ({
      href: `${this.hostname}${alternatePageUrl}`,
      hrefLang: LocalizationUtils.getAnyHrefLangFromUrl(alternatePageUrl) ?? 'en-us',
    }));

    const defaultPage = alternatePages?.filter(({ hrefLang }) => hrefLang === 'en-us').shift();

    return (
      <Helmet>
        <meta charSet="utf-8"/>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription}></meta>
        <link rel="canonical" href={`${this.hostname}${url}`} />
        {alternatePages?.map(({ href, hrefLang }) => (
          <link
            rel="alternate"
            hrefLang={hrefLang}
            href={href}
            key={hrefLang}
          />
        ))}
        {defaultPage &&  (
          <link
            rel="alternate"
            hrefLang="x-default"
            href={defaultPage?.href}
          />
        )}
        <meta itemProp="name" content={pageTitle} />
        <meta itemProp="description" content={pageDescription} />
        <meta itemProp="image" content={pageImage} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@hiburrow" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
        <meta name="twitter:creator" content="@hiburrow" />
        <meta name="twitter:image:src" content={pageImage} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={pageImage} />
        <meta property="og:site_name" content="Burrow" />
        <meta property="fb:admins" content="612471195588500" />
        <meta name="p:domain_verify" content="9ef39d57d3744196a0cab4faaf4cd823" />
        {(isPDP && productDetails) && (
          <script type="application/ld+json">
            {generateLdJson({ productDetails, isProductSoldOut, url })}
          </script>
        )}
      </Helmet>
    );
  }
}

Meta.propTypes = {
  matchedRoutes: PropTypes.arrayOf(PropTypes.object),
  url: PropTypes.string,
  pageTitle: PropTypes.string,
  pageDescription: PropTypes.string,
  pageImage: PropTypes.string,
  productDetails: PropTypes.object,
  product: PropTypes.object,
  isPDP: PropTypes.bool,
};
