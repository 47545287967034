import type { Request } from '@burrow/express-manager';
import qs from 'qs';
import { getRegexGroupByLocalePath } from './Utils';

export const SLEEPKIT_SKUS = ['ALRSK-OSGR01', 'ALRSK-RG-OSGR01', 'ALRSK-FD-OSGR01'];
export const sleepkitSkusByUrl: Record<string, string> = {
  '/nomad-fabric/sofa': 'ALRSK-OSGR01',
  '/nomad-fabric/sleeper': 'ALRSK-OSGR01',
  '/nomad-leather/sleeper': 'ALRSK-OSGR01',
  '/range-fabric/sleeper': 'ALRSK-RG-OSGR01',
  '/field-fabric/sleeper': 'ALRSK-FD-OSGR01',
};

export const isSleepkitSku = (value: string) => SLEEPKIT_SKUS.includes(value);

export const includesSleepkitSku = (value?: string | string[]) => {
  if (Array.isArray(value)) {
    return value.some(isSleepkitSku);
  }

  if (typeof value === 'string') {
    return isSleepkitSku(value);
  }

  return false;
};

export const isSleeperPDP = (url: string) => !!url.match(/\/sleeper(?!s)/);

export const filterSleeperAddonItems = (url: string, addOnItems: string[]) => (isSleeperPDP(url)
  ? addOnItems
  : addOnItems.filter((addOnItem) => !SLEEPKIT_SKUS.includes(addOnItem)));

export const getQueryParamsMinusSleepKit = ({ query }: Pick<Request, 'query'>) => Object.fromEntries(
  Object.entries(query).filter(([, value]) => !includesSleepkitSku(value)),
);

export const getQueryParamSleepKitSlugs = ({ query }: Pick<Request, 'query'>): string[] => Object.entries(query).reduce((acc: string[], [key, value]) => {
  if (Array.isArray(value)) {
    value.forEach((innerValue) => {
      if (isSleepkitSku(String(innerValue))) {
        acc.push(key);
      }
    });
  } else if (isSleepkitSku(String(value))) {
    acc.push(key);
  }

  return acc;
}, []);

interface ProductModifier extends Record<string, unknown> {
  slug: string;
  options: Array<{
    [index: string]: unknown;
    value: string;
  }>;
}
export const getSleepKitModifierOptionSlug = (
  productModifiers: ProductModifier[],
) => (
  productModifiers.find(({ options }) => (
    options.find(({ value }) => isSleepkitSku(value))
  ))?.slug
);

export const getSleepKitRedirect = (
  request: Pick<Request, 'path' | 'url' | 'query'>,
  productModifiers: ProductModifier[],
) => {
  // the objective of this function is to ensure that:
  // * no duplicate sleep kit values (ALRSK-OSGR01) exist in query params
  // * the correct sleep kit slug is chosen based on cmsPage's modifier options
  // * sleeper products always include the slug in query params
  const { path, url } = request;
  const queryParamsMinusSleepKit = getQueryParamsMinusSleepKit(request);
  const sleepKitModifierOptionSlug = getSleepKitModifierOptionSlug(productModifiers);

  if (!sleepKitModifierOptionSlug) {
    return null;
  }

  const { pathWithoutLocale } = getRegexGroupByLocalePath(path);

  const queryWithSlugSleepKit = {
    ...queryParamsMinusSleepKit,
    [sleepKitModifierOptionSlug]: sleepkitSkusByUrl[pathWithoutLocale],
  };
  const queryParamSleepKitSlugs = getQueryParamSleepKitSlugs(request);
  const hasSleepKitAddOn = !!queryParamSleepKitSlugs.length;

  if (hasSleepKitAddOn) {
    if (
      queryParamSleepKitSlugs.length > 1
      || sleepKitModifierOptionSlug !== queryParamSleepKitSlugs[0]
    ) {
      return `${path}?${qs.stringify(queryWithSlugSleepKit)}`;
    }
  } else if (isSleeperPDP(url)) {
    return `${path}?${qs.stringify(queryWithSlugSleepKit)}`;
  }

  return null;
};
