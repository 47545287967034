import React, { Component } from 'react';
import Formsy from 'formsy-react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import Analytics from '../../global/Analytics';
import Input from '../../global/fields/Input';
import { cart as cartGlobalAPI, cartErrors as cartErrorsGlobalAPI } from '../../../mock/globalAPI';
import { setErrorPopupMessage } from '../../../store/actions/ui.actions';
import { getIsPromoInputVisible } from '../../../store/selectors/cart.selectors';
import { getIsUserTradePartner } from '../../../store/selectors/user.selectors';
import { setIsPromoInputVisible } from '../../../store/actions/cart.actions';

export class PromoInput extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleApplyClick = this.handleApplyClick.bind(this);
    this.togglePromoInput = this.togglePromoInput.bind(this);
  }

  static track(properties) {
    Analytics.enqueue({
      method: 'track',
      params: {
        event: 'Promo Input',
        properties,
      },
    });
  }

  static handleFocus() {
    PromoInput.track({ action: 'Focus' });
  }

  handleSubmit(form) {
    const { promoCode } = form;
    const {
      cartId,
      calculatingTotal,
      applyDiscount,
      setErrorPopupMessageAction,
    } = this.props;

    if (calculatingTotal) {
      return;
    }

    if (promoCode) {
      applyDiscount(cartId, promoCode);
    } else {
      setErrorPopupMessageAction(cartErrorsGlobalAPI.NO_PROMO_CODE);
    }
    PromoInput.track({ action: 'Submit', code: promoCode || '' });

    if (this.formRef.current) {
      this.formRef.current.reset();
    }
  }

  handleApplyClick() {
    this.formRef.current.submit();
  }

  togglePromoInput() {
    const { isPromoInputVisible, setIsPromoInputVisibleAction } = this.props;

    PromoInput.track({ action: isPromoInputVisible ? 'Close' : 'Open' });
    setIsPromoInputVisibleAction(!isPromoInputVisible);
  }

  renderPromoInputOption() {
    const { invalidPromo, calculating, isPromoInputVisible } = this.props;

    return (
      <>
        <button
          className={classNames('promo__title', {
            'promo__title--heathercharcoal': isPromoInputVisible,
            'promo__title--burgundy': !isPromoInputVisible,
          })}
          onClick={this.togglePromoInput}
        >
          {cartGlobalAPI.apply_promo}{isPromoInputVisible ? '' : ' +'}
        </button>
        <Formsy ref={this.formRef} onValidSubmit={this.handleSubmit}>
          <div className={classNames('promo__form', {
            'promo__form-show': isPromoInputVisible,
            'promo__form-hide': !isPromoInputVisible,
          })}
          >
            <Input
              id="promoinput"
              extraClass="promo__form-input"
              name="promoCode"
              ariaLabel="Promo Code"
              label={true}
              inputClickClass="promo__form-click"
              inputClickText={cartGlobalAPI.promo_input_apply}
              iconTitle="Delete Promo Code"
              disabled={calculating}
              onFocus={PromoInput.handleFocus}
              validations={{
                matchRegexp: /^[a-zA-Z0-9]+$/,
              }}
            />
          </div>
        </Formsy>
        {invalidPromo ? (
          <p className="small grey center" id="invalid-promo">{cartGlobalAPI.invalid_promo}</p>
        ) : null}
      </>
    );
  }

  render() {
    return (
      <div className="promo">
        {this.renderPromoInputOption()}
      </div>
    );
  }
}

const mapDispatchToProps = {
  setErrorPopupMessageAction: setErrorPopupMessage,
  setIsPromoInputVisibleAction: setIsPromoInputVisible,
};

const mapStateToProps = (state) => ({
  isPromoInputVisible: getIsPromoInputVisible(state),
  isUserTradePartner: getIsUserTradePartner(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(PromoInput);
