import React, {
  MouseEvent, ReactNode, useEffect,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import LinkAdapter from './LinkAdapter';
import Analytics from './Analytics';
import { refreshAffirm, getProductURL, getVariantBySKU } from './ProductHelpers';
import { getContainerStyles } from '../../../../utils/Utils';
import { Consumer } from '../../contexts/Context';

import {
  fetchProductDetails,
  loadProductVariant,
} from '../../store/actions/product.actions';
import {
  getInjectedScripts,
} from '../../store/selectors/ui.selectors';
import { getHrefLang } from '../../store/selectors/general.selectors';
import { Color } from '../../types/common';
import { Link, LinkTypes } from './header/header.types';
import Nullable from '../../../../shared/nullable';
import HrefLang from '../../../../shared/localization/hrefLang';
import { withCssModulesClassNames } from '../../common/nextMigrationHelpers';

const withCssModulesClassNamesHandler = withCssModulesClassNames();

export type UniversalLinkProps = Partial<Pick<Link, 'anchor' | 'destinationPage' | 'id' | 'hoverColor' | 'color'>> & {
  role?: string,
  href?: string;
  to?: string,
  type?: LinkTypes,
  className?: string,
  label?: string,
  'aria-label'?: string,
  target?: string,
  tabIndex?: number,
  buttonColor?: Nullable<Color>,
  linkType?: string,
  location?: string,
  clickHandler?: (props?: { preventMobile?: Nullable<boolean> | undefined } | MouseEvent<HTMLAnchorElement>) => void,
  productCollectionItems?: {
    sku: Nullable<string>,
    tuftedSetting: Nullable<string>,
    flipSetting: Nullable<string>,
    addOnItems: string[]
  }[],
  children?: ReactNode,
};

const trackClick = (location?: string, destination?: string, linkType?: string) => {
  if (destination) {
    try {
      Analytics.enqueue({
        method: 'track',
        params: {
          event: 'Link Clicked',
          properties: {
            location,
            destination,
            linkType,
          },
        },
      });
    } catch (e) { /* nothing to do */ }
  }
};

const UniversalLink = (props: UniversalLinkProps) => {
  const {
    destinationPage,
    href,
    anchor,
    to,
    id,
    productCollectionItems = [],
    children,
    className,
    label: _label,
    role,
    target,
    tabIndex,
    clickHandler,
    location,
    color,
    buttonColor,
    hoverColor,
    type,
    linkType,
    'aria-label': ariaLabel,
  } = props;

  const label = _label || ariaLabel;

  const dispatch = useDispatch();
  const injectedScripts: string[] = useSelector(getInjectedScripts);
  const hrefLang: `${HrefLang}` | undefined = useSelector(getHrefLang);

  useEffect(() => {
    if (type === 'Affirm') {
      refreshAffirm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLink = () => {
    let selectedLink;
    switch (type) {
      case 'Page':
      case 'Shipping':
        selectedLink = to || destinationPage?.url || href;
        break;
      case 'External':
      case 'Mailto':
        selectedLink = to || href;
        break;
      case 'Product':
        if (!destinationPage?.url) {
          break;
        }
        if (productCollectionItems?.length > 0) {
          selectedLink = getProductURL(
            { url: destinationPage.url },
            productCollectionItems[0].sku,
            productCollectionItems[0].addOnItems,
            {
              tufted: productCollectionItems[0].tuftedSetting,
              door: productCollectionItems[0].flipSetting,
            },
          );
        } else {
          selectedLink = destinationPage.url;
        }
        break;
      default:
        break;
    }
    if (!selectedLink && type !== 'Affirm' && type !== 'Mock') {
      // eslint-disable-next-line no-console
      console.warn(`Link with id: ${id} has no destination`);
      return '';
    }
    if (anchor && !selectedLink?.includes('#')) {
      const parsedAnchor = anchor.includes('#')
        ? anchor.slice(anchor.indexOf('#') + 1)
        : anchor;
      selectedLink = `${selectedLink}#${parsedAnchor}`;
    }
    return selectedLink;
  };

  const handleProductLinkClick = (sku: string, selectedLink: string) => async (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    if (typeof clickHandler === 'function') {
      clickHandler();
    }

    if (productCollectionItems?.length > 0) {
      const variant = await getVariantBySKU(sku, productCollectionItems[0].addOnItems);
      dispatch(loadProductVariant({ productDetails: variant, url: selectedLink }));
    } else {
      dispatch(fetchProductDetails({
        ...props,
        sku,
      }));
    }

    trackClick(location, selectedLink, linkType);
  };

  const handleShippingAndPageLinkClick = (selectedLink: string) => (event: MouseEvent<HTMLAnchorElement>) => {
    if (typeof clickHandler === 'function') {
      clickHandler(event);
    }
    trackClick(location, selectedLink, linkType);
  };

  const sku = (productCollectionItems?.length > 0
    ? productCollectionItems.find((p) => !!p.sku)?.sku
    : destinationPage?.product?.defaultSku) || '';
  const selectedLink = getLink();
  let itemContent;

  return (
    <Consumer>
      {({ nextApp }) => {
        switch (type) {
          case 'Shipping':
          case 'Page':
            itemContent = selectedLink ? (
              <LinkAdapter
                role={role}
                nextApp={nextApp}
                tabIndex={tabIndex}
                className={className}
                style={buttonColor ? getContainerStyles(buttonColor) : undefined}
                to={selectedLink}
                href={selectedLink}
                aria-label={label}
                color={color?.hex}
                hoverColor={hoverColor?.hex}
                hrefLang={hrefLang}
                onClick={handleShippingAndPageLinkClick(selectedLink)}
              >
                {children}
              </LinkAdapter>
            ) : null;
            break;
          case 'External':
          case 'Mailto':
            itemContent = selectedLink ? (
              <a
                role={role}
                onClick={() => {
                  if (typeof clickHandler === 'function') {
                    clickHandler();
                  }
                  trackClick(location, selectedLink, linkType);
                }}
                className={className}
                href={selectedLink}
                style={getContainerStyles(buttonColor, color)}
                aria-label={label}
                target={target}
                hrefLang={hrefLang}
              >
                {children}
              </a>
            ) : null;
            break;
          case 'Affirm':
            itemContent = (
              <a
                role="button"
                tabIndex={0}
                className={withCssModulesClassNamesHandler('affirm-site-modal', className)}
                data-affirm-type="text"
                data-page-type="banner"
                aria-label="Prequalify now (opens in modal)"
                style={injectedScripts?.includes('affirm') ? getContainerStyles(buttonColor, color) : { pointerEvents: 'none' }}
                hrefLang={hrefLang}
              >
                {children}
              </a>
            );
            break;
          case 'Product':
            itemContent = selectedLink ? (
              <LinkAdapter
                color={color?.hex}
                hoverColor={hoverColor?.hex}
                role={role}
                nextApp={nextApp}
                to={selectedLink}
                href={selectedLink}
                tabIndex={tabIndex}
                aria-label={label}
                style={getContainerStyles(buttonColor, color)}
                className={className}
                onClick={handleProductLinkClick(sku, selectedLink)}
                hrefLang={hrefLang}
              >
                {children}
              </LinkAdapter>
            ) : null;
            break;
          default:
            itemContent = '';
            break;
        }
        return itemContent;
      }}
    </Consumer>
  );
};

export default UniversalLink;
