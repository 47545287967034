import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import HeaderLink from './HeaderLink';
import Dropdown from './Dropdown';
import Images from '../Images';
import ErrorBoundary from '../ErrorBoundary';
import ProductCollection from '../../shopping/ProductCollection';
import { default as styles } from '../../../scss/components/Header.module.scss';
import { generateVisibilityClassNames, shouldHideByScreenSize } from '../../../../../utils/Utils';
import {
  FeaturedItem, NavigationItem as NavigationItemType, CloseMenuParams,
} from './header.types';
import { withCssModulesClassNames } from '../../../common/nextMigrationHelpers';
import ItemHeader from './ItemHeader';
import { getIsSearchDropdownOpen, getWindowWidth } from '../../../store/selectors/ui.selectors';
import { setIsSearchDropdownOpen } from '../../../store/actions/ui.actions';
import { ABTestObserver } from './NavigationItem.helpers';

const withCssModulesClassNamesHandler = withCssModulesClassNames(styles);

export type NavigationItemProps = {
  neighbors: string[],
  link: NavigationItemType,
  isMobile?: boolean,
  visibleGalleryID?: string,
  closeMenu: (config?: CloseMenuParams) => void,
  dropdownStates: { [key: string]: boolean },
  toggleCurrentDropdown: (id: string, header: string, neighbors: string[]) => void
};

const NavigationItem = ({
  link,
  dropdownStates,
  toggleCurrentDropdown,
  neighbors,
  closeMenu,
  isMobile = false,
  visibleGalleryID = '',
}: NavigationItemProps) => {
  const observerRef = useRef<MutationObserver | null>(null);
  const dispatch = useDispatch();
  const windowWidth = useSelector(getWindowWidth);
  const [showDrawerOnlyOnClient, setShowDrawerOnlyOnClient] = useState(false);
  const [isHoverNavigation, setIsHoverNavigation] = useState(false);
  const isSearchDropdownOpen = useSelector(getIsSearchDropdownOpen);

  const onMouseEnter = !isHoverNavigation ? undefined : () => {
    if (isSearchDropdownOpen) {
      dispatch(setIsSearchDropdownOpen(false));
    }
    closeMenu({ preventMobile: true });
  };

  const navRef = useCallback((navEl: HTMLDivElement) => {
    if (!isMobile && navEl) {
      const obs = ABTestObserver(navEl, setIsHoverNavigation);
      obs.observe(navEl, {
        attributes: true,
        attributeFilter: ['class'],
        attributeOldValue: true,
      });
      observerRef.current = obs;
    }
  }, [isMobile]);

  useEffect(() => () => {
    if (observerRef.current) {
      observerRef.current.disconnect();
    }
  }, []);

  useEffect(() => {
    setShowDrawerOnlyOnClient(true);
  }, []);

  const renderLinkChildren = () => {
    if (!link.features?.length) {
      return null;
    }

    const navigationColumns = [];
    for (let i = 0; i < link.features.length; i += 5) {
      navigationColumns.push(link.features.slice(i, i + 5));
    }

    const isColumnLayout = link.features?.map(({ __typename }) => __typename)
      .filter((type) => type !== 'Link').length === 0;

    return (
      <div
        className={withCssModulesClassNamesHandler('navigation-position-drawer__content__styled-columns')}
        data-testid="link-children"
      >
        {navigationColumns.map((items, index) => (
          <div key={index} className={withCssModulesClassNamesHandler(
            'navigation-position-drawer__children',
            {
              'navigation-position-drawer__children--column': isColumnLayout && !isMobile,
            },
          )}
          >
            {/* eslint-disable-next-line no-underscore-dangle */}
            {items.map((child) => (child.__typename === 'Link' ? (
              <HeaderLink
                link={child}
                key={child.id}
                baseClass={withCssModulesClassNamesHandler('links-section-item')}
                closeMenu={closeMenu}
              />
            ) : (
              <Dropdown
                link={child}
                key={child.navigationId}
                dropdownStates={dropdownStates}
                toggleCurrentDropdown={toggleCurrentDropdown}
                neighbors={
                  (link.features as FeaturedItem[])?.map(({ navigationId }) => navigationId)
                    .filter((id) => id !== child.navigationId)
                }
                closeMenu={closeMenu}
                isMobile={isMobile || false}
              />
            )))}
        </div>
        ))}
    </div>
    );
  };

  const renderPoints = () => {
    const hasNeighbors = link?.features?.length > 0;
    const titledPoints = link.points?.map((point) => ({
      ...point,
      forcePointTitle: true,
      pointTitle: point.header,
      header: point.subHeader,
      subHeader: null,
    }));

    return link.points?.length ? (
      <Images
        ancestorImitatesLink
        points={titledPoints}
        className={withCssModulesClassNamesHandler({ 'has-neighbors': hasNeighbors })}
      />
    ) : null;
  };

  const renderProductCollection = () => {
    const isAnyChildOpen = (link.features as FeaturedItem[])?.find(({ navigationId }) => dropdownStates[navigationId]);
    return (
      <ErrorBoundary errorMessage="Sorry, this image could not load!">
        {!isAnyChildOpen && link.featuredProductCollection ? (
          <div className={withCssModulesClassNamesHandler('navigation-position-drawer__featured')}>
            <ProductCollection
              collection={link.featuredProductCollection}
              activeFilters={[]}
              source={'navigation'}
            />
          </div>
        ) : null}
      </ErrorBoundary>
    );
  };

  const renderItemHeader = () => {
    const isOpen = dropdownStates[link.navigationId];

    return (
      <ItemHeader
        link={link}
        isMobile={isMobile}
        isOpen={isOpen}
        onClick={isHoverNavigation ? undefined : () => toggleCurrentDropdown(link.navigationId, link.header, neighbors)}
      />
    );
  };

  const renderExpandableItem = () => {
    const isOpen = dropdownStates[link.navigationId];
    const hasSections = link?.features?.length > 0;
    const visibilities = link?.visibilities;

    const hasVisibleGallery = visibleGalleryID === link.navigationId;
    const shouldDisplayGallery = isMobile
      ? hasVisibleGallery || link.expandedMobileSection
      : true;

    // hide links that are not needed to be displayed based on user screen
    const hideLink = shouldHideByScreenSize({
      windowWidth,
      visibilities,
    });

    if (hideLink) {
      return null;
    }

    return (
      <div
        className={classNames(
          withCssModulesClassNamesHandler('navigation-position'),
          generateVisibilityClassNames(visibilities),
        )}
        ref={navRef}
        key={link.navigationId}
        data-testid="navigation-item"
        onMouseEnter={onMouseEnter}
      >
        {isMobile && link.expandedMobileSection && (
          <div
            className={withCssModulesClassNamesHandler('navigation-position__break')}
          />
        )}
        {renderItemHeader()}
        {
          showDrawerOnlyOnClient && (
            <div
              className={withCssModulesClassNamesHandler(
                'navigation-position-drawer',
                {
                  active: isOpen,
                  expandedMobileSection: link.expandedMobileSection,
                },
              )}
              data-cy={isOpen ? 'active-navigation-position-drawer' : undefined}
              data-testid={isOpen ? 'active-navigation-position-drawer' : undefined}
            >
              <div
                className={withCssModulesClassNamesHandler('navigation-position-drawer__content')}
              >
                {renderLinkChildren()}
                {shouldDisplayGallery ? renderPoints() : null}
                {shouldDisplayGallery ? renderProductCollection() : null}
                {hasSections && !link.expandedMobileSection && isMobile && (
                  <div className={withCssModulesClassNamesHandler('navigation-position-drawer__content__break')} />
                )}
              </div>
            </div>
          )
        }
      </div>
    );
  };

  if (link.navigationType === 'Link') {
    return (
      <HeaderLink
        link={link}
        baseClass={withCssModulesClassNamesHandler(
          'navigation-position-button',
          'navigation-section-item',
        )}
      />
    );
  }

  return (
    renderExpandableItem()
  );
};

export default NavigationItem;
