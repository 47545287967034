import { useEffect, useState, useRef } from 'react';
import { gql, useQuery } from '@apollo/client';

import { SearchProductsResponse } from '../../../../../Product/dto/search.dto';
import ApolloClient from '../../../../../Apollo/ApolloClient';
import Analytics from '../Analytics';

const TIMEOUT_TO_SET_TERM_QUERY_PARAMETER = 250;

export const SEARCH_PRODUCTS_QUERY = gql`
  query searchProducts($term: String!, $after: String) {
    searchProducts(term: $term, after: $after) {
      totalItems
      pageInfo {
        hasNextPage
        endCursor
      }
      products {
        id
        sku
        name
        path
        cursor
        description
        defaultImageUrl
        pricing {
          price {
            raw
            formatted
            inCents
          }
          salePrice {
            raw
            formatted
            inCents
          }
          originalPrice {
            raw
            formatted
            inCents
          }
        }
      }
    }
  }
`;

export const getDefaultSearchTermInputValue = (search: string) => {
  const searchTerm = new URLSearchParams(search).get('term');

  return searchTerm || '';
};

const useSearchProducts = () => {
  const { search = '' } = typeof window === 'undefined' ? {} : window.location;
  const [searchTermInputValue, setSearchTermInputValue] = useState(getDefaultSearchTermInputValue(search));
  const searchTermInputValueRef = useRef(searchTermInputValue);
  const {
    data, loading, fetchMore,
  } = useQuery<{ searchProducts: SearchProductsResponse }>(SEARCH_PRODUCTS_QUERY, {
    skip: searchTermInputValue.length < 3,
    client: ApolloClient,
    variables: {
      term: searchTermInputValue,
    },
  });

  const totalProducts = data?.searchProducts?.totalItems ?? 0;
  const searchProducts = data?.searchProducts?.products ?? [];
  const hasNextPage = data?.searchProducts?.pageInfo?.hasNextPage ?? false;

  useEffect(() => {
    if (searchTermInputValue.length >= 3 && !loading) {
      Analytics.enqueue({
        method: 'track',
        params: {
          event: 'Products Searched',
          properties: {
            query: searchTermInputValue,
            hasNextPage,
            numOfSearchResults: totalProducts,
          },
        },
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalProducts, searchTermInputValue, loading]);

  // effect to set the term input value from the term query parameter
  useEffect(() => {
    const searchTermQueryParameter = getDefaultSearchTermInputValue(search);
    if (searchTermQueryParameter !== searchTermInputValueRef.current) {
      setSearchTermInputValue(searchTermQueryParameter);
    }
  }, [search]);

  // effect to set the term query parameter after user stops typing
  useEffect(() => {
    searchTermInputValueRef.current = searchTermInputValue;

    const timer = setTimeout(() => {
      const searchTermQueryParameter = getDefaultSearchTermInputValue(window.location.search);
      if (searchTermQueryParameter !== searchTermInputValue && searchTermInputValue.length) {
        window.history.pushState({}, '', `?term=${encodeURIComponent(searchTermInputValue)}`);
      }
      if (searchTermInputValue.length === 0) {
        const url = new URL(window.location.href);

        url.searchParams.delete('term');
        window.history.pushState({}, '', url.toString());
      }
    }, TIMEOUT_TO_SET_TERM_QUERY_PARAMETER);

    return () => {
      clearTimeout(timer);
    };
  }, [searchTermInputValue]);

  return {
    searchTermInputValue,
    isLoading: loading,
    totalProducts,
    searchProducts,
    hasNextPage,
    onChangeSearchTermInputValue: (event: React.ChangeEvent<HTMLInputElement>) => setSearchTermInputValue(event.target.value),
    onLoadMoreSearchProducts: () => {
      fetchMore({
        variables: {
          after: data?.searchProducts?.pageInfo?.endCursor,
        },
      });
    },
  };
};

export default useSearchProducts;
