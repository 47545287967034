import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { HrefLang } from '../../../types/schema';
import UniversalLinkContainer from '../UniversalLink';
import Analytics from '../Analytics';
import { withCssModulesClassNames } from '../../../common/nextMigrationHelpers';
// eslint-disable-next-line import/no-named-default
import { default as styles } from '../../../scss/components/Header.module.scss';

import { getHrefLang } from '../../../store/selectors/general.selectors';
import generateUrlWithHrefLang from '../../../common/generateUrlWithHrefLang';
import paths from '../../../paths';

const withCssModulesClassNamesHandler = withCssModulesClassNames(styles);

const handleClick = () => {
  Analytics.enqueue({
    method: 'track',
    params: {
      event: 'Logo',
      properties: {
        action: 'Clicked',
        location: 'header',
      },
    },
  });
};

const BurrowLogo = () => {
  const hrefLang = useSelector(getHrefLang);
  const homepageLink = generateUrlWithHrefLang({ hrefLang, endpoint: paths.HOME });
  return (
    <div className={withCssModulesClassNamesHandler('header-logo')}>
      <UniversalLinkContainer
        label="Home page"
        to={homepageLink}
        type="Page"
        clickHandler={handleClick}
      >
        <i className={withCssModulesClassNamesHandler('icon', 'icon-burrow')} />
      </UniversalLinkContainer>
    </div>
  );
};

BurrowLogo.defaultProps = {
  hrefLang: HrefLang.EN_US,
};

BurrowLogo.propTypes = {
  hrefLang: PropTypes.string,
};

export default BurrowLogo;
