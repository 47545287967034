import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';

import CartFooter from './CartFooter';
import RecommendedProducts from './RecommendedProducts';
import Items from './CartItems';
import SaleThermometer from '../SaleThermometer';

import {
  getIsCartOpen,
  getIsOnline,
} from '../../../store/selectors/ui.selectors';
import {
  getCartDetails,
  getIsCartDataPending,
  getAppliedTieredSale,
  getCartBaseAmountInt,
  getCurrentSalesTier,
  getNextSalesTier,
  getIsActiveTieredSalePromoCodeApplied,
  getIsCartSavedForLater,
  getIsThermometerVisible,
  getAutomaticallyAppliedPromotion,
  getCartHasItems,
  getMSRPSubtotalNumber,
} from '../../../store/selectors/cart.selectors';
import {
  getConsignmentTax,
  getConsignmentTotal,
  getSubmitPending,
  getIsConsignmentDataPending,
} from '../../../store/selectors/checkout.selectors';
import {
  getShippingEstimates,
} from '../../../store/selectors/product.selectors';
import {
  fetchProductShippingEstimateSuccess,
  fetchProductDetails,
} from '../../../store/actions/product.actions';
import {
  addCouponToCart,
  applyAutomaticDiscount,
  addToCart,
  fetchCart,
  fetchCheckoutUrlAndRedirect,
  removeCouponFromCart,
  updateCartItem,
  saveCartForLater,
  setSaveCartModalOpen,
} from '../../../store/actions/cart.actions';
import {
  openCart,
} from '../../../store/actions/ui.actions';

export class CartPopout extends Component {
  checkout() {
    // TODO can this be replaced with a link?
    const {
      fetchCheckoutUrlAndRedirectAction,
      isCartDataPending,
      cartHasItems,
    } = this.props;

    if (!isCartDataPending && cartHasItems) {
      return fetchCheckoutUrlAndRedirectAction();
    }

    return false;
  }

  closeCart = () => {
    this.props.openCartAction(false);
  };

  constructor(props) {
    super(props);

    this.checkout = this.checkout.bind(this);
    this.closeCart = this.closeCart.bind(this);
  }

  componentDidMount() {
    return this.props.fetchCartAction();
  }

  render() {
    const {
      isCartOpen,
      isCartDataPending,
      cartDetails,
      isOnline,
      cartHasItems,
      tieredCoupon,
      isActiveTieredSalePromoCodeApplied,
      isThermometerVisible,
      subtotalNumber,
      cartBaseAmountInt,
      currentCouponTier,
      nextCouponTier,
      automaticallyAppliedPromotion,
    } = this.props;

    const calculating = isCartDataPending || !cartDetails;
    const isSecureCheckoutDisabled = isCartDataPending
      || !cartDetails
      || (cartDetails && !cartDetails.items)
      || !isOnline;
    const coupon = tieredCoupon || automaticallyAppliedPromotion;
    const cartNumber = cartDetails?.cartNumber ?? 0;

    return (
      <div className={classNames('cart', { 'is-open': isCartOpen })}>
        <div className="cart__header">
          <div className="cart__header__inner-wrapper">
            <div className="cart__header__inner-wrapper__cart-items">
              <h3>Cart</h3>
              <h4 className="cart__header__inner-wrapper__cart-items__number">{cartNumber !== 1 ? `${cartNumber} items` :  '1 item'}</h4>
            </div>
            <button
              aria-label="Close cart menu"
              onClick={this.closeCart}
              className="icon icon-close"
            />
          </div>
          {isThermometerVisible && (
            <div className="cart__sale-thermometer">
              <SaleThermometer
                isPromoCodeApplied={isActiveTieredSalePromoCodeApplied}
                subtotalNumber={subtotalNumber}
                cartValue={cartBaseAmountInt}
                currentTier={currentCouponTier}
                nextTier={nextCouponTier}
                enterCodeCopy={coupon?.enterCodeCopy}
                tierDistanceCopy={coupon?.tierDistanceCopy}
                maxSavingsCopy={coupon?.maxSavingsCopy}
                automaticallyAppliedPromotion={automaticallyAppliedPromotion}
              />
            </div>
          )}
        </div>
        <div className={
          classNames(
            'cart__wrapper',
            {
              'cart__wrapper-spread': cartHasItems,
              'cart__wrapper-top': !cartHasItems,
            },
          )}
        >
          <div className="cart__items cart__items-spacing">
            <Items
              {...this.props}
              isCheckoutCart={false}
            />
          </div>
          {cartHasItems && (
            <div className="cart-footer caption cart-footer--tight-leading cart-footer-shadow">
              <div className="cart-footer-inner-wrapper cart-footer--spacing">
                <CartFooter
                  calculating={calculating}
                  {...this.props}
                />
                <div className="cart-footer-button">
                  <button
                    className={classNames('button btn-yellow', { disabled:isSecureCheckoutDisabled })}
                    disabled={isSecureCheckoutDisabled}
                    onClick={this.checkout}
                  >
                    Secure Checkout
                  </button>
                </div>
                { this.renderSaveForLaterButton() }
              </div>
            </div>
          )}
        </div>
        <RecommendedProducts />
      </div>
    );
  }

  renderSaveForLaterButton() {
    const {
      setSaveCartModalOpenAction,
    } = this.props;

    return (
      <button
        className="cart__save-button cart__save-button__popout button"
        type="button"
        onClick={() => setSaveCartModalOpenAction(true)}
      >
        <span className="cart__save-button__text">SAVE FOR LATER</span>
      </button>
    );
  }
}

const mapStateToProps = (state) => ({
  isCartOpen: getIsCartOpen(state),
  cartDetails: getCartDetails(state),
  isCartDataPending: getIsCartDataPending(state),
  isCartSavedForLater: getIsCartSavedForLater(state),
  cartBaseAmountInt: getCartBaseAmountInt(state),
  tieredCoupon: getAppliedTieredSale(state),
  currentCouponTier: getCurrentSalesTier(state),
  shippingEstimates: getShippingEstimates(state),
  nextCouponTier: getNextSalesTier(state),
  isActiveTieredSalePromoCodeApplied: getIsActiveTieredSalePromoCodeApplied(state),
  isOnline: getIsOnline(state),
  consignmentTax: getConsignmentTax(state),
  checkoutTotal: getConsignmentTotal(state),
  isSubmitPending: getSubmitPending(state),
  isConsignmentPending: getIsConsignmentDataPending(state),
  isThermometerVisible: getIsThermometerVisible(state),
  automaticallyAppliedPromotion: getAutomaticallyAppliedPromotion(state),
  cartHasItems: getCartHasItems(state),
  subtotalNumber: getMSRPSubtotalNumber(state),
});

const mapDispatchToProps = {
  openCartAction: openCart,
  fetchCartAction: fetchCart,
  addToCartAction: addToCart,
  updateCartItemAction: updateCartItem,
  addCouponToCartAction: addCouponToCart,
  applyAutomaticDiscountAction: applyAutomaticDiscount,
  removeCouponFromCartAction: removeCouponFromCart,
  fetchProductShippingEstimateSuccessAction: fetchProductShippingEstimateSuccess,
  fetchCheckoutUrlAndRedirectAction: fetchCheckoutUrlAndRedirect,
  saveCartForLaterAction: saveCartForLater,
  setSaveCartModalOpenAction: setSaveCartModalOpen,
  fetchProductDetailsAction: fetchProductDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(CartPopout);
