import Queue from './Queue';
import { AnalyticsFactory } from './Analytics';

export const safeMethods = [
  ...Object.getOwnPropertyNames(Queue.prototype).slice(1),
  'init',
  'onEnqueue',
  'getLocation',
  'isAlreadyIdentified',
  'prepareEventPayload',
  'prepareItemProperties',
  'getLocale',
  'getCookiesAnalytics',
  'setTraits',
];
export const dummyFunction = () => {};

export const generateProxy = <Target extends AnalyticsFactory>(target: Target) => {
  const fields = Object.getOwnPropertyNames(target);

  return new Proxy<Target>(target, {
    get(...args) {
      const [_target, prop] = args;

      const methodName = prop.toString();

      if (_target.isReady || fields.includes(methodName) || safeMethods.includes(methodName)) {
        return Reflect.get.apply(_target, args);
      }

      // Analytics.js is not ready yet. Run dummy instead.
      return dummyFunction;
    },
  });
};
