import { createSelector } from 'reselect';
import HrefLang from '../../../../shared/localization/hrefLang';

/** @typedef {import('../store.types').RootState} RootState */

/** @param {RootState} state */
export const getHrefLang = ({ general }) => general?.hrefLang;
/** @param {RootState} state */
export const getHostUrl = ({ general }) => general?.hostUrl;
export const getIsUsLang = createSelector(
  getHrefLang,
  /**
   * @param {HrefLang} hrefLang
   * @returns {boolean}
   */
  (hrefLang) => hrefLang === HrefLang.EN_US,
);
