import { createSelector } from 'reselect';
import { getWindowWidth } from './ui.selectors';
import { getQueryParams } from './router.selectors';

export const getIsCollectionsDataPending = ({ collections }) => collections.isPending;
export const getCollectionsPaths = ({ collections }) => collections.paths;
export const getActiveFilters = ({ collections }) => collections?.activeFilters || [];
export const getAvailableFilters = ({ collections }) => collections?.availableFilters || {};

export const getMatchingCollectionLayout = (collectionLayouts, windowWidth) => (
  collectionLayouts && [...collectionLayouts]
    .sort(({ screenSize: a }, { screenSize: b }) => {
      if (a && !b) return -1;
      if (!a && b) return 1;
      return 0;
    })
    .find(({ screenSize }) => (
      !screenSize
      || (screenSize === 'Mobile' && windowWidth >= 0 && windowWidth <= 640)
      || (screenSize === 'Tablet' && windowWidth > 640 && windowWidth <= 1024)
      || (screenSize === 'Desktop' && windowWidth > 1024)
    ))
);

export const getDefaultCollectionLayout = createSelector(
  getWindowWidth,
  (windowWidth) => {
    const defaultLayout = (() => {
      if (windowWidth <= 640) return 'SingleGrid';
      if (windowWidth <= 920) return 'DoubleGrid';
      return 'TripleGrid';
    })();

    return {
      defaultLayout,
      availableLayouts: [defaultLayout],
    };
  },
);

const getCollectionsData = (state) => state?.page?.data?.content?.productCollections || [];

const getComponents = (state) => state?.page?.data?.content?.components || [];

export const getCollectionsFromComponents = createSelector(
  getCollectionsData,
  getComponents,
  (collectionsData, components) => {
    if (collectionsData.length > 0) {
      return collectionsData;
    }
    return components
      .filter(({ type }) => ([
        'ProductCollection',
        'ProductCollections',
      ].includes(type)))
      .reduce((allCollections, component) => ([
        ...allCollections,
        ...component.collections,
      ]), []);
  },
);

export const getActiveCollectionLayout = createSelector(
  getDefaultCollectionLayout,
  getCollectionsFromComponents,
  getWindowWidth,
  (defaultCollectionLayout, collections, windowWidth) => {
    let collectionLayouts;
    try {
      ([{ collectionLayouts }] = collections);
    } catch (e) {
      return defaultCollectionLayout;
    }

    return getMatchingCollectionLayout(collectionLayouts, windowWidth)
    || defaultCollectionLayout;
  },
);

const mapLayoutToLayoutGroup = (layout) => {
  if (layout.toLowerCase().includes('grid')) return 'GRID';
  if (layout.toLowerCase().includes('carousel')) return 'CAROUSEL';
  return null;
};

export const getAvailableLayoutGroups = createSelector(
  getActiveCollectionLayout,
  (activeCollectionLayout) => activeCollectionLayout.availableLayouts
    .map(mapLayoutToLayoutGroup)
    .filter((layout) => layout),
);

const mapQueryParamToLayoutGroup = (query) => {
  switch (query) {
    case 'grid': return 'GRID';
    case 'swipe': return 'CAROUSEL';
    default: return null;
  }
};

export const getSelectedLayoutGroup = createSelector(
  getQueryParams,
  (queryParams) => mapQueryParamToLayoutGroup(queryParams.layout),
);

export const getActiveLayout = createSelector(
  getSelectedLayoutGroup,
  getActiveCollectionLayout,
  (selectedLayoutGroup, collectionLayout) => {
    const {
      availableLayouts,
      defaultLayout,
    } = collectionLayout;

    const isLayoutFromSelectedGroup = (layout) => (
      mapLayoutToLayoutGroup(layout) === selectedLayoutGroup
    );

    return availableLayouts.find(isLayoutFromSelectedGroup) || defaultLayout;
  },
);

export const getSelectedLayout = ({ collections }) => collections?.selectedLayout;

export const getActiveLayoutGroup = createSelector(
  getAvailableLayoutGroups,
  getSelectedLayoutGroup,
  getActiveLayout,
  (availableLayoutGroups, selectedLayoutGroup, activeLayout) => {
    if (selectedLayoutGroup && availableLayoutGroups && availableLayoutGroups.includes(selectedLayoutGroup)) {
      return selectedLayoutGroup;
    }

    return mapLayoutToLayoutGroup(activeLayout);
  },
);

export const getGridContainerClassNames = ({ collectionLayouts, selectedLayout }) => (
  Array.isArray(collectionLayouts)
    ? collectionLayouts
      .map(({ availableLayouts, screenSize }) => {
        const availableGridLayout = availableLayouts.find((availableLayout) => mapLayoutToLayoutGroup(availableLayout) === 'GRID');
        return availableGridLayout ? { availableGridLayout, screenSize } : null;
      })
      .filter((x) => x)
      .map(({ availableGridLayout, screenSize }) => {
        // prefer `selectedLayout` for mobile devices only which is set in PLPToolbar
        const layout = screenSize === 'Mobile' ? selectedLayout || availableGridLayout : availableGridLayout;

        let prefix = '';
        switch (layout) {
          case 'SingleGrid': { prefix = 'single-grid'; break; }
          case 'DoubleGrid': { prefix = 'double-grid'; break; }
          case 'TripleGrid': { prefix = 'triple-grid'; break; }
          default: break;
        }
        let suffix = '';
        switch (screenSize) {
          case 'Mobile': { suffix = '-on-mobile'; break; }
          case 'Tablet': { suffix = '-on-tablet'; break; }
          case 'Desktop': { suffix = '-on-desktop'; break; }
          default: break;
        }
        return `${prefix}${suffix}`;
      })
    : []
);

export const getAllCollections = createSelector(
  getCollectionsFromComponents,
  getActiveLayoutGroup,
  getWindowWidth,
  (collections, activeLayoutGroup, windowWidth) => (
    collections
      .map((collection) => ({
        ...collection,
        slug: collection.slug?.replace(/ /g, '-'),
        activeCollectionLayout: (
          getMatchingCollectionLayout(collection.collectionLayouts, windowWidth)
          || null
        ),
        gridContainerClassNames: activeLayoutGroup === 'GRID'
          ? getGridContainerClassNames(collection)
          : [],
      }))
      .sort(({ order: a }, { order: b }) => (a !== b ? a - b : 0))
  ),
);

export const getFlattenedSubNavigationTypes = createSelector(
  getAllCollections,
  (collections) => (collections
    ? collections.reduce((subNavTypes, collection) => ([
      ...subNavTypes,
      ...(collection.collectionLayouts?.map(({ subNavigationType }) => subNavigationType) || []),
    ]), [])
    : []
  ),
);

export const mapLayoutGroupToQueryParam = (query) => {
  switch (query) {
    case 'GRID': return 'grid';
    case 'CAROUSEL': return 'swipe';
    default: return null;
  }
};

export const getCollectionsError = ({ collections }) => collections.error;
