/* eslint no-underscore-dangle: ["warn", { "allow": ["__PRELOADED_STATE__", "__NEXT_DATA__"] }] */

import createHash from 'create-hash';

import Queue from './Queue';
import { cleanObject, formatCartItemAnalytics } from '../../../../utils/Utils';
import getCurrencyCode from '../../common/getCurrencyCode';
import { identitySync, queryCustomerId } from '../../../../server/3rd-party/Segment.client-side';
import { generateProxy } from './Analytics.proxy';
import { setUserAnalyticsFromCookies } from '../../hooks/useDynamicData';

/** @typedef {'email' | 'phone_number' | 'user_id' | 'anonymous_id'} IdentifierType */
/** @typedef {import('../../../../server/3rd-party/Segment').SegmentProfileIdentity} SegmentProfileIdentity */
/** @typedef {import('../../../../server/3rd-party/Segment.types').Traits} Traits */

export class AnalyticsFactory extends Queue {
  identity = {};

  traits = {};

  cookiesAnalytics = null;

  isPending = false;

  isReady = false;

  constructor(args) {
    super(args);

    super.onEnqueue = this.onEnqueue.bind(this);

    this.synchronousMethods = ['page'];

    if (global.window) {
      this.identity = window.__PRELOADED_STATE__?.general.vcID || window.__NEXT_DATA__?.props.pageProps.vcID || {};

      if (process.env.NODE_ENV === 'test' && !window.testWaitForEvent) {
        this.init();
      } else {
        document.addEventListener('analytics-ready', () => {
          this.init();
        }, { once: true });
      }
    }
  }

  init() {
    const { identity: { userId, anonymousId } } = this;

    const user = window.analytics.user();
    const traits = user.traits() || {};
    const clientIdentity = {
      anonymousId: user?.anonymousId() || null,
      userId: user?.id() || null,
    };

    /*
     * This ensures that a logged-in user, identified on the server side by the Personas API,
     * also has its Segment's userId assigned to Analytics.js when it loads.
     */
    if (userId) {
      clientIdentity.userId = userId;
      user.id(userId);
    }

    if (anonymousId) {
      clientIdentity.anonymousId = anonymousId;
      user.anonymousId(anonymousId);
    } else {
      this.identity.anonymousId = clientIdentity.anonymousId;
    }

    this.setTraits({
      ...traits,
      // Ensures that any user's traits change made prior to Analytics.js loading is persisted
      ...this.traits,
    });

    this.isReady = true;

    identitySync(clientIdentity, true).then((syncedIdentity) => {
      this.setIdentity(syncedIdentity);
    });
  }

  getCookiesAnalytics() {
    if (!this.cookiesAnalytics) {
      this.cookiesAnalytics = setUserAnalyticsFromCookies() || {};
    }

    return this.cookiesAnalytics;
  }

  /**
   * @returns {HrefLang | undefined}
   */
  static getLocale() {
    return window.__PRELOADED_STATE__?.general.hrefLang || window.__NEXT_DATA__?.props.pageProps.hrefLang;
  }

  /**
   * @param {null | Traits} [newTraits]
   * @returns {Traits}
   */
  setTraits(newTraits = {}) {
    this.traits = newTraits === null ? {} : { ...this.traits, ...newTraits };

    if (this.isReady) {
      window.analytics.user().traits(this.traits);
    }

    return this.traits;
  }

  /**
   * @param {SegmentProfileIdentity} [identity]
   */
  setIdentity(identity = {}) {
    if (this.isReady) {
      window.analytics.user().id(identity.userId);
      window.analytics.user().anonymousId(identity.anonymousId);
    }

    this.identity = {
      ...this.identity,
      ...identity,
    };
  }

  /**
   * @param {{ [k in IdentifierType]?: string }} payload
   * @param {boolean} [forceUpdate]
   * @returns {Promise<Record<string, string>>}
   */
  async updateUserIdentity(payload, forceUpdate) {
    if (this.identity?.userId && !forceUpdate) {
      return;
    }

    const [args] = Object.entries(payload);

    if (args) {
      const [field, value] = args;

      if (forceUpdate) {
        window.analytics?.user().id(null);
      }

      const { userId, generatedUserId } = await queryCustomerId({
        field,
        value,
      });

      this.setIdentity({ userId: userId || generatedUserId || null });
      await identitySync(this.identity);
    }
  }

  static getUTMParams(search = '') {
    const utmParams = Array.from(new URLSearchParams(search))
      .filter(([key]) => key.indexOf('utm') === 0);

    return utmParams.length
      ? utmParams.reduce((params, [paramName, paramValue]) => {
        const parsedParamName = paramName.slice(4); // cut out 'utm_' prefix
        return {
          ...params,
          [parsedParamName]: paramValue,
        };
      }, {})
      : undefined;
  }

  onEnqueue() {
    if (!this.isPending) {
      this.runMethods();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getLocation() {
    return {
      path: document.location.pathname,
      url: document.location.href,
      referrer: document.referrer,
      title: document.title,
      search: document.location.search,
    };
  }

  runMethods() {
    if (this.length()) {
      const {
        method,
        params = {},
        location = {},
      } = this.peek();
      this.isPending = true;

      if (this.synchronousMethods.includes(method)) {
        this.callback = () => {
          this.dequeue();

          if (this.length()) {
            this.runMethods();
          } else {
            this.isPending = false;
          }
        };
        this[method](params, location);
      } else {
        this.callback = undefined;
        this[method](params, location);
        this.dequeue();
        this.runMethods();
      }
    } else {
      this.isPending = false;
    }
  }

  subscribe({
    email,
    phone,
    url,
    city,
    country,
    firstName,
    isFooterEmailCapture,
    lastName,
    providedConsent,
    state,
    subscriptionSource,
    userId,
  }) {
    const traits = {
      email,
      phone,
      firstName,
      lastName,
    };

    if (city || country || state) {
      traits.address = {
        city,
        country,
        state,
      };
    }

    if (userId) {
      this.setIdentity({ userId });
    }

    this.track({
      event: 'Subscribed',
      properties: {
        email,
        phone,
        url,
        isFooterEmailCapture,
        providedConsent,
        subscriptionSource,
      },
      traits,
    });
  }

  prepareEventPayload({ properties: { _email, ..._properties }, integrations }, location = {}) {
    let timezone;
    try {
      timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    } catch (e) {
      // do nothing
    }

    const email = _email || this.traits.email;

    const properties = cleanObject({
      email,
      ignore_user_events: Boolean(this.traits.isBurrowHouseAssociate),
      ..._properties,
    });

    return {
      properties: {
        ...this.getCookiesAnalytics(),
        ...properties,
      },
      context: {
        integrations,
        locale: AnalyticsFactory.getLocale(),
        page: {
          ...this.getLocation(),
          ...location,
        },
        timezone,
        userAgent: window.navigator?.userAgent,
      },
    };
  }

  track({
    event,
    properties: _properties,
    traits,
    integrations = {},
  }, location = {}) {
    const { properties, context } = this.prepareEventPayload({
      properties: _properties,
      integrations,
    }, location);

    if (traits) {
      context.traits = traits;
    }

    window.analytics?.track(
      event,
      properties,
      context,
      this.callback,
    );
  }

  pinterest({ email }) {
    let hashedEmail;
    if (email) {
      const hash = createHash('sha256');
      hash.update(email);
      hashedEmail = hash.digest('hex');
    }

    const { fbc: _fbc, fbp: _fbp, ...GAParams } = this.getCookiesAnalytics();

    window.analytics?.track(
      'Pinterest Tracking',
      {
        nonInteraction: 1,
        email,
        hashedEmail,
        ...GAParams,
      },
      {},
      this.callback,
    );
  }

  page(params, location) {
    window.analytics?.page(
      location.path,
      {
        ...location,
        ...params,
        ...this.getCookiesAnalytics(),
      },
      {
        ...this.identity,
        locale: AnalyticsFactory.getLocale(),
        utm: AnalyticsFactory.getUTMParams(location?.url?.slice(location.url.indexOf('?'))),
      },
      this.callback,
    );
  }

  /**
   * @param {{ email?: string }} params
   * @returns {boolean}
   */
  isAlreadyIdentified({ email }) {
    const user = window.analytics?.user();
    const userTraits = user?.traits();
    const userId = user?.id();

    const sameEmail = !!email && userTraits?.email === email;
    const sameUserId = !!userId && userId === this.identity?.userId;

    return sameEmail && sameUserId;
  }

  identify({ user }, location = {}) {
    const {
      email,
      firstName = '',
      lastName = '',
    } = user;

    if (this.isAlreadyIdentified({ email })) {
      return;
    }

    const name = `${firstName} ${lastName}`.trim();
    const { userId } = this.identity;
    const traits = {
      email,
    };
    if (name) traits.name = name;

    window.analytics?.identify(
      userId || undefined,
      traits,
      {
        ...location,
        locale: AnalyticsFactory.getLocale(),
      },
      this.callback,
    );
  }

  /**
   * @param {{ email?: string; attributes?: { userUuid?: string } }} user
   * @param {boolean} [forceUpdate]
   */
  async updateUserIdentityAndIdentify(user, forceUpdate = false) {
    const { email, attributes } = user;
    const userId = attributes?.userUuid;

    if (!email || this.isAlreadyIdentified({ email })) return;

    if (userId) {
      window.analytics?.user().id(userId);
      this.setIdentity({ userId });
      await identitySync({ userId });
    } else {
      await this.updateUserIdentity({ email }, forceUpdate);
    }

    this.enqueue({
      method: 'identify',
      params: {
        user,
      },
    });
  }

  collections(
    {
      event, action, email, collectionName, collectionUrl, layout, activeFilter = '',
    },
    location = {},
  ) {
    const currency = getCurrencyCode(window.__PRELOADED_STATE__?.general.hrefLang || window.__NEXT_DATA__?.props.pageProps.hrefLang);

    this.track(
      {
        event,
        properties: {
          email,
          currency,
          action,
          collectionName,
          collectionUrl,
          layout,
          activeFilter,
        },
      },
      location,
    );
  }

  PDPAction(
    {
      event, item = {}, action, email, quantity, modifierName, modifierChange,
    },
    location = {},
  ) {
    const currency = getCurrencyCode(window.__PRELOADED_STATE__?.general.hrefLang || window.__NEXT_DATA__?.props.pageProps.hrefLang);

    this.track(
      {
        event,
        properties: {
          email,
          currency,
          action,
          quantity,
          sku: item.sku,
          name: item.name,
          modifierName,
          modifierChange,
        },
      },
      location,
    );
  }

  carousel(
    {
      event, item = {}, action, email, position, typeOfAsset,
    },
    location = {},
  ) {
    const currency = getCurrencyCode(window.__PRELOADED_STATE__?.general.hrefLang || window.__NEXT_DATA__?.props.pageProps.hrefLang);
    const {
      id, image: imageUrl, name, price, sku, url, category,
    } = item;

    this.track(
      {
        event,
        properties: {
          imageUrl,
          name,
          price,
          product_id: id,
          sku,
          url,
          category,
          email,
          currency,
          action,
          position,
          typeOfAsset,
        },
      },
      location,
    );
  }

  collection(
    {
      event, action, email, collectionName, collectionUrl, showMoreLimit, totalProducts, activeLayoutGroup, category, listId,
    },
    location = {},
  ) {
    const currency = getCurrencyCode(AnalyticsFactory.getLocale());
    this.track(
      {
        event,
        properties: {
          email,
          currency,
          action,
          collectionName,
          collectionUrl,
          category,
          listId,
          showMoreLimit,
          totalProducts,
          activeLayoutGroup,
        },
      },
      location,
    );
  }

  static prepareItemProperties(item) {
    const hrefLang = AnalyticsFactory.getLocale();

    const {
      price,
      image,
      name,
      category,
    } = formatCartItemAnalytics(item, hrefLang);
    const currency = getCurrencyCode(hrefLang);

    return {
      imageUrl: image,
      category,
      name,
      price,
      product_id: item.sku,
      sku: item.sku,
      url: item.url,
      quantity: item?.quantity || 1,
      currency,
    };
  }

  item(
    {
      event, item, integrations = {}, ...properties
    },
    location = {},
  ) {
    const itemProperties = AnalyticsFactory.prepareItemProperties(item);

    const payload = {
      event,
      properties: {
        ...itemProperties,
        ...properties,
      },
      integrations,
    };

    this.track(
      payload,
      location,
    );
  }
}

export const AnalyticsInstance = new AnalyticsFactory();

export default generateProxy(AnalyticsInstance);
