import { put, select } from 'redux-saga/effects';
import { CallHistoryMethodAction } from 'connected-react-router';
import { getHrefLang } from '../selectors/general.selectors';
import excludedLocalizationEndpoints from '../../../../shared/localization/excludedLocalizationEndpoints';
import LocalizationUtils from '../../../../utils/localization.utils';
import HrefLang from '../../../../shared/localization/hrefLang';
import generateUrlWithHrefLang from '../../common/generateUrlWithHrefLang';

interface DecoratorProps {
  url: string | { pathname: string, search: string },
  method: (url: string | { pathname: string, search: string }) => CallHistoryMethodAction
}

export default function* withLocalizationSagaDecorator({ url, method }: DecoratorProps) {
  const hrefLang: HrefLang = yield select(getHrefLang);

  if (typeof url === 'string' && !excludedLocalizationEndpoints.includes(url) && !LocalizationUtils.getAnyHrefLangFromUrl(url) && hrefLang !== HrefLang.EN_US) {
    const localizedUrl = generateUrlWithHrefLang({ hrefLang, endpoint: url });
    yield put(method(localizedUrl));

    return;
  }

  yield put(method(url));
}
