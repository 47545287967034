import { useState } from 'react';

import {
  NavigationItem as NavigationItemType,
} from './header.types';
import { default as styles } from '../../../scss/components/Header.module.scss';
import { withCssModulesClassNames } from '../../../common/nextMigrationHelpers';

const withCssModulesClassNamesHandler = withCssModulesClassNames(styles);

interface ItemHeaderProps {
  onClick?: () => void;
  isOpen: boolean;
  isMobile?: boolean;
  link: NavigationItemType;
}

const ItemHeader = ({
  link,
  isOpen,
  isMobile,
  onClick,
}: ItemHeaderProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <button
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ color: isHovered ? link.hoverColor?.hex : link.color?.hex }}
      data-cy={!link.expandedMobileSection ? 'navigation-position-button' : undefined}
      data-testid={'ItemHeaderButton'}
      className={withCssModulesClassNamesHandler(
        'navigation-position-button',
        {
          active: isOpen,
        },
      )}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...((link.expandedMobileSection && isMobile) && { disabled: true })}
    >
      <span
        className={withCssModulesClassNamesHandler('navigation-position__header')}
      >
        {link.header}
      </span>
      <i
        className={withCssModulesClassNamesHandler(
          'icon',
          'icon-nav-arrow',
          {
            active: isOpen,
            expandedMobileSection: link.expandedMobileSection,
          },
        )}
      />
    </button>
  );
};

export default ItemHeader;
