import { ReactNode } from 'react';
import dynamic from 'next/dynamic';
import loadable from '@loadable/component';

import type { AccordionProps } from '../Accordion';

import { LinkDto } from '../../../types/schema';
import { withCssModulesClassNames } from '../../../common/nextMigrationHelpers';
import SMSContainer from '../SMS';
import { footer as footerGlobalAPI } from '../../../mock/globalAPI';
import UniversalLinkContainer from '../UniversalLink';
import ErrorBoundary from '../ErrorBoundary';

import { default as styles } from '../../../scss/components/Footer.module.scss';
import { CXHours, FooterNavigationItem } from './footer.types';
import { NavigationTier } from '../../../types/common';

const NewsletterDynamic = dynamic(() => import('../Newsletter'), { ssr: false });
const NewsletterLoadable = loadable(() => import('../Newsletter'), { ssr: false });
const AccordionContainerDynamic = dynamic(() => import('../Accordion'), { ssr: false });
const AccordionContainerLoadable = loadable<AccordionProps>(() => import('../Accordion'), { ssr: false });

const withCssModulesClassNamesHandler = withCssModulesClassNames(styles);
export interface FooterProps {
  navigation: FooterNavigationItem[],
  cxHours?: Omit<CXHours, 'id' | '__typename'>,
  nextApp?: boolean,
  anchor?: string
}

const Footer = ({
  navigation,
  cxHours,
  nextApp,
  anchor = '',
}: FooterProps) => {
  const filterNavigation = (navigationArray: FooterNavigationItem[], type: NavigationTier) => (
    navigationArray ? navigationArray.filter((section) => section.tier === type) : []
  );

  const renderSMS = (copy: string | null, className: string): ReactNode => (
    <SMSContainer
      cxHours={cxHours}
      icon={false}
      className={className}
      copy={copy || undefined}
    />
  );

  const renderNavItem = (item : LinkDto) => {
    const {
      type,
      anchor: itemAnchor,
      text,
      href,
      className,
      destinationPage,
    } = item;
    // FIXME: anchor should not be used as a url
    const link = (destinationPage && destinationPage.url) || href || itemAnchor;
    let content: string | ReactNode = text ?? '';
    let itemContent: string | ReactNode = content;
    const label = text || undefined;

    if (className && className.includes('icon')) {
      content = <i className={className} />;
    }

    if (type === 'SMS') {
      itemContent = renderSMS(text, styles.sms);
    } else if (link) {
      itemContent = (
        <UniversalLinkContainer
          className={withCssModulesClassNamesHandler('footer__nav-item-link')}
          to={link}
          location="footer"
          type={type || undefined}
          label={text !== content ? label : undefined}
        >
          {content}
        </UniversalLinkContainer>
      );
    }

    return (
      <li
        key={item.id}
        className={withCssModulesClassNamesHandler('footer__nav-item')}
      >
        {itemContent}
      </li>
    );
  };

  const renderNavSocial = (nav: FooterNavigationItem[]) => (
    <div className={withCssModulesClassNamesHandler('footer__social')}>
      <div className={withCssModulesClassNamesHandler('footer__social-header')}>Connect with us:</div>
      {nav.map((section) => (
        <ul key={section.id} className={withCssModulesClassNamesHandler('footer__nav-items')}>
          {section.categories.map(renderNavItem)}
        </ul>
      ))}
    </div>
  );

  const renderNavSections = (nav: FooterNavigationItem[], accordionOnMobile?: boolean) => {
    const AccordionContainer = nextApp ? AccordionContainerDynamic : AccordionContainerLoadable;

    return (
      <>
        {accordionOnMobile && (
          <AccordionContainer
            id="footer"
            additionalClassName={styles.accordion}
            itemWrapperClassName={styles['accordion-inner-wrapper']}
            itemClassName={styles.accordion__item}
            iconCloseClassName={styles['icon-close']}
            itemActionClassName={styles['accordion__item-actions']}
            itemBodyClassName={styles['accordion__item-body']}
            headerClassName={styles['accordion__item-title']}
            points={nav.map(({ id, header, ...body }) => ({ id, header, body }))}
            renderItemBody={(navigationData: FooterNavigationItem) => (
              <ul className={withCssModulesClassNamesHandler('footer__nav-items')}>
                {navigationData.categories.map(renderNavItem)}
              </ul>
            )}
          />
        )}
        <div className={withCssModulesClassNamesHandler('footer__nav-sections')}>
          {nav.map((section) => (
            <div
              key={section.id}
              className={withCssModulesClassNamesHandler('footer__nav-section')}
              data-testid={`footer-section-${section.header}`}
            >
              {section.header && (
                <div className={withCssModulesClassNamesHandler(
                  'footer__nav-item',
                  'footer__nav-item--title',
                )}
                >
                  {section.header}
                </div>
              )}
              <ul className={withCssModulesClassNamesHandler('footer__nav-items')}>
               {section.categories.map(renderNavItem)}
              </ul>
            </div>
          ))}
        </div>
      </>
    );
  };

  const navSocial = filterNavigation(navigation, 'FooterSocial');
  const navColumns = filterNavigation(navigation, 'FooterColumns');
  const navCopyright = filterNavigation(navigation, 'FooterCopyright');

  if (navCopyright && navCopyright.length) {
    navCopyright[0] = {
      ...navCopyright[0],
      header: `© ${new Date().getFullYear()} ${navCopyright[0].header}`,
    };
  }

  return (
    <ErrorBoundary errorMessage="Could not load footer, try again.">
      <footer
        id={anchor || undefined}
        className={withCssModulesClassNamesHandler('footer')}
      >
        <div className={withCssModulesClassNamesHandler('footer__container')}>
          <div
            className={withCssModulesClassNamesHandler(
              'footer__section',
              'footer__section--top',
            )}
            data-testid="footer-top"
          >
            {
              nextApp ? <NewsletterDynamic nextApp /> : <NewsletterLoadable />
            }
            {renderNavSocial(navSocial)}
          </div>
          <div className={
            withCssModulesClassNamesHandler(
              'footer__section',
              'footer__section--middle',
            )}
          data-testid="footer-middle"
          >
            {renderNavSections(navColumns, true)}
          </div>
          <div className={
            withCssModulesClassNamesHandler(
              'footer__section',
              'footer__section--bottom',
            )}
          data-testid="footer-bottom"
          >
            {renderNavSections(navCopyright)}
            <address>{footerGlobalAPI.address}</address>
          </div>
        </div>
      </footer>
    </ErrorBoundary>
  );
};

export default Footer;
