import { useSelector } from 'react-redux';
import { getIsOnline } from '../../store/selectors/ui.selectors';
import { withCssModulesClassNames } from '../../common/nextMigrationHelpers';
import LoadingIcon from './LoadingIcon';

const withCssModulesClassNamesHandler = withCssModulesClassNames();

const Loading = ({ className }: { className?: string }) => {
  const isOnline = useSelector(getIsOnline);
  return isOnline ? (
    <div className={withCssModulesClassNamesHandler('notFound', 'loading', className)}
      aria-label="loading"
    >
      <LoadingIcon />
    </div>
  ) : (
    <div className={withCssModulesClassNamesHandler('offline')}>
      <p className={withCssModulesClassNamesHandler('offline__message')}>
        you&apos;re offline.
      </p>
    </div>
  );
};

export default Loading;
