import {
  FETCH_ORDER_HISTORY__FAILURE,
  FETCH_ORDER_HISTORY__REQUEST,
  FETCH_ORDER_HISTORY__SUCCESS,
} from '../actions/order.actions';

export const initialState = {
  isPending: false,
  error: null,
  data: {
    history: [],
  },
};

const fetchOrderHistoryRequest = (state) => ({
  ...state,
  isPending: true,
});

const fetchOrderHistorySuccess = (state, payload) => ({
  ...state,
  isPending: false,
  error: null,
  data: {
    ...state.data,
    history: payload || [],
  },
});

const fetchOrderHistoryFailure = (state, payload) => ({
  ...state,
  isPending: false,
  error: payload.error,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ORDER_HISTORY__REQUEST:
      return fetchOrderHistoryRequest(state);

    case FETCH_ORDER_HISTORY__SUCCESS:
      return fetchOrderHistorySuccess(state, action.payload);

    case FETCH_ORDER_HISTORY__FAILURE:
      return fetchOrderHistoryFailure(state, action.payload);

    default:
      return state;
  }
};
