import { ProductDto } from '../../../types/schema';
import { addToCart } from '../../../store/actions/cart.actions';

export function getAllOptions(
  productDetails?: ProductDto,
  modifiers: unknown[] = [],
) {
  const options = (
    productDetails && Array.isArray(productDetails.optionValues)
      ? productDetails.optionValues
      : []
  );

  return [...options, ...(Array.isArray(modifiers) ? modifiers : [])];
}

interface TriggerAddToCartProps {
  disabled: boolean;
  productDetails?: ProductDto | null;
  checkedInventory: boolean;
  soldOut: boolean;
  source?: string;
  addToCartAction: typeof addToCart;
  selectedAddons?: { outOfStock: boolean }[];
  selectedQty: number;
  callback?: () => void;
  onAdded?: () => void;
  selectedModifiersList: unknown[];
}

export const triggerAddToCartAction = ({
  disabled,
  productDetails,
  checkedInventory,
  soldOut,
  source,
  addToCartAction,
  selectedAddons = [],
  selectedQty,
  callback,
  onAdded,
  selectedModifiersList,
}: TriggerAddToCartProps) => {
  const isSetProducts = !!productDetails?.setProducts?.length;
  const productActive = (productDetails?.id || isSetProducts) && checkedInventory && !soldOut;
  const allOptions = productDetails ? getAllOptions(productDetails, selectedModifiersList) : [];

  if (productActive && !disabled) {
    addToCartAction(
      productDetails,
      allOptions,
      selectedQty,
      source,
      selectedAddons,
      false,
      selectedQty,
      selectedModifiersList,
      null,
      onAdded,
    );
    if (callback) callback();
  }
};

/**
 * @deprecated Use triggerAddToCartAction instead
 */
export function handleAddToCartClick() {
  triggerAddToCartAction(this.props);
}
