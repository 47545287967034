import { withCssModulesClassNames } from '../../../common/nextMigrationHelpers';
import Menu from './Menu';
import BurrowLogo from './BurrowLogo';
import Hamburger from './Hamburger';
import { default as styles } from '../../../scss/components/Header.module.scss';
import { CloseMenuParams, NavigationItem } from './header.types';
import { SearchDropdown } from '../Search';

const withCssModulesClassNamesHandler = withCssModulesClassNames(styles);

export type ShoppingNavigationProps = {
  cartNumber: number,
  isLoggedIn: boolean,
  navigation: NavigationItem[],
  isMobileMenuOpen: boolean,
  openCart: (booleanValue: boolean) => void,
  closeMenu: (config?: CloseMenuParams) => void,
  dropdownStates: { [key: string]: boolean }
  isDropdownOpen: boolean,
  toggleCurrentDropdown: (id: string, header: string, neighbors: string[]) => void,
  experiments?: Record<string, boolean>,
  toggleMobileMenu: () => void
  isSearchDropdownOpen: boolean,
  isDesktop: boolean,
};

const ShoppingNavigation = ({
  cartNumber,
  closeMenu,
  isDropdownOpen,
  isLoggedIn,
  isMobileMenuOpen,
  navigation,
  openCart,
  dropdownStates,
  toggleCurrentDropdown,
  toggleMobileMenu,
  experiments = {},
  isDesktop = true,
  isSearchDropdownOpen,
}: ShoppingNavigationProps) => (
  <>
    <div className={withCssModulesClassNamesHandler('header-menu-desktop')}>
      {
        !isDesktop && (
          <Hamburger
            toggleMobileMenu={toggleMobileMenu}
            isMobileMenuOpen={isMobileMenuOpen}
          />
        )
      }
      <BurrowLogo />
      <Menu
        cartNumber={cartNumber}
        closeMenu={closeMenu}
        isLoggedIn={isLoggedIn}
        experiments={experiments}
        navigation={navigation}
        openCart={openCart}
        toggleCurrentDropdown={toggleCurrentDropdown}
        dropdownStates={dropdownStates}
      />
    </div>
    { isSearchDropdownOpen ? <SearchDropdown /> : null }
    <div className={withCssModulesClassNamesHandler(
      'shopping-container',
      {
        active: isDropdownOpen,
      },
    )}
    />
  </>
);

export default ShoppingNavigation;
