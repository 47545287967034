import React from 'react';
import PropTypes from 'prop-types';
import { withCssModulesClassNames } from '../../../common/nextMigrationHelpers';
import UniversalLinkContainer from '../UniversalLink';
import FeatureBox from './FeatureBox';
import CallToAction from '../CallToAction';
import { default as styles } from '../../../scss/components/Header.module.scss';
import { NavigationItem, Link, Asset } from './header.types';
import Nullable from '../../../../../shared/nullable';

import HeaderLinkTitle from './HeaderLinkTitle';

const withCssModulesClassNamesHandler = withCssModulesClassNames(styles);

export type HeaderLinkProps = {
  baseClass: string,
  closeMenu?: (props?: { preventMobile?: Nullable<boolean> | undefined } | React.MouseEvent<HTMLAnchorElement>) => void,
  link?: NavigationItem | Link,
  sectionHeader?: string,
};

const HeaderLink = ({
  baseClass = '',
  closeMenu = () => {},
  link,
  sectionHeader = '',
}: HeaderLinkProps) => {
  if (!link) {
    return null;
  }

  let asset;
  let callToAction;
  let destinationPage;
  let header;
  let pageLink;
  let text;
  let linkAnchor;

  if ('expandedMobileSection' in link) {
    ({
      header,
      link: pageLink,
    } = link);
  } else if ('anchor' in link) {
    ({
      asset,
      callToAction,
      destinationPage,
      header,
      text,
      anchor: linkAnchor,
    } = link);
  }
  const anchor = linkAnchor || '';
  const url = pageLink?.destinationPage?.url || destinationPage?.url;
  const linkProps = pageLink || link;

  const hasAsset = !!asset;
  return url ? (
    <UniversalLinkContainer
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...linkProps}
      label={`${header || text}`}
      location="header"
      to={`${url}${anchor}`}
      href={`${url}${anchor}`}
      clickHandler={closeMenu}
      className={withCssModulesClassNamesHandler(baseClass)}
      linkType={hasAsset ? 'Featured' : undefined}
    >
      {hasAsset && (
        <FeatureBox
          asset={asset as Asset}
          text={text}
          callToAction={callToAction}
          sectionHeader={sectionHeader}
          color={link.color?.hex}
          hoverColor={link.hoverColor?.hex}
        />
      )}
      {!hasAsset && (
        header ? (
          <HeaderLinkTitle
            header={header}
            color={link.color}
            hoverColor={link.hoverColor}
          />
        ) : text)}
      {!hasAsset && callToAction && (
        <CallToAction
          callToAction={callToAction}
          additionalClassName={withCssModulesClassNamesHandler(
            'link-subtitle',
            'call-to-action',
          )}
        />
      )}
    </UniversalLinkContainer>
  ) : null;
};

HeaderLink.propTypes = {
  baseClass: PropTypes.string,
  closeMenu: PropTypes.func,
  link: PropTypes.shape({
    anchor: PropTypes.string,
    asset: PropTypes.shape({}),
    callToAction: PropTypes.shape({}),
    destinationPage: PropTypes.shape({
      url: PropTypes.string,
    }),
    header: PropTypes.string,
    text: PropTypes.string,
    link: PropTypes.shape({
      destinationPage: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }),
  sectionHeader: PropTypes.string,
};

export default HeaderLink;
