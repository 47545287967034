import {
  CaseReducer,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { addToCart } from '../actions/cart.actions';
import { generateMultipleAddToCartLineItemsFromArray } from '../../components/global/Cart/CartHelpers';

import { ProductDto, ProductCollectionDto } from '../../types/schema';
import { Nullable } from '../../types/common';
import { RootState } from '../store.types';

export const FAVORITES_STORAGE_KEY = 'persist:favoritesState';

export type FavoritesCaseReducer<T> = CaseReducer<
FavoritesState,
PayloadAction<T>
>;
export type FavoriteItem = Pick<
ProductDto,
'productID' | 'productCardImage' | 'name' | 'sku' | 'optionValues'
> & {
  collectionHeader: ProductCollectionDto['header'];
};

export interface FavoritesState {
  loading: 'idle' | 'pending' | 'rejected' | 'fulfilled';
  error: Nullable<string>;
  data: {
    [key: string]: {
      [key: string]: FavoriteItem;
    };
  };
}

export const initialState: FavoritesState = {
  loading: 'idle',
  error: null,
  data: {},
};

export const restoreFavoritesData = () => {
  let storedData = initialState.data;
  try {
    const serializedState = localStorage.getItem(FAVORITES_STORAGE_KEY);
    if (serializedState !== null) {
      storedData = JSON.parse(serializedState);
    }
  } catch {
    // do nothing
  }
  return storedData;
};

const addItemReducer: FavoritesCaseReducer<{
  group: string;
  item: FavoriteItem;
}> = (draftState, action) => {
  const {
    group,
    item: {
      productID, productCardImage, name, sku, optionValues, collectionHeader,
    },
  } = action.payload;

  if (!draftState.data[group]) {
    draftState.data[group] = {};
  }

  draftState.data[group][sku] = {
    productID,
    productCardImage,
    name,
    sku,
    optionValues,
    collectionHeader,
  };
};

const removeItemReducer: FavoritesCaseReducer<{
  group: string;
  sku: FavoriteItem['sku'];
}> = (draftState, action) => {
  const { group, sku } = action.payload;

  delete draftState.data[group][sku];
};

const clearFavoritesReducer: FavoritesCaseReducer<{ group: string } | null> = (
  draftState,
  action,
) => {
  const { group } = action.payload ?? {};

  if (group) {
    draftState.data[group] = {};
  }
};

const setLoadingReducer: FavoritesCaseReducer<FavoritesState['loading']> = (
  draftState,
  action,
) => {
  draftState.loading = action.payload;
};

const setErrorReducer: FavoritesCaseReducer<FavoritesState['error']> = (
  state,
  action,
) => ({
  ...state,
  error: action.payload,
});

export const addFavoritesToCart = createAsyncThunk<
{ group: string } | null,
string,
{ state: RootState }
>('favorites/addItemsToCart', (group: string, { dispatch, getState }) => {
  const state = getState();
  const favorites = Object.entries(state.favorites.data[group] || {});

  if (favorites && favorites.length) {
    const items = favorites.map(([, item]) => item);
    const [item] = items;
    const lineItems = generateMultipleAddToCartLineItemsFromArray(items, 1);

    dispatch(addToCart(item, [], 1, 'favorites', [], false, 1, [], lineItems));

    return { group };
  }

  return null;
});

export const slice = createSlice({
  name: 'favorites',
  initialState,
  reducers: {
    addFavoriteItem: addItemReducer,
    removeFavoriteItem: removeItemReducer,
    clearFavorites: clearFavoritesReducer,
    setLoading: setLoadingReducer,
    setError: setErrorReducer,
  },
  extraReducers: (builder) => {
    builder.addCase(addFavoritesToCart.fulfilled, clearFavoritesReducer);
  },
});

export const {
  addFavoriteItem,
  removeFavoriteItem,
  clearFavorites,
  setLoading,
  setError,
} = slice.actions;

export default slice.reducer;
