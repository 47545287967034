import {
  call, put, select, takeLatest,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import qs from 'qs';

import {
  PRODUCT_LANDING_PAGE_DID_MOUNT,
  COLLECTION_LANDING_PAGE_DID_MOUNT,
} from '../actions/ui.actions';
import {
  AUTO_SELECT_MOBILE_LAYOUT,
  SET_SELECTED_LAYOUT_GROUP,
  setSelectedLayout,
} from '../actions/collections.actions';
import {
  FETCH_PAGE_CONTENT__SUCCESS,
} from '../actions/page.actions';

import {
  getAllCollections,
  mapLayoutGroupToQueryParam,
  getActiveFilters,
} from '../selectors/collections.selectors';
import {
  getQueryParams,
  getLocation,
} from '../selectors/router.selectors';
import {
  getUserDetails,
} from '../selectors/user.selectors';
import {
  getProductCollectionItemsCount,
} from '../selectors/page.selectors';

import { logError } from '../../../../utils/Utils';
import Analytics from '../../components/global/Analytics';

function* viewWithCollectionsMounted({ payload }) {
  const { collectionsPaths } = payload;
  const collections = yield select(getAllCollections);
  const collectionsToFetch = [];

  collectionsPaths.forEach((viewCollection) => {
    if (!collections.some(({ page }) => page === viewCollection)) {
      collectionsToFetch.push(viewCollection);
    }
  });
}

export function* setSelectedLayoutGroup({ payload: layoutGroup }) {
  try {
    const { pathname } = yield select(getLocation);
    const queryParams = yield select(getQueryParams);
    const layout = mapLayoutGroupToQueryParam(layoutGroup);
    yield put(push({
      pathname,
      search: qs.stringify({ ...queryParams, layout }),
    }));

    const {
      email = '',
    } = yield select(getUserDetails);
    const activeFilters = yield select(getActiveFilters);
    const collections = yield select(getAllCollections);
    const [{
      header,
    }] = collections;

    yield call([Analytics, Analytics.updateUserIdentityAndIdentify], { email });

    Analytics.enqueue({
      method: 'collections',
      params: {
        event: 'Product Collections Layout',
        action: 'Change',
        currency: 'USD',
        email,
        collectionName: header,
        collectionUrl: window && window.location.pathname,
        layout: layoutGroup,
        activeFilters,
      },
      location: Analytics.getLocation(),
    });
  } catch (error) {
    yield call([this, logError], error, setSelectedLayoutGroup.name);
  }
}

export function* autoSelectMobileLayout() {
  const collectionItemsCount = yield select(getProductCollectionItemsCount);

  if (collectionItemsCount === 0) {
    return;
  }

  if (collectionItemsCount < 20) {
    yield put(setSelectedLayout('SingleGrid'));
  } else {
    yield put(setSelectedLayout('DoubleGrid'));
  }
}

const collectionsSagas = [
  takeLatest(PRODUCT_LANDING_PAGE_DID_MOUNT, viewWithCollectionsMounted),
  takeLatest(COLLECTION_LANDING_PAGE_DID_MOUNT, viewWithCollectionsMounted),
  takeLatest(SET_SELECTED_LAYOUT_GROUP, setSelectedLayoutGroup),
  takeLatest(FETCH_PAGE_CONTENT__SUCCESS, autoSelectMobileLayout),
  takeLatest(AUTO_SELECT_MOBILE_LAYOUT, autoSelectMobileLayout),
];

export default collectionsSagas;
