import { useDispatch, useSelector } from 'react-redux';
import { setIsSearchDropdownOpen } from '../../../store/actions/ui.actions';
import { getIsSearchDropdownOpen } from '../../../store/selectors/ui.selectors';
import { withCssModulesClassNames } from '../../../common/nextMigrationHelpers';
import { default as searchStyles } from '../../../scss/components/Search.module.scss';
import Nullable from '../../../../../shared/nullable';

const withCssModulesClassNamesHandler = withCssModulesClassNames(searchStyles);

const SearchButton = ({ closeMenu }: { closeMenu: (config?: { preventMobile?: Nullable<boolean> }) => void }) => {
  const dispatch = useDispatch();
  const isSearchDropdownOpen = useSelector(getIsSearchDropdownOpen);

  const handleClick = () => {
    closeMenu();
    dispatch(setIsSearchDropdownOpen(!isSearchDropdownOpen));
  };

  return (
    <button
      aria-label="Search"
      data-testid="search-dropdown-toggle"
      className={withCssModulesClassNamesHandler('search-cta')}
      onClick={handleClick}
    >
      <i className={withCssModulesClassNamesHandler('icon', 'icon-search')} data-testid="icon-nav-search"/>
      <span className={withCssModulesClassNamesHandler('mobile-link-span')}>
        Search
      </span>
    </button>
  );
};

export default SearchButton;
