import {
  useState,
  ReactNode,
  CSSProperties,
  AnchorHTMLAttributes,
} from 'react';
import NextLink, { type LinkProps } from 'next/link';
import { Link as ReactDomLink } from 'react-router-dom';
import type { LinkProps as ReactRouterLinkProps } from 'react-router-dom';

import { getRegexGroupByLocalePath } from '../../../../utils/Utils';

const validUrlPathnames = [
  '/living-room/seating',
  '/living-room/seating/sectionals',
  '/living-room/seating/accent-chairs',
];

type LinkAdapterProps = (
  LinkProps | ReactRouterLinkProps | AnchorHTMLAttributes<HTMLAnchorElement>
) & {
  children?: ReactNode;
  nextApp?: boolean;
  hrefLang?: string;
  color?: string;
  hoverColor?: string;
  href: string;
  style?: CSSProperties;
};

const shouldRenderAnchor = (href: string) => {
  const { pathWithoutLocale } = getRegexGroupByLocalePath(href);

  return validUrlPathnames.includes(pathWithoutLocale);
};

const LinkAdapter = ({
  children,
  nextApp,
  href,
  color,
  hoverColor,
  ...props
}: LinkAdapterProps) => {
  let newHref = href;
  const { hrefLang } = props;
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  if (hrefLang && hrefLang !== 'en-us' && !href?.includes(hrefLang)) {
    newHref = `/${hrefLang}${href}`;
  }

  const { to: _to, ...rest } = props;

  const linkStyleProps = {
    onMouseEnter: handleMouseEnter,
    onMouseLeave: handleMouseLeave,
    style: {
      ...props.style,
      color: isHovered ? hoverColor : color,
    },
  };

  // if we are on express and want to navigate to a nextjs page
  // we need to need to render an anchor tag instead of a RRL or NL for a proper navigation
  if (shouldRenderAnchor(newHref) && !nextApp) {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <a {...rest} {...linkStyleProps} href={newHref}>{children}</a>
    );
  }

  return nextApp ? (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <NextLink {...rest} {...linkStyleProps} legacyBehavior={false} href={newHref} prefetch={false}>
      {children}
    </NextLink>
  ) : (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <ReactDomLink {...rest} {...linkStyleProps} to={newHref} href={newHref}>
      {children}
    </ReactDomLink>
  );
};

export default LinkAdapter;
