import { memo } from 'react';
import { Waypoint } from 'react-waypoint';

import { useSelector } from 'react-redux';
import ProductCardContainer from './ProductCard';
import LifestyleCard from './LifestyleCard';
import ContentCard from './ContentCard';
import { FavoriteCard } from './FavoriteCard';
import ErrorBoundary from '../global/ErrorBoundary';
import { ProductCollectionDto, ProductCollectionItemDto } from '../../types/schema';
import { generateVisibilityClassNames, generateColumnSpanClassNames } from '../../../../utils/Utils';
import { getPlpSiteWideText } from '../../store/selectors/ui.selectors';

interface ProductCollectionItemProps {
  source?: string;
  tabIndex?: number;
  hideDetails?: boolean;
  productCarouselLifestyleCard?: boolean;
  waypointProps?: Waypoint.WaypointProps;
  setItemViewedCallback?: (cb: () => void) => void;
  collectionItem: ProductCollectionItemDto;
  collectionHeader: ProductCollectionDto['header'];
}

const ProductCollectionItem = memo(({
  source,
  tabIndex,
  hideDetails,
  waypointProps,
  collectionItem,
  setItemViewedCallback,
  productCarouselLifestyleCard,
  collectionHeader = null,
}: ProductCollectionItemProps) => {
  const {
    id,
    sku,
    link,
    item,
    order,
    modal,
    salesCopy,
    columnSpan,
    addOnItems,
    flipSetting,
    columnWidths,
    visibilities,
    lifestyleCopy,
    lifestyleIcon,
    callToActions,
    tuftedSetting,
    lifestyleAsset,
    lifestyleHeader,
    hideColorPicker,
    alignHorizontally,
    lifestyleCopyBackgroundColor,
  } = collectionItem;
  const visibilityClassNames = generateVisibilityClassNames(visibilities);
  const plpSiteWideText = useSelector(getPlpSiteWideText);

  switch (collectionItem.type) {
    case 'LifestyleCard': {
      const columnWidthsClassNames = generateColumnSpanClassNames(columnWidths);
      return (
        <ErrorBoundary errorMessage="Sorry, this image could not load!">
          <LifestyleCard
            productCarouselLifestyleCard={productCarouselLifestyleCard}
            header={lifestyleHeader}
            copy={lifestyleCopy}
            asset={lifestyleAsset}
            copyBackgroundColor={lifestyleCopyBackgroundColor}
            alignHorizontally={alignHorizontally}
            icon={lifestyleIcon}
            link={link}
            visibilityClassNames={visibilityClassNames}
            columnWidthsClassNames={columnWidthsClassNames}
            tabIndex={tabIndex}
          />
        </ErrorBoundary>
      );
    }
    case 'ProductCard': {
      return (
        <ErrorBoundary errorMessage="Sorry, this product could not load!">
          <ProductCardContainer
            key={`product-collection-item-${id}-${sku}`}
            sku={sku}
            item={item}
            position={order}
            addOnItems={addOnItems}
            callToActions={callToActions}
            tuftedSetting={tuftedSetting}
            flipSetting={flipSetting}
            className={'bundle'}
            hideDetails={hideDetails}
            source={source}
            hideColorPicker={hideColorPicker}
            salesCopy={salesCopy}
            waypointProps={waypointProps}
            setItemViewedCallback={setItemViewedCallback}
            visibilityClassNames={visibilityClassNames}
            tabIndex={tabIndex}
            plpSiteWideText={plpSiteWideText}
          />
        </ErrorBoundary>
      );
    }
    case 'ContentCard': {
      return (
        <ErrorBoundary errorMessage="Sorry, this image could not load!">
          <ContentCard
            visibilityClassNames={visibilityClassNames}
            productCarouselLifestyleCard={productCarouselLifestyleCard}
            header={lifestyleHeader}
            copy={lifestyleCopy}
            columnSpan={columnSpan}
            asset={lifestyleAsset}
            backgroundCopyColor={lifestyleCopyBackgroundColor}
            alignHorizontally={alignHorizontally}
            icon={lifestyleIcon}
            link={link}
            tabIndex={tabIndex}
          />
        </ErrorBoundary>
      );
    }
    case 'FavoriteCard': {
      if (item) {
        return (
          <ErrorBoundary errorMessage="Sorry, this product could not load!">
            <FavoriteCard
              item={item}
              modal={modal}
              collectionHeader={collectionHeader}
            />
          </ErrorBoundary>
        );
      }

      return null;
    }
    default:
      return null;
  }
});

ProductCollectionItem.displayName = 'ProductCollectionItem';

export default ProductCollectionItem;
