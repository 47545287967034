import { Component, ReactNode } from 'react';

import Nullable from '../../../../shared/nullable';

interface ErrorBoundaryProps {
  errorMessage?: string;
  error?: Error;
  children?: ReactNode;
}

interface ErrorBoundaryState {
  error: Nullable<Error>;
  errorMessage: string;
}

export default class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      error: null,
      errorMessage: this.props.errorMessage ? this.props.errorMessage : 'Something went wrong and this component did not render.',
    };
  }

  componentDidCatch(error: Error) {
    // eslint-disable-next-line no-console
    console.error('ErrorBoundary: error.stack: ', error.stack);
    // eslint-disable-next-line no-console
    console.error('ErrorBoundary: error.message: ', error.message);

    this.setState({
      error,
    });
  }

  render() {
    if (this.state.error || this.props.error) {
      return (
        <section className="error-container">
          <h3>Oops!</h3>
          <p>{this.state.errorMessage}</p>
        </section>
      );
    }
    return this.props.children;
  }
}
