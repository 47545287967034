import Image from 'next/image';
import { ImgHTMLAttributes } from 'react';
import { Asset as AssetType } from './header/header.types';
import { defaultBlurryImage } from './Asset.helpers';

export const blurredImageSrc = (handle: string) => (
  `https://media.graphassets.com/resize=h:20,fit:crop/blur=amount:2/compress/${handle}`
);

export const imageLoader = ({ src, width }: { src: string, width: string | number }) => (
  `https://media.graphassets.com/resize=w:${width},fit:crop/output=format:webp/quality=value:50/compress/${src}`
);

interface ImgProps extends ImgHTMLAttributes<HTMLImageElement> {
  asset: AssetType;
  imageClassName?: string;
  customHeight?: number | null;
  blurryPlaceholder?: boolean;
  priority?: boolean;
  onLoad?: () => void;
  alt?: string;
}

const NextImage = ({
  asset,
  imageClassName,
  customHeight,
  priority,
  alt,
}: ImgProps) => {
  const style = customHeight ? { height: customHeight } : {};

  return (
    <Image
      className={imageClassName}
      loader={imageLoader}
      src={asset.handle}
      blurDataURL={defaultBlurryImage}
      fill
      placeholder="blur"
      alt={asset?.alt || alt || ''}
      priority={priority}
      style={{
        ...style,
        objectFit: 'cover',
        objectPosition: 'center center',
      }}
    />
  );
};

export default NextImage;
