import React, { Component, createRef } from 'react';
import { Waypoint } from 'react-waypoint';

import ProductCollectionItem from './ProductCollectionItem';

export default class CollectionCarousel extends Component {
  itemViewedCallbacks = [];

  verticallyVisible = false;

  horizontallyVisible = [];

  constructor(props) {
    super(props);

    this.state = { scrollableAncestor: null };
    this.carouselRef = createRef();

    this.setItemViewedCallback = this.setItemViewedCallback.bind(this);
    this.handleHorizontalWaypointEnter = this.handleHorizontalWaypointEnter.bind(this);
    this.handleHorizontalWaypointLeave = this.handleHorizontalWaypointLeave.bind(this);
    this.handleVerticalWaypointEnter = this.handleVerticalWaypointEnter.bind(this);
    this.handleVerticalWaypointLeave = this.handleVerticalWaypointLeave.bind(this);
    this.renderCollectionItem = this.renderCollectionItem.bind(this);
  }

  setItemViewedCallback(index, cb) {
    this.itemViewedCallbacks[index] = cb;
  }

  handleHorizontalWaypointEnter(index) {
    const cb = this.itemViewedCallbacks[index];
    if (!this.horizontallyVisible[index] && this.verticallyVisible && cb) {
      cb();
    }
    this.horizontallyVisible[index] = true;
  }

  handleHorizontalWaypointLeave(index) {
    this.horizontallyVisible[index] = false;
  }

  handleVerticalWaypointEnter() {
    this.horizontallyVisible.forEach((isVisible, index) => {
      const cb = this.itemViewedCallbacks[index];
      if (isVisible && cb) {
        cb();
      }
    });
    this.verticallyVisible = true;
  }

  handleVerticalWaypointLeave() {
    this.verticallyVisible = false;
  }

  renderCollectionItem(collectionItem, index) {
    const {
      history,
    } = this.props;
    const {
      skuModifier,
      id,
    } = collectionItem;
    const horizontalWaypointProps = {
      horizontal: true,
      scrollableAncestor: this.carouselRef?.current,
      onEnter: this.handleHorizontalWaypointEnter.bind(this, index),
      onLeave: this.handleHorizontalWaypointLeave.bind(this, index),
    };

    return (
      <ProductCollectionItem
        key={id}
        history={history}
        collectionItem={collectionItem}
        modifier={skuModifier}
        waypointProps={horizontalWaypointProps}
        setItemViewedCallback={(cb) => this.setItemViewedCallback(index, cb)}
        source="collection-carousel"
      />
    );
  }

  componentDidMount() {
    this.setState({ scrollableAncestor: window });
  }

  render() {
    const {
      collection,
    } = this.props;
    const { scrollableAncestor } = this.state;

    return (
      <Waypoint
        scrollableAncestor={scrollableAncestor}
        onEnter={this.handleVerticalWaypointEnter}
        onLeave={this.handleVerticalWaypointLeave}
      >
        <div className="collection-carousel" ref={this.carouselRef} data-testid="collection-carousel">
          {collection.map(this.renderCollectionItem)}
        </div>
      </Waypoint>
    );
  }
}
