import {
  COLOR_KEY,
  calculateSelectedFilters,
  getFilterRedirect,
  getFilterRedirectFromCurrentPathname,
  getFilterResults,
  getProductCollectionItemsCount,
  getSanitizedFilterQueryParams,
  getUnselectedConfigurations, MATERIAL_KEY,
} from './utils';

export const updateFormValuesFromQueryParams = ({
  queryParams,
  location,
  filterRedirects,
  formRef,
  onChange,
}) => {
  const queryParamsSanitized = getSanitizedFilterQueryParams(queryParams);
  const filterRedirect = getFilterRedirectFromCurrentPathname(
    location.pathname,
    filterRedirects,
  );
  const filterRedirectCollection = filterRedirect?.collectionFilter?.slug;
  queryParamsSanitized[COLOR_KEY] = queryParamsSanitized[COLOR_KEY] || 'all';
  queryParamsSanitized[MATERIAL_KEY] = 'all';
  if (filterRedirectCollection) {
    queryParamsSanitized[filterRedirectCollection] = true;
  }

  formRef.current.updateInputsWithValue(queryParamsSanitized);
  onChange(queryParamsSanitized);
};

/**
 * Generates a URLSearchParams object from the form values.
 * @param {Record<string, string | boolean | undefined>} formValues
 */
export const generateSearchParams = (formValues) => {
  const newFormValues = Object.entries(formValues).reduce(
    (acc, item) => {
      const [key, value] = item;
      if (value && value !== 'all') {
        // eslint-disable-next-line no-param-reassign
        acc[key] = value;
      }
      return acc;
    },
    /**
     * @type {Object.<string, string | boolean>}
     */
    {},
  );

  const withFilters = Object.keys(newFormValues).length > 0;

  return new URLSearchParams(
    withFilters
      ? { withFilters, ...newFormValues }
      : undefined,
  );
};

export const onFormSubmitHandler = ({
  filterRedirects,
  collectionFilters,
  pushAction,
  pathname,
  currentSearch,
}) => (formValues) => {
  const formValuesWithMaterial = {
    ...formValues,
    material: 'all',
  };
  const urlRedirect = getFilterRedirect(formValuesWithMaterial, filterRedirects, collectionFilters)?.page
    ?.url || pathname;

  // clear all filters from the current search
  const currentSearchParams = new URLSearchParams(currentSearch);
  currentSearchParams.delete('withFilters');
  Object.keys(formValuesWithMaterial).forEach((key) => {
    currentSearchParams.delete(key);
  });

  const searchParams = generateSearchParams(formValuesWithMaterial);
  searchParams.forEach((value, key) => {
    // add the new filters to the current search
    currentSearchParams.set(key, value);
  });

  pushAction({
    ...(urlRedirect && { pathname: urlRedirect }),
    search: currentSearchParams.toString(),
  });
};

export const onFormChangeHandler = ({
  productCollections,
  collectionFilters,
  colorBuckets,
  availableMaterials,
  availableConfigurations,
  setFiltersSelectedAction,
  numberOfAppliedFilters,
  state,
  setState,
}) => (formValues) => {
  const nextState = { ...state };

  const urlSearchParams = generateSearchParams(formValues);

  nextState.urlSearchString = urlSearchParams.toString();

  const unselectedConfigurations = getUnselectedConfigurations(
    formValues,
    availableConfigurations,
  );
  const isEveryConfigurationUnselected = unselectedConfigurations.length === availableConfigurations.length;
  const isAnyColorSelected = formValues[COLOR_KEY] !== 'all';
  const isAnyCollectionSelected = Object.entries(formValues).some(
    ([key, value]) => {
      if (value) {
        return collectionFilters.some(({ slug }) => slug === key);
      }

      return false;
    },
  );

  nextState.isAnyConfigurationSelected = !isEveryConfigurationUnselected;
  nextState.isAnyColorSelected = isAnyColorSelected;
  nextState.isAnyCollectionSelected = isAnyCollectionSelected;

  const filterResults = getFilterResults(
    formValues,
    productCollections,
    availableMaterials,
    colorBuckets,
    availableConfigurations,
    collectionFilters,
  );
  nextState.filterResults = filterResults;
  nextState.productsMatchingCriteriaCount = getProductCollectionItemsCount(filterResults);

  setState(nextState);

  const newAmountOfFilters = calculateSelectedFilters(formValues);
  if (newAmountOfFilters !== numberOfAppliedFilters) {
    setFiltersSelectedAction(newAmountOfFilters);
  }
};

export const clearConfigurationFiltersHandler = ({
  availableConfigurations,
  formRef,
}) => () => {
  if (!availableConfigurations || !formRef) {
    return;
  }
  const configurationValuesFalse = availableConfigurations.reduce(
    (acc, configuration) => ({
      ...acc,
      [configuration]: false,
    }),
    {},
  );

  formRef.current.updateInputsWithValue(configurationValuesFalse);
};

export const clearColorsFiltersHandler = ({ formRef }) => () => {
  if (!formRef) {
    return;
  }

  formRef.current.updateInputsWithValue({
    [COLOR_KEY]: 'all',
  });
};

export const clearCollectionFiltersHandler = ({
  collectionFilters,
  formRef,
}) => () => {
  const collectionValuesFalse = collectionFilters.reduce(
    (acc, { slug }) => ({
      ...acc,
      [slug]: false,
    }),
    {},
  );

  formRef.current.updateInputsWithValue(collectionValuesFalse);
};
