import { ElementType } from 'react';
import { useAppContext } from '../../contexts/Context';
import NextImage from './NextImage';
import VideoTag from './VideoTag';
import { withCssModulesClassNames } from '../../common/nextMigrationHelpers';
import { Asset as AssetType } from './header/header.types';
import {
  constructURL, getFilteredPresetImageSizes, imgSizes, srcSet, getAssetPaddingBottomValue,
} from './Asset.helpers';
import { default as styles } from '../../scss/components/Asset.module.scss';

const withCssModulesClassNamesHandler = withCssModulesClassNames(styles);

interface AssetProps {
  asset?: AssetType;
  alt?: string;
  imageWrapper?: ElementType;
  imageClassName?: string;
  outerWrapperClassName?: string;
  imageProps?: {
    blurryPlaceholder?: boolean;
    outerWrapperClassName?: string;
  };
  maxWidth?: number;
  customHeight?: number | null;
  onLoad?: () => void;
  videoWrapper?: ElementType;
  videoWrapperProps?: {
    className?: string;
    alignment?: string;
  };
  videoClassName?: string;
  lazyLoad?: boolean;
  autoPlay?: boolean;
  preload?: string;
  autoPaddingBottom?: boolean;
  priority?: boolean;
}

const Asset = ({
  asset,
  alt,
  imageWrapper: ImageWrapper,
  imageClassName = '',
  outerWrapperClassName = '',
  imageProps = {},
  maxWidth,
  customHeight = null,
  onLoad = () => {},
  videoWrapper: VideoWrapper,
  videoWrapperProps = {},
  videoClassName = '',
  autoPlay,
  preload = 'none',
  autoPaddingBottom,
  priority,
}: AssetProps) => {
  const { nextApp } = useAppContext();
  if (!asset) {
    return null;
  }

  const srcBase = constructURL(asset?.handle, true);
  const fit = 'crop';
  const sizes = imgSizes(asset?.width);
  const paddingBottomValue = getAssetPaddingBottomValue(asset);

  const srcSetImgs = srcSet(
    srcBase,
    getFilteredPresetImageSizes(asset?.width),
    fit,
    [],
  );

  const renderImage = () => {
    const image = (
      <div
        className={withCssModulesClassNamesHandler(outerWrapperClassName, 'graphcms-image-outer-wrapper')}
      >
        <div
          className={withCssModulesClassNamesHandler(imageClassName, 'graphcms-image-wrapper')}
        >
          <div
            className={withCssModulesClassNamesHandler('image-padding')}
            style={{
              paddingBottom: paddingBottomValue,
            }}
          />
          {
            nextApp
              ? (
                <NextImage
                  className={imageClassName}
                  asset={asset}
                  customHeight={customHeight}
                  blurryPlaceholder={imageProps.blurryPlaceholder}
                  onLoad={onLoad}
                  priority={priority}
                  alt={asset?.alt || alt || ''}
                />
              )
              : (
                <img
                  src={asset?.url}
                  alt={asset?.alt || alt || ''}
                  width={maxWidth || asset?.width || undefined}
                  height={customHeight || asset?.height || undefined}
                  onLoad={onLoad}
                  className={withCssModulesClassNamesHandler('burrow-image')}
                  srcSet={srcSetImgs}
                  sizes={sizes}
                />
              )
          }
        </div>
      </div>
    );
    return ImageWrapper
      ? <ImageWrapper>{image}</ImageWrapper>
      : image;
  };

  const renderVideo = () => {
    const video = (
      <VideoTag
        className={videoClassName}
        autoPaddingBottom={autoPaddingBottom}
        asset={asset}
        autoPlay={autoPlay}
        preload={priority ? 'auto' : preload}
      />
    );
    return VideoWrapper
      // eslint-disable-next-line react/jsx-props-no-spreading
      ? <VideoWrapper {...videoWrapperProps}>{video}</VideoWrapper>
      : video;
  };

  const renderSVG = () => (
      <picture className={withCssModulesClassNamesHandler('asset__icon', imageClassName || 'pdp-options-icon__picture')}>
        <source type="image/webp" srcSet={`https://media.graphassets.com/output=f:webp/output=c:true/output=t:true/${asset.handle}`} />
        <source type="image/jpeg" srcSet={`https://media.graphassets.com/output=c:true/output=t:true/${asset.handle}`} />
        <img alt={asset.alt ?? ''} src={`https://media.graphassets.com/output=c:true/output=t:true/${asset.handle}`} />
      </picture>
  );

  switch (asset.mimeType) {
    case 'video/mp4':
      return renderVideo();
    case 'image/svg+xml':
      return renderSVG();
    default:
      return renderImage();
  }
};

export default Asset;
