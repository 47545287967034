import { call, put, takeLatest } from 'redux-saga/effects';

import { gql } from '@apollo/client/core';
import RequestHelpers from '../../components/global/RequestHelpers';
import { getOrderHistorySchema } from '../../../../apis/QueryBuilder';

import {
  FETCH_ORDER_HISTORY,
  fetchOrderHistoryFailure,
  fetchOrderHistoryRequest,
  fetchOrderHistorySuccess,
} from '../actions/order.actions';
import { setErrorPopupMessage } from '../actions/ui.actions';
import { logError } from '../../../../utils/Utils';

export function* fetchOrderHistory() {
  yield put(fetchOrderHistoryRequest());

  try {
    const query = gql`query GetOrderHistory {
      orders: getOrderHistory { ${getOrderHistorySchema()} }
    }`;

    const { orders } = yield call(() => RequestHelpers.apolloGqlQuery(query));
    yield put(fetchOrderHistorySuccess(orders));
  } catch (error) {
    yield call([this, logError], error, fetchOrderHistory.name);
    const msg = RequestHelpers.handleErrorMessage(error);
    yield put(setErrorPopupMessage(msg));
    yield put(fetchOrderHistoryFailure({ error: msg }));
  }
}

const orderSagas = [
  takeLatest(FETCH_ORDER_HISTORY, fetchOrderHistory),
];

export default orderSagas;
