import {
  FETCH_JOBS__REQUEST,
  FETCH_JOBS__SUCCESS,
  FETCH_JOBS__FAILURE,
} from '../actions/careers.actions';

export const initialState = {
  isPending: false,
  error: null,
  data: {
    jobs: null,
  },
};

const fetchJobsRequest = (state) => ({
  ...state,
  isPending: true,
  error: null,
});

const fetchJobsSuccess = (state, jobs) => ({
  ...state,
  isPending: false,
  error: null,
  data: {
    ...state.data,
    jobs,
  },
});

const fetchJobsFailure = (state, error) => ({
  ...state,
  isPending: false,
  error,
  data: {
    ...state.data,
    jobs: null,
  },
});

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_JOBS__REQUEST:
      return fetchJobsRequest(state);

    case FETCH_JOBS__SUCCESS:
      return fetchJobsSuccess(state, action.payload);

    case FETCH_JOBS__FAILURE:
      return fetchJobsFailure(state, action.error);
    default:
      return state;
  }
};
