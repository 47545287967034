import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import ColorPicker from './ColorPicker';
import UniversalLinkContainer from '../global/UniversalLink';

import { getModifierOptionBackground } from '../../../../utils/Utils';
import { withCssModulesClassNames } from '../../common/nextMigrationHelpers';
import { getWindowWidth } from '../../store/selectors/ui.selectors';

import { Nullable } from '../../types/common';
import { ModifierOptionDto, Breakpoints } from '../../types/schema';

import { default as styles } from '../../scss/components/ColorPicker.module.scss';

export const MAX_COLORS_TO_SHOW = 5;

type Variation = Pick<
ModifierOptionDto, 'borderColor' | 'colors' | 'description' | 'value'
> & { activeVariant?: Nullable<string> };

interface MoreOptionsProps {
  url: string;
  onClick: () => void;
}

export interface ProductCardColorPickerProps {
  activeVariant: Nullable<string>;
  chooseOption: (value: string) => Promise<void>;
  displayColorPicker: boolean;
  moreOptions: MoreOptionsProps;
  variations: Variation[];
}

const withCssModulesClassNamesHandler = withCssModulesClassNames(styles);

export const getPartialVariations = ({
  activeVariant,
  variations,
  windowWidth,
  maxColorsToShow = MAX_COLORS_TO_SHOW,
}: Pick<ProductCardColorPickerProps, 'activeVariant' | 'variations'> & {
  maxColorsToShow?: number;
  windowWidth: number;
}): Variation[] => {
  const reduction = windowWidth >= Breakpoints.Phone && windowWidth < Breakpoints.Kit ? 2 : 1;
  const sliceEnd = maxColorsToShow - reduction;
  const findActiveVariant = variations.findIndex(({ value }) => value === activeVariant);

  if (findActiveVariant >= sliceEnd) {
    return [
      ...variations.slice(0, sliceEnd - 1),
      variations[findActiveVariant],
    ];
  }

  return variations.slice(0, sliceEnd);
};

const MoreOptionsLink = ({
  url, onClick,
}: MoreOptionsProps) => (
    <UniversalLinkContainer
      to={url}
      href={url}
      type="Page"
      className={withCssModulesClassNamesHandler('link-more-options')}
      clickHandler={onClick}
    >
      + More options
    </UniversalLinkContainer>
);

const ProductCardColorPicker = ({
  displayColorPicker,
  variations,
  activeVariant,
  chooseOption,
  moreOptions,
}: ProductCardColorPickerProps) => {
  const windowWidth = useSelector(getWindowWidth);
  const [partialVariations, setPartialVariations] = useState(variations);
  const optionPicker = displayColorPicker && !!activeVariant && variations.length > 0;

  useEffect(() => {
    if (optionPicker && variations.length > MAX_COLORS_TO_SHOW) {
      setPartialVariations(getPartialVariations({
        activeVariant,
        variations,
        windowWidth,
      }));
    }
    // shouldn't run when activeVariant changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionPicker, variations, windowWidth]);

  return optionPicker ? (
    <div className={withCssModulesClassNamesHandler('color-picker')}>
      <div className={withCssModulesClassNamesHandler('color-picker-container')}>
        {partialVariations.map(({
          borderColor,
          colors,
          description,
          value,
        }) => (
          <ColorPicker
            active={value === activeVariant}
            backgroundColor={colors?.length ? getModifierOptionBackground(colors) : undefined}
            borderColor={borderColor?.hex}
            description={description}
            hasBorder={!!borderColor?.hex}
            key={value}
            onClick={chooseOption}
            value={value}
          />
        ))}
      </div>
      {variations.length > partialVariations.length && (
        <MoreOptionsLink
          url={moreOptions.url}
          onClick={moreOptions.onClick}
        />
      )}
    </div>
  ) : null;
};

export default ProductCardColorPicker;
