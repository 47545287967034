import { withCssModulesClassNames } from '../../../common/nextMigrationHelpers';
import NavigationItem from './NavigationItem';
import UserSection from './UserSection';
import { default as styles } from '../../../scss/components/Header.module.scss';
import { CloseMenuParams, NavigationItem as NavigationItemType } from './header.types';

const withCssModulesClassNamesHandler = withCssModulesClassNames(styles);

export type MenuProps = {
  navigation: NavigationItemType[],
  isLoggedIn: boolean,
  closeMenu: (config?: CloseMenuParams) => void,
  dropdownStates: { [key: string]: boolean }
  toggleCurrentDropdown: (id: string, header: string, neighbors: string[]) => void,
  experiments?: Record<string, boolean>,
  openCart: (booleanValue: boolean) => void,
  'data-cy'?: string,
  isMobile?: boolean,
  visibleGalleryID?: string,
  cartNumber?: number,
};

const Menu = ({
  navigation,
  isLoggedIn,
  closeMenu,
  openCart,
  dropdownStates,
  toggleCurrentDropdown,
  experiments,
  isMobile = false,
  visibleGalleryID = '',
  cartNumber = 0,
  'data-cy': dataCy = 'desktopNav',
}: MenuProps) => {
  const { shoppingLinks, utilityLinks } = navigation.reduce((obj: { shoppingLinks: NavigationItemType[], utilityLinks: NavigationItemType[] }, nav) => {
    if (nav.tier === 'Utility') {
      obj.utilityLinks.push(nav);
    }

    if (nav.tier === 'Shopping') {
      obj.shoppingLinks.push(nav);
    }

    return obj;
  }, { shoppingLinks: [], utilityLinks: [] });

  const getNeighboringNavigationIds = (currentNavigationId: string) => navigation.reduce<string[]>((arr, { navigationId }) => {
    if (navigationId && navigationId !== currentNavigationId) {
      arr.push(navigationId);
    }
    return arr;
  }, []);

  return (
    <>
      <div className={withCssModulesClassNamesHandler('navigation')} data-cy={dataCy} data-testid={dataCy}>
        {shoppingLinks.map((link) => (
          <NavigationItem
            link={link}
            key={link.navigationId}
            dropdownStates={dropdownStates}
            toggleCurrentDropdown={toggleCurrentDropdown}
            closeMenu={closeMenu}
            visibleGalleryID={visibleGalleryID}
            neighbors={getNeighboringNavigationIds(link.navigationId)}
            isMobile={isMobile}
          />
        ))}
      </div>
      <div className={withCssModulesClassNamesHandler('accounts-section')} data-testid="utility-link">
        <div className={withCssModulesClassNamesHandler(
          'navigation',
          'utility-navigation-items',
        )}
        >
          {isMobile ? utilityLinks.map((link) => (
            <NavigationItem
              link={link}
              key={link.navigationId}
              dropdownStates={dropdownStates}
              toggleCurrentDropdown={toggleCurrentDropdown}
              closeMenu={closeMenu}
              neighbors={getNeighboringNavigationIds(link.navigationId)}
              isMobile={isMobile}
            />
          ))
            : null
          }
        </div>
        <UserSection
          cartNumber={cartNumber}
          isLoggedIn={isLoggedIn}
          experiments={experiments}
          closeMenu={closeMenu}
          openCart={openCart}
        />
      </div>
    </>
  );
};

export default Menu;
