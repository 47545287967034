import { ParsedQs } from 'qs';
import { SessionPayloadDto } from '../shared/sessionManager';

export const availableFlags = {
  hideDynamicAffirm: 'HIDE_DYNAMIC_AFFIRM',
  shippingDateRange: 'SHIPPING_DATE_RANGE',
  PLPStarReviews: 'PLP_STAR_REVIEWS',
  HeaderNavWithCategories: 'HEADER_NAV_WITH_CATEGORIES',
} as const;

export type FlagExperiments = Partial<Record<keyof typeof availableFlags, string | boolean>>;

export type AvailableFlags = typeof availableFlags[keyof typeof availableFlags];

export const isFeatureEnabled = (
  flag: AvailableFlags,
  experiments?: SessionPayloadDto['experiments'],
  query: Record<string, string> | ParsedQs = {},
) => {
  // If feature flag is set at an environment level
  if (process.env[flag]) {
    return process.env[flag] === 'enabled';
  }
  // If feature flag is set at a query param level

  if (query[flag]) {
    return query[flag] === 'enabled';
  }
  // Allow experiment to place on a user by user basis
  if (experiments) {
    const {
      ShippingMessageWithDates, PLPStarReviews, hideDynamicAffirm, HeaderNavWithCategories,
    } = experiments;
    switch (flag) {
      case availableFlags.shippingDateRange:
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore -- backward compatibility & will be removed later
        return !!(ShippingMessageWithDates?.value?.boolValue ?? ShippingMessageWithDates);
      case availableFlags.hideDynamicAffirm:
        return !!hideDynamicAffirm;
      case availableFlags.PLPStarReviews:
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore -- backward compatibility & will be removed later
        return !!(PLPStarReviews?.value?.boolValue ?? PLPStarReviews);
      case availableFlags.HeaderNavWithCategories:
        return !!HeaderNavWithCategories;
      default:
        break;
    }
  }
  return false;
};

export const isFeatureEnabledHandler = (
  experiments?: SessionPayloadDto['experiments'],
  query?: Record<string, string> | ParsedQs,
  _isFeatureEnabled = isFeatureEnabled,
) => (
  (flag: AvailableFlags) => _isFeatureEnabled(flag, experiments, query)
);
