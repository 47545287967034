import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { HrefLang } from '../../../types/schema';
import UniversalLinkContainer from '../UniversalLink';
import Analytics from '../Analytics';
import { default as styles } from '../../../scss/components/Header.module.scss';
import { withCssModulesClassNames } from '../../../common/nextMigrationHelpers';

import generateUrlWithHrefLang from '../../../common/generateUrlWithHrefLang';
import { getHrefLang } from '../../../store/selectors/general.selectors';
import paths from '../../../paths';

const withCssModulesClassNamesHandler = withCssModulesClassNames(styles);

export type AccountLinkProps = {
  isLoggedIn?: boolean,
  onClick?: () => void
};

const getLinkProps = (hrefLang: HrefLang, isLoggedIn?: boolean) => (isLoggedIn ? {
  title: 'Account',
  link: generateUrlWithHrefLang(({ hrefLang, endpoint: paths.ACCOUNT })),
  icon: 'icon-account-logged-in',
} : {
  title: 'Login',
  link: generateUrlWithHrefLang(({ hrefLang, endpoint: paths.LOGIN })),
  icon: 'icon-account',
});

const AccountLink = ({
  isLoggedIn,
  onClick,
}: AccountLinkProps) => {
  const hrefLang = useSelector(getHrefLang);

  const handleClick = () => {
    const { link: destination } = getLinkProps(hrefLang, isLoggedIn);

    if (onClick) {
      onClick();
    }

    Analytics.enqueue({
      method: 'track',
      params: {
        event: 'Profile Icon',
        properties: {
          action: 'Clicked',
          destination,
        },
      },
    });
  };

  const {
    title,
    link,
    icon,
  } = getLinkProps(hrefLang, isLoggedIn);

  return (
    <UniversalLinkContainer
      className={withCssModulesClassNamesHandler(
        'mobile-login-link',
        'navigation-position-button',
      )}
      clickHandler={handleClick}
      type="Page"
      to={link}
    >
      <i className={withCssModulesClassNamesHandler(`icon ${icon}`)} />
      <span className={withCssModulesClassNamesHandler('mobile-link-span')}>{title}</span>
    </UniversalLinkContainer>
  );
};

AccountLink.defaultProps = {
  onClick: () => {},
  isLoggedIn: false,
};

AccountLink.propTypes = {
  onClick: PropTypes.func,
  isLoggedIn: PropTypes.bool,
};

export default AccountLink;
