import {
  SET_WINDOW_WIDTH,
  SET_HEADER_TEXT_COLOR,
  SET_ERROR_POPUP_MESSAGE,
  UPDATE_EXPERIMENTS_FLAGS,
  UPDATE_HEADER_NAVIGATION,
  UPDATE_FOOTER_NAVIGATION,
  UPDATE_CX_HOURS,
  OPEN_CART,
  SET_IS_FILTERS_POPOUT_OPEN,
  SET_IS_GOOGLE_MAPS_ACTIVE,
  SET_NAV_STATE,
  SCRIPT_INJECTED,
  SET_IS_ONLINE,
  SET_DISPLAY_PRODUCT_PREVIEW,
  SET_NAV_ITEMS,
  SET_IS_MOBILE_MENU_OPEN,
  SET_IS_SEARCH_DROP_DOWN_OPEN,
  SET_SEARCH_LAYOUT,
} from '../actions/ui.actions';

/**
 * @typedef {import('./ui.reducer.types').UIState} UIState
 */

/**
 * @type {UIState}
 */
export const initialState = {
  windowWidth: 0,
  headerTextColor: '',
  isCartOpen: false,
  isFiltersPopoutOpen: false,
  errorPopupMessage: false,
  popupRedirect: null,
  headerNavigation: [],
  footerNavigation: [],
  isGoogleMapsActive: false,
  navHeight: 0,
  navPinned: true,
  pinned: true,
  injectedScripts: [],
  isOnline: true,
  displayProductPreview: false,
  navItems: null,
  isMobileMenuOpen: false,
  isSearchDropDownOpen: false,
  searchLayout: null,
  nextApp: false,
  ssg: false,
  plpSiteWideText: null,
  experiments: {},
  cxHours: {
    cxDailyHours: [],
    holidays: [],
    isClosed: false,
    cxSmsNumber: '',
  },
};

const setWindowWidth = (state, payload) => ({
  ...state,
  windowWidth: payload,
});

const setHeaderTextColor = (state, payload) => ({
  ...state,
  headerTextColor: payload,
});

const setErrorPopupMessage = (state, payload) => ({
  ...state,
  errorPopupMessage: payload.message,
  popupRedirect: payload.redirect,
  popupOnClose: payload.onClose,
});

const updateHeaderNavigation = (state, payload) => ({
  ...state,
  headerNavigation: payload,
});

const updateExperiments = (state, payload) => ({
  ...state,
  experiments: payload,
});

const updateFooterNavigation = (state, payload) => ({
  ...state,
  footerNavigation: payload,
});

const updateCXHours = (state, payload) => ({
  ...state,
  cxHours: payload,
});

const openCart = (state, payload) => ({
  ...state,
  isCartOpen: payload,
});

const setIsFiltersPopoutOpen = (state, isFiltersPopoutOpen) => ({
  ...state,
  isFiltersPopoutOpen,
});

const displayProductPreview = (state, payload) => ({
  ...state,
  displayProductPreview: payload,
});

const setIsGoogleMapsActive = (state, isGoogleMapsActive) => ({
  ...state,
  isGoogleMapsActive,
});

const setNavState = (state, payload) => {
  const {
    height: navHeight = state.navHeight,
    pinned: navPinned = state.navPinned,
  } = payload;
  return {
    ...state,
    ...payload,
    navHeight,
    navPinned,
  };
};

const scriptInjected = (state, script) => ({
  ...state,
  injectedScripts: [
    ...state.injectedScripts,
    script,
  ],
});

const setIsOnline = (state, isOnline) => ({
  ...state,
  isOnline,
});

const setNavItems = (state, navItems) => ({
  ...state,
  navItems,
});

const setIsMobileMenuOpen = (state, isMobileMenuOpen) => ({
  ...state,
  isMobileMenuOpen,
});

const setIsSearchDropDownOpen = (state, isSearchDropDownOpen) => ({
  ...state,
  isSearchDropDownOpen,
});

const setSearchLayout = (state, searchLayout) => ({
  ...state,
  searchLayout,
});

const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_WINDOW_WIDTH:
      return setWindowWidth(state, action.payload);

    case SET_HEADER_TEXT_COLOR:
      return setHeaderTextColor(state, action.payload);

    case SET_ERROR_POPUP_MESSAGE:
      return setErrorPopupMessage(state, action.payload);

    case UPDATE_HEADER_NAVIGATION:
      return updateHeaderNavigation(state, action.payload);

    case UPDATE_EXPERIMENTS_FLAGS:
      return updateExperiments(state, action.payload);

    case UPDATE_FOOTER_NAVIGATION:
      return updateFooterNavigation(state, action.payload);

    case UPDATE_CX_HOURS:
      return updateCXHours(state, action.payload);

    case OPEN_CART:
      return openCart(state, action.payload);

    case SET_IS_FILTERS_POPOUT_OPEN:
      return setIsFiltersPopoutOpen(state, action.payload);

    case SET_IS_GOOGLE_MAPS_ACTIVE:
      return setIsGoogleMapsActive(state, action.payload);

    case SET_NAV_STATE:
      return setNavState(state, action.payload);

    case SET_DISPLAY_PRODUCT_PREVIEW:
      return displayProductPreview(state, action.payload);

    case SCRIPT_INJECTED:
      return scriptInjected(state, action.payload);

    case SET_IS_ONLINE:
      return setIsOnline(state, action.payload);

    case SET_NAV_ITEMS:
      return setNavItems(state, action.payload);

    case SET_IS_MOBILE_MENU_OPEN:
      return setIsMobileMenuOpen(state, action.payload);

    case SET_IS_SEARCH_DROP_DOWN_OPEN:
      return setIsSearchDropDownOpen(state, action.payload);

    case SET_SEARCH_LAYOUT:
      return setSearchLayout(state, action.payload);

    default:
      return state;
  }
};

export default uiReducer;
