import { withCssModulesClassNames } from '../../../common/nextMigrationHelpers';
import { default as searchDropdownStyles } from '../../../scss/components/SearchDropdown.module.scss';

interface SearchDropdownNoResultsProps {
  searchTermInputValue: string;
}

const withCssModulesClassNamesHandler = withCssModulesClassNames(searchDropdownStyles);

export const SEARCH_DROPDOWN_NO_RESULTS_CONTAINER_TEST_ID = 'search-dropdown-no-results-container';

const SearchDropdownNoResults = ({
  searchTermInputValue,
}: SearchDropdownNoResultsProps) => (
  <div data-testid={SEARCH_DROPDOWN_NO_RESULTS_CONTAINER_TEST_ID} className={withCssModulesClassNamesHandler('search-dropdown-no-results-container')}>
    <h4>We couldn&apos;t find any results for that search</h4>
    <p>{`0 matches for "${searchTermInputValue}"`}</p>
  </div>
);

export default SearchDropdownNoResults;
