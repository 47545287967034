import { withCssModulesClassNames } from '../../../common/nextMigrationHelpers';
import { default as searchDropdownStyles } from '../../../scss/components/SearchDropdown.module.scss';

const withCssModulesClassNamesHandler = withCssModulesClassNames(searchDropdownStyles);

export const SEARCH_DROPDOWN_RESULTS_SKELETON_CONTAINER_TEST_ID = 'search-dropdown-results-skeleton-container';

const SearchDropdownResultsSkeleton = () => (
  <div data-testid={SEARCH_DROPDOWN_RESULTS_SKELETON_CONTAINER_TEST_ID} className={withCssModulesClassNamesHandler('search-dropdown-products-skeleton-grid-container')}>
    {
      Array.from({ length: 5 }).map((_, index) => (
        <div key={`loading-card-${index}`} className={withCssModulesClassNamesHandler('product-card-container-skeleton')}>
          <div className={withCssModulesClassNamesHandler('product-card-image-skeleton')} />
          <div className={withCssModulesClassNamesHandler('product-card-description-skeleton')}>
            <div />
            <div />
          </div>
        </div>
      ))
    }
  </div>
);

export default SearchDropdownResultsSkeleton;
