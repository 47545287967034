import React, { Component } from 'react';
import { withFormsy, addValidationRule } from 'formsy-react';
import classNames from 'classnames';

addValidationRule('isNotBlankString', (values, value) => value && value.trim().length > 0);

class Input extends Component {
  constructor(props) {
    super(props);
    this.changeValue = this.changeValue.bind(this);
    if (this.props.value) {
      this.props.setValue(this.props.value);
    }
  }

  changeValue(event) {
    this.props.setValue(event.target.value);
  }

  render() {
    const {
      className,
      showError,
      isPristine,
      value = '',
      label,
      id,
      errorMessage,
      isValid,
      showRequired,
      onFocus,
    } = this.props;

    const hasLabel = Boolean(label);

    return (
      <div className={`form-fancy${!isPristine && showRequired && !value
        ? ' required'
        : ''
      }${showError
        ? ' error'
        : ''
      }${!isPristine && value && isValid
        ? ' valid'
        : ' invalid'
      }${className
        ? ` ${className}`
        : ''
      }`}
      >
        <input
          onChange={this.changeValue}
          onBlur={this.changeValue}
          onFocus={onFocus}
          type={this.props.type || 'text'}
          name={this.props.name}
          ref={this.props.name}
          required={showRequired}
          autoComplete={this.props.autoComplete}
          className={classNames({ 'has-label': hasLabel }, this.props.extraClass)}
          placeholder={this.props.placeholder}
          id={this.props.id}
          autoCapitalize={this.props.autocapitalize}
          value={value}
          aria-label={this.props.ariaLabel || this.props.name}
          disabled={this.props.disabled}
        />
        {this.props.inputClickClass && (
          <button
            className={classNames(this.props.inputClickClass, {
              disabled: this.props.disabled,
            })}
            disabled={this.props.disabled}
          >
            {this.props.inputClickText}
          </button>
        )}
        <label htmlFor={id} className={hasLabel ? '' : 'hidden'}>
          {showError ? errorMessage : label}
        </label>
      </div>
    );
  }
}

export default withFormsy(Input);
