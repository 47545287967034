import PropTypes from 'prop-types';
import HeaderLink from './HeaderLink';
import { default as styles } from '../../../scss/components/Header.module.scss';

import ProductCollection from '../../shopping/ProductCollection';
import { withCssModulesClassNames } from '../../../common/nextMigrationHelpers';
import { CloseMenuParams, FeaturedItem, NavigationItem } from './header.types';
import ErrorBoundary from '../ErrorBoundary';

const withCssModulesClassNamesHandler = (...classes: (string | undefined | { [key: string]: boolean })[]) => withCssModulesClassNames(styles)(...classes);

export type DropdownProps = {
  link: NavigationItem | FeaturedItem,
  dropdownStates: { [key: string]: boolean },
  closeMenu: (config?: CloseMenuParams) => void,
  isMobile: boolean,
  toggleCurrentDropdown: (id: string, header: string, neighbors: string[]) => void,
  neighbors: string[]
};

const Dropdown = ({
  link,
  dropdownStates,
  closeMenu,
  isMobile,
  toggleCurrentDropdown,
  neighbors,
}: DropdownProps) => {
  const handleHeaderClick = isMobile ? () => {
    toggleCurrentDropdown(link.navigationId, link.header, neighbors);
  } : undefined;

  const TitleTag = isMobile ? 'button' : 'h5';

  const isOpen = dropdownStates[link.navigationId];
  const isAnyChildOpen = (link?.features as FeaturedItem[])?.find(({ navigationId }) => dropdownStates[navigationId]);
  return (
    <div className={withCssModulesClassNamesHandler('navigation-dropdown')} >
      <TitleTag
        onClick={handleHeaderClick}
        className={withCssModulesClassNamesHandler(
          'links-section-item',

          {
            active: isOpen,
          },
        )
        }
      >
        <span className={withCssModulesClassNamesHandler('header')}>
          {link.header}
        </span>
        {<i className={withCssModulesClassNamesHandler('icon', `${isOpen ? 'icon-close' : 'icon-plus'}`)} />}
      </TitleTag>
      <div className={withCssModulesClassNamesHandler(
        'navigation-dropdown__content',
        {
          active: isOpen,
        },
      )}
      >
        {/* eslint-disable-next-line no-underscore-dangle */}
        {link?.features?.map((child) => (child.__typename === 'Link' && (
          <HeaderLink
            link={child}
            key={child.id}
            baseClass={withCssModulesClassNamesHandler('links-section-item')}
            closeMenu={closeMenu}
          />
        )))}
        <ErrorBoundary errorMessage="Sorry, the featured collection could not load at this time!">
          {!isAnyChildOpen && link.featuredProductCollection ? (
            <div className={withCssModulesClassNamesHandler('navigation-dropdown__featured')}>
              <ProductCollection
                collection={link.featuredProductCollection}
                activeLayout="Carousel"
                activeFilters={[]}
                source={'navigation'}
              />
            </div>
          ) : null}
        </ErrorBoundary>
      </div>
    </div>
  );
};

Dropdown.propTypes = {
  toggleCurrentDropdown: PropTypes.func.isRequired,
  dropdownStates: PropTypes.objectOf(PropTypes.bool).isRequired,
  link: PropTypes.shape({
    id: PropTypes.number,
    features: PropTypes.arrayOf(PropTypes.shape({})),
    header: PropTypes.string,
    neighbors: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  neighbors: PropTypes.arrayOf(PropTypes.string).isRequired,
  closeMenu: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default Dropdown;
