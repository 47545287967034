import {
  FETCH_REVIEWS_AVERAGE_RATING__FAILURE,
  FETCH_REVIEWS_AVERAGE_RATING__REQUEST,
  FETCH_REVIEWS_AVERAGE_RATING__SUCCESS,
} from '../actions/reviews.actions';

export const initialState = {
  isPending: false,
  error: null,
  data: {
    averageRating: null,
  },
};

const fetchReviewsAverageRatingRequest = (state) => ({
  ...state,
  isPending: true,
});

const fetchReviewsAverageRatingSuccess = (state, payload) => ({
  ...state,
  isPending: false,
  error: null,
  data: {
    ...state.data,
    averageRating: payload,
  },
});

const fetchReviewsAverageRatingFailure = (state, payload) => ({
  ...state,
  isPending: false,
  error: payload.error,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REVIEWS_AVERAGE_RATING__REQUEST:
      return fetchReviewsAverageRatingRequest(state);

    case FETCH_REVIEWS_AVERAGE_RATING__SUCCESS:
      return fetchReviewsAverageRatingSuccess(state, action.payload);

    case FETCH_REVIEWS_AVERAGE_RATING__FAILURE:
      return fetchReviewsAverageRatingFailure(state, action.payload);

    default:
      return state;
  }
};
