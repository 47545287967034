import React from 'react';
import loadable from '@loadable/component';
import LoadingContainer from './components/global/Loading';
import paths from './paths';
import AppContainer from './App';
import HrefLang from '../../shared/localization/hrefLang';

const fallback = <LoadingContainer />;
const Index = loadable(() => import('./components/pages/Index'), { fallback });
const Login = loadable(() => import('./components/account/Login'), { fallback });
const Forgot = loadable(() => import('./components/account/Forgot'), { fallback });
const Reset = loadable(() => import('./components/account/Reset'), { fallback });
const Account = loadable(() => import('./components/account/Account'), { fallback });
const History = loadable(() => import('./components/account/History'), { fallback });
const Reviews = loadable(() => import('./components/pages/Reviews'), { fallback });
const Referral = loadable(() => import('./components/pages/Referral'), { fallback });
const ShareCart = loadable(() => import('./components/pages/ShareCart'), { fallback });
const Order = loadable(() => import('./components/pages/Order'), { fallback });
const NotFound = loadable(() => import('./components/pages/NotFound'), { fallback });
const Checkout = loadable(() => import('./components/checkout/Checkout'), { fallback });
const Search = loadable(() => import('./components/pages/Search'), { fallback });

const generateRoutes = (routePaths, component, prev = []) => routePaths.reduce((acc, path) => acc.concat(Object.values(HrefLang).map((lang) => ({
  path: `${lang === 'en-us' ? '' : `/${lang}`}${path}`,
  component,
})).filter(Boolean)), prev);

let routes = [{
  path: paths.HOME,
  exact: true,
  component: Index,
}, {
  path: paths.POS_CHECKOUT,
  component: Checkout,
}, {
  path: paths.NOT_FOUND,
  component: NotFound,
}];
routes = generateRoutes([paths.REFERRAL], Referral, routes);
routes = generateRoutes([paths.CREATE, paths.SIGN_IN, paths.LOGIN], Login, routes);
routes = generateRoutes([paths.FORGOT_PASSWORD], Forgot, routes);
routes = generateRoutes([paths.RESET_PASSWORD], Reset, routes);
routes = generateRoutes([paths.ACCOUNT], Account, routes);
routes = generateRoutes([paths.ACCOUNT_HISTORY], History, routes);
routes = generateRoutes([paths.REVIEWS], Reviews, routes);
routes = generateRoutes([paths.ORDER], Order, routes);
routes = generateRoutes([paths.SHARE_CART], ShareCart, routes);
routes = generateRoutes([paths.SEARCH], Search, routes);
routes.push({
  path: '*',
  component: Index,
});

export default [{
  component: AppContainer,
  routes,
}];
