import {
  call, put, select, takeLatest,
} from 'redux-saga/effects';
import cloneDeep from 'lodash/cloneDeep';
import { gql } from '@apollo/client/core';
import {
  CREATE_USER,
  createUserFailure,
  createUserRequest,
  createUserSuccess,
  FETCH_LOGGED_IN_USER,
  fetchLoggedInUserFailure,
  fetchLoggedInUserRequest,
  fetchLoggedInUserSuccess,
  FORGOT_PASSWORD,
  forgotPasswordFailure,
  forgotPasswordRequest,
  forgotPasswordSuccess,
  setCanUpdateProfile,
  SIGN_IN_USER,
  SIGN_OUT_USER,
  signInUserFailure,
  signInUserRequest,
  signInUserSuccess,
  signOutUserFailure,
  signOutUserRequest,
  signOutUserSuccess,
  SUBSCRIBE_TO_NEWSLETTER,
  subscribeToNewsletterFailure,
  subscribeToNewsletterRequest,
  subscribeToNewsletterSuccess,
  UPDATE_USER,
  updateUserFailure,
  updateUserRequest,
  updateUserSuccess,
} from '../actions/user.actions';
import RequestHelpers from '../../components/global/RequestHelpers';
import { setErrorPopupMessage, push } from '../actions/ui.actions';
import { getUserDetails } from '../selectors/user.selectors';
import { fetchCheckoutUrlAndRedirect } from '../actions/cart.actions';
import Analytics from '../../components/global/Analytics';
import { logError } from '../../../../utils/Utils';
import { userFields } from '../../../../apis/QueryBuilder';
import paths from '../../paths';
import NewsletterSubmitStatus from '../../types/schema/enums/NewsletterSubmitStatus';

const generatePathWithQuerystring = (path, queryParams) => {
  const queryString = new URLSearchParams(queryParams).toString();

  return `${path}${queryString && `?${queryString}`}`;
};

function* createUser(action) {
  yield put(createUserRequest());

  try {
    const {
      data,
      queryParams,
    } = action;
    const {
      email,
      password,
      firstName,
      lastName,
    } = data;

    const mutation = gql`mutation CreateUser {
      create(email: "${email}", password: "${password}", firstName: "${firstName}", lastName: "${lastName}") {
        ${userFields}
      }
    }`;
    const { create: user } = yield call(() => RequestHelpers.apolloGqlMutate(mutation));

    try {
      yield call([Analytics, Analytics.updateUserIdentityAndIdentify], user);
    } catch (e) { /* nothing to do */ }

    if (queryParams && queryParams.redirect_to) {
      const clonedQueryParams = cloneDeep(queryParams);
      const redirect = `${decodeURIComponent(queryParams.redirect_to)}`;

      delete clonedQueryParams.redirect_to;

      if (redirect === '/checkout') {
        yield put(fetchCheckoutUrlAndRedirect());
      }
      yield put(push(generatePathWithQuerystring(redirect, clonedQueryParams)));
    } else {
      yield put(push('/account'));
    }

    yield put(createUserSuccess(user));
  } catch (error) {
    yield call([this, logError], error, createUser.name);
    const msg = RequestHelpers.handleErrorMessage(error);
    if (error.message === 'USER_EXISTS') {
      yield put(setErrorPopupMessage(msg, 'forgot-password'));
    } else {
      yield put(setErrorPopupMessage(msg));
    }
    yield put(createUserFailure(error));
  }
}

function* updateUser(action) {
  yield put(updateUserRequest());
  yield put(setCanUpdateProfile(false));

  const { data } = action;
  const {
    email,
    firstName,
    lastName,
  } = data;

  try {
    const currentData = yield select(getUserDetails);
    const mutation = gql`mutation UpdateUser {
      update(email: "${email}", firstName: "${firstName}", lastName: "${lastName}", id: ${currentData.id}) {
        ${userFields}
      }
    }`;
    const { update: user } = yield call(() => RequestHelpers.apolloGqlMutate(mutation));

    yield put(setCanUpdateProfile(true));
    Analytics.enqueue({
      method: 'identify',
      params: {
        user,
      },
    });

    yield put(updateUserSuccess(user));
  } catch (error) {
    yield call([this, logError], error, updateUser.name);
    const msg = RequestHelpers.handleErrorMessage(error);
    yield put(setErrorPopupMessage(msg));
    yield put(updateUserFailure(error));
  }
}

function* signInUser(action) {
  yield put(signInUserRequest());

  try {
    const {
      credentials: { email, password, remember },
      queryParams,
    } = action;

    const mutation = gql`mutation Login {
      login(email: "${email}", password: "${password}", rememberMe: ${!!remember}) {
        ${userFields}
      }
    }`;

    const { login: user } = yield call(() => RequestHelpers.apolloGqlMutate(mutation));

    if (user && user.burrowHouseAssociate) {
      yield call([Analytics, Analytics.setTraits], { isBurrowHouseAssociate: true });
    }

    yield call([Analytics, Analytics.updateUserIdentityAndIdentify], user, true);

    if (queryParams && queryParams.redirect_to) {
      const clonedQueryParams = cloneDeep(queryParams);
      const redirect = `${decodeURIComponent(clonedQueryParams.redirect_to)}`;

      delete clonedQueryParams.redirect_to;

      if (redirect === '/checkout') {
        yield put(fetchCheckoutUrlAndRedirect());
      } else {
        yield put(push(generatePathWithQuerystring(redirect, clonedQueryParams)));
      }
    } else {
      yield put(push('/account'));
    }

    yield put(signInUserSuccess(user));
  } catch (error) {
    yield call([this, logError], error, signInUser.name);
    const msg = RequestHelpers.handleErrorMessage(error);
    if (error.message === 'NEED_TO_RESET_PASSWORD') {
      yield put(setErrorPopupMessage(msg, 'forgot-password'));
    } else {
      yield put(setErrorPopupMessage(msg));
    }
    yield put(signInUserFailure(error.message || msg));
  }
}

function* signOutUser() {
  yield put(signOutUserRequest());

  try {
    const mutation = gql`mutation Logout { logout }`;
    const { logout } = yield call(() => RequestHelpers.apolloGqlMutate(mutation));

    if (logout) {
      yield call([Analytics, Analytics.setTraits], null);

      yield put(push(paths.HOME));
      yield put(signOutUserSuccess());
    } else {
      const error = new Error('Something went wrong');

      yield put(signOutUserFailure(error));
    }
  } catch (error) {
    yield call([this, logError], error, signOutUser.name);
    const msg = RequestHelpers.handleErrorMessage(error);
    yield put(setErrorPopupMessage(msg));
    yield put(signOutUserFailure(error));
  }
}

function* fetchLoggedInUser() {
  yield put(fetchLoggedInUserRequest());

  try {
    const query = gql`query GetLoggedInCustomer {
      user: getLoggedInCustomer {
        ${userFields}
      }
    }`;
    const { user } = yield call(() => RequestHelpers.apolloGqlQuery(query));

    if (user && user.burrowHouseAssociate) {
      yield call([Analytics, Analytics.setTraits], { isBurrowHouseAssociate: true });
    }

    yield put(fetchLoggedInUserSuccess(user));
  } catch (error) {
    yield call([this, logError], error, fetchLoggedInUser.name);
    yield put(fetchLoggedInUserFailure(error));
  }
}

function* subscribeToNewsletter(action) {
  try {
    yield put(subscribeToNewsletterRequest());
    const params = action.payload;

    params.isFooterEmailCapture = params.isFooterEmailCapture ?? false;
    params.providedConsent = params.providedConsent ?? true;
    params.url = params.url || document.location.href;

    const mutation = gql`mutation Newsletter(
      $email: String
      $phone: String
      $url: String!
      $firstName: String
      $lastName: String
      $country: String
      $state: String
      $city: String
      $providedConsent: Boolean
      $isFooterEmailCapture: Boolean
      $subscriptionSource: String
      $subscriptionState: SubscriptionState
    ) {
      subscribe(
        email: $email
        phone: $phone
        url: $url
        firstName: $firstName
        lastName: $lastName
        country: $country
        state: $state
        city: $city
        providedConsent: $providedConsent
        isFooterEmailCapture: $isFooterEmailCapture
        subscriptionSource: $subscriptionSource
        subscriptionState: $subscriptionState
      ) {
        userId
      }
    }`;

    const { subscribe: { userId } } = yield call(() => RequestHelpers.apolloGqlMutate(
      mutation,
      params,
    ));

    Analytics.setIdentity({ userId });

    const message = params.isFooterEmailCapture ?  NewsletterSubmitStatus.FooterNewsletterSuccess : NewsletterSubmitStatus.ExpandedNewsletterSuccess;

    yield put(subscribeToNewsletterSuccess(message));
  } catch (error) {
    yield call([this, logError], error, subscribeToNewsletter.name);
    const msg = RequestHelpers.handleErrorMessage(error);
    yield put(setErrorPopupMessage(msg));
    yield put(subscribeToNewsletterFailure(error));
  }
}

function* forgotPassword(action) {
  yield put(forgotPasswordRequest());

  const { email } = action;

  try {
    const mutation = gql`mutation ForgotPassword {
      forgot(email: "${email}")
    }`;
    yield call(() => RequestHelpers.apolloGqlMutate(mutation));

    const message = RequestHelpers.errorMessages.forgot;

    yield put(forgotPasswordSuccess(message));
  } catch (error) {
    yield call([this, logError], error, forgotPassword.name);
    const msg = RequestHelpers.handleErrorMessage(error);
    yield put(setErrorPopupMessage(msg));
    yield put(forgotPasswordFailure(error));
  }
}

const userSagas = [
  takeLatest(CREATE_USER, createUser),
  takeLatest(UPDATE_USER, updateUser),
  takeLatest(SIGN_IN_USER, signInUser),
  takeLatest(SIGN_OUT_USER, signOutUser),
  takeLatest(FETCH_LOGGED_IN_USER, fetchLoggedInUser),
  takeLatest(SUBSCRIBE_TO_NEWSLETTER, subscribeToNewsletter),
  takeLatest(FORGOT_PASSWORD, forgotPassword),
];

export default userSagas;
