import qs from 'qs';
import { createSelector } from 'reselect';
import { RootState } from '../store.types';

export const getLocation = ({ router }: RootState) => router?.location;
export const getQueryParamsAsString = ({ ui, router }: RootState) => {
  try {
    let search = router?.location.search?.slice(1);
    if (ui?.nextApp) {
      search = typeof window !== 'undefined' ? window.location.search.slice(1) : '';
    }
    return search;
  } catch {
    return '';
  }
};

export const getQueryParams = createSelector(
  getQueryParamsAsString,
  (search) => {
    try {
      const queryParams = qs.parse(search, {
        decoder: (str) => encodeURIComponent(str),
      });

      return queryParams;
    } catch {
      return {};
    }
  },
);

export const getRawQueryParams = createSelector(
  getQueryParamsAsString,
  (search) => {
    try {
      const queryParams = qs.parse(search);

      return queryParams;
    } catch {
      return {};
    }
  },
);
