import { ReactNode } from 'react';

interface GridProps {
  itemsLength: number;
  children: ReactNode;
}

const gridStyle = (itemsLength: GridProps['itemsLength']) => ({
  display: 'grid',
  gridAutoFlow: 'column',
  gridGap: '1rem',
  gridTemplateColumns: `repeat(${itemsLength < 5 ? 1 : 2}, 1fr)`,
  gridTemplateRows: `repeat(${
    itemsLength < 5 ? itemsLength : Math.ceil(itemsLength / 2)
  }, 1fr)`,
});

export const ConfigurationsGrid = ({ itemsLength, children }: GridProps) => (
  <div
    style={{
      ...gridStyle(itemsLength),
      marginTop: '1rem',
    }}
  >
    {children}
  </div>
);

export const ColorsGrid = ({ itemsLength, children }: GridProps) => (
  <ul
    style={{
      ...gridStyle(itemsLength),
      alignItems: 'flex-start',
    }}
  >
    {children}
  </ul>
);
