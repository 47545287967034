const sofas = [
  'armchair',
  'armchair-ottoman',
  'chaise-lounge',
  'loveseat',
  'loveseat-ottoman',
  'loveseat-chaise',
  'loveseat-chaise-ottoman',
  'sofa',
  'sleeper',
  'sofa-ottoman',
  'sofa-sectional',
  'sofa-sectional-ottoman',
  'sofa-chaise',
  'king-sofa',
  'king-sofa-ottoman',
  'king-sofa-sectional',
  'king-sofa-chaise-ottoman',
  'king-sofa-chaise',
  'ottoman',
  '5-seat-corner-sectional',
  '6-seat-corner-sectional',
  '7-seat-corner-sectional',
  '5-seat-corner-sectional-ottoman',
  '6-seat-corner-sectional-ottoman',
  '7-seat-corner-sectional-ottoman',
  '5-seat-corner-sectional-chaise',
  '6-seat-corner-sectional-chaise',
  '7-seat-corner-sectional-chaise',
  '5-seat-corner-sectional-chaise-ottoman',
  '6-seat-corner-sectional-chaise-ottoman',
  '7-seat-corner-sectional-chaise-ottoman',
  '6-seat-u-sectional',
  '7-seat-u-sectional',
  '8-seat-u-sectional',
  'double-chaise-u-sectional',
  'bolster-pillow-set-2',
  'lumbar-pillow',
  '4-seat-corner-sectional',
  '4-seat-corner-sectional-ottoman',
];
export const sofaRegex = `(${sofas.join('|')})`;
const sofaParts = [
  'arm-sets?',
  'chaise-sets?',
  'corner-sets?',
  'seat-sets?',
  'seat-cushions?',
  'back-cushions?',
  'chaise-cushions?',
  'corner-back-cushions?',
];
export const sofaPartRegex = `(${sofaParts.join('|')})`;
const legSets = [
  'leg-set-armchair',
  'leg-set-armchairs',
  'leg-set-chaises',
  'leg-set-loveseat',
  'leg-set-sofa',
  'leg-set-king-sofa',
  'leg-set-4l',
  'leg-set-5l',
  'leg-set-6l',
  'leg-set-7l',
  'leg-set-6u',
  'leg-set-7u',
  'leg-set-8u',
];
export const legSetRegex = `(${legSets.join('|')})`;

const paths = {
  HOME: '/',
  TV: '/tv',
  HULU: '/hulu',
  CREATE: '/create',
  SIGN_IN: '/sign-in',
  LOGIN: '/login',
  TABLES_AND_BENCHES_PDP: '/tables-and-benches/:product',
  SHELVES_AND_STORAGE_PDP: '/shelves-and-storage/:product',
  NOMAD_LEATHER_PDP: `/nomad-leather/:product${sofaRegex}`,
  NOMAD_FABRIC_PDP: `/nomad-fabric/:product${sofaRegex}`,
  NOMAD_VELVET_PDP: `/nomad-velvet/:product${sofaRegex}`,
  NOMAD_FABRIC_PART_PDP: `/nomad-fabric/:product${sofaPartRegex}`,
  NOMAD_LEATHER_PART_PDP: `/nomad-leather/:product${sofaPartRegex}`,
  NOMAD_VELVET_PART_PDP: `/nomad-velvet/:product${sofaPartRegex}`,
  NOMAD_LEG_SET: `/nomad-fabric/:product${legSetRegex}`,
  RANGE_PDP: '/range-fabric/:product',
  ACCESSORY_PDP: '/accessories/:product',
  RUG_PDP: '/rugs/:product',
  TABLE_PDP: '/tables/:product',
  CARTA_HAIRPIN_PDP: '/carta-legs-hairpin',
  CARTA_STRAIGHT_PDP: '/carta-legs-straight',
  HOME_OFFICE_CHAIRS_PDP: '/home-office/chairs/:product',
  HOME_OFFICE_ACCESSORIES_PDP: '/home-office/accessories/:product',
  HOME_OFFICE_PDP__DESK: '/home-office/desks/:product',
  FIELD_FABRIC_PDP: '/field-fabric/:product',
  LIGHTING_PDP: '/lighting/:product',
  SAMPLES_PDP: '/samples/upholstery/:product',
  BEDDING_PDP: '/bedroom/bedding/:product',
  NIGHTSTAND_PDP: '/bedroom/nightstands/:product',
  BED_PDP: '/bedroom/beds/:product',
  HEADBOARD_PDP: '/bedroom/headboards/:product',
  MATTRESS_PDP: '/bedroom/mattresses/:product',
  DRESSER_PDP: '/bedroom/dressers/:product',
  ACCENT_CHAIR_PDP: '/living-room/seating/accent-chairs/:product',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  ACCOUNT: '/account',
  ACCOUNT_HISTORY: '/account-history',
  REVIEWS: '/reviews',
  REFERRAL: '/referral',
  TERMS: '/terms',
  TERMS__REFERRAL: '/terms/referral',
  SITEMAP: '/sitemap',
  ORDER: '/order',
  RETURNS: '/returns',
  ACCESSIBILITY: '/accessibility',
  COVID: '/covid-19-response',
  PATENTS: '/patents',
  CAREERS: '/careers',
  SHARE_CART: '/share-cart',
  POS_CHECKOUT: '/burrow-house/pos',
  NOT_FOUND: '/404',
  CHECKOUT: '/checkout',
  FURNITURE_COLLECTIONS: '/furniture-collections',
  SEARCH: '/search',
};

export default paths;

export const pathsMap = Object.entries(paths)
  .map(([, path]) => path.replace(':product', ''))
  .flatMap((path) => {
    const matchingRegex = [sofaRegex, sofaPartRegex, legSetRegex].find((regex) => path.includes(regex));
    if (matchingRegex) {
      const pre = path.slice(0, path.indexOf('('));
      return matchingRegex.slice(1, -1).split('|').map((product) => `${pre}${product}`);
    }
    return [path];
  });

const joinedPaths = `(${pathsMap.map((path) => path.replace('/', '\\/')).join('|')})`;
const optionalQueryParams = '(\\/?\\?{0}|\\/?\\?{1}.*)';

export const pathsRegex = new RegExp(
  `${joinedPaths}${optionalQueryParams}$`,
  'i',
);
