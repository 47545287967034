export default abstract class Queue {
  queue: unknown[];

  onEnqueue?: () => void;

  constructor() {
    this.queue = [];
  }

  enqueue(...values: unknown[]) {
    const length = this.queue.push(...values);

    if (this.onEnqueue) {
      this.onEnqueue();
    }

    return length;
  }

  dequeue() {
    return this.queue.shift();
  }

  peek() {
    return this.queue[0];
  }

  length() {
    return this.queue.length;
  }
}
