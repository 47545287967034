import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withFormsy } from 'formsy-react';
import ColorPickerRadio from './ColorPickerRadio';

export class ColorPickerRadioGroup extends Component {
  render() {
    const {
      options,
      name,
      value,
      setValue,
    } = this.props;

    return options.map(({ label, id, color }) => (
      <ColorPickerRadio
        key={id}
        name={name}
        id={id}
        label={label}
        checked={value === id}
        value={id}
        onChange={() => setValue(id)}
        color={color || id}
      />
    ));
  }
}

ColorPickerRadioGroup.propTypes = {
  setValue: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      color: PropTypes.string,
    }).isRequired,
  ).isRequired,
};

export default withFormsy(ColorPickerRadioGroup);
