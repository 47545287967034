import PropTypes from 'prop-types';
import Nullable from '../../../../shared/nullable';
import Asset from '../global/Asset';
import { AssetProps } from '../global/Helpers';

import { default as styles } from '../../scss/components/ColorPicker.module.scss';
import { withCssModulesClassNames } from '../../common/nextMigrationHelpers';
import { Asset as AssetType } from '../global/header/header.types';

const withCssModulesClassNamesHandler = withCssModulesClassNames(styles);
interface ColorPickerProps {
  active: boolean,
  asset?: Nullable<AssetType>,
  backgroundColor?: string,
  borderColor?: string,
  description?: string,
  hasBorder?: boolean,
  onClick: (value: string) => void,
  title?: string,
  value: string
}

const ColorPicker = ({
  asset,
  active,
  backgroundColor,
  borderColor,
  description,
  hasBorder,
  onClick,
  value,
}: ColorPickerProps) => (
    <div
      aria-label={description}
      className={withCssModulesClassNamesHandler(
        value,
        'color-picker-color',
        {
          active,
          'has-border': hasBorder,
        },
      )}
      style={{
        ...(borderColor && { border: `0.0625rem solid ${borderColor}` }),
        ...(backgroundColor && { background: backgroundColor }),
      }}
      onClick={() => onClick(value)}
      title={description}
      role="button"
    >
    {asset ? <Asset asset={asset} /> : null}
  </div>
);

export default ColorPicker;

ColorPicker.defaultProps = {
  asset: null,
  backgroundColor: '',
  borderColor: '',
  description: '',
  hasBorder: false,
  title: '',
};

ColorPicker.propTypes = {
  active: PropTypes.bool.isRequired,
  asset: AssetProps,
  backgroundColor: PropTypes.string,
  borderColor: PropTypes.string,
  description: PropTypes.string,
  hasBorder: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string,
  value: PropTypes.string.isRequired,
};
