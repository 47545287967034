/* eslint-disable no-underscore-dangle */
import { captureException } from '@sentry/browser';

import { createReduxEnhancer as createSentryReduxEnhancer } from '@sentry/react';
import { configureStore as configureStoreTK, combineReducers, applyMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory, createMemoryHistory } from 'history';

import generalReducer from './reducers/general.reducer';
import uiReducer, { initialState as uiInitialState } from './reducers/ui.reducer';
import collectionsReducer from './reducers/collections.reducer';
import pageReducer from './reducers/page.reducer';
import productReducer from './reducers/product.reducer';
import userReducer from './reducers/user.reducer';
import cartReducer from './reducers/cart.reducer';
import reviewsReducer from './reducers/reviews.reducer';
import orderReducer from './reducers/order.reducer';
import careersReducer from './reducers/careers.reducer';
import checkoutReducer from './reducers/checkout.reducer';
import favoritesReducer, { restoreFavoritesData } from './slices/favorites.slice';

import collectionsSagas from './sagas/collections.sagas';
import pageSagas from './sagas/page.sagas';
import productSagas from './sagas/product.sagas';
import userSagas from './sagas/user.sagas';
import cartSagas from './sagas/cart.sagas';
import reviewsSagas from './sagas/reviews.sagas';
import orderSagas from './sagas/order.sagas';
import uiSagas from './sagas/ui.sagas';
import careersSagas from './sagas/careers.sagas';
import checkoutSagas from './sagas/checkout.sagas';

import { isServer } from '../../../utils/Utils';
import listenerMiddleware from './listeners';

export function* rootSaga() {
  yield all([
    ...collectionsSagas,
    ...pageSagas,
    ...productSagas,
    ...userSagas,
    ...cartSagas,
    ...reviewsSagas,
    ...orderSagas,
    ...uiSagas,
    ...careersSagas,
    ...checkoutSagas,
  ]);
}

export default function configureStore(initialState, url = '/') {
  let state = initialState;
  const isProduction = process.env.NODE_ENV !== 'production';

  if (state && !isServer) {
    // overwrite initial state with values available to a client only
    const hash = window.location?.hash;
    const isOnline = window.navigator ? window.navigator.onLine : uiInitialState.isOnline;

    state = {
      ...state,
      router: state.router?.location && hash ? ({
        ...state.router,
        location: {
          ...state.router.location,
          hash,
        },
      }) : state.router,
      ui: state.ui && {
        ...state.ui,
        isOnline,
      },
      favorites: {
        ...state.favorites,
        data: restoreFavoritesData(),
      },
    };
  }

  const history = isServer
    ? createMemoryHistory({ initialEntries: [url] })
    : createBrowserHistory();

  const reducers = combineReducers({
    router: connectRouter(history),
    general: generalReducer,
    collections: collectionsReducer,
    page: pageReducer,
    product: productReducer,
    ui: uiReducer,
    user: userReducer,
    cart: cartReducer,
    reviews: reviewsReducer,
    order: orderReducer,
    careers: careersReducer,
    checkout: checkoutReducer,
    favorites: favoritesReducer,
  });

  const sagaMiddleware = createSagaMiddleware({
    onError(err) {
      captureException(err);
    },
  });
  const middlewares = [
    routerMiddleware(history),
    sagaMiddleware,
  ];

  const store = configureStoreTK({
    reducer: reducers,
    preloadedState: state,
    devTools: isProduction,
    middleware: (getDefaultMiddleware) => (
      getDefaultMiddleware().prepend(listenerMiddleware.middleware)
    ),
    enhancers: [
      applyMiddleware(...middlewares),
      createSentryReduxEnhancer(),
    ],
  });

  if (typeof window !== 'undefined') {
    window.store = store;
  }

  sagaMiddleware.run(rootSaga);

  return {
    store,
    history,
    middlewares,
  };
}
