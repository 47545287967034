import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Formsy from 'formsy-react';
import { useDispatch, useSelector } from 'react-redux';

import { ColorsGrid, ConfigurationsGrid } from './FiltersPopout.styled';
import {
  filters as filtersGlobalAPI,
  configurations as configurationsGlobalAPI,
} from '../../../mock/globalAPI';
import {
  COLOR_KEY,
  getAllColorOptions, getSanitizedFilterQueryParams,
} from './utils';

import { getRawQueryParams } from '../../../store/selectors/router.selectors';
import { fetchPageContent, setFiltersSelected } from '../../../store/actions/page.actions';
import { push } from '../../../store/actions/ui.actions';
import {
  getAvailableConfigurations,
  getAvailableMaterials,
  getFilterID,
  getIsLoading,
  getCollectionFilters,
  getProductCollections,
  getColorBuckets,
  getIsPageDataPending,
  getNumberOfAppliedFilters,
  getFilterRedirects,
} from '../../../store/selectors/page.selectors';
import FormsyCheckbox from '../fields/Checkbox';
import ColorPickerRadioGroupContainer from '../fields/ColorPickerRadioGroup';
import CollectionPickerFormsyCheckbox from '../fields/CollectionPickerCheckbox';
import LoadingContainer from '../Loading';
import {
  onFormChangeHandler,
  onFormSubmitHandler,
  clearConfigurationFiltersHandler,
  clearColorsFiltersHandler,
  clearCollectionFiltersHandler,
} from './FiltersPopout.helpers';
import FilterSectionHeader from './FilterSectionHeader';
import { useAppContext } from '../../../contexts/Context';
import { getLocationFields } from '../../../../../utils/Utils';

/**
 * @typedef {Object} FiltersProps
 * @property {boolean} [updateOnChange] - Indicates if the filter should update on change.
 * @property {React.Ref<{ clear: () => void }>=} ref - Ref object with a clear method.
 *
 * Filters component with forward ref.
 *
 * @param {FiltersProps} props - The properties passed to the component.
 * @param {FiltersProps['ref']} ref - Forwarded ref that provides access to certain methods.
 */
const FiltersFn = ({ updateOnChange }, ref) => { // eslint-disable-line react/prop-types
  const dispatch = useDispatch();
  const { location, nextApp } = useAppContext();
  const availableConfigurations = useSelector(getAvailableConfigurations);
  const availableMaterials = useSelector(getAvailableMaterials);
  const collectionFilters = useSelector(getCollectionFilters);
  const colorBuckets = useSelector(getColorBuckets);
  const filterID = useSelector(getFilterID);
  const filterRedirects = useSelector(getFilterRedirects);
  const isPending = useSelector(getIsPageDataPending);
  const isLoading = useSelector(getIsLoading);
  const numberOfAppliedFilters = useSelector(getNumberOfAppliedFilters);
  const productCollections = useSelector(getProductCollections);
  const queryParams = useSelector(getRawQueryParams);

  const filtersForm = useRef(null);
  const [state, setState] = useState({
    isAnyConfigurationSelected: false,
    isAnyColorSelected: false,
    isAnyCollectionSelected: false,
    productsMatchingCriteriaCount: 0,
    filterResults: [],
    urlSearchString: '',
  });

  const pushAction = ({ pathname, search }) => {
    const urlToPush = `${pathname}${search ? `?${search}` : ''}`;

    if (nextApp) {
      location.push(location, urlToPush, { shallow: true });
      return;
    }

    dispatch(push(urlToPush));
  };
  const setFiltersSelectedAction = (...args) => dispatch(setFiltersSelected(...args));

  const { pathname, search: currentSearch  } = getLocationFields(location);
  const onFormSubmit = onFormSubmitHandler({
    filterRedirects,
    collectionFilters,
    pushAction,
    pathname,
    currentSearch,
  });

  const onFormChange = onFormChangeHandler({
    productCollections,
    collectionFilters,
    colorBuckets,
    availableMaterials,
    availableConfigurations,
    setFiltersSelectedAction,
    numberOfAppliedFilters,
    state,
    setState,
    formRef: filtersForm,
  });

  const clearConfigurationFilters = clearConfigurationFiltersHandler({
    availableConfigurations,
    formRef: filtersForm,
  });

  const clearColorsFilters = clearColorsFiltersHandler({
    formRef: filtersForm,
  });

  const clearCollectionFilters = clearCollectionFiltersHandler({
    collectionFilters,
    formRef: filtersForm,
  });

  useImperativeHandle(ref, () => ({
    clear: () => {
      clearConfigurationFilters();
      clearColorsFilters();
      clearCollectionFilters();
    },
  }), [clearCollectionFilters, clearConfigurationFilters, clearColorsFilters]);

  const {
    isAnyCollectionSelected,
    isAnyConfigurationSelected,
    isAnyColorSelected,
    productsMatchingCriteriaCount,
    urlSearchString,
  } = state;

  useEffect(() => {
    if (updateOnChange) {
      filtersForm?.current.submit();
    }
  }, [urlSearchString, updateOnChange]);

  useEffect(() => {
    const { search, pathname } = getLocationFields(location);

    if (search) {
      let isAnyColorSelected = false;
      let isAnyCollectionSelected = false;
      let isAnyConfigurationSelected = false;
      const searchParams = new URLSearchParams(search);
      const formValues = getSanitizedFilterQueryParams(Object.fromEntries(searchParams));

      searchParams.forEach((value, key) => {
        if (availableConfigurations.includes(key)) {
          isAnyConfigurationSelected = true;
        } else if (collectionFilters.some(({ slug }) => slug === key)) {
          isAnyCollectionSelected = true;
        } else if (key === COLOR_KEY) {
          isAnyColorSelected = value !== 'all';
        } else {
          searchParams.delete(key);
        }
      });

      const newUrlSearchString = searchParams.toString();
      setState((prevState) => ({
        ...prevState,
        isAnyColorSelected,
        isAnyCollectionSelected,
        isAnyConfigurationSelected,
        urlSearchString: newUrlSearchString,
      }));

      if (nextApp) {
        onFormChange(formValues);
        dispatch(fetchPageContent(pathname));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- should only run on mount
  }, []);

  const colorOptions = getAllColorOptions(colorBuckets);
  const isSubmitDisabled = productsMatchingCriteriaCount === 0 || isPending;

  return (
    <Formsy
      onChange={onFormChange}
      onSubmit={onFormSubmit}
      ref={filtersForm}
      className={classNames('filters__form')}
    >
      {!filterID || isLoading ? (
        <LoadingContainer />
      ) : (
        <div className="filters__wrapper">
          <div className="filters__wrapper__inner filters__items">
            <div className="filter-section-wrapper">
              <div className="form-fancy">
                <FilterSectionHeader
                  title={filtersGlobalAPI.configurationsSectionHeader}
                  label="Clear configuration filters"
                  onClick={clearConfigurationFilters}
                  disabled={!isAnyConfigurationSelected}
                />
                <ConfigurationsGrid itemsLength={availableConfigurations.length}>
                  {availableConfigurations.map((configuration) => (
                    <FormsyCheckbox
                      key={configuration}
                      name={configuration}
                      value={configuration in queryParams}
                      label={configurationsGlobalAPI[configuration] || configuration}
                    />
                  ))}
                </ConfigurationsGrid>
              </div>
            </div>
            <div className="filter-section-wrapper">
              <div>
                <FilterSectionHeader
                  title={filtersGlobalAPI.colorSectionHeader}
                  label="Clear color filters"
                  onClick={clearColorsFilters}
                  disabled={!isAnyColorSelected}
                />
              </div>
              <div />
              <div className="form-fancy">
                <ColorsGrid itemsLength={colorOptions.length}>
                  <ColorPickerRadioGroupContainer
                    name={COLOR_KEY}
                    options={colorOptions}
                    value={queryParams?.[COLOR_KEY] || 'all'}
                  />
                </ColorsGrid>
              </div>
            </div>
            <div className="filter-section-wrapper collection">
              <div className="form-fancy">
                <FilterSectionHeader
                  title={filtersGlobalAPI.seatingCollectionsHeader}
                  label="Clear collection filters"
                  onClick={clearCollectionFilters}
                  disabled={!isAnyCollectionSelected}
                />
                <div className="collection-pickers">
                  {Object.values(collectionFilters).map((collection) => (
                    <CollectionPickerFormsyCheckbox
                      key={collection.slug}
                      asset={collection?.asset || {}}
                      description={collection.description}
                      label={collection.subHeader || collection.header}
                      name={collection.slug}
                      value={collection.slug in queryParams}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
          { !updateOnChange && (
            <div className="filters-footer caption filters-footer_tight-leading filters-footer-shadow">
              <div className="filters-footer-inner-wrapper filters-footer-spacing">
                <button
                  disabled={isSubmitDisabled}
                  type="submit"
                  data-cy="submit-filters"
                  className={classNames(
                    'button btn btn-sandstone200 filter-apply',
                    { disabled: isSubmitDisabled },
                  )}
                >
                  <div className="filter-apply__text">
                    <span>{filtersGlobalAPI.applyCopy}</span>
                    <span
                      className="filter-apply__text__results"
                      data-cy="filter-results"
                    >
                      {`${productsMatchingCriteriaCount} ${filtersGlobalAPI.resultsCopy}`}
                    </span>
                  </div>
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </Formsy>
  );
};

const Filters = forwardRef(FiltersFn);

Filters.propTypes = {
  updateOnChange: PropTypes.bool,
};

Filters.defaultProps = {
  updateOnChange: false,
};

export default Filters;
