import { useDispatch, useSelector } from 'react-redux';
import {
  getFavoritesFrom,
  getLoading,
} from '../../../store/selectors/favorites.selector';
import {
  removeFavoriteItem,
  addFavoritesToCart,
  FavoriteItem,
} from '../../../store/slices/favorites.slice';
import { withCssModulesClassNames } from '../../../common/nextMigrationHelpers';
import Analytics from '../../global/Analytics';
import { getUserDetails } from '../../../store/selectors/user.selectors';
import { default as styles } from '../../../scss/components/FavoritesBucket.module.scss';
import FavoritesBucketItem from './FavoritesBucketItem';

const cssModulesClassNames = withCssModulesClassNames(styles);

/**
 * Fill items array with empty objects to match limit
 * @example
 * // for items.length = 2
 * fillItems(items, 5); // returns [...items, {}, {}, {}]
 */
export const fillItems = (items: FavoriteItem[], limit: number) => {
  const extraItems = items.length < limit && Array(limit - items.length);
  const fill: Record<string, undefined>[] = (extraItems || []).fill({});
  return [...items, ...fill];
};

export interface FavoritesBucketProps {
  group: string;
  limit?: number;
}

export const getSwatchDrawerMessage = ({
  total,
  limit,
}: { total: number, limit: number }) => {
  if (total === limit - 1) {
    return 'You can add one more swatch for free';
  }

  if (total === limit) {
    return `Your first ${limit} swatches are free`;
  }

  return `Add up to ${limit} free swatches`;
};

const FavoritesBucket = ({ group, limit = 5 }: FavoritesBucketProps) => {
  const dispatch = useDispatch();
  const items = useSelector(getFavoritesFrom(group));
  const loading = useSelector(getLoading);
  const userDetails = useSelector(getUserDetails);
  const disabled = loading === 'pending' || items.length === 0;
  const filledItems = fillItems(items, limit);

  const removeFavoriteHandler = ({ sku, name }: { sku: string; name: string; }) => () => {
    dispatch(
      removeFavoriteItem({
        group,
        sku,
      }),
    );

    Analytics.enqueue({
      method: 'item',
      params: {
        email: userDetails.email,
        label: 'Product Removed from Wishlist',
        event: 'Product Removed from Wishlist',
        item: {
          name,
          sku,
        },
      },
    });
  };

  return (
    <div className={cssModulesClassNames('swatch-favorites')}>
      <div className={cssModulesClassNames('swatch-favorites__container')}>
        <span>{getSwatchDrawerMessage({ limit, total: items.length })}</span>
        <div
          className={cssModulesClassNames(
            'swatch-favorites__items-list-wrapper',
          )}
        >
          <div className={cssModulesClassNames('swatch-favorites__items-list')}>
            {filledItems.map(({
              sku,
              name,
              productCardImage,
              collectionHeader,
            }, idx) => (
              <FavoritesBucketItem
                key={`${idx}-${sku || 'empty'}`}
                sku={sku}
                name={name}
                productCardImage={productCardImage}
                collectionHeader={collectionHeader}
                removeFavoriteHandler={
                  sku && name ? removeFavoriteHandler({ sku, name }) : undefined
                }
              />
            ))}
          </div>
          <button
            className={cssModulesClassNames(
              'btn-add-favorites-to-cart',
              'btn',
              'btn-yellow',
              { disabled },
            )}
            role="button"
            onClick={() => {
              if (!disabled) {
                dispatch(addFavoritesToCart(group));
                Analytics.enqueue({
                  method: 'track',
                  params: {
                    event: 'Wishlist Product Added to Cart',
                    properties: {
                      email: userDetails.email,
                      label: 'Wishlist Product Added to Cart',
                      items: items.map(({ sku, name, productID }) => ({
                        sku,
                        name,
                        productID,
                      })),
                    },
                  },
                });
              }
            }}
          >
            Add to Cart
          </button>
        </div>
      </div>
    </div>
  );
};

export default FavoritesBucket;
