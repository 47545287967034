import { useRef, useEffect } from 'react';
import { withCssModulesClassNames } from '../../../common/nextMigrationHelpers';
import { default as searchStyles } from '../../../scss/components/Search.module.scss';

const withCssModulesClassNamesHandler = withCssModulesClassNames(searchStyles);

export const SEARCH_TERM_INPUT_LABEL = 'search-term-input';
export interface SearchTermInputProps {
  value: string;
  autoFocus?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onCloseClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const SearchTermInput = ({
  value,
  onChange,
  onCloseClick,
  autoFocus = false,
}: SearchTermInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (autoFocus) {
      inputRef.current?.focus();
    }
  }, [autoFocus]);

  return (
    <div className={withCssModulesClassNamesHandler('search-term-input-container')}>
      <i className={withCssModulesClassNamesHandler('icon', 'icon-search')} />
      <label htmlFor="search" className={withCssModulesClassNamesHandler('visually-hidden')}>Search</label>
      <input
        name="search"
        type="text"
        placeholder="Search products"
        value={value}
        ref={inputRef}
        autoFocus={autoFocus}
        aria-label={SEARCH_TERM_INPUT_LABEL}
        className={withCssModulesClassNamesHandler('search-term-input')}
        onChange={onChange}
      />
      {typeof onCloseClick === 'function'
        && (
          <button onClick={onCloseClick} className={withCssModulesClassNamesHandler('mobile-close-button')} data-testid="mobile-search-close">
            <i className={withCssModulesClassNamesHandler('icon', 'icon-close')} aria-label="Close search" />
          </button>
        )
      }
    </div>
  );
};

export default SearchTermInput;
