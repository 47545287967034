import { withCssModulesClassNames } from '../../../common/nextMigrationHelpers';
import { default as searchStyles } from '../../../scss/components/Search.module.scss';

const withCssModulesClassNamesHandler = withCssModulesClassNames(searchStyles);

const SearchDivider = () => (
  <div role="separator" className={withCssModulesClassNamesHandler('search-divider')} />
);

export default SearchDivider;
