import React, { createRef, PureComponent } from 'react';
import PropTypes from 'prop-types';
import SwiperCore, {
  A11y, Autoplay, EffectFade, Lazy, Pagination, SwiperOptions,
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import isFinite from 'lodash/isFinite';
import throttle from 'lodash/throttle';
import isObjectLike from 'lodash/isObjectLike';
import { connect } from 'react-redux';

import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/effect-fade/effect-fade.scss';
import { default as sliderStyles } from '../../scss/components/Slider.module.scss';

import { withCssModulesClassNames } from '../../common/nextMigrationHelpers';
import { getWindowWidth } from '../../store/selectors/ui.selectors';
import SliderAlignment from '../../types/schema/models/SliderAlignment.dto';
import { Nullable } from '../../types/common';

const withCssModulesClassNamesHandler = withCssModulesClassNames(sliderStyles);

SwiperCore.use([
  EffectFade,
  Pagination,
  Autoplay,
  A11y,
  Lazy,
]);

interface SliderProps {
  swiper?: Swiper;
  windowWidth: number;
  breakpoints: { [key: number]: {
    [key: string]: number
  } };
  paginationRef?: React.RefObject<HTMLSpanElement | HTMLDivElement>;
  align: SliderAlignment;
  isPointCarousel: boolean;
  className?: string;
  paginationClassName?: string;
  theme?: string;
  setSlideIndex: (n: number) => void;
  onReInit: () => void;
  onChange: (n: number) => void;
  children?: React.ReactNode;
  slidesPerView?: number;
  slidesPerGroup?: number;
  fade?: boolean;
  autoplay?: boolean;
  autoplayDelay?: number;
  speed?: number;
  pauseOnHover?: boolean;
  allowTouchMove?: boolean;
  lazyLoad?: boolean;
  disableInfinite?: boolean;
  effect?: SwiperOptions['effect'];
  pagination?: boolean;
  renderArrow?: ArrowComponentProps['renderArrow'];
  arrows?: boolean;
  navStyles?: React.CSSProperties;
  spaceBetween?: number;
  isRecommendedItems?: boolean;
}

interface SliderState {
  slideChanged: boolean;
  index: number;
  isBeginning: boolean;
  isEnd: boolean;
  isAutoplay: boolean;
}

interface ArrowComponentProps {
  side: string;
  onClick: () => void;
  renderArrow?: (side: string,
    className: string,
    handleClick: React.MouseEventHandler) => JSX.Element;
  style: React.CSSProperties;
}

const Arrow = ({
  renderArrow, side, style, onClick,
}: ArrowComponentProps) => {
  const className = withCssModulesClassNamesHandler('slider__arrow', `slider__arrow--${side}`);
  const handleClick: React.MouseEventHandler = (e) => {
    e.preventDefault();
    if (onClick) {
      onClick();
    }
  };

  return renderArrow ? renderArrow(side, className, handleClick) : (
    <div
      className={className}
      onClick={handleClick}
      data-testid={`slider__arrow--${side}`}
    >
      <i
        className={withCssModulesClassNamesHandler('icon', `icon-arrow-${side}`)}
        style={style}
      />
    </div>
  );
};

Arrow.defaultProps = {
  renderArrow: undefined,
};

const sliderPropTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  theme: PropTypes.oneOf(['dark', 'light', 'yellow']),
  pagination: PropTypes.bool,
  arrows: PropTypes.bool,
  slidesPerView: PropTypes.number,
  slidesPerGroup: PropTypes.number,
  fade: PropTypes.bool,
  autoplay: PropTypes.bool,
  autoplayDelay: PropTypes.number,
  speed: PropTypes.number,
  pauseOnHover: PropTypes.bool,
  disableInfinite: PropTypes.bool,
  allowTouchMove: PropTypes.bool,
  breakpoints: PropTypes.shape({
    breakpoint: PropTypes.shape({
      slidesPerGroup: PropTypes.number,
      slidesPerView: PropTypes.number,
      arrows: PropTypes.bool,
      pagination: PropTypes.bool,
    }),
  }),
  lazyLoad: PropTypes.bool,
  navStyles: PropTypes.object,
  className: PropTypes.string,
  setSlideIndex: PropTypes.func,
  align: PropTypes.string,
  isPointCarousel: PropTypes.bool,
  windowWidth: PropTypes.number,
  onChange: PropTypes.func,
  onReInit: PropTypes.func,
  spaceBetween: PropTypes.number,
  isRecommendedItems: PropTypes.bool,
};

export class Slider extends PureComponent<SliderProps, SliderState> {
  state = {
    slideChanged: false,
    index: 0,
    isBeginning: true,
    isEnd: false,
    isAutoplay: true,
  };

  initialIndex = 0;

  private readonly paginationRef: React.RefObject<HTMLDivElement>;

  private swiper: Nullable<SwiperCore>;

  private readonly handleNextClick: () => void;

  private readonly handlePrevClick: () => void;

  constructor(props: SliderProps) {
    super(props);

    this.paginationRef = createRef();

    this.handleSwiper = this.handleSwiper.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleResize = throttle(this.handleResize.bind(this), 300);
    this.slideToIndex = this.slideToIndex.bind(this);
    this.slideNext = this.slideNext.bind(this);
    this.slidePrev = this.slidePrev.bind(this);
    this.handleNextClick = this.slideNext.bind(this);
    this.handlePrevClick = this.slidePrev.bind(this);
    this.getBreakpointsProps = this.getBreakpointsProps.bind(this);
    this.getIsLoop = this.getIsLoop.bind(this);
    this.disableAutoplay = this.disableAutoplay.bind(this);
    this.swiper = null;
    this.detectSliderVisibility = this.detectSliderVisibility.bind(this);
  }

  componentDidMount() {
    document.addEventListener('visibilitychange', this.detectSliderVisibility);
  }

  componentDidUpdate(prevProps: SliderProps) {
    const {
      onReInit,
      windowWidth,
    } = this.props;
    const {
      windowWidth: prevWindowWidth,
    } = prevProps;

    if (onReInit) {
      onReInit();
    }

    if (windowWidth !== prevWindowWidth) {
      this.handleResize();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('visibilitychange', this.detectSliderVisibility);
  }

  handleSwiper(swiper: Nullable<SwiperCore>) {
    this.swiper = swiper;
    this.slideToIndex(this.initialIndex);
  }

  handleChange(swiper: SwiperCore) {
    const {
      onChange,
      setSlideIndex,
    } = this.props;
    const {
      index,
      slideChanged,
    } = this.state;

    this.setState({
      slideChanged: slideChanged || swiper.realIndex !== index,
      index: swiper.realIndex,
      isBeginning: swiper.isBeginning,
      isEnd: swiper.isEnd,
    });

    if (isFinite(swiper?.realIndex) && onChange) {
      onChange(swiper.realIndex);
      setSlideIndex(swiper.realIndex);
    }
  }

  handleResize() {
    this.detectSliderVisibility();
  }

  slideToIndex(index: number) {
    if (!this.swiper) {
      this.initialIndex = index;
      return;
    }

    const loop = this.getIsLoop();
    if (loop) {
      this.swiper.slideToLoop(index);
    } else {
      this.swiper.slideTo(index);
    }
  }

  slideNext() {
    if (this.swiper) {
      this.swiper.slideNext();
    }
  }

  slidePrev() {
    if (this.swiper) {
      this.swiper.slidePrev();
    }
  }

  getBreakpointsProps() {
    const {
      windowWidth,
      breakpoints,
      ...rest
    } = this.props;

    if (!breakpoints) {
      return this.props;
    }

    return Object.entries(breakpoints)
      .filter(([breakpoint]) => parseInt(breakpoint, 10) <= windowWidth)
      .sort(([breakpointA = 0], [breakpointB = 0]) => +breakpointA - +breakpointB)
      .reduce((acc, [, breakpointSettings]) => (
        {
          ...acc,
          ...(isObjectLike(breakpointSettings) ? breakpointSettings as object : {}),
        }), rest);
  }

  getIsLoop() {
    const {
      children,
    } = this.props;
    const {
      slidesPerGroup,
      disableInfinite,
    } = this.getBreakpointsProps();

    // FIXME: Going backward seems to be bugged when slidesPerGroup > 1
    // https://app.hive.com/workspace/BPQTWhi9hPh6N6usf?section=comments&actionId=HuHPA6rPW6esrEdrt
    // https://github.com/nolimits4web/swiper/issues/2875
    return !disableInfinite && React.Children.count(children) > 1 && slidesPerGroup === 1;
  }

  detectSliderVisibility() {
    const {
      setSlideIndex,
    } = this.props;

    const {
      isAutoplay,
      index,
    } = this.state;

    if (document.visibilityState === 'visible') {
      if (isAutoplay) {
        this.slideToIndex(0);
        setSlideIndex(0);
      } else {
        this.slideToIndex(index);
        setSlideIndex(index);
      }
    }
  }

  renderSlider() {
    const {
      children,
      setSlideIndex,
      isPointCarousel,
    } = this.props;
    const {
      slidesPerView,
      slidesPerGroup,
      fade,
      autoplay,
      autoplayDelay,
      speed,
      pauseOnHover,
      allowTouchMove,
      lazyLoad,
      pagination,
      spaceBetween,
      isRecommendedItems,
    } = this.getBreakpointsProps();

    const paginationSettings: SwiperOptions['pagination'] = {
      clickable: true,
      renderBullet(index) {
        return `<span class="${withCssModulesClassNamesHandler('swiper-pagination-bullet')}" aria-label="Go to slide ${index}" role="button"></span>`;
      },
      el: this.paginationRef.current,
      bulletActiveClass: withCssModulesClassNamesHandler('swiper-pagination-bullet-active'),
    };

    let effect: SwiperOptions['effect'] = 'slide';
    let fadeEffect;
    if (fade || isPointCarousel) {
      effect = 'fade';
      fadeEffect = { crossFade: true };
    }

    let autoplaySettings;
    if (autoplay) {
      autoplaySettings = {
        delay: autoplayDelay,
        disableOnInteraction: pauseOnHover,
      };
    }

    const loop = this.getIsLoop();

    return (
      <Swiper
        pagination={pagination ? paginationSettings : {
          clickable: true,
          el: this.paginationRef.current,
          bulletActiveClass: withCssModulesClassNamesHandler('swiper-pagination-bullet-active'),
        }}
        effect={effect}
        fadeEffect={fadeEffect}
        loop={loop}
        loopedSlides={loop ? slidesPerView : 0}
        loopPreventsSlide={false}
        slidesPerView={slidesPerView}
        slidesPerGroup={slidesPerGroup}
        autoplay={autoplaySettings}
        speed={speed}
        onSwiper={this.handleSwiper}
        onActiveIndexChange={this.handleChange}
        allowTouchMove={allowTouchMove}
        lazy={lazyLoad}
        threshold={10}
        onSlideChange={() => setSlideIndex(this?.swiper?.realIndex || 0)}
        spaceBetween={spaceBetween}
        isRecommendedItems={isRecommendedItems}
      >
        {children}
      </Swiper>
    );
  }

  renderArrow(direction: 'left' | 'right') {
    const { renderArrow, navStyles } = this.getBreakpointsProps();
    const { isBeginning, isEnd } = this.state;
    const loop = this.getIsLoop();
    const arrowProps = {
      left: {
        onClick: this.handlePrevClick,
        shouldShow: loop || !isBeginning,
      },
      right: {
        onClick: this.handleNextClick,
        shouldShow: loop || !isEnd,
      },
    };
    const { onClick, shouldShow } = arrowProps[direction];

    if (shouldShow) {
      return (
        <Arrow
          renderArrow={renderArrow}
          side={direction}
          style={navStyles as React.CSSProperties}
          onClick={onClick}
        />
      );
    }

    return null;
  }

  renderArrows() {
    const { children } = this.props;
    const { arrows } = this.getBreakpointsProps();

    if (arrows && React.Children.count(children) > 1) {
      return (
        <div className={withCssModulesClassNamesHandler('slider__arrows-container')}>
          {this.renderArrow('left')}
          {this.renderArrow('right')}
        </div>
      );
    }
    return null;
  }

  disableAutoplay() {
    if (!this?.swiper) return;

    this.swiper.autoplay.stop();

    this.setState({
      isAutoplay: false,
    });
  }

  getPaginationClassNames() {
    const { align, isPointCarousel, paginationClassName } = this.props;
    const { isAutoplay } = this.state;
    const { pagination } = this.getBreakpointsProps();

    return withCssModulesClassNamesHandler('slider__pagination', paginationClassName, {
      'slider__pagination--visible': pagination,
      'slider__pagination--unanimated': !isAutoplay && isPointCarousel,
      'slider__pagination--animated': isAutoplay && isPointCarousel,
      'slider__pagination--left-aligned': align === 'left',
    });
  }

  renderPagination() {
    const { align } = this.props;

    return (
      <div
        ref={this.paginationRef}
        className={this.getPaginationClassNames()}
        style={{ textAlign: align || 'center' }}
        onClick={this.disableAutoplay}
      />
    );
  }

  render() {
    const {
      className,
      theme,
      isRecommendedItems,
    } = this.props;

    const themeClassName = `slider--theme-${theme || 'dark'}`;

    return (
      <div className={withCssModulesClassNamesHandler('slider', className, themeClassName, { isRecommendedItems })}>
        {this.renderSlider()}
        {isRecommendedItems && (
          <div className={withCssModulesClassNamesHandler('recommended_items_container')}>
            {this.renderPagination()}
            {this.renderArrows()}
          </div>
        )}
        {!isRecommendedItems && this.renderArrows()}
        {!isRecommendedItems && this.renderPagination()}
      </div>
    );
  }

  static defaultProps = {
    children: [],
    theme: 'dark',
    pagination: true,
    arrows: true,
    slidesPerView: 1,
    slidesPerGroup: 1,
    fade: false,
    autoplay: false,
    autoplayDelay: 3500,
    speed: 300,
    pauseOnHover: false,
    disableInfinite: false,
    allowTouchMove: true,
    breakpoints: null,
    navStyles: undefined,
    lazyLoad: false,
    className: '',
    setSlideIndex: () => null,
    align: 'center',
    isPointCarousel: false,
    windowWidth: 768,
    onChange: () => null,
    onReInit: () => null,
    spaceBetween: 0,
    isRecommendedItems: undefined,
  };

  static propTypes = sliderPropTypes;
}

const mapStateToProps = (state: { ui: unknown }) => ({
  windowWidth: getWindowWidth(state),
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(Slider);

export const Slide = SwiperSlide;
