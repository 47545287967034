import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import groupBy from 'lodash/groupBy';
import UniversalLinkContainer from '../global/UniversalLink';

import { withCssModulesClassNames } from '../../common/nextMigrationHelpers';
import CallToAction, { CallToActionProps } from '../global/CallToAction';

import SliderContainer, { Slide } from '../global/Slider';
import {
  generateImageAltTag,
} from '../global/ProductHelpers';
import { default as styles } from '../../scss/components/ProductCardImage.module.scss';
import { PLPLayout } from '../../types/common';
import { ProductDto, CallToActionDto } from '../../types/schema';
import { Asset } from '../global/header/header.types';
import AssetComponent from '../global/Asset';

interface ProductCardImageProps {
  activeLayout: PLPLayout;
  callToActions?: CallToActionDto[];
  className?: string;
  images: Asset[];
  isMobile: boolean;
  item: ProductDto;
  onProductCardClick: (props: string) => void;
  productCardRef: React.RefObject<HTMLDivElement> | null;
  productName: string;
  salesCopy?: string | null;
  source?: string;
  tabIndex?: number;
  url: string;
}

const withCssModulesClassNamesHandler = withCssModulesClassNames(styles);

const ProductCardImage = ({
  productName,
  item,
  className,
  salesCopy,
  tabIndex,
  url,
  onProductCardClick,
  activeLayout,
  isMobile,
  source,
  callToActions,
  images,
  productCardRef,
}: ProductCardImageProps) => {
  const groupCallToActionsByAlignment = (callToActionsArray?: CallToActionDto[]) => {
    const verticalAlignments = ['Top', 'Bottom'];
    const alignments = ['Left', 'Right'];

    const callToActionsWithGroupKey = callToActionsArray?.map((callToAction) => {
      const verticalAlign = verticalAlignments.includes(callToAction.verticalAlign)
        ? callToAction.verticalAlign.toLowerCase()
        : verticalAlignments[0].toLowerCase();

      const align = alignments.includes(callToAction.align)
        ? callToAction.align
        : alignments[0];

      return {
        ...callToAction,
        groupKey: `${verticalAlign}${align}`,
      };
    });

    return groupBy(callToActionsWithGroupKey, 'groupKey');
  };

  const renderBadges = () => {
    if (callToActions?.length === 0) return null;

    const callToActionsGrouped = groupCallToActionsByAlignment(callToActions);
    const displayTopRightGroup = callToActionsGrouped.topRight?.length > 0 || callToActionsGrouped.topLeft?.length > 0;
    const displayBottomLeftGroup = callToActionsGrouped.bottomLeft?.length > 0 || callToActionsGrouped.bottomRight?.length > 0;

    const renderCallToActions = (badges: CallToActionProps['callToAction'][]) => badges && badges.map((badge, i) => (
      <CallToAction
        key={i}
        callToAction={badge}
      />
    ));

    return (
      <div className={withCssModulesClassNamesHandler('product-card__badge-wrapper')}>
        <div className={withCssModulesClassNamesHandler('product-card__badge-wrapper-relative')}>
          {/* topRight and topLeft are grouped together to prevent UI elements from overlapping */}
          {displayTopRightGroup && (
            <div className={withCssModulesClassNamesHandler('product-card__badge-wrapper', 'top', 'right')}>
              {renderCallToActions(callToActionsGrouped.topRight)}
              {renderCallToActions(callToActionsGrouped.topLeft)}
            </div>
          )}
          {/* bottomLeft and bottomRight are grouped together to prevent UI elements from overlapping */}
          {displayBottomLeftGroup && (
            <div className={withCssModulesClassNamesHandler('product-card__badge-wrapper', 'bottom', 'left')}>
              {renderCallToActions(callToActionsGrouped.bottomRight)}
              {renderCallToActions(callToActionsGrouped.bottomLeft)}
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderAsset = (img: Asset) => {
    if (img.handle && img.width) {
      return (
        <AssetComponent
          maxWidth={productCardRef?.current?.offsetWidth}
          asset={img}
          alt={generateImageAltTag(item)}
        />
      );
    }

    return (
      // eslint-disable-next-line @next/next/no-img-element
      <img
        src={img.url}
        alt={generateImageAltTag(item)}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          transition: 'opacity 0.5s',
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          objectPosition: 'center',
        }}
      />
    );
  };

  const renderOverlayImages = () => images && images.map((img) => (
      <div
        key={img.handle || img.url}
        className={withCssModulesClassNamesHandler('product-card__image')}
        data-testid="overLayImageRenderer"
      >
        {renderAsset(img)}
      </div>
  ));

  const renderGalleryImages = () => images && images.map((img) => (
      <Slide
        data-testid="galleryImageRenderer"
        key={img.handle}
      >
        {renderAsset(img)}
      </Slide>
  ));

  const displaySlider = isMobile && source === 'plp' && activeLayout === 'SingleGrid';
  // eslint-disable-next-line react/display-name
  const Wrapper = displaySlider ? ({ children }: { children: React.ReactNode }) => (
    <SliderContainer
      arrows={false}
    >
      {children}
    </SliderContainer>
  ) : Fragment;

  return (
    <UniversalLinkContainer
      to={url}
      href={url}
      type="Page"
      aria-label={productName}
      tabIndex={tabIndex}
      className={withCssModulesClassNamesHandler(className, {
        'product-card__image-overlay': !displaySlider,
        'product-card__image-gallery': displaySlider,
      })}
      clickHandler={() => onProductCardClick('image')}
    >
      <Wrapper>
        {displaySlider
          ? renderGalleryImages()
          : renderOverlayImages()
        }
      </Wrapper>
      {renderBadges()}
      {salesCopy && (
        <div className={withCssModulesClassNamesHandler('product-card__sales-copy')}>
          <span className={withCssModulesClassNamesHandler('product-card__sales-copy--small')}>{salesCopy}</span>
        </div>
      )}
    </UniversalLinkContainer>
  );
};

export default ProductCardImage;

ProductCardImage.defaultProps = {
  className: '',
  salesCopy: null,
  tabIndex: undefined,
  callToActions: [],
  source: '',
};

ProductCardImage.propTypes = {
  activeLayout: PropTypes.string.isRequired,
  callToActions: PropTypes.array,
  className: PropTypes.string,
  images: PropTypes.array.isRequired,
  isMobile: PropTypes.bool.isRequired,
  item: PropTypes.object.isRequired,
  onProductCardClick: PropTypes.func.isRequired,
  productCardRef: PropTypes.object.isRequired,
  productName: PropTypes.string.isRequired,
  salesCopy: PropTypes.string,
  source: PropTypes.string,
  tabIndex: PropTypes.number,
  url: PropTypes.string.isRequired,
};
