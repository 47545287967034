const prefix = '[CAREERS]';

export const FETCH_JOBS = `${prefix} FETCH_JOBS`;
export const FETCH_JOBS__REQUEST = `${prefix} FETCH_JOBS__REQUEST`;
export const FETCH_JOBS__SUCCESS = `${prefix} FETCH_JOBS__SUCCESS`;
export const FETCH_JOBS__FAILURE = `${prefix} FETCH_JOBS__FAILURE`;

export const fetchJobs = () => ({
  type: FETCH_JOBS,
});
export const fetchJobsRequest = () => ({
  type: FETCH_JOBS__REQUEST,
});
export const fetchJobsSuccess = (payload) => ({
  type: FETCH_JOBS__SUCCESS,
  payload,
});
export const fetchJobsFailure = (error) => ({
  type: FETCH_JOBS__FAILURE,
  error,
});
