import { createSelector } from 'reselect';

import { getUserDetails } from './user.selectors';
import { getHrefLang } from './general.selectors';
import getCurrencyPrefix from '../../common/getCurrencyPrefix';

export const getIsCartDataPending = ({ cart }) => cart.isPending;
export const getCartError = ({ cart }) => cart.error;
export const getCartDetails = ({ cart }) => cart.data.details;
export const getAppliedCoupons = ({ cart }) => cart.data.details?.coupons;
export const getCartItems = ({ cart }) => cart?.data?.details?.items;
export const getIsItemModalVisible = ({ cart }) => cart.isItemEditModalVisible || false;

export const getBundleSaving = ({ cart }) => cart.data.details?.discounts?.bundleSaving;

export const getBundleSavingNumber = createSelector(
  getBundleSaving,
  getHrefLang,
  (bundleSaving, hrefLang) => (bundleSaving
    ? parseFloat(bundleSaving.replace(getCurrencyPrefix({ hrefLang }), '').replace('-', ''))
    : 0),
);

export const getCartHasItems = createSelector(
  getCartDetails,
  (cartDetails) => cartDetails && cartDetails.items && cartDetails.items.length >= 1,
);

export const getCartBaseAmountInt = createSelector(
  getCartDetails,
  getHrefLang,
  (cartDetails, hrefLang) => {
    const currencyPrefix = getCurrencyPrefix({ hrefLang });

    if (!cartDetails) return null;

    const { baseAmount } = cartDetails;

    if (!(typeof baseAmount === 'string' || baseAmount === null)) {
      return null;
    }

    if (baseAmount === null) {
      return 0;
    }

    return +(baseAmount.replace(currencyPrefix, '').replace(',', ''));
  },
);

export const getMSRPSubtotalNumber = createSelector(
  getCartDetails,
  getHrefLang,
  (cartDetails, hrefLang) => {
    const currencyPrefix = getCurrencyPrefix({ hrefLang });
    const MSRPSubtotal = cartDetails?.MSRPSubtotal;

    if (!MSRPSubtotal) {
      return 0;
    }

    return +(MSRPSubtotal.replace(currencyPrefix, '').replace(',', ''));
  },
);

export const graphCMSCouponPromotionLookup = ({ cart }) => cart.data.tieredCoupons;

export const getAutomaticallyAppliedPromotion = createSelector(
  graphCMSCouponPromotionLookup,
  (tieredCoupons) => tieredCoupons?.find(({ appliesAutomatically }) => appliesAutomatically),
);

/**
 * The active tiered sale is the tiered sale set up in GraphCMS
 */
export const getActiveTieredSale = createSelector(
  graphCMSCouponPromotionLookup,
  (tieredCoupons) => {
    if (!(Array.isArray(tieredCoupons) && tieredCoupons.length)) {
      return null;
    }
    const [coupon] = [...tieredCoupons].sort((a, b) => new Date(b.startDate) - new Date(a.startDate));
    return coupon;
  },
);

/**
 * The applied tiered sale is a tiered sale applied by a user and confirmed in BigCommerce.
 * It might be different than the tiered sale set up in GraphCMS, therefore it should be used in cart only.
 */
export const getAppliedTieredSale = createSelector(
  graphCMSCouponPromotionLookup,
  getAppliedCoupons,
  (tieredCoupons, appliedCoupons) => {
    if (!(Array.isArray(tieredCoupons) && tieredCoupons.length)) {
      return null;
    }
    const coupon = appliedCoupons?.[0];
    if (!coupon) {
      return null;
    }
    return tieredCoupons.find(({ couponCode }) => couponCode === coupon.code);
  },
);

export const getCurrentSalesTier = createSelector(
  getAppliedTieredSale,
  getMSRPSubtotalNumber,
  (appliedTieredSale, MSRPSubtotalNumber) => {
    if (!appliedTieredSale) {
      return null;
    }
    const { salesTiers } = appliedTieredSale;
    return salesTiers.find((salesTier, index, array) => {
      const nextCouponTier = array[index + 1];
      return MSRPSubtotalNumber >= salesTier.level
        && !(nextCouponTier && MSRPSubtotalNumber >= nextCouponTier.level);
    }) || null;
  },
);

export const getIsActiveTieredSalePromoCodeApplied = createSelector(
  getAppliedTieredSale,
  getAppliedCoupons,
  (appliedTieredSale, appliedCoupons) => {
    if (!Array.isArray(appliedTieredSale?.salesTiers)) {
      return false;
    }
    return Array.isArray(appliedCoupons) && appliedCoupons.some((coupon) => (
      appliedTieredSale.couponCode === coupon.code
    ));
  },
);

export const getNextSalesTier = createSelector(
  getAppliedTieredSale,
  getMSRPSubtotalNumber,
  getAutomaticallyAppliedPromotion,
  getIsActiveTieredSalePromoCodeApplied,
  getUserDetails,
  (
    appliedTieredSale,
    MSRPSubtotalNumber,
    automaticallyAppliedPromotion,
    isActiveTieredSalePromoCodeApplied,
    userDetails,
  ) => {
    if (appliedTieredSale) {
      const { salesTiers } = appliedTieredSale;
      const subtotalMatchingTier = salesTiers.find((salesTier) => MSRPSubtotalNumber < salesTier.level);

      if (subtotalMatchingTier) {
        return subtotalMatchingTier;
      }
    }

    // when the first auto applied tier is not yet achieved show it anyway as a "teaser"
    // but only for non-trade partners
    const firstAutoApplyTier = automaticallyAppliedPromotion?.salesTiers?.[0];
    if (!isActiveTieredSalePromoCodeApplied && firstAutoApplyTier && !userDetails?.tradePartner) {
      return firstAutoApplyTier;
    }

    return null;
  },
);

export const getIsCartSavedForLater = ({ cart }) => cart.data.savedForLater;

export const getSaveCartModalOpen = ({ cart }) => cart.data.saveCartModalOpen;
export const getSalesTiersModalOpen = ({ cart }) => cart.data.salesTiersModalOpen;
export const getIsPromoInputVisible = ({ cart }) => cart.isPromoInputVisible;

export const getIsThermometerVisible = createSelector(
  getCartHasItems,
  getAppliedCoupons,
  getAppliedTieredSale,
  getAutomaticallyAppliedPromotion,
  getUserDetails,
  (
    cartHasItems,
    appliedCoupons,
    appliedTieredSale,
    autoAppliedPromotion,
    userDetails,
  ) => {
    // hide thermometer for empty cart
    if (!cartHasItems) {
      return false;
    }

    // get manually entered coupon or automatically applied tiered promo
    const [activeCoupon] = appliedCoupons;

    // show thermometer if there are no active coupon but auto applied promotion is available.
    // Do not show the no base tier thermometer "teaser" for trade partners
    // since auto codes do not apply to them
    if (!activeCoupon && autoAppliedPromotion && !userDetails?.tradePartner) {
      return true;
    }

    // if automatically applied promotion is tiered - show thermometer
    return !!appliedTieredSale;
  },
);
