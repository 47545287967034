import { gql } from '@apollo/client/core';
import { captureException } from '@sentry/browser';
import { GetCustomerIdQueryArgs, SegmentProfileIdentity } from './Segment';
import RequestHelpers from '../../template/src/components/global/RequestHelpers';
import { EnrichEventPayload } from './Segment.helpers';

export interface GetCustomerIdResponse {
  getCustomerId: {
    userId?: string;
    anonymousId?: string;
    generatedUserId?: string;
    __typename: 'CustomerIdentity';
  };
}

export const queryCustomerId = async (payload: GetCustomerIdQueryArgs) => {
  const query = gql`
    query GetCustomerId($field: String!, $value: String!) {
      getCustomerId(field: $field, value: $value) {
        userId
        anonymousId
        generatedUserId
      }
    }
  `;

  return RequestHelpers.apolloGqlQuery<GetCustomerIdResponse>(query, payload)
    .then(({ getCustomerId: { __typename, ...identity } }) => identity)
    .catch((error) => {
      captureException(error);
      return {};
    });
};

type EventFields = keyof EnrichEventPayload;
export interface TrackOnServerParams {
  events: EnrichEventPayload[];
  include?: EventFields[];
}

export interface TrackOnServerResponseBase {
  status: 'ok';
}

export type TrackOnServerResponseExtended<T extends EventFields> = {
  sent: { [K in T]: EnrichEventPayload[K] }[];
} & TrackOnServerResponseBase;

export type TrackOnServerResponse<T extends TrackOnServerParams> = T['include'] extends EventFields[]
  ? T['include'] extends never[]
    ? TrackOnServerResponseBase
    : TrackOnServerResponseExtended<T['include'][number]>
  : TrackOnServerResponseBase;

export const trackOnServer = async <T extends TrackOnServerParams>({
  events,
  include,
}: T) => (
  RequestHelpers.fetchPost<TrackOnServerResponse<T>>({
    url: '/api/events',
    body: {
      events,
      include,
    },
  }).catch(() => null)
);

const customEventIdentitySynced = new CustomEvent('identity-synced');

export const identitySync = async (
  identity: SegmentProfileIdentity,
  prioritizeSession = false,
) => (
  RequestHelpers.fetchPost<SegmentProfileIdentity>({
    url: '/api/identity-sync',
    body: {
      identity,
      prioritizeSession,
    },
  }).then((data) => {
    window.dispatchEvent(customEventIdentitySynced);
    return data;
  }).catch(() => identity)
);
