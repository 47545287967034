import {
  call, put, select, takeEvery,
} from 'redux-saga/effects';
import { LOCATION_CHANGE, replace, push } from 'connected-react-router';

import {
  INJECT_SCRIPT,
  openCart as openCartAction,
  scriptInjected,
  SET_IS_MOBILE_MENU_OPEN,
  setIsFiltersPopoutOpen,
  setIsGoogleMapsActive,
  setIsMobileMenuOpen,
  setIsSearchDropdownOpen,
  PUSH,
  REPLACE,
  SET_IS_SEARCH_DROP_DOWN_OPEN,
  OPEN_CART,
} from '../actions/ui.actions';
import {
  getInjectedScripts,
  getIsCartOpen,
  getIsFiltersPopoutOpen,
  getIsMobileMenuOpen,
  getIsSearchDropdownOpen,
} from '../selectors/ui.selectors';
import { getIsUsLang } from '../selectors/general.selectors';

import { logError } from '../../../../utils/Utils';
import withLocalizationSagaDecorator from '../decorators/withLocalizationSaga.decorator';

export const injectScript = (src) => {
  const scriptElement = document.createElement('script');
  scriptElement.src = src;
  scriptElement.async = true;
  scriptElement.type = 'text/javascript';

  let resolveLoadPromise;
  const loadPromise = new Promise((resolve) => { resolveLoadPromise = resolve; });
  const onload = () => { resolveLoadPromise(); };

  scriptElement.onload = onload;
  document.getElementsByTagName('head')[0].appendChild(scriptElement);
  return loadPromise;
};

export function* injectScriptSaga({ payload: script }) {
  try {
    const injectedScripts = yield select(getInjectedScripts);
    if (
      !Array.isArray(injectedScripts)
      || injectedScripts.includes(script)
      || !(typeof document !== 'undefined' && document.createElement)
    ) {
      return;
    }
    switch (script) {
      case 'google-maps':
        yield call(injectScript, `https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_MAPS_KEY}&v=3&libraries=places,geometry`);
        yield put(setIsGoogleMapsActive(true));
        break;
      case 'affirm':
        if (window && window.injectAffirm) {
          const isUsLang = yield select(getIsUsLang);

          if (isUsLang) {
            yield call(window.injectAffirm);
          }
        }
        break;

      default:
        throw new Error(`no "${script}" script defined`);
    }
    yield put(scriptInjected(script));
  } catch (error) {
    yield call([this, logError], error, injectScriptSaga.name);
  }
}

export function* closeCartSagaOnLocationChange({ payload }) {
  const isCartOpen = yield select(getIsCartOpen);

  if (isCartOpen && ['POP', 'PUSH'].includes(payload.action)) {
    yield put(openCartAction(false));
  }
}

export function* closeFiltersPopout() {
  const isFiltersPopoutOpen = yield select(getIsFiltersPopoutOpen);

  if (isFiltersPopoutOpen) {
    yield put(setIsFiltersPopoutOpen(false));
  }
}

export function* closeCartSaga() {
  const isCartOpen = yield select(getIsCartOpen);
  const isMobileMenuOpen = yield select(getIsMobileMenuOpen);
  const isSearchDropdownOpen = yield select(getIsSearchDropdownOpen);

  if (isCartOpen && isMobileMenuOpen) {
    yield put(openCartAction(false));
    yield put(setIsMobileMenuOpen(false));
  }

  if (isCartOpen && isSearchDropdownOpen) {
    yield put(openCartAction(false));
    yield put(setIsSearchDropdownOpen(false));
  }
}

export function* openCartSaga() {
  const isCartOpen = yield select(getIsCartOpen);
  const isSearchDropdownOpen = yield select(getIsSearchDropdownOpen);
  if (isSearchDropdownOpen && isCartOpen) {
    yield put(openCartAction(true));
    yield put(setIsSearchDropdownOpen(false));
  }
}

export function* closeMobileMenu() {
  const isMobileMenuOpen = yield select(getIsMobileMenuOpen);
  const isCartOpen = yield select(getIsCartOpen);
  const isSearchDropdownOpen = yield select(getIsSearchDropdownOpen);

  if (isMobileMenuOpen && isCartOpen) {
    yield put(setIsMobileMenuOpen(false));
  }

  if (isMobileMenuOpen && isSearchDropdownOpen) {
    yield put(setIsMobileMenuOpen(false));
    yield put(setIsSearchDropdownOpen(true));
  }
}

export function* closeSearchDropdown() {
  const isSearchDropdownOpen = yield select(getIsSearchDropdownOpen);

  if (isSearchDropdownOpen) {
    yield put(setIsSearchDropdownOpen(false));
  }
}

export function* historyPushSaga({ payload: { url } }) {
  yield withLocalizationSagaDecorator({ url, method: push });
}

export function* historyReplaceSaga({ payload: { url } }) {
  yield withLocalizationSagaDecorator({ url, method: replace });
}

const uiSagas = [
  takeEvery(INJECT_SCRIPT, injectScriptSaga),
  takeEvery(LOCATION_CHANGE, closeCartSaga),
  takeEvery(LOCATION_CHANGE, closeFiltersPopout),
  takeEvery(LOCATION_CHANGE, closeCartSagaOnLocationChange),
  takeEvery(LOCATION_CHANGE, closeSearchDropdown),
  takeEvery(SET_IS_MOBILE_MENU_OPEN, closeCartSaga),
  takeEvery(SET_IS_MOBILE_MENU_OPEN, closeFiltersPopout),
  takeEvery(SET_IS_MOBILE_MENU_OPEN, closeSearchDropdown),
  takeEvery(SET_IS_SEARCH_DROP_DOWN_OPEN, closeMobileMenu),
  takeEvery(OPEN_CART, openCartSaga),
  takeEvery(PUSH, historyPushSaga),
  takeEvery(REPLACE, historyReplaceSaga),
];

export default uiSagas;
