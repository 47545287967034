import { filters as filtersGlobalAPI } from '../../../mock/globalAPI';
import { withCssModulesClassNames } from '../../../common/nextMigrationHelpers';
import { default as styles } from '../../../scss/components/FilterSectionHeader.module.scss';

const cssModulesClassNames = withCssModulesClassNames(styles);

interface FilterSectionHeaderProps {
  title: string;
  label: string;
  onClick: () => void;
  disabled?: boolean;
}

const FilterSectionHeader = ({
  title,
  label,
  onClick,
  disabled,
}: FilterSectionHeaderProps) => (
  <div className={cssModulesClassNames('filter-section__header')}>
    <h5>{title}</h5>
    <button
      type="button"
      aria-label={label}
      onClick={onClick}
      className={cssModulesClassNames({ disabled })}
    >
      {filtersGlobalAPI.clearButtonCopy}
    </button>
  </div>
);

export default FilterSectionHeader;
