import React from 'react';
import { withCssModulesClassNames } from '../../../common/nextMigrationHelpers';
import { default as styles } from '../../../scss/components/Header.module.scss';

const withCssModulesClassNamesHandler = withCssModulesClassNames(styles);

const FreeShippingIcon = () => (
  <div className={withCssModulesClassNamesHandler('free-shipping-icon')} role="img"/>
);

export default FreeShippingIcon;
