const prefix = '[CHECKOUT]';

export const FETCH_TERMINALS = `${prefix} FETCH_TERMINALS`;
export const FETCH_TERMINALS__REQUEST = `${prefix} FETCH_TERMINALS__REQUEST`;
export const FETCH_TERMINALS__SUCCESS = `${prefix} FETCH_TERMINALS__SUCCESS`;
export const FETCH_TERMINALS__FAILURE = `${prefix} FETCH_TERMINALS__FAILURE`;
export const CREATE_OR_UPDATE_CONSIGNMENT = `${prefix} CREATE_OR_UPDATE_CONSIGNMENT`;
export const CREATE_OR_UPDATE_CONSIGNMENT__REQUEST = `${prefix} CREATE_OR_UPDATE_CONSIGNMENT__REQUEST`;
export const CREATE_OR_UPDATE_CONSIGNMENT__SUCCESS = `${prefix} CREATE_OR_UPDATE_CONSIGNMENT__SUCCESS`;
export const CREATE_OR_UPDATE_CONSIGNMENT__FAILURE = `${prefix} CREATE_OR_UPDATE_CONSIGNMENT__FAILURE`;

export const FETCH_CONSIGNMENT = `${prefix} FETCH_CONSIGNMENT`;
export const FETCH_CONSIGNMENT__REQUEST = `${prefix} FETCH_CONSIGNMENT__REQUEST`;
export const FETCH_CONSIGNMENT__SUCCESS = `${prefix} FETCH_CONSIGNMENT__SUCCESS`;
export const FETCH_CONSIGNMENT__FAILURE = `${prefix} FETCH_CONSIGNMENT__FAILURE`;

export const SUBMIT_CHECKOUT = `${prefix} SUBMIT_CHECKOUT`;
export const SUBMIT_CHECKOUT__REQUEST = `${prefix} SUBMIT_CHECKOUT__REQUEST`;
export const SUBMIT_CHECKOUT__SUCCESS = `${prefix} SUBMIT_CHECKOUT__SUCCESS`;
export const SUBMIT_CHECKOUT__FAILURE = `${prefix} SUBMIT_CHECKOUT__FAILURE`;

export const RESET_IS_CHECKOUT_FETCHED = `${prefix} RESET_IS_CHECKOUT_FETCHED`;

export const RESEND_PAYMENT_LINK = `${prefix} RESEND_PAYMENT_LINK`;
export const RESEND_PAYMENT_LINK__REQUEST = `${prefix} RESEND_PAYMENT_LINK__REQUEST`;
export const RESEND_PAYMENT_LINK__SUCCESS = `${prefix} RESEND_PAYMENT_LINK__SUCCESS`;
export const RESEND_PAYMENT_LINK__FAILURE = `${prefix} RESEND_PAYMENT_LINK__FAILURE`;

export const fetchTerminals = () => ({
  type: FETCH_TERMINALS,
});

export const fetchTerminalsRequest = () => ({
  type: FETCH_TERMINALS__REQUEST,
});

export const fetchTerminalsSuccess = (payload) => ({
  type: FETCH_TERMINALS__SUCCESS,
  payload,
});

export const fetchTerminalsFailure = (error) => ({
  type: FETCH_TERMINALS__FAILURE,
  error,
});

export const submitCheckout = ({
  terminal,
  salesChannel,
  sendPayLink,
  paymentMethod,
}) => ({
  type: SUBMIT_CHECKOUT,
  salesChannel,
  terminal,
  paymentMethod,
  sendPayLink,
});

export const fetchConsignment = () => ({
  type: FETCH_CONSIGNMENT,
});

export const fetchConsignmentRequest = () => ({
  type: FETCH_CONSIGNMENT__REQUEST,
});

export const fetchConsignmentSuccess = (payload) => ({
  type: FETCH_CONSIGNMENT__SUCCESS,
  payload,
});

export const fetchConsignmentFailure = (error) => ({
  type: FETCH_CONSIGNMENT__FAILURE,
  error,
});

export const submitCheckoutRequest = () => ({
  type: SUBMIT_CHECKOUT__REQUEST,
});

export const submitCheckoutSuccess = (payload) => ({
  type: SUBMIT_CHECKOUT__SUCCESS,
  payload,
});

export const submitCheckoutFailure = (error) => ({
  type: SUBMIT_CHECKOUT__FAILURE,
  error,
});

export const createOrUpdateConsignment = (shippingAddress) => ({
  type: CREATE_OR_UPDATE_CONSIGNMENT,
  shippingAddress,
});

export const createOrUpdateConsignmentRequest = () => ({
  type: CREATE_OR_UPDATE_CONSIGNMENT__REQUEST,
});

export const createOrUpdateConsignmentSuccess = (payload) => ({
  type: CREATE_OR_UPDATE_CONSIGNMENT__SUCCESS,
  payload,
});

export const createOrUpdateConsignmentFailure = (error) => ({
  type: CREATE_OR_UPDATE_CONSIGNMENT__FAILURE,
  error,
});

export const resetIsCheckoutFetched = () => ({
  type: RESET_IS_CHECKOUT_FETCHED,
});

export const resendPaymentLink = (
  phone,
  email,
  id,
  total,
  textUpdates,
) => ({
  type: RESEND_PAYMENT_LINK,
  phone,
  email,
  id,
  total,
  textUpdates,
});

export const resendPaymentLinkRequest = () => ({
  type: RESEND_PAYMENT_LINK__REQUEST,
});

export const resendPaymentLinkSuccess = (payload) => ({
  type: RESEND_PAYMENT_LINK__SUCCESS,
  payload,
});

export const resendPaymentLinkFailure = (error) => ({
  type: RESEND_PAYMENT_LINK__FAILURE,
  error,
});
