import {
  CREATE_USER__FAILURE,
  CREATE_USER__REQUEST,
  CREATE_USER__SUCCESS,
  UPDATE_USER__FAILURE,
  UPDATE_USER__REQUEST,
  UPDATE_USER__SUCCESS,
  SIGN_IN_USER__FAILURE,
  SIGN_IN_USER__REQUEST,
  SIGN_IN_USER__SUCCESS,
  SIGN_OUT_USER__FAILURE,
  SIGN_OUT_USER__REQUEST,
  SIGN_OUT_USER__SUCCESS,
  FETCH_LOGGED_IN_USER__FAILURE,
  FETCH_LOGGED_IN_USER__REQUEST,
  FETCH_LOGGED_IN_USER__SUCCESS,
  SUBSCRIBE_TO_NEWSLETTER__REQUEST,
  SUBSCRIBE_TO_NEWSLETTER__SUCCESS,
  SUBSCRIBE_TO_NEWSLETTER__FAILURE,
  SET_CAN_UPDATE_PROFILE,
  SET_CAN_FORGET,
  FORGOT_PASSWORD__REQUEST,
  FORGOT_PASSWORD__FAILURE,
  FORGOT_PASSWORD__SUCCESS,
  SET_ANALYTICS_INFO,
} from '../actions/user.actions';

/** @type {UserState} */
export const initialState = {
  isPending: false,
  error: null,
  data: {
    details: {},
    isLoggedIn: false,
    canUpdateProfile: false,
    canForget: false,
    forgotPasswordFormMessage: null,
  },
  newsletter: {
    isPending: false,
    response: null,
    error: null,
  },
  analytics: {
    gaSessionID: null,
    gaSessionNumber: null,
    gaClientId: '',
    fbc: null,
    fbp: null,
  },
};

const createUserRequest = (state) => ({
  ...state,
  isPending: true,
  error: null,
});

const createUserSuccess = (state, payload) => ({
  ...state,
  isPending: false,
  error: null,
  data: {
    ...state.data,
    details: payload || {},
    isLoggedIn: true,
  },
});

const createUserFailure = (state, error) => ({
  ...state,
  isPending: false,
  error,
  data: {
    ...state.data,
    details: {},
    isLoggedIn: false,
  },
});

const updateUserRequest = (state) => ({
  ...state,
  isPending: true,
  error: null,
});

const updateUserSuccess = (state, payload) => ({
  ...state,
  isPending: false,
  error: null,
  data: {
    ...state.data,
    details: {
      ...state.data.details,
      ...payload,
    },
  },
});

const updateUserFailure = (state, error) => ({
  ...state,
  isPending: false,
  error,
  data: {
    ...state.data,
    details: {},
    isLoggedIn: false,
  },
});

const signInUserRequest = (state) => ({
  ...state,
  isPending: true,
  error: null,
});

const signInUserSuccess = (state, payload) => ({
  ...state,
  isPending: false,
  error: null,
  data: {
    ...state.data,
    details: payload || {},
    isLoggedIn: true,
  },
});

const signInUserFailure = (state, error) => ({
  ...state,
  isPending: false,
  error,
  data: {
    ...state.data,
    details: {},
    isLoggedIn: false,
  },
});

const signOutUserRequest = (state) => ({
  ...state,
  isPending: true,
  error: null,
});

const signOutUserSuccess = (state) => ({
  ...state,
  isPending: false,
  error: null,
  data: {
    ...state.data,
    details: {},
    isLoggedIn: false,
  },
});

const signOutUserFailure = (state, error) => ({
  ...state,
  isPending: false,
  error,
});

const fetchLoggedInUserRequest = (state) => ({
  ...state,
  isPending: true,
  error: null,
});

const fetchLoggedInUserSuccess = (state, payload) => ({
  ...state,
  isPending: false,
  error: null,
  data: {
    ...state.data,
    details: payload || {},
    isLoggedIn: !!payload,
  },
});

const fetchLoggedInUserFailure = (state, error) => ({
  ...state,
  isPending: false,
  error,
  data: {
    ...state.data,
    details: {},
    isLoggedIn: false,
  },
});

const subscribeToNewsletterRequest = (state) => ({
  ...state,
  newsletter: {
    isPending: true,
    response: null,
    error: null,
  },
});

const subscribeToNewsletterSuccess = (state, response) => ({
  ...state,
  newsletter: {
    response,
    isPending: false,
  },
});

const subscribeToNewsletterFailure = (state, error) => ({
  ...state,
  newsletter: {
    isPending: false,
    error,
  },
});

const setCanUpdateProfile = (state, payload) => ({
  ...state,
  data: {
    ...state.data,
    canUpdateProfile: payload,
  },
});

const setCanForget = (state, payload) => ({
  ...state,
  data: {
    ...state.data,
    canForget: payload,
  },
});

const forgotPasswordRequest = (state) => ({
  ...state,
  isPending: true,
  error: null,
  data: {
    ...state.data,
    forgotPasswordFormMessage: null,
  },
});

const forgotPasswordSuccess = (state, payload) => ({
  ...state,
  isPending: false,
  error: null,
  data: {
    ...state.data,
    forgotPasswordFormMessage: payload,
  },
});

const forgotPasswordFailure = (state, error) => ({
  ...state,
  isPending: false,
  error,
});

const setAnalyticsInfo = (state, payload) => ({
  ...state,
  analytics: {
    ...state.analytics,
    ...payload,
  },
});

const userReducer =  (state = initialState, action) => {
  switch (action.type) {
    case CREATE_USER__REQUEST:
      return createUserRequest(state);

    case CREATE_USER__SUCCESS:
      return createUserSuccess(state, action.payload);

    case CREATE_USER__FAILURE:
      return createUserFailure(state, action.error);

    case UPDATE_USER__REQUEST:
      return updateUserRequest(state);

    case UPDATE_USER__SUCCESS:
      return updateUserSuccess(state, action.payload);

    case UPDATE_USER__FAILURE:
      return updateUserFailure(state, action.error);

    case SIGN_IN_USER__REQUEST:
      return signInUserRequest(state);

    case SIGN_IN_USER__SUCCESS:
      return signInUserSuccess(state, action.payload);

    case SIGN_IN_USER__FAILURE:
      return signInUserFailure(state, action.error);

    case SIGN_OUT_USER__REQUEST:
      return signOutUserRequest(state);

    case SIGN_OUT_USER__SUCCESS:
      return signOutUserSuccess(state, action.payload);

    case SIGN_OUT_USER__FAILURE:
      return signOutUserFailure(state, action.error);

    case FETCH_LOGGED_IN_USER__REQUEST:
      return fetchLoggedInUserRequest(state);

    case FETCH_LOGGED_IN_USER__SUCCESS:
      return fetchLoggedInUserSuccess(state, action.payload);

    case FETCH_LOGGED_IN_USER__FAILURE:
      return fetchLoggedInUserFailure(state, action.error);

    case SUBSCRIBE_TO_NEWSLETTER__REQUEST:
      return subscribeToNewsletterRequest(state, action.payload);

    case SUBSCRIBE_TO_NEWSLETTER__SUCCESS:
      return subscribeToNewsletterSuccess(state, action.payload);

    case SUBSCRIBE_TO_NEWSLETTER__FAILURE:
      return subscribeToNewsletterFailure(state, action.error);

    case SET_CAN_UPDATE_PROFILE:
      return setCanUpdateProfile(state, action.payload);

    case SET_CAN_FORGET:
      return setCanForget(state, action.payload);

    case FORGOT_PASSWORD__REQUEST:
      return forgotPasswordRequest(state);

    case FORGOT_PASSWORD__SUCCESS:
      return forgotPasswordSuccess(state, action.payload);

    case FORGOT_PASSWORD__FAILURE:
      return forgotPasswordFailure(state, action.error);

    case SET_ANALYTICS_INFO:
      return setAnalyticsInfo(state, action.payload);

    default:
      return state;
  }
};

export default userReducer;
