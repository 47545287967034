import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { addToCart } from '../../../store/actions/cart.actions';
import { getAllOptions } from '../../shopping/AddToCart/common';
import { withCssModulesClassNames } from '../../../common/nextMigrationHelpers';
import { default as styles } from '../../../scss/components/RecommendedAddToCart.module.scss';

const withCssModulesClassNamesHandler = withCssModulesClassNames(styles);
export interface RecommendedAddToCartProps {
  recommendedProductInfo: {
    id: number;
    setProducts?: Record<string, unknown>[];
  };
}

const RecommendedAddToCart = ({
  recommendedProductInfo,
}: RecommendedAddToCartProps) => {
  const { setProducts, id } = recommendedProductInfo;
  const dispatch = useDispatch();
  const isSetProducts = (setProducts?.length ?? 0) > 0;
  const productActive = id || isSetProducts;
  const source = 'recommended-items';

  const allOptions = useMemo(
    () => getAllOptions(recommendedProductInfo, []),
    [recommendedProductInfo],
  );

  const handleRecommendedAddToCartClick = useCallback(() => {
    if (productActive) {
      const addToCartAction = addToCart(
        recommendedProductInfo,
        allOptions,
        1,
        source,
        [],
        false,
        1,
        [],
      );
      dispatch(addToCartAction);
    }
  }, [dispatch, productActive, recommendedProductInfo, allOptions, source]);

  return (
    <button
      className={withCssModulesClassNamesHandler('recommended__cart-item__button')}
      onClick={handleRecommendedAddToCartClick}
    >
      <span>Add</span>
    </button>
  );
};

export default RecommendedAddToCart;
