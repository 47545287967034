import { ModalDto } from './Modal.dto';
import { ModifierOptionDto } from './ModifierOption.dto';
import SharedOnSelectFunction from '../../../../../shared/onSelectFunction';

export enum ModifierRenderType {
  List = 'List',
  Quantity = 'Quantity',
  ColorPicker = 'ColorPicker',
  TypeAdjust = 'TypeAdjust',
  AddToCart = 'AddToCart',
  PickList = 'PickList',
  AddToCartModifier = 'AddToCartModifier',
  QuantitySet = 'QuantitySet',
}

export enum ModifierOperation {
  UpdateVariant = 'UpdateVariant',
  AttachAddonItem = 'AttachAddonItem',
  UpdateAddonItemVariant = 'UpdateAddonItemVariant',
  UpdateGalleryImage = 'UpdateGalleryImage',
}

export enum ModifierStyle {
  RadioDials = 'RadioDials',
  Buttons = 'Buttons',
  Checkbox = 'Checkbox',
  Icons = 'Icons',
  ColorButtons = 'ColorButtons',
  Counter = 'Counter',
}

export type OnSelectFunction = SharedOnSelectFunction;

export type ModifierActiveStateProp =
  | 'ActiveLegs'
  | 'ActiveArms'
  | 'ActiveColor'
  | 'ActiveSeat'
  | 'ActiveSize'
  | 'ActiveTufted'
  | 'ActiveLegStyle'
  | 'ActiveCushionColor'
  | 'ActiveTableFinish'
  | 'ActiveTableTop'
  | 'ActiveMaterial'
  | 'ActiveCoverOrientation';

/** @see Hygraph Schema > Enumerations > Seat Type */
export type SeatType =
  | 'AP'
  | 'APLG'
  | 'BN'
  | 'CNLG'
  | 'CR'
  | 'CRLG'
  | 'OT'
  | 'OTT'
  | 'SC'
  | 'SCC'
  | 'SCO'
  | 'SF'
  | 'SFT'
  | 'SL'
  | 'SLC'
  | 'SLCO'
  | 'SLO'
  | 'SLT'
  | 'SO'
  | 'SPLG'
  | 'ST'
  | 'SU'
  | 'TB';

export interface ProductModifierDto {
  id?: string;
  slug: string;
  order: number;
  selected?: boolean;
  showModifierValue: boolean | null;
  type: `${ModifierRenderType}` | null;
  modifierOperation: `${ModifierOperation}`;
  title: string;
  subTitle: string | null;
  optionValuesProp?: string | null;
  className: string | null;
  bcOptionDisplayName: string | null;
  modifierStyle: `${ModifierStyle}`;
  options: ModifierOptionDto[];
  requiredActiveModifierOptions: ModifierOptionDto[];
  maxQuantity?: number | null;
  modals?: ModalDto[] | null;
  shouldUpdateQueryParam: boolean | null;
  onSelectFunction: OnSelectFunction | null;
  activeStateProp: ModifierActiveStateProp | null;
}
