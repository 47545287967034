import { ProductDto } from '../../types/schema';
import Currency from '../../../../shared/currency';

// eslint-disable-next-line import/prefer-default-export
export const generateLdJson = ({
  productDetails: {
    name,
    metaImage: image,
    description,
    sku,
    pricing,
    bottomline,
    review,
  },
  isProductSoldOut,
  url,
}: {
  productDetails: Pick<
  ProductDto,
  | 'name'
  | 'metaImage'
  | 'description'
  | 'sku'
  | 'pricing'
  | 'bottomline'
  | 'review'
  >;
  isProductSoldOut: boolean;
  url: string;
}) => (
  JSON.stringify({
    '@context': 'http://schema.org/',
    '@type': 'Product',
    name,
    image,
    description,
    sku,
    brand: {
      '@type': 'Brand',
      name: 'Burrow',
    },
    offers: {
      '@type': 'Offer',
      url,
      priceCurrency: Currency.USD,
      availability: isProductSoldOut
        ? 'https://schema.org/OutOfStock'
        : 'https://schema.org/InStock',
      price: pricing ? pricing.price?.raw : '',
    },
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: bottomline?.averageScore,
      reviewCount: bottomline?.totalReview,
      bestRating: '5',
      worstRating: '1',
    },
    review,
  })
);
