import loadable from '@loadable/component';
import { Suspense } from 'react';
import dynamic from 'next/dynamic';

import LoadingContainer from './Loading';
import { ComponentType } from '../../types/schema';

const fallback = <LoadingContainer />;

export const componentMap = {
  [ComponentType.StoryCard]: 'global/StoryCard',
  [ComponentType.FeaturedProducts]: 'global/FeaturedProducts',
  [ComponentType.Welcome]: 'global/Welcome',
  [ComponentType.ShopByTheme]: 'global/ShopByTheme',
  [ComponentType.InfoHighlights]: 'global/InfoHighlights',
  [ComponentType.ShopBySpaces]: 'global/ShopBySpaces',
  [ComponentType.ProductCarousel]: 'shopping/ProductCarousel',
  [ComponentType.PLPHero]: 'global/PLPHero',
  [ComponentType.SalesTiers]: 'global/SalesTiers',
  [ComponentType.ProductCollection]: 'shopping/ProductComponentCollection',
  [ComponentType.SwatchesDropdown]: 'shopping/SwatchesDropdown',
  [ComponentType.FilterNav]: 'global/PageNavigation/FilterNav',
  [ComponentType.AnchorNav]: 'global/PageNavigation/AnchorNav',
  [ComponentType.PLPToolbar]: 'global/PageNavigation/PLPToolbar',
  [ComponentType.Countdown]: 'global/Countdown',
  [ComponentType.DesignFeatures]: 'global/DesignFeatures',
  [ComponentType.SelectedReviews]: 'global/SelectedReviews',
  [ComponentType.ThreePointBox]: 'global/ThreePointBox',
  [ComponentType.TypeformWidget]: 'global/TypeformWidget',
  [ComponentType.Values]: 'global/Values',
  [ComponentType.MaxWidthCard]: 'global/MaxWidthCard',
  [ComponentType.Testimonials]: 'global/Testimonials',
  [ComponentType.Accordion]: 'global/Accordion',
  [ComponentType.YoutubeVideo]: 'global/YoutubeVideo',
  [ComponentType.ProductCollections]: 'global/ProductCollections',
  [ComponentType.ProductCategoryCard]: 'global/ProductCategoryCard',
  [ComponentType.DownloadDropdown]: 'global/DownloadDropdown',
  [ComponentType.HeroGeneral]: 'global/HeroGeneral',
  [ComponentType.OurApproach]: 'global/OurApproach',
  [ComponentType.KeyFeatures]: 'global/KeyFeatures',
  [ComponentType.Details]: 'global/Details',
  [ComponentType.FullWidthVideo]: 'global/FullWidthVideo',
  [ComponentType.NarrowImages]: 'global/NarrowImages',
  [ComponentType.Images]: 'global/Images',
  [ComponentType.Card]: 'global/Card',
  [ComponentType.PlainText]: 'global/PlainText',
  [ComponentType.SpotlightCard]: 'global/SpotlightCard',
  [ComponentType.Margin]: 'global/Margin',
  [ComponentType.ContentBreak]: 'global/ContentBreak',
  [ComponentType.Dimensions]: 'global/Dimensions',
  [ComponentType.CollectionHero]: 'shopping/CollectionHero',
  [ComponentType.RichText]: 'global/RichText',
  [ComponentType.WideStoryCard]: 'global/WideStoryCard',
  [ComponentType.NarrowStoryCard]: 'global/NarrowStoryCard',
  [ComponentType.SiteMap]: 'global/Sitemap',
  [ComponentType.Newsletter]: 'global/Newsletter',
  [ComponentType.NewsletterEmail]: 'global/Newsletter',
  [ComponentType.RecruiteeJobs]: 'global/RecruiteeJobs',
  [ComponentType.InfoCard]: 'global/WideStoryCard',
  [ComponentType.Reviews]: 'shopping/ReviewsWidget',
  [ComponentType.ProductAttributeSet]: 'global/ProductAttributeSet',
  [ComponentType.Footer]: 'global/footer/Footer',
  [ComponentType.CompareCollection]: 'global/CompareCollectionCard',
  // not exists in Hygraph
  Header: 'global/header/Header',
  Filters: 'global/Filters/Filters',
  CartPopout: 'global/Cart/CartPopout',
  SaveCartModal: 'shopping/SaveCartModal',
  SalesTiersModal: 'shopping/SalesTiersModal',
  FiltersPopout: 'global/Filters/FiltersPopout',
  SearchDropdown: 'global/Search/SearchDropdown',
} as const;

type ComponentMapKeys = keyof typeof componentMap | `${ComponentType}`;
export type ComponentProps = {
  [K in ComponentMapKeys]: Record<string, unknown>;
} & {
  Header: import('./header/Header').HeaderProps;
  Footer: import('./footer/Footer').FooterProps;
  Filters: import('./Filters/Filters').FiltersProps;
  [ComponentType.AnchorNav]: import('./PageNavigation/AnchorNav').AnchorNavProps;
};

export const loadLoadableAsyncComponent = <K extends ComponentMapKeys>(componentName: K) => {
  if (componentName in componentMap) {
    return loadable<ComponentProps[K]>(
      () => import(`../${componentMap[componentName as keyof typeof componentMap]}`),
      { fallback },
    );
  }

  throw new Error(`Component ${componentName} not found`);
};

export const loadNextAsyncComponent = <K extends ComponentMapKeys>(componentName: K) => {
  if (componentName in componentMap) {
    const Component = dynamic<ComponentProps[K]>(
      () => import(`../${componentMap[componentName as keyof typeof componentMap]}`),
      { loading: () => fallback },
    );
    return function DynamicComponent(props: ComponentProps[K]) {
      return (
        <Suspense fallback={fallback}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Component {...props} />
        </Suspense>
      );
    };
  }

  throw new Error(`Component ${componentName} not found`);
};

export default componentMap;
