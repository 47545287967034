import React, {
  useRef, useEffect, useState, ReactNode,
} from 'react';
import classNames from 'classnames';
import kebabCase from 'lodash/kebabCase';
import throttle from 'lodash/throttle';
import toLower from 'lodash/toLower';

import Asset from './Asset';
import ParsedMarkdown from './ParsedMarkdown';
import { getContainerStyles } from '../../../../utils/Utils';
import ColorDto from '../../../../shared/color.dto';
import UniversalLinkContainer from './UniversalLink';

import { default as styles } from '../../scss/components/Images.module.scss';
import { default as headerStyles } from '../../scss/components/Header.module.scss';
import { Point, PointLink } from './header/header.types';

export const variants = ['DEFAULT', 'NARROW'] as const;

export type ImagesPoint = {
  pointTitle: Point['header'],
  forcePointTitle: boolean,
  links: Array<PointLink | {
    clickHandler: () => void,
    className: string,
    children: ReactNode,
    label: string,
    role: string,
    to: string,
    type: string,
    text: string,
    target: string,
    anchor: string,
    href: string,
    destinationPage: {
      url: string,
    },
    hrefLang: string,
  }>,
};

export interface ImagesProps {
  header?: string;
  points: Array<Point & ImagesPoint>;
  className: string;
  ancestorImitatesLink: boolean;
  backgroundColor?: ColorDto;
  variant?: typeof variants[number];
}

const Images = ({
  header,
  points,
  className,
  backgroundColor,
  ancestorImitatesLink,
  variant = 'DEFAULT',
}: ImagesProps) => {
  const imageTileRefs = useRef<HTMLDivElement[]>([]);
  const [tileWidths, setTileWidths] = useState<number[]>([]);

  const applyAllClassNames = (args: string[]) => {
    const ret = [];
    for (const cn of args) {
      if (cn) {
        ret.push(cn);
      }
      if (typeof cn === 'string') {
        if (styles[cn]) {
          ret.push(styles[cn]);
        }
        if (headerStyles[cn]) {
          ret.push(headerStyles[cn]);
        }
      }
    }
    return ret;
  };

  const getPointsClassNames = (quantity: number, _variant: typeof variants[number]) => {
    let classes = [];
    switch (quantity) {
      case 1:
        classes = ['1-col-grid']; break;
      case 2:
        classes = ['1-col-grid', '2-col-grid-tablet']; break;
      case 3:
        classes = ['1-col-grid', '3-col-grid-tablet']; break;
      case 4:
      case 5:
      case 6:
        classes = ['2-col-grid', 'auto-fit-grid-tablet']; break;
      default:
        classes = ['2-col-grid', '4-col-grid-tablet', _variant === variants[0] && 'auto-fit-grid-desktop'];
    }

    return classes.map((classNameModifier) => classNames(...applyAllClassNames([`images__points--${classNameModifier}`])));
  };

  const renderPoint = (point: Point & ImagesPoint, i: number) => {
    const {
      images,
      aspectRatios,
      align,
      subHeader,
      copy,
      markdownCopy,
      headerSize,
      bodyFontSize,
      links: [link] = [],
      header: pointHeader,
      verticalAlign = 'middle',
      pointTitle,
      forcePointTitle,
    } = point;

    const headerSizeClassName = headerSize ? toLower(headerSize) : null;
    const bodyFontSizeClassName = `images__point-copy--${bodyFontSize ? toLower(bodyFontSize) : 'm'}`;

    const shouldRenderAsLink = ancestorImitatesLink && link;

    const wrapperContent = (
      <>
        {pointTitle || forcePointTitle ? (
          <div
            className={classNames(...applyAllClassNames(['images__point-title']))}
          >
            {pointTitle && <h2>{pointTitle}</h2>}
          </div>
        ) : null}
        {images.length ? (
          <div
            ref={(ref) => {
              if (ref) {
                imageTileRefs.current[i] = ref;
              }
            }}
            className={classNames(...applyAllClassNames([
              'images__point-asset',
              (typeof verticalAlign === 'string' && verticalAlign.toLowerCase()) || '',
            ].concat(aspectRatios)))}
          >
            <Asset
              asset={images[0]}
              imageClassName={classNames(...applyAllClassNames(['graphcms-image-wrapper']))}
              outerWrapperClassName={classNames(...applyAllClassNames(['graphcms-image-outer-wrapper']))}
              maxWidth={Math.ceil(tileWidths[i])}
            />
          </div>
        ) : null}
        <div
          className={classNames(
            ...applyAllClassNames([
              'images__point-description',
              (typeof verticalAlign === 'string' && verticalAlign.toLowerCase()) || '',
            ]),
            {
              'images__point-description--center': align === 'Center',
              'images__point-description--right': align === 'Right',
              [styles['images__point-description--center']]: align === 'Center',
              [styles['images__point-description--right']]: align === 'Right',
              [headerStyles['images__point-description--center']]: align === 'Center',
              [headerStyles['images__point-description--right']]: align === 'Right',
            },
          )}
        >
          {pointHeader && (
            <h3
              className={classNames(...applyAllClassNames(['images__point-header', headerSizeClassName || '']))}
            >
              {pointHeader}
            </h3>
          )}
          {subHeader && (
            <div
              className={classNames(...applyAllClassNames(['images__point-subheader']))}
            >
              <h4>{subHeader}</h4>
            </div>
          )}
          {markdownCopy && (
            <ParsedMarkdown
              options={{ forceBlock: true }}
              className={classNames(...applyAllClassNames(['images__point-copy', bodyFontSizeClassName]))}
            >
              {markdownCopy}
            </ParsedMarkdown>
          )}
          {!markdownCopy && copy && (
            <div
              className={classNames(...applyAllClassNames(['images__point-copy', bodyFontSizeClassName]))}
            >
              <p>{copy}</p>
            </div>
          )}
          {link && ancestorImitatesLink && (
            <span
              className={classNames(...applyAllClassNames(['images__point-link', link.className || 'body-medium heavy']))}
            >
              {link.text}
            </span>
          )}
          {link && !ancestorImitatesLink && (
            <UniversalLinkContainer
              {...link}
              className={classNames(...applyAllClassNames(['images__point-link', link.className || 'body-medium heavy']))}
            >
              {link.text}
            </UniversalLinkContainer>
          )}
        </div>
      </>
    );

    if (shouldRenderAsLink) {
      return (
        <UniversalLinkContainer
          {...link}
          key={i}
          data-testid={pointHeader}
          className={classNames(...applyAllClassNames(['images__point']))}
        >
          {wrapperContent}
        </UniversalLinkContainer>
      );
    }

    return (
      <div
        key={i}
        data-testid={pointHeader}
        className={classNames(...applyAllClassNames(['images__point']))}
      >
        {wrapperContent}
      </div>
    );
  };

  useEffect(() => {
    const updateTileSizeThrottled = throttle(() => {
      const newTileWidths = imageTileRefs.current.map((imageTileRef) => {
        const width = imageTileRef?.getBoundingClientRect()?.width;

        return width || 0;
      });

      setTileWidths(newTileWidths);
    }, 500, { leading: false });

    window.addEventListener('resize', updateTileSizeThrottled);

    return () => {
      window.removeEventListener('resize', updateTileSizeThrottled);
    };
  }, []);

  return (
    <div
      className={classNames(applyAllClassNames(['images', className]))}
      style={getContainerStyles(backgroundColor)}
    >
      <div
        className={classNames(applyAllClassNames(['images__inner-wrapper']))}
      >
        {header && (
          <div
            className={classNames(applyAllClassNames(['images__header-wrapper']))}
          >
            <h2
              className={classNames(applyAllClassNames([
                'images__header',
                variant && `images__header--${kebabCase(variant)}`,
              ]))}
              aria-label={header}
            >
              {header}
            </h2>
          </div>
        )}
        {points?.length ? (
          <div className={classNames(...applyAllClassNames(['images__points-wrapper']))}>
            <div
              className={classNames(...applyAllClassNames([
                'images__points',
                variant && `images__points--${kebabCase(variant)}`,
                ...getPointsClassNames(points.length, variant),
              ]))}
            >
              {points.map(renderPoint)}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Images;
