import HrefLang from '../../../shared/localization/hrefLang';
import Currency from '../../../shared/currency';

const getCurrencyCode = (hrefLang: HrefLang) => {
  switch (hrefLang) {
    case HrefLang.EN_US:
      return Currency.USD;
    case HrefLang.EN_CA:
      return Currency.CAD;
    default:
      // eslint-disable-next-line no-case-declarations
      const exhaustiveCheck: never = hrefLang;
      throw new Error(`Unsupported hrefLang: ${exhaustiveCheck}`);
  }
};

export default getCurrencyCode;
