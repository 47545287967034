/* eslint-disable no-underscore-dangle */
import * as Sentry from '@sentry/react';
import React from 'react';
import { hydrate } from 'react-dom';
import { createRoot } from 'react-dom/client';
import { matchPath } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import { Provider, ReactReduxContext } from 'react-redux';
import { loadableReady } from '@loadable/component';
import { ConnectedRouter } from 'connected-react-router';
import smoothscroll from 'smoothscroll-polyfill';
import { ignoreErrors as ignoreErrorsGlobalAPI, blacklistUrls as blacklistUrlsGlobalAPI } from './mock/globalAPI';

import configureStore from './store/store';
import routes from './routes';

window.process = { browser: true, env: { ENVIRONMENT: 'BROWSER' } };

smoothscroll.polyfill();

const { store, history } = configureStore(window.__PRELOADED_STATE__);

const { general: { env :{ SENTRY_ENVIRONMENT } } } = store.getState();

if (process.env.SENTRY_DSN_FRONTEND) {
  const SENTRY_ENV = SENTRY_ENVIRONMENT || process.env.SENTRY_ENVIRONMENT || 'development';

  Sentry.init({
    dsn: process.env.SENTRY_DSN_FRONTEND,
    environment: SENTRY_ENV,
    enableTracing: true,
    tracesSampleRate: SENTRY_ENV === 'prod-production-main' ? 0.1 : 0.7,
    ignoreErrors: ignoreErrorsGlobalAPI,
    denyUrls: blacklistUrlsGlobalAPI,
    release: process.env.SENTRY_RELEASE,
    normalizeDepth: 10, // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/redux/#normalization-depth
    integrations: [
      Sentry.browserTracingIntegration({
        enableInp: true,
      }),
      Sentry.browserProfilingIntegration(),
      Sentry.reactRouterV5BrowserTracingIntegration({
        history,
        matchPath,
        routes: [...routes[0].routes].sort((a, b) => b.path.length - a.path.length).map(({ path }) => ({ path })),
      }),
    ],
  });
}

const app =  (
  <Provider store={store}>
    <ConnectedRouter history={history} context={ReactReduxContext}>
      {renderRoutes(routes)}
    </ConnectedRouter>
  </Provider>
);
const container = document.getElementById('main');
const root = createRoot(container);

loadableReady(() => {
  if (process.env.NODE_ENV === 'production') {
    hydrate(app, container);
    return;
  }

  root.render(app);
});
