import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { getIsUserTradePartner } from '../../../store/selectors/user.selectors';
import { getAppliedTieredSale } from '../../../store/selectors/cart.selectors';
import { setSalesTiersModalOpen } from '../../../store/actions/cart.actions';
import Analytics from '../../global/Analytics';

import { cart as cartGlobalAPI } from '../../../mock/globalAPI';

const PromoDisplay = ({
  coupons,
  applyAutomaticDiscount,
  removeDiscount,
  discounts,
  calculating,
  automaticallyAppliedPromotion,
}) => {
  const dispatch = useDispatch();
  const appliedTieredSale = useSelector(getAppliedTieredSale);
  const isUserTradePartner = useSelector(getIsUserTradePartner);

  const getActiveCouponCode = () => coupons?.[0]?.code || null;

  useEffect(() => {
    const activeCouponCode = getActiveCouponCode();
    if (!activeCouponCode) {
      applyAutomaticDiscount();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const track = (properties) => {
    Analytics.enqueue({
      method: 'track',
      params: {
        event: 'Promo Input',
        properties,
      },
    });
  };

  const handleActivePromoCodeClick = () => {
    const activeCouponCode = getActiveCouponCode();

    if (activeCouponCode) {
      removeDiscount(activeCouponCode);
      track({ action: 'Code Removed', code: activeCouponCode });
    }
  };

  const renderActivePromoCode = (activeCouponCode) => {
    const discount = discounts?.coupon ? `${discounts?.coupon}` : '';
    const isCurrentCodeRemovable = isUserTradePartner || automaticallyAppliedPromotion?.couponCode !== activeCouponCode;

    return (
      <div className="cart-footer__item cart-footer__item-promo-code">
        <span>
          <span>{`${cartGlobalAPI.valid_promo}`}</span>
          <span> – </span>
          {appliedTieredSale ? (
            <button
              data-testid="sales-tier-applied-button"
              className="code code-clickable"
              onClick={() => dispatch(setSalesTiersModalOpen(true))}
            >
              {activeCouponCode}
            </button>
          ) : (
            <span className="code">{activeCouponCode}</span>
          )}
          {isCurrentCodeRemovable && (
            <button
              className={classNames('remove-code', { disabled: calculating })}
              onClick={handleActivePromoCodeClick}
              disabled={calculating}
            >
              Remove
            </button>
          )}
        </span>
        {discount !==  '-$0.00' ? (
          <span className="amount">
            {discount}
          </span>
        ) : null}
      </div>
    );
  };

  const activeCouponCode = getActiveCouponCode();
  return (
    <div className="promo">
      {activeCouponCode && renderActivePromoCode(activeCouponCode)}
    </div>
  );
};

PromoDisplay.defaultProps = {
  coupons: [],
};

PromoDisplay.propTypes = {
  coupons: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    code: PropTypes.string,
    name: PropTypes.string,
    couponType: PropTypes.string,
    discountedAmount: PropTypes.string,
    __typename: PropTypes.string,
  })),
  applyAutomaticDiscount: PropTypes.func,
  removeDiscount: PropTypes.func,
  discounts : PropTypes.shape({
    cartLevel: PropTypes.string,
    bundleSaving: PropTypes.string,
    coupon: PropTypes.string,
    __typename: PropTypes.string,
  }),
  calculating: PropTypes.bool,
  automaticallyAppliedPromotion: PropTypes.bool,
};

export default PromoDisplay;
