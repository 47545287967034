enum NavigationTiers {
  Shopping = 'Shopping',
  Utility = 'Utility',
  FooterSocial = 'FooterSocial',
  FooterColumns = 'FooterColumns',
  FooterCopyright = 'FooterCopyright',
  Locale = 'Locale',
}

export default NavigationTiers;
