import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';
import {
  gql,
} from '@apollo/client/core';

import { replace } from 'connected-react-router';
import RequestHelpers from '../../components/global/RequestHelpers';
import {
  FETCH_PAGE_CONTENT,
  fetchPageContentFailure,
  fetchPageContentRequest,
  fetchPageContentSuccess,
} from '../actions/page.actions';
import { getPageQuery } from '../../../../apis/QueryBuilder';
import { setErrorPopupMessage, push } from '../actions/ui.actions';
import { logError } from '../../../../utils/Utils';
import { getQueryParams, getQueryParamsAsString } from '../selectors/router.selectors';
import { getIsUsLang } from '../selectors/general.selectors';
import paths from '../../paths';
import { handleRefreshAffirm } from '../../components/global/ProductHelpers';

export function* fetchPageContent({ pathname }) {
  yield put(fetchPageContentRequest());
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });

  try {
    if (pathname === paths.NOT_FOUND) {
      return;
    }
    const { sku, withFilters } = yield select(getQueryParams);
    const queryParams = yield select(getQueryParamsAsString);
    const query = gql`query GetCMSPage {
        getCMSPage(
          url: "${pathname}"
          ${sku ? `, sku: "${sku}"` : ''}
          ${withFilters ? `, query: "${queryParams}"` : ''}
        ) {
          ${getPageQuery(true)}
        }
    }`;
    const { getCMSPage: payload } = yield call(() => RequestHelpers.apolloGqlQuery(query));
    if (!payload) {
      yield put(fetchPageContentFailure(new Error('Page not found')));
      yield put(push(paths.NOT_FOUND));
      return;
    }

    if (payload.url !== pathname) {
      yield put(replace(payload.url));
    }

    yield put(fetchPageContentSuccess(payload));
    const isUsLang = yield select(getIsUsLang);

    if (isUsLang) {
      yield call(handleRefreshAffirm, payload);
    }
  } catch (error) {
    yield call([this, logError], error, fetchPageContent.name);
    if (RequestHelpers.permissibleErrors[error.message]) {
      yield put(setErrorPopupMessage(RequestHelpers.permissibleErrors[error.message]));
    }
    yield put(fetchPageContentFailure(error));
    yield put(push(paths.NOT_FOUND));
  }
}

const pageSagas = [
  takeLatest(FETCH_PAGE_CONTENT, fetchPageContent),
];

export default pageSagas;
