enum PageType {
  PLP = 'PLP',
  PDP = 'PDP',
  Home = 'Home',
  Landing = 'Landing',
  Podcast = 'Podcast',
  Showrooms = 'Showrooms',
}

export default PageType;
