import React, { Component } from 'react';
import { withFormsy } from 'formsy-react';
import PropTypes from 'prop-types';

import { AssetProps } from '../Helpers';
import { Checkbox } from './Checkbox';
import Asset from '../Asset';

export class CollectionPickerCheckbox extends Component {
  render() {
    const {
      name,
      label,
      setValue,
      onChange,
      asset,
      checked,
      value,
      id,
      description,
      disabled,
    } = this.props;

    return (
      <label className="collection-picker" htmlFor={name}>
        <Asset maxWidth={125} asset={asset} />
        <div className="collection-picker-wrapper-inner">
          <Checkbox
            name={name}
            onChange={onChange}
            setValue={setValue}
            checked={checked}
            id={id}
            value={value}
            withinAnExternalLabel
            disabled={disabled}
          />
          <span className="collection-picker-wrapper-inner__label">{label}</span>
          <span className="collection-picker-wrapper-inner__description">
            {description}
          </span>
        </div>
      </label>
    );
  }
}

CollectionPickerCheckbox.propTypes = {
  asset: AssetProps.isRequired,
  checked: PropTypes.bool,
  description: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  setValue: PropTypes.func.isRequired,
  value: PropTypes.bool,
};

export default withFormsy(CollectionPickerCheckbox);
