import React from 'react';
import Markdown, { MarkdownToJSX } from 'markdown-to-jsx';

import { parseMarkdownLineBreaks } from '../../../../utils/Utils';

export interface ParsedMarkdownProps {
  children: string;
  options: MarkdownToJSX.Options;
  className?: string;
}

const ParsedMarkdown = (props: ParsedMarkdownProps) => {
  const { children: markdown, ...restOfProps } = props;
  const parsedMarkdown = parseMarkdownLineBreaks(markdown);
  return (
    <Markdown {...restOfProps}>
      {parsedMarkdown || ''}
    </Markdown>
  );
};

export default ParsedMarkdown;
