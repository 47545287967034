function generateSingleAddToCartLineItem(quantity, id, optionValues, quantityMultiplier = 1) {
  const itemQuantity = quantity * quantityMultiplier;
  const formattedOptions = optionValues.map(({ optionId, optionValue }) => `{ optionId: ${optionId}, optionValue: "${optionValue}" }`);
  return `{ quantity: ${itemQuantity}, productId: ${id}, optionSelections: [${formattedOptions.join(', ')}] }`;
}

function generateMultipleAddToCartLineItemsFromAddOnItems(addOnItems, quantityMultiplier) {
  return addOnItems
    .reduce((allAdditionalProducts, addOnItem) => {
      // "None" modifier option does not have a product
      if (addOnItem.product) {
        /**
         * Multiplication by parent quantity should be prevented if user selected more than 1 add-on.
         * It can happen when an add-on is added with:
         *  - A Counter modifier. We want to multiply by parent quantity if user select 1 add-on, and prevent multiplication if more.
         *    -> selectedCount > 1 check
         *  - An AddToCart modifier that has multiple options that differ in quantity, i.e. side table add-on with one or two side tables options.
         *    Single option modifiers define sets and we want to multiply them, i.e. nomad sofa set of lumbar pillows - that's why we're checking if it's not an only option.
         *    We want to multiply by parent if user select a single add-on, and prevent multiplication if he/she select two add-ons.
         *    -> !isOnlyOption && addOnItem.quantity > 1 check
         * Also check https://hiburrow.atlassian.net/browse/BTR-1050
         */
        const { selectedCount, isOnlyOption } = addOnItem;
        const addOnQuantity = selectedCount ?? addOnItem.quantity;
        const preventMultiplicationByProductQuantity = selectedCount > 1 || (!isOnlyOption && addOnItem.quantity > 1);
        const addOnQuantityMultiplier = preventMultiplicationByProductQuantity ? 1 : quantityMultiplier;

        allAdditionalProducts.push(generateSingleAddToCartLineItem(
          addOnQuantity,
          addOnItem.product.id,
          addOnItem.product.optionValues,
          addOnQuantityMultiplier,
        ));
      }

      return allAdditionalProducts;
    }, []);
}

function generateMultipleAddToCartLineItemsFromSetProducts(
  item,
  quantityMultiplier = 1,
) {
  const { setProducts } = item;
  const items = setProducts.map((setProduct) => generateSingleAddToCartLineItem(
    setProduct.quantity,
    setProduct.product.productID,
    setProduct.product.optionValues,
    quantityMultiplier,
  ));

  return `[${items.join(', ')}]`;
}

export function generateMultipleAddToCartLineItemsFromArray(
  items,
  quantityMultiplier = 1,
) {
  const lineItems = items.map(({ productID, optionValues }) => (
    generateSingleAddToCartLineItem(
      1,
      productID,
      optionValues,
      quantityMultiplier,
    )
  ));

  return `[${lineItems.join(', ')}]`;
}

/**
 * Generate an Add To Cart Line Items
 * Creates an array of line items depending upon if an item is a variant or a product.
 * If it is a variant then we need to send back a variantId along with the other lineItem attributes.
 * @param item
 * @param allOptions
 * @param quantity
 * @param addOnItems
 * @param quantityMultiplier
 */
function generateMultipleAddToCartLineItems(item, allOptions, quantity, addOnItems = [], quantityMultiplier = 1) {
  // FIXME: This should actually take multiple items (not as just addOnItems) and generate them into correct syntax.
  const productID = item.productID || item.id;
  let additionalProducts = '';
  // If there's any add-on items then we want to
  // generate line item for them.
  if (addOnItems && addOnItems.length > 0) {
    additionalProducts = generateMultipleAddToCartLineItemsFromAddOnItems(addOnItems, quantityMultiplier);
  }

  if (item.setProducts?.length) {
    return generateMultipleAddToCartLineItemsFromSetProducts(item, quantityMultiplier);
  }

  const baseProduct = generateSingleAddToCartLineItem(quantity, productID, allOptions);

  return `[${[baseProduct, ...additionalProducts].join(', ')}]`;
}

/**
 * Generate an Update Cart query
 * Creates an update cart query that generates a line item for one of the situations:
 * either we're updating an item or removing an item.
 * @param item
 * @param sign
 */
function generateUpdateCartQuery(item, sign) {
  const finalQuantity = sign ? item.quantity + sign : sign;
  let query;
  if (finalQuantity) {
    const lineItem = `
        {
          quantity: ${finalQuantity},
          productId: ${item.productId},
          variantId: ${item.variantId},
        }
      `;
    query = `updateItem(itemId: "${item.id}", lineItem: ${lineItem})`;
  } else {
    query = `removeItem(itemId: "${item.id}")`;
  }
  return query;
}

function getCartLabel(source) {
  let label;
  switch (source) {
    case 'cart': {
      label = 'Added from cart recommendation';
      break;
    }
    case 'pdp': {
      label = 'Added from PDP';
      break;
    }
    case 'product-card': {
      label = 'Added from product card';
      break;
    }
    case 'product-preview': {
      label = 'Added from PDP product preview';
      break;
    }
    case 'recommended-items': {
      label = 'Added from recommendation items list';
      break;
    }
    default:
      break;
  }
  return label;
}

export function getCartDetailsFromState(state) {
  const cartDetails = state.cart?.data?.details?.items ?? {};
  const recommendedItems = state.cart?.data?.details?.recommendedItems ?? [];

  return {
    cartDetails,
    recommendedItems,
  };
}

/**
 * @typedef {import('../../../../Cart/CartLineItem').default} CartLineItem
 * @typedef {object} ProductsForAnalytics
 * @property {string} imageUrl
 * @property {string} category
 * @property {string} name
 * @property {number} price
 * @property {string} product_id
 * @property {string} sku
 * @property {string} url
 * @property {number} quantity
 */

/**
 * @param {{ items: CartLineItem[] }} cartDetails
 * @returns {ProductsForAnalytics[]}
 */
function generateProductsForAnalytics(cartDetails) {
  return cartDetails.items.map(({
    productCardImage: { url: imageUrl = '' } = {},
    category,
    name,
    listPrice,
    sku,
    seo: { url = '' } = {},
    quantity,
  }) => ({
    imageUrl,
    category,
    name,
    price: Number(listPrice.replace(/[^0-9.]/g, '')),
    product_id: sku, // eslint-disable-line camelcase
    sku,
    url,
    quantity,
  }));
}

export default {
  generateMultipleAddToCartLineItems,
  generateUpdateCartQuery,
  getCartLabel,
  getCartDetailsFromState,
  generateProductsForAnalytics,
};
