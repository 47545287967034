import { REVIEWS_WIDGET_ID } from './ReviewsWidget';

export type RatingOptions = {
  store: string;
  mode: string;
  linebreak: boolean,
  lang: string,
  usePolaris: boolean,
  showEmptyStars: boolean,
  color?: string;
  text?: string;
  singularText?: string;
};

declare global {
  interface Window {
    ratingSnippet?: (className: string, options: RatingOptions) => void;
  }
}

export const REVIEWS_RATING_WIDGET_CLASSNAME = 'ruk_rating_snippet';
const BASE_RATING_WIDGET_OPTIONS: RatingOptions = {
  store: 'Burrow.com',
  mode: 'default',
  linebreak: false,
  lang: 'en',
  usePolaris: false,
  showEmptyStars: false,
};
export const RATING_WIDGET_OPTIONS_PDP: RatingOptions = {
  ...BASE_RATING_WIDGET_OPTIONS,
  color: '#383633',
  text: 'Reviews',
  singularText: 'Review',
};
export const RATING_WIDGET_OPTIONS_PLP: RatingOptions = {
  ...BASE_RATING_WIDGET_OPTIONS,
  color: '#767472',
};

type ReviewsRatingWidgetProps = {
  sku: string;
  isPlp?: boolean;
};

const ReviewsRatingWidget = ({ sku }: ReviewsRatingWidgetProps) => {
  const handleReviewsRatingOnClick = () => {
    window.document.getElementById(REVIEWS_WIDGET_ID)?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  return (
    <div
      data-testid={REVIEWS_RATING_WIDGET_CLASSNAME}
      className={REVIEWS_RATING_WIDGET_CLASSNAME}
      data-sku={sku}
      onClick={handleReviewsRatingOnClick}
    />
  );
};

export default ReviewsRatingWidget;
