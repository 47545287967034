import { useDispatch, useSelector } from 'react-redux';
import Item from './CartItem';

import { fetchProductDetails } from '../../../store/actions/product.actions';
import { openCart } from '../../../store/actions/ui.actions';
import { getIsCartOpen } from '../../../store/selectors/ui.selectors';

import SliderContainer, { Slide } from '../Slider';

import { withCssModulesClassNames } from '../../../common/nextMigrationHelpers';
import { default as styles } from '../../../scss/components/CartRecommendedItems.module.scss';

const withCssModulesClassNamesHandler = withCssModulesClassNames(styles);

interface RecommendedItem {
  __typename: string;
  image: string;
  name: string;
  path: string;
  pricing: number;
  sku: string;
  variantSku: string;
  price: number;
}
interface CartRecommendedItemsProps {
  recommendedItems: RecommendedItem[];
}

const CartRecommendedItems = ({
  recommendedItems,
}: CartRecommendedItemsProps) => {
  const dispatch = useDispatch();
  const { isCartOpen } = useSelector((state) => ({ isCartOpen: getIsCartOpen(state) }));
  const loadAndCloseCart = ({ sku }) => {
    dispatch(fetchProductDetails({ sku }));
    dispatch(openCart(false));
  };

  return (
    <div className={withCssModulesClassNamesHandler('cart__recommended-container')} data-testid="cart__recommended-container">
      <SliderContainer
        slidesPerView={1.25}
        spaceBetween={50}
        pagination={true}
        arrows={true}
        autoplay={true}
        isRecommendedItems={true}
      >
      {
      recommendedItems && Array.isArray(recommendedItems) && recommendedItems.map((product) => (
        <Slide key={`recommended-item-${product.sku}`}>
          <Item
            recommendedItems={product}
            key={`recommended-item-${product.sku}`}
            editable={false}
            item={{
              name: product.name,
              sku: product.sku,
              pricing: product.pricing,
              price: product.price,
              url: product.path,
              productCardImage:
            {
              url: product.image,
              __typename: 'Asset',
              assetSources: [],
              externalAssetUrl: product.image,
              handle: null,
              height: 1500,
              mimeType: 'image/jpeg',
              screenSize: null,
              width: 2400,
              alt: null,
            },
            }}
            options={true}
            recommended={true}
            waypointProps={{}}
            isCartOpen={isCartOpen}
            loadAndCloseCart={loadAndCloseCart}
          />
        </Slide>
      ))
    }
    </SliderContainer>
    </div>
  );
};

export default CartRecommendedItems;
