import { createSelector } from 'reselect';

import { formatStringToDollar } from '../../../../utils/Utils';

export const getSubmitPending = ({ checkout }) => checkout.isSubmittingCheckout;
export const getIsTerminalsDataPending = ({ checkout }) => checkout.isFetchingTerminals;
export const getCheckoutError = ({ checkout }) => checkout.error;
export const getPaymentTerminals = ({ checkout }) => checkout.data.terminals;
export const getSalesChannels = ({ checkout }) => checkout.data.salesChannels;
export const getPayLinkSent = ({ checkout }) => checkout.payLinkSent;
export const getSalesChannelOptions = createSelector(
  getSalesChannels,
  (salesChannels) => salesChannels?.map(({
    displayName,
    paymentLocation,
    paymentMethods,
    paymentTerminals,
  }) => ({
    value: displayName,
    label: displayName,
    paymentLocation,
    paymentMethods,
    paymentTerminals,
  }) || []),
);

export const getIsCheckoutFetched = ({ checkout }) => checkout.isCheckoutFetched;
export const getIsConsignmentDataPending = ({ checkout }) => checkout.isConsignmentDataPending;
export const getConsignment = ({ checkout }) => checkout.data?.consignment;
export const getConsignmentId = ({ checkout }) => checkout.data?.consignment?.shippingConsignment?.consignmentId;
export const getShippingConsignmentAddress = ({ checkout }) => checkout?.data?.consignment?.shippingConsignment?.address;

export const getConsignmentTax = ({ checkout, general: { hrefLang } }) => {
  const tax = checkout?.data?.consignment?.tax;

  if (tax) {
    return formatStringToDollar({
      value: tax, minimumFractionDigits: 2, haveComma: true, hrefLang,
    });
  }

  return '';
};

export const getConsignmentTotal = ({ checkout, general: { hrefLang } }) => {
  const grandTotal = checkout?.data?.consignment?.grandTotal;

  if (grandTotal) {
    return formatStringToDollar({
      value: grandTotal, minimumFractionDigits: 2, haveComma: true, hrefLang, verbose: true,
    });
  }

  return '';
};
