import {
  ApolloClient, HttpLink, InMemoryCache, from,
} from '@apollo/client/core';

import fetch from 'cross-fetch';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import createHash from 'create-hash';

function sha256(str: string) {
  return createHash('sha256').update(str).digest('hex');
}

const persistedQueriesLink = createPersistedQueryLink({ sha256 });
const httpLink = new HttpLink({
  uri: '/gql',
  fetch,
});

const apolloClient = new ApolloClient({
  link: from([
    persistedQueriesLink,
    httpLink,
  ]),
  cache: new InMemoryCache({
    // for infinite scroll pagination for searchProducts query
    // we need to merge incoming data with existing data instead of overwriting it
    typePolicies: {
      Query: {
        fields: {
          searchProducts: {
            keyArgs: ['term'],
            merge(existing, incoming) {
              const products = [...(existing?.products ?? [])];
              let totalItems = existing?.totalItems ?? incoming.totalItems;

              incoming.products.forEach((product: { __ref: string }) => {
                const index = products.findIndex((p) => p.__ref === product.__ref);
                if (index === -1) {
                  products.push(product);
                }
              });

              // if we have reached the end of the list
              // and there are no more pages to load
              // and the total number of items is not equal to the number of items we have
              // then we need to update the total number of items
              if (
                incoming.pageInfo.endCursor === null
                && incoming.pageInfo.hasNextPage === false
                && totalItems !== products.length
              ) {
                totalItems = products.length;
              }

              return {
                ...incoming,
                products,
                totalItems,
              };
            },
          },
        },
      },
    },
  }),
});

export default apolloClient;
