import PropTypes from 'prop-types';

import Asset from '../global/Asset';
import UniversalLinkContainer from '../global/UniversalLink';
import { linkPropTypes } from '../../common/point.propTypes';
import { assetPropTypes } from '../../common/asset.propTypes';
import { Nullable } from '../../types/common';
import { AssetDto, LinkDto, Align } from '../../types/schema';
import { withCssModulesClassNames } from '../../common/nextMigrationHelpers';
// eslint-disable-next-line import/no-named-default
import { default as styles } from '../../scss/components/LifeStyleCard.module.scss';

const withCssModulesClassNamesHandler = withCssModulesClassNames(styles);

interface LifestyleCardProps {
  link?: Nullable<LinkDto>;
  copy?: Nullable<string>;
  header?: Nullable<string>;
  icon?: Nullable<AssetDto>;
  asset?: Nullable<AssetDto>;
  tabIndex?: number;
  copyBackgroundColor?: Nullable<string>;
  visibilityClassNames?: string;
  columnWidthsClassNames?: string;
  alignHorizontally?: Nullable<Align.Right | Align.Left>;
  productCarouselLifestyleCard?: boolean;
}

const LifestyleCard = ({
  link,
  copy,
  icon,
  asset,
  header,
  tabIndex,
  alignHorizontally,
  copyBackgroundColor,
  visibilityClassNames,
  columnWidthsClassNames,
  productCarouselLifestyleCard,
}: LifestyleCardProps) => {
  const Wrapper = link ? UniversalLinkContainer : 'div';
  const wrapperProps = link ? { ...link, tabIndex } : {};

  return (
    <div
      className={withCssModulesClassNamesHandler(
        'lifestyle-card',
        visibilityClassNames,
        columnWidthsClassNames,
        {
          'lifestyle-card__carousel-wrapper': productCarouselLifestyleCard,
        },
      )}
    >
      <Wrapper
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...wrapperProps}
        className={withCssModulesClassNamesHandler(
          'lifestyle-card__wrapper',
          {
            'lifestyle-card__carousel': productCarouselLifestyleCard,
          },
        )}
      >
        {
          icon?.width || header || copy || link?.text ? (
            <div
              className={withCssModulesClassNamesHandler('lifestyle-card__text-wrapper', {
                'lifestyle-card__text-wrapper_right': alignHorizontally === 'Right',
                'lifestyle-card__text-wrapper_left': alignHorizontally === 'Left',
              })}
            >
              <div
                className={withCssModulesClassNamesHandler(
                  'lifestyle-card__text',
                  (
                    copyBackgroundColor ? {
                      [`bg-color-${copyBackgroundColor.toLowerCase()}`]: copyBackgroundColor,
                    } : null
                  ),
                )}
              >
                {icon?.width && (
                  <Asset
                    imageProps={{
                      outerWrapperClassName: withCssModulesClassNamesHandler(
                        'lifestyle-card__text-icon',
                      ),
                    }}
                    asset={icon}
                  />
                )}
                {header && (
                  <span
                    className={withCssModulesClassNamesHandler(
                      'h4',
                      'lifestyle-card__text--heavy',
                    )}
                  >
                    {header}
                  </span>
                )}
                {copy && (
                  <p
                    className={withCssModulesClassNamesHandler(
                      'lifestyle-card__text--small-text',
                    )}
                  >
                    {copy}
                  </p>
                )}
                {link?.text && (
                  <p
                    data-location="lifestyle-card"
                    className={withCssModulesClassNamesHandler(
                      'lifestyle-card__link',
                      'lifestyle-card__text--heavy',
                    )}
                  >
                    {link.text}
                  </p>
                )}
              </div>
            </div>
          ) : null
        }
        {
          asset && (
            <Asset
              imageProps={{
                outerWrapperClassName: withCssModulesClassNamesHandler(
                  'lifestyle-card__wrapper-image',
                ),
              }}
              asset={asset}
              autoPaddingBottom={true}
            />
          )
        }
      </Wrapper>
    </div>
  );
};

LifestyleCard.defaultProps = {
  copy: undefined,
  icon: undefined,
  link: undefined,
  asset: undefined,
  header: undefined,
  tabIndex: undefined,
  alignHorizontally: undefined,
  copyBackgroundColor: undefined,
  visibilityClassNames: undefined,
  columnWidthsClassNames: undefined,
  productCarouselLifestyleCard: false,
};

LifestyleCard.propTypes = {
  copy: PropTypes.string,
  header: PropTypes.string,
  tabIndex: PropTypes.number,
  link: PropTypes.shape(linkPropTypes),
  icon: PropTypes.shape(assetPropTypes),
  copyBackgroundColor: PropTypes.string,
  visibilityClassNames: PropTypes.string,
  asset: PropTypes.shape(assetPropTypes),
  columnWidthsClassNames: PropTypes.string,
  productCarouselLifestyleCard: PropTypes.bool,
  alignHorizontally: PropTypes.oneOf(['Right', 'Left']),
};

export default LifestyleCard;
