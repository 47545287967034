import {
  Dispatch, ReactEventHandler, SetStateAction, SyntheticEvent, RefObject,
} from 'react';

export interface MetadataState {
  videoWidth: number;
  videoHeight: number;
  duration: number;
}

// eslint-disable-next-line import/prefer-default-export
export const onPlay = (
  (
    setMetadata: Dispatch<SetStateAction<MetadataState>>,
    onSetMetadata?: (metadata: MetadataState) => void,
  ) => (
    (event: SyntheticEvent<HTMLVideoElement, Event>) => {
      const { videoWidth, videoHeight, duration } = event.target as HTMLVideoElement;

      setMetadata({ videoWidth, videoHeight, duration });
      if (typeof onSetMetadata === 'function') {
        onSetMetadata({
          videoWidth,
          videoHeight,
          duration,
        });
      }
    }
  )
);

export const togglePlayHandler = (
  (
    videoRef: RefObject<HTMLVideoElement>,
    isPlaying: boolean,
    setIsPlaying: Dispatch<SetStateAction<boolean>>,
  ): ReactEventHandler<HTMLDivElement | HTMLButtonElement> => (e) => {
    e.stopPropagation();

    videoRef.current?.[isPlaying ? 'pause' : 'play']();
    setIsPlaying(!isPlaying);
  }
);

export const toggleControls = ({
  metadata: { duration },
  setShowControls,
}: {
  metadata: MetadataState,
  setShowControls: Dispatch<SetStateAction<boolean>>,
}) => {
  if (duration > 0 && duration < 5) {
    setShowControls(false);
  } else {
    setShowControls(true);
  }
};

export const isInViewport = (element: HTMLElement) => {
  const rect = element.getBoundingClientRect();

  return (
    rect.top >= 0
    && rect.left >= 0
    && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
    && rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const SLIDE_CHANGE_OBSERVER_OPTIONS = { attributes: true } as const;
export const slideChangeObserver = ({
  video,
  setIsPlaying,
}: {
  video: HTMLVideoElement | null;
  setIsPlaying: Dispatch<SetStateAction<boolean>>;
}) => {
  if (video === null) {
    return null;
  }

  const targetNode = video.closest('.swiper-slide');

  if (targetNode) {
    const slideIndex = targetNode.getAttribute('data-swiper-slide-index');

    const observer = new MutationObserver(() => {
      const isVideoPlaying = !video.paused && video.readyState > 2;

      if (targetNode.classList.contains('swiper-slide-active')) {
        if (!isVideoPlaying) {
          if (slideIndex === '0' && video.readyState === 0) {
            // this will be played by the onViewportObserver
            return;
          }

          video.play();
          setIsPlaying(true);
        }
      } else if (isVideoPlaying) {
        video.pause();
        setIsPlaying(false);
      }
    });

    observer.observe(targetNode, SLIDE_CHANGE_OBSERVER_OPTIONS);

    return observer;
  }

  return null;
};

export const ON_VIEWPORT_OBSERVER_OPTIONS = {
  root: null,
  rootMargin: '0px',
  threshold: 0.1,
} as const;

export const onViewportObserver = ({
  video,
  autoPlay,
  setIsPlaying,
}: {
  video: HTMLVideoElement | null;
  autoPlay: boolean;
  setIsPlaying: Dispatch<SetStateAction<boolean>>;
}) => {
  if (video === null) {
    return null;
  }

  const observer = new IntersectionObserver((entries) => {
    const isVideoPlaying = !video.paused && video.readyState > 2;

    entries.forEach((entry) => {
      if (entry.intersectionRatio > 0) {
        if (autoPlay && !isVideoPlaying) {
          video.play();
          setIsPlaying(true);
          observer.disconnect();
        }
      }
    });
  }, ON_VIEWPORT_OBSERVER_OPTIONS);

  observer.observe(video);

  return observer;
};

export const addListeners = (
  video: HTMLVideoElement | null,
  onMediaPlay?: () => void,
  onMediaClick?: () => void,
) => {
  const eventListeners: Array<[
    string, // eventName
    () => void, // cb function
  ]> = [];

  if (video === null) return eventListeners;

  if (onMediaPlay) {
    video?.addEventListener('play', onMediaPlay);
    eventListeners.push(['play', onMediaPlay]);
  }
  if (onMediaClick) {
    video?.addEventListener('click', onMediaClick);
    eventListeners.push(['click', onMediaClick]);
  }

  return eventListeners;
};
