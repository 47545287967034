const prefix = '[USER]';

export const CREATE_USER = `${prefix} CREATE_USER`;
export const CREATE_USER__REQUEST = `${prefix} CREATE_USER__REQUEST`;
export const CREATE_USER__SUCCESS = `${prefix} CREATE_USER__SUCCESS`;
export const CREATE_USER__FAILURE = `${prefix} CREATE_USER__FAILURE`;
export const UPDATE_USER = `${prefix} UPDATE_USER`;
export const UPDATE_USER__REQUEST = `${prefix} UPDATE_USER__REQUEST`;
export const UPDATE_USER__SUCCESS = `${prefix} UPDATE_USER__SUCCESS`;
export const UPDATE_USER__FAILURE = `${prefix} UPDATE_USER__FAILURE`;
export const SIGN_IN_USER = `${prefix} SIGN_IN_USER`;
export const SIGN_IN_USER__REQUEST = `${prefix} SIGN_IN_USER__REQUEST`;
export const SIGN_IN_USER__SUCCESS = `${prefix} SIGN_IN_USER__SUCCESS`;
export const SIGN_IN_USER__FAILURE = `${prefix} SIGN_IN_USER__FAILURE`;
export const SIGN_OUT_USER = `${prefix} SIGN_OUT_USER`;
export const SIGN_OUT_USER__REQUEST = `${prefix} SIGN_OUT_USER__REQUEST`;
export const SIGN_OUT_USER__SUCCESS = `${prefix} SIGN_OUT_USER__SUCCESS`;
export const SIGN_OUT_USER__FAILURE = `${prefix} SIGN_OUT_USER__FAILURE`;
export const FETCH_LOGGED_IN_USER = `${prefix} FETCH_LOGGED_IN_USER`;
export const FETCH_LOGGED_IN_USER__REQUEST = `${prefix} FETCH_LOGGED_IN_USER__REQUEST`;
export const FETCH_LOGGED_IN_USER__SUCCESS = `${prefix} FETCH_LOGGED_IN_USER__SUCCESS`;
export const FETCH_LOGGED_IN_USER__FAILURE = `${prefix} FETCH_LOGGED_IN_USER__FAILURE`;
export const SUBSCRIBE_TO_NEWSLETTER = `${prefix} SUBSCRIBE_TO_NEWSLETTER`;
export const SUBSCRIBE_TO_NEWSLETTER__REQUEST = `${prefix} SUBSCRIBE_TO_NEWSLETTER__REQUEST`;
export const SUBSCRIBE_TO_NEWSLETTER__SUCCESS = `${prefix} SUBSCRIBE_TO_NEWSLETTER__SUCCESS`;
export const SUBSCRIBE_TO_NEWSLETTER__FAILURE = `${prefix} SUBSCRIBE_TO_NEWSLETTER__FAILURE`;
export const SET_CAN_UPDATE_PROFILE = `${prefix} SET_CAN_UPDATE_PROFILE`;
export const SET_CAN_FORGET = `${prefix} SET_CAN_FORGET`;
export const FORGOT_PASSWORD = `${prefix} FORGOT_PASSWORD`;
export const FORGOT_PASSWORD__REQUEST = `${prefix} FORGOT_PASSWORD__REQUEST`;
export const FORGOT_PASSWORD__SUCCESS = `${prefix} FORGOT_PASSWORD__SUCCESS`;
export const FORGOT_PASSWORD__FAILURE = `${prefix} FORGOT_PASSWORD__FAILURE`;
export const SET_ANALYTICS_INFO = `${prefix} SET_ANALYTICS_INFO`;

export const createUser = (data, queryParams) => ({
  type: CREATE_USER,
  data,
  queryParams,
});

export const createUserRequest = () => ({
  type: CREATE_USER__REQUEST,
});

export const createUserSuccess = (payload) => ({
  type: CREATE_USER__SUCCESS,
  payload,
});

export const createUserFailure = (error) => ({
  type: CREATE_USER__FAILURE,
  error,
});

export const updateUser = (data) => ({
  type: UPDATE_USER,
  data,
});

export const updateUserRequest = () => ({
  type: UPDATE_USER__REQUEST,
});

export const updateUserSuccess = (payload) => ({
  type: UPDATE_USER__SUCCESS,
  payload,
});

export const updateUserFailure = (error) => ({
  type: UPDATE_USER__FAILURE,
  error,
});

export const signInUser = (credentials, queryParams) => ({
  type: SIGN_IN_USER,
  credentials,
  queryParams,
});

export const signInUserRequest = () => ({
  type: SIGN_IN_USER__REQUEST,
});

export const signInUserSuccess = (payload) => ({
  type: SIGN_IN_USER__SUCCESS,
  payload,
});

export const signInUserFailure = (error) => ({
  type: SIGN_IN_USER__FAILURE,
  error,
});

export const signOutUser = () => ({
  type: SIGN_OUT_USER,
});

export const signOutUserRequest = () => ({
  type: SIGN_OUT_USER__REQUEST,
});

export const signOutUserSuccess = () => ({
  type: SIGN_OUT_USER__SUCCESS,
});

export const signOutUserFailure = (error) => ({
  type: SIGN_OUT_USER__FAILURE,
  error,
});

export const fetchLoggedInUser = () => ({
  type: FETCH_LOGGED_IN_USER,
});

export const fetchLoggedInUserRequest = () => ({
  type: FETCH_LOGGED_IN_USER__REQUEST,
});

export const fetchLoggedInUserSuccess = (payload) => ({
  type: FETCH_LOGGED_IN_USER__SUCCESS,
  payload,
});

export const fetchLoggedInUserFailure = (error) => ({
  type: FETCH_LOGGED_IN_USER__FAILURE,
  error,
});

export const subscribeToNewsletter = (payload) => ({
  type: SUBSCRIBE_TO_NEWSLETTER,
  payload,
});

export const subscribeToNewsletterRequest = (payload) => ({
  type: SUBSCRIBE_TO_NEWSLETTER__REQUEST,
  payload,
});

export const subscribeToNewsletterSuccess = (payload) => ({
  type: SUBSCRIBE_TO_NEWSLETTER__SUCCESS,
  payload,
});

export const subscribeToNewsletterFailure = (error) => ({
  type: SUBSCRIBE_TO_NEWSLETTER__FAILURE,
  error,
});

export const setCanUpdateProfile = (canUpdate) => ({
  type: SET_CAN_UPDATE_PROFILE,
  payload: canUpdate,
});

export const setCanForget = (canForget) => ({
  type: SET_CAN_FORGET,
  payload: canForget,
});

export const forgotPassword = (email) => ({
  type: FORGOT_PASSWORD,
  email,
});

export const forgotPasswordRequest = () => ({
  type: FORGOT_PASSWORD__REQUEST,
});

export const forgotPasswordSuccess = (payload) => ({
  type: FORGOT_PASSWORD__SUCCESS,
  payload,
});

export const forgotPasswordFailure = (error) => ({
  type: FORGOT_PASSWORD__FAILURE,
  error,
});

export const setAnalyticsInfo = (payload) => ({
  type: SET_ANALYTICS_INFO,
  payload,
});
