import classNames from 'classnames';
import bindableClassNames from 'classnames/bind';

export const withCssModulesClassNames = (styles?: Record<string, string>) => (...classes: classNames.ArgumentArray) => {
  if (!styles) {
    return classNames(...classes);
  }

  const moduleClassNames = bindableClassNames.bind(styles);
  return [...new Set([...classNames(...classes).split(' '), ...moduleClassNames(...classes).split(' ')])].join(' ');
};

export default withCssModulesClassNames;
