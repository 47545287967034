const prefix = '[REVIEWS]';

export const FETCH_REVIEWS_AVERAGE_RATING = `${prefix} FETCH_REVIEWS_AVERAGE_RATING`;
export const FETCH_REVIEWS_AVERAGE_RATING__REQUEST = `${prefix} FETCH_REVIEWS_AVERAGE_RATING__REQUEST`;
export const FETCH_REVIEWS_AVERAGE_RATING__SUCCESS = `${prefix} FETCH_REVIEWS_AVERAGE_RATING__SUCCESS`;
export const FETCH_REVIEWS_AVERAGE_RATING__FAILURE = `${prefix} FETCH_REVIEWS_AVERAGE_RATING__FAILURE`;

export const fetchReviewsAverageRating = () => ({
  type: FETCH_REVIEWS_AVERAGE_RATING,
});

export const fetchReviewsAverageRatingRequest = () => ({
  type: FETCH_REVIEWS_AVERAGE_RATING__REQUEST,
});

export const fetchReviewsAverageRatingSuccess = (payload) => ({
  type: FETCH_REVIEWS_AVERAGE_RATING__SUCCESS,
  payload,
});

export const fetchReviewsAverageRatingFailure = (payload) => ({
  type: FETCH_REVIEWS_AVERAGE_RATING__FAILURE,
  payload,
});
