import clamp from 'lodash/clamp';
import { cartThermometer as cartThermometerGlobalAPI } from '../../mock/globalAPI';
import { getHrefLang } from '../../store/selectors/general.selectors';
import getCurrencyPrefix from '../../common/getCurrencyPrefix';
import { HrefLang, PromotionDto, SalesTier } from '../../types/schema';
import { useTypedSelector } from '../../store/hooks';
import { graphCMSCouponPromotionLookup } from '../../store/selectors/cart.selectors';

export const getFormattedTierSavings = (
  tier: SalesTier,
  hrefLang: HrefLang = HrefLang.EN_US,
) => {
  switch (tier.type) {
    case 'Percent':
      return `${tier.discountAmount}%`;
    case 'Amount':
      return `${getCurrencyPrefix({ hrefLang })}${tier.discountAmount}`;
    default:
      return `${tier.discountAmount}`;
  }
};

export const getFormattedTiersDifference = (
  tier1: SalesTier | null,
  tier2: SalesTier | null,
  hrefLang: HrefLang = HrefLang.EN_US,
) => {
  if (!(tier1 && tier1.type === 'Amount' && tier2 && tier2.type === 'Amount')) {
    return null;
  }
  return `${getCurrencyPrefix({ hrefLang })}${tier2.discountAmount - tier1.discountAmount}`;
};

export const getTiersBasedOnSubtotal = ({
  salesTiers,
  subtotalNumber,
}: {
  salesTiers: SalesTier[];
  subtotalNumber: number;
}) => {
  const nextTier = salesTiers.find((salesTier) => (
    salesTier.level > subtotalNumber
  )) || null;
  const currentTier = [...salesTiers].reverse().find((salesTier) => (
    salesTier.level < subtotalNumber
  )) || null;
  return { currentTier, nextTier };
};

interface SaleThermometerProps {
  isPromoCodeApplied: boolean;
  subtotalNumber: number;
  currentTier: SalesTier | null;
  nextTier: SalesTier | null;
  enterCodeCopy?: string;
  tierDistanceCopy?: string;
  maxSavingsCopy?: string;
  automaticallyAppliedPromotion?: boolean;
}

const SaleThermometer = ({
  isPromoCodeApplied,
  subtotalNumber,
  enterCodeCopy = '',
  tierDistanceCopy = cartThermometerGlobalAPI.tierDistanceCopy,
  maxSavingsCopy = cartThermometerGlobalAPI.maxSavingsCopy,
  automaticallyAppliedPromotion,
  ...props
}: SaleThermometerProps) => {
  const hrefLang = useTypedSelector(getHrefLang);
  const [tieredCoupon]: [PromotionDto] = useTypedSelector(graphCMSCouponPromotionLookup) || [];

  let {
    currentTier,
    nextTier = null,
  } = props;

  /**
   * @description
   * this is a temporary solution for BigCommerce's limitations to support
   * sale + tiered promotion
   *
   * isSpecialTieredPromotion field is set on Hygraph, at the Promotion entry.
   */
  if (tieredCoupon?.isSpecialTieredPromotion && subtotalNumber >= (nextTier?.level || Infinity)) {
    const { salesTiers } = tieredCoupon;
    ({ currentTier, nextTier } = getTiersBasedOnSubtotal({ salesTiers, subtotalNumber }));
  }

  const renderLabel = () => {
    const label = (() => {
      if ((!isPromoCodeApplied && !automaticallyAppliedPromotion)) {
        return enterCodeCopy;
      }

      if (!nextTier) {
        return currentTier && maxSavingsCopy?.replace('$savings', getFormattedTierSavings(currentTier));
      }

      const tiersDifference = getFormattedTiersDifference(
        currentTier,
        nextTier,
      );
      const nextTierSavings = tiersDifference
        ? `another ${tiersDifference}`
        : getFormattedTierSavings(nextTier);
      const discountDiff = clamp(nextTier.level - subtotalNumber, 0, Number.POSITIVE_INFINITY);

      return tierDistanceCopy
        ?.replace('$distance', `${getCurrencyPrefix({ hrefLang })}${discountDiff}`)
        .replace('$nextTierSavings', nextTierSavings);
    })();

    return label ? (
      <div className="sale-thermometer__label">
        {label}
      </div>
    ) : null;
  };

  const renderProgressBar = () => {
    const noTiersPromo = currentTier?.level === 0 && !nextTier;

    if ((!isPromoCodeApplied || noTiersPromo) && !automaticallyAppliedPromotion) {
      return null;
    }

    const subtotal = currentTier ? subtotalNumber - currentTier.level : subtotalNumber;

    const width = nextTier
      ? `${clamp(Number(((subtotal / nextTier.level) * 100).toFixed(2)), 0, 100)}%`
      : '100%';

    return (
      <div className="sale-thermometer__progress-bar-box">
        <div
          className="sale-thermometer__progress-bar"
          style={{ width }}
        />
      </div>
    );
  };

  return (
    <div className="sale-thermometer">
      {renderLabel()}
      {renderProgressBar()}
    </div>
  );
};

export default SaleThermometer;
