import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { getNavPinned, getIsFiltersPopoutOpen } from '../../../store/selectors/ui.selectors';
import Filters from './Filters';
import { filters as filtersGlobalAPI } from '../../../mock/globalAPI';
import { getNumberOfAppliedFilters } from '../../../store/selectors/page.selectors';
import { setIsFiltersPopoutOpen } from '../../../store/actions/ui.actions';

const FiltersPopout = () => {
  const dispatch = useDispatch();
  const isFiltersPopoutOpen = useSelector(getIsFiltersPopoutOpen);
  const isUtilityNavPinned = useSelector(getNavPinned);
  const numberOfAppliedFilters = useSelector(getNumberOfAppliedFilters);

  const setIsFiltersPopoutOpenAction = (...args) => dispatch(setIsFiltersPopoutOpen(...args));

  return (
    <div className={classNames(
      'filters',
      {
        'is-open': isFiltersPopoutOpen,
        'with-utility-nav': isUtilityNavPinned,
      },
    )}
    >
      <div className="filters__header filters__header">
        <div className="filters__header__inner-wrapper filters__header__inner-wrapper">
          <span>
            <h4>{filtersGlobalAPI.header}</h4>
            {numberOfAppliedFilters > 0 ? <span>({numberOfAppliedFilters})</span> : null}
          </span>
          <button
            type="button"
            aria-label="Close filters menu"
            onClick={() => setIsFiltersPopoutOpenAction(false)}
            className="icon icon-close"
          />
        </div>
      </div>
      <Filters />
    </div>
  );
};

export default FiltersPopout;
