import {
  FETCH_TERMINALS__REQUEST,
  FETCH_TERMINALS__SUCCESS,
  FETCH_TERMINALS__FAILURE,
  SUBMIT_CHECKOUT__REQUEST,
  SUBMIT_CHECKOUT__FAILURE,
  SUBMIT_CHECKOUT__SUCCESS,
  FETCH_CONSIGNMENT__REQUEST,
  FETCH_CONSIGNMENT__SUCCESS,
  FETCH_CONSIGNMENT__FAILURE,
  CREATE_OR_UPDATE_CONSIGNMENT__REQUEST,
  CREATE_OR_UPDATE_CONSIGNMENT__SUCCESS,
  CREATE_OR_UPDATE_CONSIGNMENT__FAILURE,
  RESET_IS_CHECKOUT_FETCHED,
  RESEND_PAYMENT_LINK__REQUEST,
  RESEND_PAYMENT_LINK__SUCCESS,
  RESEND_PAYMENT_LINK__FAILURE,
} from '../actions/checkout.actions';

export const initialState = {
  error: null,
  isFetchingTerminals: null,
  isCheckoutFetched: false,
  data: {
    consignment: null,
    terminals: [],
    salesChannels: [],
  },
};

const fetchTerminalsRequest = (state) => ({
  ...state,
  isFetchingTerminals: true,
  error: null,
});

const fetchTerminalsSuccess = (state, { terminals, salesChannels }) => ({
  ...state,
  isFetchingTerminals: false,
  error: null,
  data: {
    ...state.data,
    terminals,
    salesChannels,
  },
});

const fetchTerminalsFailure = (state, error) => ({
  ...state,
  isFetchingTerminals: false,
  error,
});

const submitCheckoutRequest = (state) => ({
  ...state,
  isSubmittingCheckout: true,
  error: null,
});

const submitCheckoutSuccess = (state, order) => ({
  ...state,
  isSubmittingCheckout: false,
  error: null,
  data: {
    ...state.data,
    order,
  },
});

const submitCheckoutFailure = (state, error) => ({
  ...state,
  isSubmittingCheckout: false,
  error,
});

const createOrUpdateConsignmentRequest = (state) => ({
  ...state,
  isConsignmentDataPending: true,
  error: null,
});

const createOrUpdateConsignmentSuccess = (state, consignment) => ({
  ...state,
  isCheckoutFetched: true,
  isConsignmentDataPending: false,
  error: null,
  data: {
    ...state.data,
    consignment,
  },
});

const createOrUpdateConsignmentFailure = (state, error) => ({
  ...state,
  isConsignmentDataPending: false,
  error,
});

const resetIsCheckoutFetched = () => ({
  ...initialState,
});

const resentPaymentLinkRequest = (state) => ({
  ...state,
  isResendingPaymentLink: true,
  error: null,
});

const resentPaymentLinkSuccess = (state, payLink) => ({
  ...state,
  payLinkSent: true,
  isResendingPaymentLink: false,
  error: null,
  data: {
    ...state.data,
    payLink,
  },
});

const resentPaymentLinkFailure = (state, error) => ({
  ...state,
  isResendingPaymentLink: false,
  error,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TERMINALS__REQUEST:
      return fetchTerminalsRequest(state);

    case FETCH_TERMINALS__SUCCESS:
      return fetchTerminalsSuccess(state, action.payload);

    case FETCH_TERMINALS__FAILURE:
      return fetchTerminalsFailure(state, action.error);
    case SUBMIT_CHECKOUT__REQUEST:
      return submitCheckoutRequest(state);

    case SUBMIT_CHECKOUT__SUCCESS:
      return submitCheckoutSuccess(state, action.payload);

    case SUBMIT_CHECKOUT__FAILURE:
      return submitCheckoutFailure(state, action.error);

    case FETCH_CONSIGNMENT__REQUEST:
    case CREATE_OR_UPDATE_CONSIGNMENT__REQUEST:
      return createOrUpdateConsignmentRequest(state);

    case FETCH_CONSIGNMENT__SUCCESS:
    case CREATE_OR_UPDATE_CONSIGNMENT__SUCCESS:
      return createOrUpdateConsignmentSuccess(state, action.payload);

    case FETCH_CONSIGNMENT__FAILURE:
    case CREATE_OR_UPDATE_CONSIGNMENT__FAILURE:
      return createOrUpdateConsignmentFailure(state, action.error);

    case RESET_IS_CHECKOUT_FETCHED:
      return resetIsCheckoutFetched(state);

    case RESEND_PAYMENT_LINK__REQUEST:
      return resentPaymentLinkRequest(state);

    case RESEND_PAYMENT_LINK__SUCCESS:
      return resentPaymentLinkSuccess(state, action.payload);

    case RESEND_PAYMENT_LINK__FAILURE:
      return resentPaymentLinkFailure(state, action.error);

    default:
      return state;
  }
};
