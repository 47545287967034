import React, { createRef } from 'react';
import PropTypes from 'prop-types';

import { withCssModulesClassNames } from './nextMigrationHelpers';
import { default as styles } from '../scss/components/WithModal.module.scss';

const withCssModulesClassNamesHandler = withCssModulesClassNames(styles);

const getDisplayName = (WrappedComponent) => WrappedComponent.displayName || WrappedComponent.name || 'Component';

export default function withModal(WrappedComponent) {
  class WithModal extends WrappedComponent {
    firstFocusableRef = createRef();

    constructor(props) {
      super(props);

      this.modal = this.modal || {};
    }

    componentDidMount(args) {
      if (super.componentDidMount) {
        super.componentDidMount.apply(this, args);
      }

      this.firstFocusableRef.current?.focus();

      /**
       * @param {KeyboardEvent} e
       */
      const onKeyDown = (e) => {
        const isEscape = e.code === 'Escape'
          || e.key === 'Escape'
          || e.key === 'Esc'
          || e.keyCode === 27;

        if (isEscape) {
          document.removeEventListener('keydown', onKeyDown);
          this.props.onClose();
        }
      };

      document.addEventListener('keydown', onKeyDown);
    }

    componentWillUnmount() {
      if (this.componentWillUnmountCallback) {
        this.componentWillUnmountCallback();
      }

      if (super.componentWillUnmount) {
        super.componentWillUnmount.apply(this);
      }
    }

    renderCloseButton() {
      const {
        onClose,
      } = this.props;

      return (
        <div
          ref={this.firstFocusableRef}
          aria-label="Close"
          className={withCssModulesClassNamesHandler('with-modal__close-button')}
          role="button"
          onClick={onClose}
          tabIndex={0}
        >
          <i className={withCssModulesClassNamesHandler('icon', 'icon-close')} />
        </div>
      );
    }

    render() {
      const {
        containerClassName,
        containerStyle,
      } = this.modal;
      const {
        open,
        onClose,
      } = this.props;

      return (
        <div
          className={withCssModulesClassNamesHandler('with-modal', { 'with-modal--closed': !open })}
          onClick={onClose}
          data-testid="with-modal"
        >
          <div
            className={withCssModulesClassNamesHandler('with-modal__container', containerClassName)}
            style={containerStyle}
            onClick={(e) => e.stopPropagation()}
          >
            {this.renderCloseButton()}
            {super.render()}
          </div>
        </div>
      );
    }
  }

  WithModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    containerClassName: PropTypes.string,
  };

  WithModal.displayName = getDisplayName(WrappedComponent);
  return WithModal;
}
