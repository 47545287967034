import { Player } from '@lottiefiles/react-lottie-player';
import { withCssModulesClassNames } from '../../common/nextMigrationHelpers';
import { default as styles } from '../../scss/components/LoadingIcon.module.scss';

const withCssModulesClassNamesHandler = withCssModulesClassNames(styles);

const LoadingIcon = () => (
  <Player
    autoplay
    loop
    src="https://lottie.host/f043a7b6-a384-4d0e-99e6-525b6e662d70/Xyl78H0y0a.json"
    className={withCssModulesClassNamesHandler('loading-icon')}
  />
);

export default LoadingIcon;
