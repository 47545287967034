import { useState } from 'react';

import { NavigationItem } from './header.types';
import { default as styles } from '../../../scss/components/Header.module.scss';
import { withCssModulesClassNames } from '../../../common/nextMigrationHelpers';

const withCssModulesClassNamesHandler = withCssModulesClassNames(styles);

interface HeaderLinkTitleProps {
  header: string;
  color: NavigationItem['color'];
  hoverColor: NavigationItem['color'];
}

const HeaderLinkTitle = ({ header, color, hoverColor } : HeaderLinkTitleProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <div
      className={withCssModulesClassNamesHandler('header')}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ color: isHovered ? hoverColor?.hex : color?.hex }}
    >
      {header}
    </div>
  );
};

export default HeaderLinkTitle;
