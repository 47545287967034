import { default as styles } from '../../../scss/components/Header.module.scss';

export const HOVER_CLASSNAME = 'navigation-position--hover-navigation';
export const CSS_MODULE_HOVER_CLASSNAME = styles[HOVER_CLASSNAME];

export const ABTestObserver = (
  element: HTMLDivElement,
  setIsHoverNavigation: (isHoverNavigation: boolean) => void,
) => (
  new MutationObserver((mutationList) => {
    mutationList.forEach(({ oldValue }) => {
      const isHoverNavigation = element.classList.contains(HOVER_CLASSNAME);

      if (!oldValue?.includes(HOVER_CLASSNAME) && isHoverNavigation) {
        element.classList.add(CSS_MODULE_HOVER_CLASSNAME);
      }

      if (oldValue?.includes(HOVER_CLASSNAME) && !isHoverNavigation) {
        element.classList.remove(CSS_MODULE_HOVER_CLASSNAME);
      }

      setIsHoverNavigation(isHoverNavigation);
    });
  })
);
