/* eslint-disable no-param-reassign,prefer-object-spread */

/** @typedef {import('./types').Analytics} Analytics */
/** @typedef {import('./after_dom_ready.types').FetchUserIdByEmail} FetchUserIdByEmail */
/** @typedef {import('./after_dom_ready.types').IdentifyUserAndTrackEvent} IdentifyUserAndTrackEvent */
/** @typedef {import('./after_dom_ready.types').GenerateFetchArguments} GenerateFetchArguments */
/** @typedef {import('./after_dom_ready.types').SetUserTraits} SetUserTraits */
/** @typedef {import('./after_dom_ready.types').FetchUserIdByEmailAndTrack} FetchUserIdByEmailAndTrack */
/** @typedef {import('./after_dom_ready.types').CustomJSAfterDomReady} CustomJSAfterDomReady */

/**
 * Custom JS: After DOM Ready
 * Define functions that other custom JS functions can use.
 *
 * @type {CustomJSAfterDomReady}
 */
const customJsAfterDomReady = (fetch) => (api) => {
  const storeFormData = () => {
    const emailInput = document.getElementById('form_input_email');
    api.formData = api.formData || {};
    api.formData.email = (emailInput && emailInput.value) || null;
  };

  /** @type {GenerateFetchArguments} */
  const generateFetchArguments = ({ lightboxName, email }) => {
    const endpointEntries = {
      dev: 'https://dev-pub.burrow.com/gql',
      qa: 'https://qa.burrow.com/gql',
    };

    // Use lower environment gql url if lightbox's name is prefixed by "(dev)" or "(qa)"
    const hasPrefix = /^\(([a-z]+)\).+$/.exec(lightboxName.toLowerCase());
    const url = (hasPrefix && endpointEntries[hasPrefix[1]]) || 'https://burrow.com/gql';

    const query = `query GetCustomerId($field: String!, $value: String!) {
                      getCustomerId(field: $field, value: $value) {
                          userId
                          anonymousId
                          generatedUserId
                      }
                   }`;

    const headers = {
      'Content-Type': 'application/json',
    };

    const variables = {
      field: 'email',
      value: email,
    };

    const body = JSON.stringify({
      query,
      variables,
    });

    return { url, headers, body };
  };

  /** @type {FetchUserIdByEmail} */
  const fetchUserIdByEmail = ({ analytics, email, lightboxName }) => {
    const analyticsUser = analytics.user();
    const currentTraits = analyticsUser.traits();
    const currentUserId = analyticsUser.id();
    const currentEmail = currentTraits.email;

    const isEmailIdentified = !!currentUserId && !!currentEmail && currentEmail === email;
    if (!email || isEmailIdentified) {
      return Promise.resolve(currentUserId);
    }

    currentTraits.email = email;
    analyticsUser.traits(currentTraits);

    const { url, headers, body } = generateFetchArguments({
      lightboxName,
      email,
    });

    return fetch(url, {
      method: 'POST',
      headers,
      body,
    })
      .then((r) => r.json())
      .then(({ data }) => {
        const {
          /** @type {string | undefined} */
          userId,
          /** @type {string} */
          anonymousId = analyticsUser.anonymousId(),
          /** @type {string | null} */
          generatedUserId,
        } = data.getCustomerId;

        analyticsUser.anonymousId(anonymousId);
        analyticsUser.id(userId || generatedUserId);

        return userId || generatedUserId;
      });
  };

  /** @type {IdentifyUserAndTrackEvent} */
  const identifyUserAndTrackEvent = ({
    analytics,
    userId: _userId,
    email,
    phone,
    lightboxId,
    extraProperties = {},
  }) => {
    const userId = _userId || analytics.user().id();
    const traits = {};

    if (email) traits.email = email;
    if (phone) traits.phone = phone;

    const eventProperties = {
      lightboxId,
    };
    if (userId) eventProperties.identifyFirst = true;

    Object.assign(
      eventProperties,
      traits,
      extraProperties // eslint-disable-line comma-dangle
    );

    const args = [
      traits,
      () => analytics.track('Subscribed to Promotions', eventProperties),
    ];

    if (userId) args.unshift(userId);

    analytics.identify.apply(null, args);
  };

  /** @type {SetUserTraits} */
  const setUserTraits = ({ analytics, x = {}, formData = {} }) => {
    const analyticsUser = analytics.user();
    const currentTraits = analyticsUser.traits();

    const email = x.email || formData.email || null;
    const phone = x.phone || formData.phone || null;

    if (email) currentTraits.email = email;
    if (phone) currentTraits.phone = phone;

    if (Object.keys(currentTraits).length > 0) {
      analyticsUser.traits(currentTraits);
    }

    return currentTraits;
  };

  /** @type {FetchUserIdByEmailAndTrack} */
  const fetchUserIdByEmailAndTrack = ({
    analytics, email, phone, s,
  }) => {
    // lightbox metadata
    const { lightbox_name: lightboxName, lightbox_short_id: lightboxId } = s;

    const eventPayload = {
      userId: null,
      email,
      phone,
      lightboxId,
    };

    return fetchUserIdByEmail({ analytics, email, lightboxName })
      .then((userId) => {
        eventPayload.userId = userId;

        // Retention.com Suppression
        if (window.parent.geq) {
          window.parent.geq.suppress();
        }
      })
      .catch((e) => {
        let errorMessage = typeof e === 'string' ? e : 'Unknown error';
        errorMessage = e instanceof Error ? e.message : errorMessage;

        eventPayload.extraProperties = {
          fetchUserIdError: errorMessage,
        };
      })
      .finally(() => {
        const param = Object.assign({ analytics }, eventPayload);

        identifyUserAndTrackEvent(param);
      });
  };

  api.storeFormData = storeFormData;
  api.fetchUserIdByEmail = fetchUserIdByEmail;
  api.identifyUserAndTrackEvent = identifyUserAndTrackEvent;
  api.setUserTraits = setUserTraits;
  api.fetchUserIdByEmailAndTrack = fetchUserIdByEmailAndTrack;
};

export default customJsAfterDomReady;
