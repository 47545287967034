const prefix = '[PRODUCT]';

export const LOAD_PRODUCT_VARIANT = `${prefix} LOAD_PRODUCT_VARIANT`;
export const FETCH_PRODUCT_DETAILS = `${prefix} FETCH_PRODUCT_DETAILS`;
export const FETCH_PRODUCT_DETAILS__REQUEST = `${prefix} FETCH_PRODUCT_DETAILS__REQUEST`;
export const FETCH_PRODUCT_DETAILS__SUCCESS = `${prefix} FETCH_PRODUCT_DETAILS__SUCCESS`;
export const FETCH_PRODUCT_DETAILS__FAILURE = `${prefix} FETCH_PRODUCT_DETAILS__FAILURE`;
export const FETCH_EDIT_MODAL_PRODUCT_DETAILS = `${prefix} FETCH_EDIT_MODAL_PRODUCT_DETAILS`;
export const FETCH_EDIT_MODAL_PRODUCT_DETAILS__REQUEST = `${prefix} FETCH_EDIT_MODAL_PRODUCT_DETAILS__REQUEST`;
export const FETCH_EDIT_MODAL_PRODUCT_DETAILS__SUCCESS = `${prefix} FETCH_EDIT_MODAL_PRODUCT_DETAILS__SUCCESS`;
export const FETCH_EDIT_MODAL_PRODUCT_DETAILS__FAILURE = `${prefix} FETCH_EDIT_MODAL_PRODUCT_DETAILS__FAILURE`;
export const SET_SELECTED_MODIFIERS = `${prefix} SET_SELECTED_MODIFIERS`;
export const SET_LAST_MODIFIER_SELECTION = `${prefix} SET_LAST_MODIFIER_SELECTION`;
export const SET_ADD_ON_MODIFIERS = `${prefix} SET_ADD_ON_MODIFIERS`;
export const SET_TUFTED = `${prefix} SET_TUFTED`;
export const SET_SELECTED_QUANTITY = `${prefix} SET_SELECTED_QUANTITY`;
export const TOGGLE_MODIFIER = `${prefix} TOGGLE_MODIFIER`;
export const TOGGLE_MODIFIER_OPTION = `${prefix} TOGGLE_MODIFIER_OPTION`;

export const loadProductVariant = (payload) => ({
  type: LOAD_PRODUCT_VARIANT,
  payload,
});

export const fetchProductDetails = (payload, withRedirect = true) => ({
  type: FETCH_PRODUCT_DETAILS,
  payload,
  withRedirect,
});

export const fetchProductDetailsRequest = (payload) => ({
  type: FETCH_PRODUCT_DETAILS__REQUEST,
  payload,
});

export const fetchProductDetailsSuccess = (product, withRedirect = true) => ({
  type: FETCH_PRODUCT_DETAILS__SUCCESS,
  payload: {
    product,
    withRedirect,
  },
});

export const fetchProductDetailsFailure = (payload) => ({
  type: FETCH_PRODUCT_DETAILS__FAILURE,
  payload,
});

export const fetchEditModalProductDetails = (payload) => ({
  type: FETCH_EDIT_MODAL_PRODUCT_DETAILS,
  payload,
});

export const fetchEditModalProductDetailsRequest = (payload) => ({
  type: FETCH_EDIT_MODAL_PRODUCT_DETAILS__REQUEST,
  payload,
});

export const fetchEditModalProductDetailsSuccess = (product) => ({
  type: FETCH_EDIT_MODAL_PRODUCT_DETAILS__SUCCESS,
  payload: {
    product,
  },
});

export const fetchEditModalProductDetailsFailure = (payload) => ({
  type: FETCH_EDIT_MODAL_PRODUCT_DETAILS__FAILURE,
  payload,
});

export const setLastModifierSelection = (payload) => ({
  type: SET_LAST_MODIFIER_SELECTION,
  payload,
});

export const setAddOnModifiers = (payload) => ({
  type: SET_ADD_ON_MODIFIERS,
  payload,
});

export const setTufted = (payload) => ({
  type: SET_TUFTED,
  payload,
});

export const setSelectedModifiers = (payload) => ({
  type: SET_SELECTED_MODIFIERS,
  payload,
});

export const setSelectedQty = (payload) => ({
  type: SET_SELECTED_QUANTITY,
  payload,
});

export const toggleModifierOption = (payload, withRedirect) => ({
  type: TOGGLE_MODIFIER_OPTION,
  payload,
  withRedirect,
});

export const FETCH_PRODUCT_AVAILABILITY = `${prefix} FETCH_PRODUCT_AVAILABILITY`;
export const FETCH_PRODUCT_AVAILABILITY__REQUEST = `${prefix} FETCH_PRODUCT_AVAILABILITY__REQUEST`;
export const FETCH_PRODUCT_AVAILABILITY__SUCCESS = `${prefix} FETCH_PRODUCT_AVAILABILITY__SUCCESS`;
export const FETCH_PRODUCT_AVAILABILITY__FAILURE = `${prefix} FETCH_PRODUCT_AVAILABILITY__FAILURE`;

export const fetchProductAvailability = (payload) => ({
  type: FETCH_PRODUCT_AVAILABILITY,
  payload,
});
export const fetchProductAvailabilityRequest = (payload) => ({
  type: FETCH_PRODUCT_AVAILABILITY__REQUEST,
  payload,
});
export const fetchProductAvailabilitySuccess = (payload) => ({
  type: FETCH_PRODUCT_AVAILABILITY__SUCCESS,
  payload,
});
export const fetchProductAvailabilityFailure = (payload) => ({
  type: FETCH_PRODUCT_AVAILABILITY__FAILURE,
  payload,
});

export const FETCH_PRODUCT_SHIPPING_ESTIMATE__SUCCESS = `${prefix} FETCH_PRODUCT_SHIPPING_ESTIMATE__SUCCESS`;

export const fetchProductShippingEstimateSuccess = (payload) => ({
  type: FETCH_PRODUCT_SHIPPING_ESTIMATE__SUCCESS,
  payload,
});

export const FETCH_PRODUCT_PROMOTIONS__REQUEST = `${prefix} FETCH_PRODUCT_PROMOTIONS__REQUEST`;
export const FETCH_PRODUCT_PROMOTIONS__SUCCESS = `${prefix} FETCH_PRODUCT_PROMOTIONS__SUCCESS`;
export const FETCH_PRODUCT_PROMOTIONS__FAILURE = `${prefix} FETCH_PRODUCT_PROMOTIONS__FAILURE`;

export const fetchProductPromotionsRequest = (payload) => ({
  type: FETCH_PRODUCT_PROMOTIONS__REQUEST,
  payload,
});
export const fetchProductPromotionsSuccess = (payload) => ({
  type: FETCH_PRODUCT_PROMOTIONS__SUCCESS,
  payload,
});
export const fetchProductPromotionsFailure = (payload) => ({
  type: FETCH_PRODUCT_PROMOTIONS__FAILURE,
  payload,
});

export const FETCH_ADDON_MODIFIER_OPTION__INVENTORY = `${prefix} FETCH_ADDON_MODIFIER_OPTION__INVENTORY`;
export const FETCH_ADDON_MODIFIER_OPTION__INVENTORY_REQUEST = `${prefix} FETCH_ADDON_MODIFIER_OPTION__INVENTORY_REQUEST`;
export const FETCH_ADDON_MODIFIER_OPTION__INVENTORY__SUCCESS = `${prefix} FETCH_ADDON_MODIFIER_OPTION__INVENTORY__SUCCESS`;
export const FETCH_ADDON_MODIFIER_OPTION__INVENTORY__FAILURE = `${prefix} FETCH_ADDON_MODIFIER_OPTION__INVENTORY__FAILURE`;

export const fetchAddonModifierOptionsInventory = (payload) => ({
  type: FETCH_ADDON_MODIFIER_OPTION__INVENTORY,
  payload,
});
export const fetchAddonModifierOptionsInventoryRequest = (payload) => ({
  type: FETCH_ADDON_MODIFIER_OPTION__INVENTORY_REQUEST,
  payload,
});
export const fetchAddonModifierOptionsInventorySuccess = (payload) => ({
  type: FETCH_ADDON_MODIFIER_OPTION__INVENTORY__SUCCESS,
  payload,
});
export const fetchAddonModifierOptionsInventoryFailure = (payload) => ({
  type: FETCH_ADDON_MODIFIER_OPTION__INVENTORY__FAILURE,
  payload,
});
