import { call, put, takeLatest } from 'redux-saga/effects';
import { gql } from '@apollo/client/core';
import {
  FETCH_JOBS, fetchJobsFailure, fetchJobsRequest, fetchJobsSuccess,
} from '../actions/careers.actions';
import RequestHelpers from '../../components/global/RequestHelpers';
import { setErrorPopupMessage } from '../actions/ui.actions';
import { getCareersSchema } from '../../../../apis/QueryBuilder';
import { logError } from '../../../../utils/Utils';

export function* fetchJobsSaga() {
  yield put(fetchJobsRequest());
  try {
    const query = gql`query GetCareers {
      careers: getCareers { ${getCareersSchema()} }
    }`;
    const {
      careers: { jobs },
    } = yield call(RequestHelpers.apolloGqlQuery, query);

    yield put(fetchJobsSuccess(jobs));
  } catch (error) {
    yield call([this, logError], error, fetchJobsSaga.name);
    if (error.message) {
      yield put(setErrorPopupMessage(RequestHelpers.errorMessages.getJobs));
    }
    yield put(fetchJobsFailure(error));
  }
}

const pageSagas = [
  takeLatest(FETCH_JOBS, fetchJobsSaga),
];

export default pageSagas;
