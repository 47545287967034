/* eslint-disable no-underscore-dangle */

import React, { Component } from 'react';
import { renderRoutes, matchRoutes } from 'react-router-config';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './scss/App.scss';
import './scss/Styleguide.scss';
import './scss/components/_product-edit-modal.scss';
import './scss/components/_product-modifiers.scss';
import './scss/components/_above-the-fold.scss';
import './scss/components/_cart.scss';
import './scss/components/_collection-picker-checkbox.scss';
import './scss/components/_storycard.scss';
import './scss/components/_color-picker-radio.scss';
import './scss/components/_testimonials.scss';
import './scss/components/_content-break.scss';
import './scss/components/_countdown.scss';
import './scss/components/_design-features.scss';
import './scss/components/_dropdown-download.scss';
import './scss/components/_featured-products.scss';
import './scss/components/_filters.scss';
import './scss/components/_hero-general.scss';
import './scss/components/_hero-plp.scss';
import './scss/components/_home-hero.scss';
import './scss/components/_info-highlights.scss';
import './scss/components/_item.scss';
import './scss/components/_maxwidthcard.scss';
import './scss/components/_modal.scss';
import './scss/components/_shop-by-spaces.scss';
import './scss/components/_slider_arrows.scss';
import './scss/components/_ourapproach.scss';
import './scss/components/_parallax-scroll.scss';
import './scss/components/_shop-by-theme.scss';
import './scss/components/_product-carousel-slider.scss';
import './scss/components/_pill-picker-radio.scss';
import './scss/components/_spotlight-card.scss';
import './scss/components/_shopping-space.scss';
import './scss/components/_selected-reviews.scss';
import './scss/components/_threepoint.scss';
import './scss/components/_save-cart-modal.scss';
import './scss/components/_sale-thermometer.scss';
import './scss/components/_plain-text.scss';
import './scss/components/_product-carousel.scss';
import './scss/components/_product-category-card.scss';
import './scss/components/_recruiteejobs.scss';
import './scss/components/_values.scss';
import './scss/components/_promotion-copy.scss';
import './scss/components/_sales-tiers.scss';
import './scss/components/_welcome.scss';
import './scss/components/_with-modal.scss';
import './scss/components/_youtube-video.scss';
import './scss/pages/_reviews.scss';
import './scss/pages/_checkout.scss';
import './scss/components/_story-block.scss';
import './scss/components/_preview-images.scss';
import './scss/components/_restock-notifications.scss';
import './scss/pages/_login-create.scss';
import './scss/pages/_orders.scss';
import './scss/pages/_showrooms.scss';
import './scss/partials/_address.scss';
import './scss/pages/product.scss';
import './scss/partials/_icons.scss';

import Meta from './Meta';
import SMSContainer from './components/global/SMS';
import { FavoritesBucket } from './components/shopping/FavoritesBucket';
import Popup from './components/checkout/partials/Popup';
import ForterRefreshToken from './components/global/ForterRefreshToken';
import BrazeInAppMessageListener from './components/global/BrazeInAppMessageListener';
import { Provider } from './contexts/Context';
import {
  getIsUserLoggedIn,
  getUserDetails,
} from './store/selectors/user.selectors';
import {
  fetchProductDetails,
  loadProductVariant,
} from './store/actions/product.actions';

import {
  getWindowWidth,
  getHeaderTextColor,
  getErrorPopupMessage,
  getExperiments,
  getFooterNavigation,
  getHeaderNavigation,
  getCXHours,
  getIsCartOpen,
  getIsFiltersPopoutOpen,
  getPopupRedirect,
  getPopupOnClose,
  getNavPinned,
  getIsBXActive,
  getIsMobileMenuOpen,
  getIsXLWidth,
} from './store/selectors/ui.selectors';
import {
  getPageContent,
  getBackgroundColor,
  getFilterID,
} from './store/selectors/page.selectors';
import {
  getCartDetails,
  getIsCartSavedForLater,
  getSaveCartModalOpen,
  getAppliedTieredSale,
  getSalesTiersModalOpen,
} from './store/selectors/cart.selectors';
import { getProductDetails, getIsProductSoldOut } from './store/selectors/product.selectors';
import { isFavoritesBucketVisible as isFavoritesBucketVisibleSelector } from './store/selectors/favorites.selector';
import { getQueryParams } from './store/selectors/router.selectors';
import { saveCartForLater, setSaveCartModalOpen, setSalesTiersModalOpen } from './store/actions/cart.actions';
import {
  fetchPageContent,
} from './store/actions/page.actions';
import { fetchLoggedInUser } from './store/actions/user.actions';
import {
  injectScript,
  openCart,
  setErrorPopupMessage,
  setWindowWidth,
  updateHeaderNavigation,
  updateFooterNavigation,
  updateExperimentsFlags,
  setIsGoogleMapsActive,
  setIsOnline,
  setIsFiltersPopoutOpen,
} from './store/actions/ui.actions';
import { initializeCollectionsData } from './store/actions/collections.actions';
import { general as generalGlobalAPI } from './mock/globalAPI';
import {
  detectIE,
  getContainerStyles,
} from '../../utils/Utils';
import { getHrefLang } from './store/selectors/general.selectors';
import { loadLoadableAsyncComponent } from './components/global/ComponentsLibrary';
import HeaderContainer from './components/global/header/Header';
import FooterContainer from './components/global/footer/Footer';
import FiltersPopout from './components/global/Filters/FiltersPopout';
import CartPopoutContainer from './components/global/Cart/CartPopout';

const SaveCartModalLoadable = loadLoadableAsyncComponent('SaveCartModal');
const SalesTiersModalLoadable = loadLoadableAsyncComponent('SalesTiersModal');

export class App extends Component {
  constructor(props) {
    super(props);

    const {
      updateHeaderNavigationAction,
      updateFooterNavigationAction,
      updateExperimentFlagsAction,
    } = this.props;
    const context = this.props.staticContext;
    if (context) {
      this.experiments = context?.experiments;
      if (context.url) {
        this.host = context.url;
      }
      if (context.headerNavigation) {
        updateHeaderNavigationAction(context.headerNavigation);
      }
      if (context.footerNavigation) {
        updateFooterNavigationAction(context.footerNavigation);
      }
    } else {
      this.experiments = window?.__PRELOADED_STATE__?.ui?.experiments;
      if (window.__PRELOADED_STATE__ && window.__PRELOADED_STATE__.ui.headerNavigation) {
        updateHeaderNavigationAction(window.__PRELOADED_STATE__.ui.headerNavigation);
      }
      if (window.__PRELOADED_STATE__ && window.__PRELOADED_STATE__.ui.footerNavigation) {
        updateFooterNavigationAction(window.__PRELOADED_STATE__.ui.footerNavigation);
      }
      this.host = window.__PRELOADED_STATE__.general.hostUrl;
    }
    updateExperimentFlagsAction(this.experiments);
  }

  componentDidMount() {
    const {
      fetchLoggedInUserAction,
      injectScriptAction,
      loadProductVariantAction,
      productDetails,
      history,
      setErrorPopupMessageAction,
    } = this.props;

    fetchLoggedInUserAction();
    injectScriptAction('affirm');

    this.setWindowState();
    window.addEventListener('resize', this.setWindowState.bind(this));
    window.addEventListener('keydown', this.handleFirstTab);
    // https://www.washington.edu/accessibility/checklist/focus/
    // if the user tabs, we add an accessibility class to the page that styles the :focus state of all the elements
    if (productDetails) {
      loadProductVariantAction({
        query: history.location.search,
        productDetails,
        tufted: true,
      });
    }

    if (detectIE()) {
      setErrorPopupMessageAction(generalGlobalAPI.detectedIE);
    }

    window.addEventListener('online', () => { this.props.setIsOnlineAction(true); });
    window.addEventListener('offline', () => { this.props.setIsOnlineAction(false); });
  }

  componentDidUpdate(prevProps) {
    const {
      queryParams,
      fetchPageContentAction,
      location,
      productDetails,
    } = this.props;

    if (prevProps.location.pathname !== location.pathname || prevProps.location.search !== location.search) {
      fetchPageContentAction(location.pathname);
    }

    // TODO dispatch sku change action, and move the logic to sagas
    const prevSku = prevProps.queryParams?.sku || prevProps.productDetails?.sku;
    const sku = queryParams?.sku || productDetails?.sku;

    if (
      sku && prevSku !== sku
      && (!productDetails || productDetails.sku !== sku)
    ) {
      this.props.fetchProductDetailsAction({ sku });
    }
  }

  handleFirstTab = (e) => {
    if (e.keyCode === 9) {
      const el = document.querySelector('.site');
      el.classList.add('accessibility');
      window.removeEventListener('keydown', this.handleFirstTab);
    }
  };

  setWindowState() {
    this.props.setWindowWidthAction(window.innerWidth);
  }

  render() {
    const {
      errorPopupMessage,
      popupRedirect,
      popupOnClose,
      experiments,
      headerTextColor,
      footerNavigation,
      headerNavigation,
      windowWidth,
      productDetails,
      isUserLoggedIn,
      userDetails,
      openCartAction,
      setIsFiltersPopoutOpenAction,
      isCartOpen,
      isFiltersPopoutOpen,
      cartDetails,
      queryParams,
      bxActive,
      cxHours,
      navPinned,
      backgroundColor,
      isCartSavedForLater,
      saveCartForLaterAction,
      setSaveCartModalOpenAction,
      isSaveCartModalOpen,
      appliedTieredSale,
      salesTiersModalOpen,
      setSalesTiersModalOpenAction,
      route,
      isProductSoldOut,
      filterID,
      isMobileMenuOpen,
      hrefLang,
      isDesktop,
      isFavoritesBucketVisible,
    } = this.props;
    const pageContent = this.props.pageContent || {};
    const context = {
      queryParams,
      userDetailsEmail: userDetails.email,
      cmsPage: pageContent,
      history: this.props.history,
      experiments,
      windowWidth: this.props.windowWidth,
      host: this.host,
      location: this.props.location,
    };
    const matchedRoutes = matchRoutes(route.routes, this.props.location.pathname);

    return (
      <div
        className={'site'}
        style={getContainerStyles(backgroundColor)}
      >
        <Meta
          url={pageContent.url}
          pageTitle={pageContent.pageTitle}
          pageDescription={pageContent.pageDescription}
          pageImage={pageContent.pageImage && pageContent.pageImage.url}
          availablePages={pageContent.availablePages}
          isPDP={pageContent.type === 'PDP'}
          productDetails={productDetails}
          host={this.host}
          matchedRoutes={matchedRoutes}
          isProductSoldOut={isProductSoldOut}
        />
        {pageContent.pageTitle ? <h1 style={{ display: 'none' }}>{pageContent.pageTitle}</h1> : null}
        <CartPopoutContainer
          history={this.props.history}
          navPinned={navPinned}
        />
        {(appliedTieredSale && salesTiersModalOpen) && (
          <SalesTiersModalLoadable
            appliedTieredSale={appliedTieredSale}
            open={salesTiersModalOpen}
            onClose={() => setSalesTiersModalOpenAction(false)}
          />
        )}
        {isSaveCartModalOpen && (
          <SaveCartModalLoadable
            open={isSaveCartModalOpen}
            saved={isCartSavedForLater}
            onClose={() => setSaveCartModalOpenAction(false)}
            onSubmit={saveCartForLaterAction}
          />
        )}
        <Provider value={context}>
          <ForterRefreshToken />
          <BrazeInAppMessageListener />
          <HeaderContainer
            path={this.props.location.pathname}
            cartNumber={(cartDetails && cartDetails.cartNumber) || 0}
            isLoggedIn={isUserLoggedIn}
            isCartOpen={isCartOpen}
            isFiltersPopoutOpen={isFiltersPopoutOpen}
            isMobileMenuOpen={isMobileMenuOpen}
            experiments={experiments}
            headerColor={headerTextColor}
            navigation={headerNavigation}
            windowWidth={windowWidth}
            bannerContent={pageContent && pageContent.bannerContent}
            openCart={openCartAction}
            setIsFiltersPopoutOpen={setIsFiltersPopoutOpenAction}
          />
          {!isDesktop && filterID && <FiltersPopout />}
          <div
            className={classNames('actual-site', {
              'bx-active': bxActive,
            })}
          >
            {renderRoutes(route.routes, pageContent)}
            {
              isFavoritesBucketVisible && (
                <FavoritesBucket group="swatches" />
              )
            }
          </div>
        </Provider>
        <FooterContainer
          cxHours={cxHours}
          navigation={footerNavigation}
          host={this.host}
        />
        <SMSContainer
          cxHours={cxHours}
          windowWidth={windowWidth}
          userDetails={userDetails}
          icon={true}
          hrefLang={hrefLang}
          isFavoritesBucketVisible={isFavoritesBucketVisible}
        />
        {(errorPopupMessage) && (
          <Popup
            history={this.props.history}
            message={errorPopupMessage}
            redirect={popupRedirect}
            onClose={popupOnClose}
          />
        )}
      </div>
    );
  }
}

App.propTypes = {
  injectScriptAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  windowWidth: getWindowWidth(state),
  headerTextColor: getHeaderTextColor(state),
  errorPopupMessage: getErrorPopupMessage(state),
  experiments: getExperiments(state),
  popupRedirect: getPopupRedirect(state),
  popupOnClose: getPopupOnClose(state),
  footerNavigation: getFooterNavigation(state),
  headerNavigation: getHeaderNavigation(state),
  cxHours: getCXHours(state),
  pageContent: getPageContent(state),
  productDetails: getProductDetails(state),
  isUserLoggedIn: getIsUserLoggedIn(state),
  userDetails: getUserDetails(state),
  isCartOpen: getIsCartOpen(state),
  isFiltersPopoutOpen: getIsFiltersPopoutOpen(state),
  isMobileMenuOpen: getIsMobileMenuOpen(state),
  bxActive: getIsBXActive(state),
  cartDetails: getCartDetails(state),
  queryParams: getQueryParams(state),
  navPinned: getNavPinned(state),
  backgroundColor: getBackgroundColor(state),
  isCartSavedForLater: getIsCartSavedForLater(state),
  isSaveCartModalOpen: getSaveCartModalOpen(state),
  appliedTieredSale: getAppliedTieredSale(state),
  salesTiersModalOpen: getSalesTiersModalOpen(state),
  isProductSoldOut: getIsProductSoldOut(state),
  filterID: getFilterID(state),
  hrefLang: getHrefLang(state),
  isDesktop: getIsXLWidth(state),
  isFavoritesBucketVisible: isFavoritesBucketVisibleSelector(state),
});

const mapDispatchToProps = {
  setWindowWidthAction: setWindowWidth,
  setErrorPopupMessageAction: setErrorPopupMessage,
  updateHeaderNavigationAction: updateHeaderNavigation,
  updateFooterNavigationAction: updateFooterNavigation,
  updateExperimentFlagsAction: updateExperimentsFlags,
  fetchPageContentAction: fetchPageContent,
  initializeCollectionsDataAction: initializeCollectionsData,
  loadProductVariantAction: loadProductVariant,
  fetchProductDetailsAction: fetchProductDetails,
  fetchLoggedInUserAction: fetchLoggedInUser,
  injectScriptAction: injectScript,
  openCartAction: openCart,
  setIsFiltersPopoutOpenAction: setIsFiltersPopoutOpen,
  setIsGoogleMapsActiveAction: setIsGoogleMapsActive,
  setIsOnlineAction: setIsOnline,
  saveCartForLaterAction: saveCartForLater,
  setSaveCartModalOpenAction: setSaveCartModalOpen,
  setSalesTiersModalOpenAction: setSalesTiersModalOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
