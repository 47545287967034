import striptags from 'striptags';
import qs from 'qs';
import pick from 'lodash/pick';
import isEqual from 'lodash/isEqual';
import { gql } from '@apollo/client/core';
import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import RequestHelpers from './RequestHelpers';
import paths from '../../paths';
import {
  circaFrameToHeadboard,
  circaHeadboardToMaterial,
  chorusColorToMaterial,
  chorusFrameColorToHeadboardColor,
  chorusOptionToSetVariable,
  legColorsToWoodTones,
  s3ProductUrlBase,
  vesperColorToPillow,
  vesperMaterialToColor,
  picaColorToMaterial,
  lodgeColorToMaterial,
  woodTonesToLegColors,
  haikuSeatsToWoodTones,
  altoSeatsToWoodTones,
} from '../../mock/globalAPI';
import { getPromotionFields, getVariantQuery } from '../../../../apis/QueryBuilder';
import {
  logError,
  lowerFirst,
  splitSku,
} from '../../../../utils/Utils';
import getCurrencyCode from '../../common/getCurrencyCode';
import { ComponentType } from '../../types/schema/enums';
import generateUrlWithHrefLang from '../../common/generateUrlWithHrefLang';
import handleSetMaterialColor from '../../common/handleSetMaterialColor';

/**
 * @typedef {import('../../types/schema/models/Asset.dto.ts').AssetDto} AssetDto
 * @typedef {import('../../types/schema').ProductModifierDto} ProductModifierDto
 * @typedef {import('../../types/schema').ModifierOptionDto} ProductModifierOptionDto
 * @typedef {import('../../types/schema').OnSelectFunction} OnSelectFunction
 * @typedef {import('../../types/schema').ProductDto} ProductDto
 * @typedef {import('../../../../Product/Variant').default} BaseVariant
 * @typedef {import('../../../../Product/dto/modifier.dto').default} CMSModifierDto
 * @typedef {import('../../../../Product/dto/modifier.dto').ModifierOptionDto} CMSModifierOptionDto
 * @typedef {import('../../../../shared/bigCommerce/formattedBigCommerceRelatedProduct.dto').default} FormattedBigCommerceRelatedProductDto
 * @typedef {import('../../../../shared/skuSplit').default} SkuSplit
 * @typedef {import('../../../../shared/graphCms/breadcrumb.dto').default} Breadcrumbs
 */

const COVER_REGEX = /CV(?<coverNumber>[0-9])(?<coverDirection>[L|R]?)/;

const VIDEO_MIMETYPES = ['video/mp4', 'application/vnd.apple.mpegURL'];

const fabricDefaultModifiers = { color: 'CG' };
const leatherDefaultModifiers = { color: 'CN' };
const velvetDefaultModifiers = { color: 'FT', arms: 'MD' };

const fabricSkus = ['NSF', 'NSO', 'NSC', 'NSCO', 'NSCC', 'NSL', 'NSU'];
const leatherSkus = ['NLSF', 'NLSO', 'NLSC', 'NLSCO', 'NLSCC', 'NLSL', 'NLSU'];
const velvetSkus = ['NVSF', 'NVSO', 'NVSC', 'NVSCO', 'NVSCC', 'NVSL'];

export const DEFAULT_NOMAD_SOFA_MODIFIERS = {};

fabricSkus.forEach((key) => {
  DEFAULT_NOMAD_SOFA_MODIFIERS[key] = { ...fabricDefaultModifiers };
});
leatherSkus.forEach((key) => {
  DEFAULT_NOMAD_SOFA_MODIFIERS[key] = { ...leatherDefaultModifiers };
});
velvetSkus.forEach((key) => {
  DEFAULT_NOMAD_SOFA_MODIFIERS[key] = { ...velvetDefaultModifiers };
});

/**
 * @param {{ [P in Uncapitalize<OnSelectFunction>]?: string }} setSkuPartObject
 * @param {SkuSplit} skuSplit
 */
export const prepareSkuPartsObject = (setSkuPartObject, skuSplit) => {
  /** @type {[Uncapitalize<OnSelectFunction>, string][]} */
  const entries = Object.entries(setSkuPartObject);

  return (
    /** @type {SkuSplit} */
    entries.reduce((acc, [onSelectFn, setValue]) => {
      const key = lowerFirst(onSelectFn.slice(3));
      let value = setValue;

      if (key === 'tableFinish') {
        const legs = setSkuPartObject.setLegs || skuSplit.legs;
        value = value || legColorsToWoodTones[legs] || skuSplit.tableFinish;
      } else if (key === 'type') {
        value = value || skuSplit[key] || '';
      } else {
        value = value || skuSplit[key];
      }

      acc[key] = value;
      return acc;
    }, {  ...skuSplit })
  );
};

/**
 *
 * @param {{ [P in Uncapitalize<OnSelectFunction>]?: string }} setSkuPartObject
 * @param {SkuSplit} skuSplit
 * @param {object} [skuParse]
 */
export function generateSku(
  setSkuPartObject,
  skuSplit,
  skuParse = {},
) {
  const skuParts = prepareSkuPartsObject(setSkuPartObject, skuSplit);
  let {
    arms = '',
    collection = '',
    color = '',
    cover = '',
    legs = '',
    material = '',
    type = '',
  } = skuParts;

  const {
    attachedOttoman,
    cushionColor,
    furnitureType,
    legStyle,
    model,
    size,
    styleIdentifier,
    tableFinish,
    woodFinish,
    accessory,
    brand,
    direction,
    partner,
  } = skuParts;

  const {
    setColor = '',
    setCover = '',
    setMaterial = '',
    setMaterialColor = '',
  } = setSkuPartObject;

  /** @type {string | undefined} */
  let sku;

  if (type.match(/^(N)?(V|L)?(SF|SO|SL|SLO|SLC|SLCO|SU|SCC|SCO|SC)$/)) {
    if (setMaterial) {
      type = setMaterial;
      ({ color, arms = arms } = DEFAULT_NOMAD_SOFA_MODIFIERS[setMaterial]);
    }
    sku = `${type}-${color}-${size}-${arms}-${legs}`;
  } else if (type.match(/^(N|N3)?(V|L)?(OT|CS|CR|ST)$/)) {
    sku = `${type}-${color}-${legs}`;
  } else if (type.match(/^(N|N3)?(V|L)?(LG)$/)) {
    sku = `${type}-${size}-${legs}`;
  } else if (type.match(/^(N|N3)?(V|L)?(AR)$/)) {
    sku = `${type}-${color}-${arms}-${legs}`;
  }

  switch (type) {
    case 'LRST': // Carta & Ember seating
      // TODO: think about a better/broader approach that doesn't require code change for upcoming new skus
      if (furnitureType === 'BN' && collection === 'BT') {
        sku = `${type}-${furnitureType}-${collection}-${cushionColor}-${color}-${legStyle}-${legs}`;
      } else {
        sku = `${type}-${furnitureType}-${collection}-${size}-${color}-${legs}`;
      }
      break;
    case 'LRAS': // Gimlet seating
      sku = `${type}-${furnitureType}-${collection}-${color}-${legs}`;
      break;
    case 'ODST': // Dunes seating
      sku = `${type}-${furnitureType}-${collection}-${size}-${arms}-${attachedOttoman}-${color}-${woodFinish}`;
      break;
    case 'ODAS': // Dunes sun lounger and Scout folding chair
      sku = `${type}-${furnitureType}-${collection}-${color}-${woodFinish}`;
      break;
    case 'ODCU': // Dunes teak care kit
      sku = `${type}-${furnitureType}-${collection}`;
      break;
    case 'ODSET': // Scout chair bundle
      if (collection === 'SCT') {
        sku = `${type}-${furnitureType}-${collection}-${woodFinish}-${color}`;
      } else if (collection === 'DN') {
        if (furnitureType === 'DT') { // Dining bundles with table and chairs
          sku = `${type}-${furnitureType}-${size}-${collection}-${color}`;
        } else {
          sku = `${type}-${furnitureType}-${size}-${collection}-${woodFinish}-${color}`;
        }
      } else {
        sku = [type, furnitureType, size, collection, color]
          .filter((modifier) => modifier)
          .join('-');
      }
      break;
    case 'ALRRG':
      sku = `${type}-${size}-${styleIdentifier}${color ? `-${color}` : ''}`;
      break;
    case 'FLRTB': // tables
    case 'FLRSR': // storage
      sku = [type, model, collection, color, cushionColor, legStyle, legs]
        .filter((modifier) => modifier)
        .join('-');

      if (collection === 'CNN') {
        sku = [type, collection, size, color, legs]
          .filter(Boolean)
          .join('-');
      }
      break;
    case 'FLRSTB': // sofa table
      sku = `${type}-${collection}-${size}-${color}`;
      break;
    case 'OLRST':
      if (furnitureType === 'AC') {
        sku = `${type}-${furnitureType}-${collection}-${color}-${legs}`;
      } else if (collection === 'DN') { // Dunes coffee table
        sku = [type, furnitureType, collection, size, woodFinish]
          .filter((modifier) => modifier)
          .join('-');
        break;
      } else {
        sku = [type, furnitureType, collection, size, arms, color]
          .filter((modifier) => modifier)
          .join('-');
      }
      break;
    case 'ODRTB':
    case 'OLRTB':
      sku = [type, furnitureType, collection, color]
        .filter((modifier) => modifier)
        .join('-');
      break;
    case 'ODRST':
      if (collection === 'DN') { // Dunes bench
        sku = [type, furnitureType, collection, size, woodFinish]
          .filter((modifier) => modifier)
          .join('-');
        break;
      } else { // Relay dining chairs, set of 2
        sku = [type, furnitureType, collection, size, color]
          .filter((modifier) => modifier)
          .join('-');
        break;
      }
    case 'AOSTCV': // Outdoor Relay covers
      sku = [type, furnitureType, collection, partner, brand, size, direction]
        .filter((modifier) => modifier)
        .join('-');
      break;
    case 'FLRST': // seating
      if (setMaterialColor) {
        ({ color, collection } = handleSetMaterialColor.fieldSeating(
          setMaterialColor,
          skuSplit,
        ));
      }

      if (furnitureType === 'BN') {
        sku = [type, furnitureType, collection, color, cushionColor, legStyle, legs]
          .filter((modifier) => modifier)
          .join('-');
      } else if (collection === 'VP') { // Vesper collection
        // FIXME: Add a multi-attribute sku modifier
        // https://hiburrow.atlassian.net/browse/BTR-367
        let materialAndCover = `${material}-${cover}`;
        if (setMaterial) {
          materialAndCover = material;
          color = vesperMaterialToColor[materialAndCover];
        }
        sku = `${type}-${furnitureType}-${collection}-${materialAndCover}-${color}-${legs}`;
      } else if (collection === 'PI' || collection === 'LD') { // Pica & Lodge collections
        if (setColor && collection === 'PI') {
          material = picaColorToMaterial[color];
        } else if (setColor && collection  === 'LD') {
          material = lodgeColorToMaterial[color];
        }
        sku = `${type}-${furnitureType}-${collection}-${material}-${color}-${legs}`;
      } else if (/(?!^STL$|^OT$|^ST$)(^.*T$)/.test(furnitureType)) {
        sku = [type, furnitureType, collection, size, arms, attachedOttoman, color, legs, tableFinish]
          .filter((modifier) => modifier)
          .join('-');
      } else if (['CRLG', 'CNLG'].includes(furnitureType)) {
        sku = `${type}-${furnitureType}-${collection}-${legs}`;
      } else {
        sku = [type, furnitureType, collection, size, arms, attachedOttoman, color, legs]
          .filter((modifier) => modifier)
          .join('-');
      }
      break;
    case 'FHOTB':
      sku = [type, model, collection, color, legs]
        .filter(Boolean)
        .join('-');
      break;
    case 'WS':
      sku = `${collection}-${type}-${size}-${color}`;
      break;
    case 'ALRLT':
      sku = [type, model, partner, brand, styleIdentifier, color, size]
        .filter((modifier) => modifier)
        .join('-');
      break;
    case 'ALRSA':
      sku = [type, accessory, collection, color, size]
        .filter((modifier) => modifier)
        .join('-');
      break;
    case 'ALRTW':
    case 'ALRPL':
      if (partner === 'CUR') {
        sku = [type, model, partner, brand, styleIdentifier, color]
          .filter((modifier) => modifier)
          .join('-');
      } else if (skuSplit.color) {
        sku = [type, model, styleIdentifier, color]
          .filter((modifier) => modifier)
          .join('-');
      } else {
        // OG Pillow and Throw skus that do not have modifiers
        sku = `${type}-${styleIdentifier}`;
      }
      break;
    case 'SAMP':
      sku = `${type}-${model}-${collection}-${material}`;
      break;
    case 'ABRFL':
    case 'ABRPL':
    case 'ABRSB':
      sku = [type, model, material, partner, brand, size, color]
        .filter((modifier) => modifier)
        .join('-');
      break;
    case 'FBRBS': {
      const isWoodColor = Object.keys(woodTonesToLegColors).includes(color);
      // FIXME: Add a multi-attribute sku modifier
      // https://hiburrow.atlassian.net/browse/BTR-367
      if (material === 'UH' && isWoodColor) {
        color = 'FRSG';
        legs = 'BM';
      } else if (material === 'WD' && !isWoodColor) {
        color = 'FRWN';
      }

      if (model === 'FR' && material === 'WD') {
        legs = woodTonesToLegColors[color];
      }

      sku = [type, model, collection, size, material, color, legs]
        .filter((modifier) => modifier)
        .join('-');

      break;
    }
    case 'BRBD': { // Chorus 3.0
      if (model === 'BF') { // If bed frame
        if (setColor === 'FROK') {
          material = 'WD';
          legs = 'OKL';
        } else if (setColor === 'FRWN') {
          material = 'WD';
          legs = 'WNL';
        } else if (setColor === 'FRSG') {
          material = 'UH';
        } else if (setColor === 'FRHC') {
          material = 'UH';
        } else if (setColor === 'FRPY') {
          material = 'UH';
        }
        sku = [type, model, collection, size, material, legStyle, color, legs]
          .filter((modifier) => modifier)
          .join('-');
      }
      if (model === 'HB') {
        if (setColor === 'OK') {
          material = 'WD';
        } else if (setColor === 'WN') {
          material = 'WD';
        } else if (setColor === 'SG') {
          material = 'UH';
        } else if (setColor === 'HC') {
          material = 'UH';
        } else if (setColor === 'PY') {
          material = 'UH';
        }
        sku = [type, model, collection, size, material, color]
          .filter((modifier) => modifier)
          .join('-');
      }
      break;
    }
    case 'FBRSR': // Prospect and Heist
      legs = woodTonesToLegColors[color];
      sku = [type, model, collection, size, color, legs]
        .filter((modifier) => modifier)
        .join('-');
      break;
    case 'FBRMT':
      sku = `${type}-${model}-${collection}-${size}`;
      break;
    case 'FDRST': // original Haiku and Alto dining collection
      legs = collection === 'HKU' ? haikuSeatsToWoodTones[color] : altoSeatsToWoodTones[color];
      sku = `${type}-${model}-${collection}-${size}-${color}-${legs}`;
      break;
    case 'FDRTB': // Serif Dining Table
      sku = `${type}-${model}-${collection}-${color}`;
      break;
    case 'FHOST':
      sku = `${type}-${model}-${partner}-${brand}-${styleIdentifier}-${color}`;
      break;
    case 'N3STNST':
    case 'N3CSNCS':
    case 'N3CRNCR':
      sku = `${type}-${color}-${legs}`;
      break;
    case 'OLRSET':
      // only reassign cover variable if the action is setting a cover option by setCover
      if (setCover) {
        const newCoverRegexMatched = cover.match(COVER_REGEX);
        const skuSplitCoverRegexMatched = skuSplit.cover.match(COVER_REGEX);
        const newCoverHasNoDirection = !newCoverRegexMatched?.groups?.coverDirection;
        const currentSkuSplitCoverNumber = skuSplitCoverRegexMatched?.groups?.coverNumber;
        const is4Or6PieceSofas = furnitureType.startsWith('4S') || furnitureType.startsWith('6S');

        // handle adding/removing
        // if there is no cover direction and new cover number with current skuSplit cover number are equals, means it is beeing removing or unselecting the cover option
        if (newCoverHasNoDirection && newCoverRegexMatched?.groups?.coverNumber === currentSkuSplitCoverNumber) {
          cover = 'CV0';
        }

        // if is 4-piece and 6-piece sofas and cover option is not selected, means it is being adding the cover option and set cover left "L" as default
        if (is4Or6PieceSofas && currentSkuSplitCoverNumber === '0') {
          cover += 'L';
        }
      }

      sku = [type, furnitureType, collection, arms, cover, color]
        .filter((modifier) => modifier)
        .join('-');
      break;
    case 'ODRSET':
      sku = [type, furnitureType, size, collection, color]
        .filter((modifier) => modifier)
        .join('-');
      break;
    default:
      if (skuParse?.skuFields && skuParse?.skuFields.length > 0) {
        const skuParseVariables = {
          arms, attachedOttoman, collection, color, cover, cushionColor, furnitureType, legs, legStyle, material, model, size, styleIdentifier, tableFinish, type, woodFinish, accessory, brand, direction, partner,
        };
        sku = skuParse.skuFields.map((field) => skuParseVariables[field]).join('-');
      }
      break;
  }
  return sku;
}

/**
 * resolve range table finish based on skuSplit and selected addons
 * @function
 * @param {Object} skuSplit product skuSplit
 * @param {Array} addOns selected AddToCart ModifierOptions
 */
export function getRangeTableFinishFromAddOns({ tableFinish, legs } = {}, addOns) {
  const rangeTableAddon = addOns?.find((addOn) => addOn.value === 'FLRSTB-RG-1');
  if (legs && rangeTableAddon) {
    return legColorsToWoodTones[legs] || 'WN';
  }
  return tableFinish;
}

// FIXME: @Steph https://app.hive.com/workspace/BPQTWhi9hPh6N6usf?actionId=juASjeSXrt6howgp9
export function getCircaHeadboardColorFromAddOns({ color, material, size } = {}, addOns) {
  const headboardParentSku = `FBRBS-HB-CI-${size}`;
  const circaHeadboardAddon = addOns?.find((addOn) => addOn.value.includes(headboardParentSku));
  switch (circaHeadboardAddon?.value) {
    case `${headboardParentSku}-UH`: {
      if (material === 'UH') {
        return circaFrameToHeadboard[color];
      }
      return 'UTHC';
    }
    case `${headboardParentSku}-WD`: {
      if (material === 'WD') {
        return circaFrameToHeadboard[color];
      }
      return 'WN';
    }
    default:
      return circaHeadboardAddon?.value;
  }
}

export function getChorusHeadboardColorFromAddOns({ color, material } = {}, addOns) {
  const chorusHeadboardAddon = addOns[0].value;
  const value = chorusHeadboardAddon;
  const desiredHeadboardMaterial = value.substring(value.length - 2);

  switch (desiredHeadboardMaterial) {
    case ('UH'): {
      if (material === 'UH') {
        return chorusFrameColorToHeadboardColor[color];
      }
      return 'SG'; // Return default headboard fabric color
    }
    case ('WD'): {
      if (material === 'WD') {
        return chorusFrameColorToHeadboardColor[color];
      }
      return 'WN'; // Return default headboard wood color
    }
    default:
      return desiredHeadboardMaterial;
  }
}

export function getRangeTableAddonSKU(skuSplit, rangeTableAddon) {
  const tableFinish = getRangeTableFinishFromAddOns(skuSplit, [rangeTableAddon]);
  return `${rangeTableAddon.value}-${tableFinish}${skuSplit.legs}`;
}

export function getCircaHeadboardAddonSKU(skuSplit, headboardAddon) {
  const circaHeadboardColor = getCircaHeadboardColorFromAddOns(skuSplit, [headboardAddon]);
  return `${headboardAddon.value}${circaHeadboardColor ? `-${circaHeadboardColor}` : ''}`;
}

export function getChorusHeadboardAddonSKU(skuSplit, headboardAddon) {
  const chorusHeadboardColor = getChorusHeadboardColorFromAddOns(skuSplit, [headboardAddon]);
  if (headboardAddon.value !== 'none') {
    return `${headboardAddon.value}${chorusHeadboardColor ? `-${chorusHeadboardColor}` : ''}`;
  }
  return 'none';
}

/**
 * @param slug
 * @param relatedProducts
 * @param optionValue
 * @param queryParamsValue
 * @param activeColor
 * @param activeLegs
 * @param activeLegStyle
 * @param activeSize
 * @returns {FormattedBigCommerceRelatedProductDto | undefined}
 */
export const getOptionRelatedProduct = ({
  slug,
  relatedProducts,
  value: optionValue,
  queryParamsValue = undefined,
  activeColor,
  activeLegs,
  activeLegStyle,
  activeSize,
}) => Array.isArray(relatedProducts) && relatedProducts.find(({ sku }) => {
  switch (slug) {
    case 'rug-pad':
      return sku.includes(`${optionValue}-PD`)
        || sku.includes(optionValue);
    case 'pickAPillow':
      return sku === `${optionValue}-${activeColor}`;
    case 'seating-pillows': {
      let pillowQuantity = '1';
      if (optionValue.startsWith('ALRSA-LB-MB')) {
        if (sku.endsWith('2')) {
          pillowQuantity = 2;
        }
      }
      if (optionValue.startsWith('ALRSA-BT')) {
        pillowQuantity = '2';
      }
      if (optionValue.includes('-VP')) {
        return sku === `${optionValue}-${vesperColorToPillow[activeColor] ? vesperColorToPillow[activeColor] : activeColor}-${pillowQuantity}`;
      }
      return sku === `${optionValue}-${activeColor}-${pillowQuantity}` || sku === queryParamsValue || sku === optionValue;
    }
    case 'coffee-table-add-on':
      if (optionValue.includes('OLRST-CT')) { // Dunes coffee table
        return sku.includes('OLRST-CT-DN-TK');
      }
      return sku.includes(`${queryParamsValue || optionValue}-${activeColor.slice(0, 3)}`);
    case 'ottoman-add-on':
      if (optionValue.includes('-RY')) { // Relay ottoman
        return sku === `${queryParamsValue || optionValue}-${activeColor}`;
      }
      if (optionValue.includes('ODST-OT')) { // Dunes ottoman
        return sku === `${queryParamsValue || optionValue}-${activeColor}-TK`;
      }
      return sku === `${queryParamsValue || optionValue}-${activeColor}-${activeLegs}`;
    case 'table-add-on':
      if (optionValue === 'FLRSTB-RG-1' && !queryParamsValue) {
        // For addon price generation, which lacks queryParamsValue
        const addonSku = getRangeTableAddonSKU({
          legs: activeLegs,
          /** TODO: this information is necessary to correctly generate the sku */
          // tableFinish: activeTableFinish,
        }, { value: optionValue });

        return sku === addonSku;
      }

      return sku === queryParamsValue;
    case 'round-tray':
    case 'headboard-add-on':
    case 'headboard-3-add-on':
      return sku === queryParamsValue;
    case 'table-finish':
      return sku === `FLRSTB-RG-1-${optionValue}${activeLegs}`;
    case 'headboard-color':
      return sku === `FBRBS-HB-CI-${activeSize}-${circaHeadboardToMaterial[optionValue]}-${optionValue}`;
    case 'headboard-3-color':
      return sku === `BRBD-HB-CI3-${activeSize}-${chorusColorToMaterial[optionValue]}-${optionValue}`;
    case 'round-tray-color':
      return sku === `ALRSA-TR-RD-${optionValue}`;
    default: {
      const formattedValue = optionValue.substring(0, optionValue.lastIndexOf('_'));
      const formattedSku = `${formattedValue || optionValue}-${activeColor}${activeLegStyle ? `-${activeLegStyle}` : ''}${activeLegs ? `-${activeLegs}` : ''}`;
      return sku === formattedSku || sku === optionValue;
    }
  }
});

export function checkManualAddress(data) {
  return new Promise((resolve, reject) => {
    if (data.formattedAddress || !window.google) {
      resolve(data);
    }
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode(
      {
        address: `${data.address} ${data.address2 ? data.address2 : ''} ${data.city} ${data.state}, ${data.zipcode}`,
      },
      (results, status) => {
        let streetNumber;
        let route;
        let city;
        let state;
        let zipcode;
        let sublocality;
        if (status === 'OK') {
          const place = results[0];
          const addressComponents = place.address_components;
          if (addressComponents) {
            addressComponents.forEach((component) => {
              const addressTypes = component.types;
              if (~addressTypes.indexOf('street_number')) {
                streetNumber = component.short_name;
              } else if (~addressTypes.indexOf('route')) {
                route = component.long_name;
              } else if (~addressTypes.indexOf('locality')) {
                city = component.long_name;
              } else if (~addressTypes.indexOf('sublocality')) {
                sublocality = component.long_name;
              } else if (~addressTypes.indexOf('administrative_area_level_1')) {
                state = component.short_name;
              } else if (~addressTypes.indexOf('postal_code')) {
                zipcode = component.long_name;
              }
            });
          }
          if (
            streetNumber
            && route
            && (city === data.city || sublocality === data.city)
            && state === data.state
            && zipcode === data.zipcode
          ) {
            resolve(place);
          } else {
            reject(new Error('Something is wrong with your shipping address'));
          }
        } else {
          reject(new Error('Something is wrong with your address, please try again.'));
        }
      },
    );
  });
}

export const modifierOptionsAreACloseMatch = (selectedModifierOptionValue, optionValue, queryParamValue, skuParse) => {
  // Vesper addons were dropped in filteredProductModifierQueryParams due to different ottomans,
  // However, the only difference between these ottomans are cover and material
  // Base attributes - type, furnitureType etc are the same

  // remove selectedModifierOptionValue from optionValue.
  const shortenedOptionSku = optionValue.replace(
    `-${selectedModifierOptionValue}`,
    '',
  );
  // Split and remove undefined and sku key, to get base attributes
  const shortenedOptionSplitSku = splitSku(shortenedOptionSku, skuParse);
  Object.keys(shortenedOptionSplitSku).forEach((key) => {
    if (shortenedOptionSplitSku[key] === undefined || key === 'sku') {
      delete shortenedOptionSplitSku[key];
    }
  });
  // split and delete the keys that do not match the keys of shortenedOptionSplitSku
  const queryParamSplitSKU = splitSku(queryParamValue, skuParse);
  Object.keys(queryParamSplitSKU).forEach((key) => {
    if (!Object.keys(shortenedOptionSplitSku).includes(key)) {
      delete queryParamSplitSKU[key];
    }
  });
  // compare shortenedOptionSplitSku & queryParamSplitSKU to verify they have the same base attributes
  return isEqual(shortenedOptionSplitSku, queryParamSplitSKU);
};

export function reapplyQueryParams(queryParams, sku, productModifiers, skuSplit, selectedModifierOptionValue, skuParse) {
  const filteredProductModifierQueryParams = Object.entries(queryParams)
    .map(([key, value]) => ({ key, value }))
    // query params represent currently selected addons,
    // so we gather them with matching productModifier by its slug.
    // Then, if the modfier has a shouldUpdateQueryParam flag set,
    // we might reapply one of its options.
    .map((queryParam) => ({
      queryParam,
      selectedOption: productModifiers
        .find((modifier) => modifier.slug === queryParam.key && modifier.shouldUpdateQueryParam)
        ?.options
        ?.find((option) => {
          // get the option sku for the heardboard size
          if (queryParam.key === 'headboard-add-on' && queryParam.value !== 'none') {
            // eslint-disable-next-line no-use-before-define
            const addOnSku = generateSku({ setSize: skuSplit.size }, splitSku(queryParam.value, skuParse), skuParse);

            if (addOnSku.includes(option.value)) {
              // eslint-disable-next-line no-param-reassign
              option.variantValue = addOnSku;
              return true;
            }
            return false;
          }
          if (queryParam.key === 'headboard-3-add-on' && queryParam.value !== 'none') {
            const onSelectFunctionToCall = chorusOptionToSetVariable[selectedModifierOptionValue];
            const sliced = queryParam.value.slice(-2);
            let newAddOnSku;
            if (onSelectFunctionToCall !== 'setColor') {
              newAddOnSku = generateSku({ [onSelectFunctionToCall]: selectedModifierOptionValue }, splitSku(queryParam.value, skuParse), skuParse);
            }
            if (onSelectFunctionToCall === 'setColor') {
              if (chorusColorToMaterial[sliced] === 'UH') {
                // If params are UH and setColor is UH
                if (chorusColorToMaterial[skuSplit.color] === 'UH') {
                  newAddOnSku = generateSku({ [onSelectFunctionToCall]: selectedModifierOptionValue }, splitSku(queryParam.value, skuParse), skuParse);
                  newAddOnSku = `${newAddOnSku.slice(0, -4)}${chorusFrameColorToHeadboardColor[skuSplit.color]}`;
                  // If params are UH and setColor is WD
                } else if (chorusColorToMaterial[skuSplit.color] === 'WD') {
                  newAddOnSku = queryParam.value;
                }
              } else if (chorusColorToMaterial[sliced] === 'WD') {
                // If params are WD and setColor is WD
                if (chorusColorToMaterial[skuSplit.color] === 'WD') {
                  newAddOnSku = generateSku({ [onSelectFunctionToCall]: selectedModifierOptionValue }, splitSku(queryParam.value, skuParse), skuParse);
                  newAddOnSku = `${newAddOnSku.slice(0, -4)}${chorusFrameColorToHeadboardColor[skuSplit.color]}`;
                  // If params are WD and setColor is UH
                } else if (chorusColorToMaterial[skuSplit.color] === 'UH') {
                  newAddOnSku = generateSku({ [onSelectFunctionToCall]: skuSplit.color, setMaterial: 'UH' }, splitSku(queryParam.value, skuParse), skuParse);
                  newAddOnSku = `${newAddOnSku.slice(0, -4)}${chorusFrameColorToHeadboardColor[skuSplit.color]}`;
                }
              }
            }
            option.variantValue = newAddOnSku;
            return true;
          }
          return (
          // round-tray and table-add-on modifier options contain only a parent sku,
          // so we have to find it in a full SKU stored query param
            ['round-tray', 'table-add-on'].includes(queryParam.key)
              ? queryParam.value.includes(option.value)
              : modifierOptionsAreACloseMatch(selectedModifierOptionValue, option.value, queryParam.value, skuParse)
          );
        }),
    }))
    // we want to reapply an option only if we find one in product modifiers
    .filter(({ selectedOption }) => selectedOption);

  // now we're creating the next query params in a { [slug]: sku } format
  return filteredProductModifierQueryParams.reduce((reappliedQueryParams, { queryParam, selectedOption }) => {
    switch (queryParam.key) {
      case 'table-add-on': {
        // range table addon SKU might need to be adjusted to sofa legs color
        return {
          ...reappliedQueryParams,
          'table-add-on': getRangeTableAddonSKU(skuSplit, selectedOption),
        };
      }
      case 'headboard-add-on': {
        return {
          ...reappliedQueryParams,
          'headboard-add-on': selectedOption.variantValue || queryParam.value,
        };
      }
      case 'headboard-3-add-on': {
        return {
          ...reappliedQueryParams,
          'headboard-3-add-on': selectedOption.variantValue || queryParam.value,
        };
      }
      default: {
        return {
          ...reappliedQueryParams,
          // we assign the selectedOption value here if it contains the selectedModifierOptionValue. If not, it would
          // use queryParam.value, which in a case like vesper would mean the value of the previous and different ottoman
          [queryParam.key]: selectedOption.value.includes(selectedModifierOptionValue) ? selectedOption.value : queryParam.value,
        };
      }
    }
  }, { sku });
}

export function reapplySelectedModifiers(selectedModifiers, productModifiers) {
  const reappliedModifiers = {};
  if (selectedModifiers) {
    Object.entries(selectedModifiers)
      .filter(([slug, label]) => (
        productModifiers.find((modifier) => (
          modifier.slug === slug
          && !modifier.shouldUpdateQueryParam
          && modifier.options.find((option) => option.label === label)
        ))
      ))
      .forEach(([slug, label]) => {
        reappliedModifiers[slug] = label;
      });
  }
  return reappliedModifiers;
}

export async function getPromotion(id) {
  const query = gql`query GetCMSProductPromotion {
    getCMSProductPromotion(id: "${id}") {
      ${getPromotionFields(true)}
    },
  }`;
  const { getCMSProductPromotion: promotion } = await RequestHelpers.apolloGqlQuery(query);
  return promotion;
}

/**
 * @desc returns the inventory and channel availability
 * @function
 * @param {string} sku product sku
 * @param {Array} productIds list of product IDs, optional
 * @returns {Promise<number | Array>} the inventory of the product if only the sku is passed or the inventory and channelAvailability data
 */

export async function getInventory(sku, productIds) {
  const query = gql`
    query GetInventoryWithChannelAvailability {
        inventory(sku: "${sku}") {
          availableInventory
          shippingMessage
        }
        ${productIds ? `,
        channelAvailability: getChannelAvailability(productIds: ${JSON.stringify(productIds)} ) {
          channelId
          productId
          channelAvailability
      }` : ''}
    }
  `;
  const {
    channelAvailability,
    inventory: {
      shippingMessage,
      availableInventory,
    } = {},
  } = await RequestHelpers.apolloGqlQuery(query);
  return { inventory: availableInventory, shippingMessage, channelAvailability };
}

export const getSelectedModifierFromModifier = ({ optionId, name, optionValues: [optionValue] }) => ({
  optionId,
  name,
  optionValue: optionValue?.id,
  optionPrice: optionValue?.price,
  productId: optionValue?.productId,
});

export function filterSelectedModifiers(selectedModifiers, { modifiers, sku } = {}) {
  if (Array.isArray(modifiers) && Array.isArray(selectedModifiers)) {
    return selectedModifiers.reduce((result, { name }) => {
      // When the selectedModifier is found in the sku or when it is a sleepkit
      let nextModifier = (sku && name && sku.startsWith(name)) || (name && name.toLowerCase().includes('sleep'));
      if (nextModifier) {
        // Format the next modifier and return it
        nextModifier = modifiers.find((modifier) => modifier.name.toLowerCase() === name.toLowerCase() || modifier.name.toLowerCase().includes('sleep'));
        result.push(getSelectedModifierFromModifier(nextModifier));
      }
      return result;
    }, []);
  }
  return [];
}

/**
 *
 * @param {string} name
 * @param {ReturnType<typeof getAddOnItems>} selectedAddonsList
 * @returns {string}
 */
export function applyModifiersToProductName(name, selectedAddonsList) {
  let productName = name;
  selectedAddonsList.forEach((modifier, i) => {
    const {
      namePrepend = '',
      nameAppend = '',
      nameChange = '',
    } = modifier;
    let append = nameAppend;
    if (i > 0 && nameAppend) {
      append = nameAppend.replace('with', 'and');
    }
    productName = `${namePrepend ? `${namePrepend} ` : ''}${productName}${nameAppend ? ` ${append}` : ''}`;
    if (nameChange) {
      productName = nameChange;
    }
  });
  return productName;
}

export function filterAddOnOptionsBySize(skuSplit, options) {
  // We want to filter the addOn options that come from GraphCMS by size,
  // in the case that a size-based variant has been selected.
  // If any of them are filterable by size then we want to return only the correct size.
  // For other products, we want to return all the options.
  const skuType = skuSplit.type;
  switch (skuType) {
    case 'FBRMT': // Mattresses
    case 'ABRSB': // Bedding
    case 'ALRRG': { // Rugs
      const filteredSizeOptions = options.filter((option) => ~option.value.indexOf(`${skuSplit.size}`));
      if (filteredSizeOptions.length === 1) {
        return filteredSizeOptions;
      }
      return [];
    }
    default: {
      const filteredSkuOptions = options.filter((option) => ~option.value.indexOf(`${skuType}-${skuSplit.size}`));
      if (filteredSkuOptions.length > 0) {
        return filteredSkuOptions;
      }
      return options;
    }
  }
}

export function filterAttachAddOnItemModifierOptions(options, relatedProducts) {
  return options?.filter((option) => {
    if (option.value === 'none') {
      return true;
    }
    const formattedValue = option.value.substring(0, option.value.lastIndexOf('_'));
    return relatedProducts?.some(({ sku }) => sku.includes(formattedValue || option.value));
  });
}

export function generateImageAltTag(item) {
  const product = item.sku.split('-')[0];
  if (
    product.includes('SF')
    || product.includes('SO')
    || product.includes('SC')
    || product.includes('OT')
  ) {
    return item.short;
  }
  return item.name;
}

/**
 * @param {ProductModifierDto} modifier
 * @returns {boolean}
 */
export function isAttachAddonItemModifier(modifier) {
  const { type, modifierOperation } = modifier;
  return modifierOperation === 'AttachAddonItem' || type === 'AddToCart';
}

/**
 * @param {ProductModifierDto} modifier
 * @returns {boolean}
 */
export function isUpdateAddonItemVariantModifier(modifier) {
  const { type, modifierOperation } = modifier;
  return modifierOperation === 'UpdateAddonItemVariant' || type === 'AddToCartModifier';
}

/**
 * @callback OptionsMapCallback
 * @param {CMSModifierOptionDto} option
 * @returns {CMSModifierOptionDto}
 */

/**
 * Returns the inventory and channel availability
 * @param {CMSModifierDto[]} productModifiers
 * @param {OptionsMapCallback} optionsMapCallback
 * @returns {CMSModifierDto[]}
 */
export function updateModifierOptions(
  productModifiers,
  optionsMapCallback,
) {
  if (typeof optionsMapCallback !== 'function') {
    return productModifiers;
  }

  return productModifiers.map((modifier) => ({
    ...modifier,
    options: modifier.options.map(optionsMapCallback),
  }));
}

export async function attachInventoryToAddOnOptions({
  addOnOptionsWithMatchingRelatedProducts,
  productModifiers,
}) {
  const addOnOptionsWithInventory = await Promise.all(
    addOnOptionsWithMatchingRelatedProducts
      .filter(({ product }) => product)
      .map(async (option) => {
        const { inventory } = await getInventory(option.product.sku);
        return { ...option, inventory };
      }),
  );

  // map the options with inventory into the modifiers
  return updateModifierOptions(productModifiers, (option) => {
    const matchingOption = addOnOptionsWithInventory.find(({ value }) => value === option.value);
    const outOfStock =  matchingOption && !matchingOption.inventory;
    return { ...option, ...(matchingOption && { outOfStock }) };
  });
}

export function stripHTMLFromString(description) {
  // Strips description and only leaves bold tags.
  return striptags(description, '<b>');
}

export function getFirstProductCardImage(productCardImage) {
  return productCardImage && productCardImage.length > 0 && productCardImage[0].images
    && productCardImage[0].images[0];
}

export function refreshAffirm() {
  let refreshed;
  try {
    window.affirm.ui.refresh();
    refreshed = true;
  } catch (e) { /* Nothing to do */ }

  if (!refreshed) {
    // wait for affirm to initialize and try again
    setTimeout(() => {
      try {
        window.affirm.ui.refresh();
      } catch (e) { /* Nothing to do */ }
    }, 300);
  }
}

const haveAllElementsRendered = (components, elementsTypesArray) => {
  const elementsCount = components.reduce((previousValue, currentComponent) => {
    if (elementsTypesArray.includes(currentComponent.type)) {
      return previousValue + 1;
    }

    return previousValue;
  }, 0);

  const elementsArray = elementsTypesArray.flatMap((element) => Array.from(document.getElementsByClassName(element)));

  return elementsCount ===  elementsArray.length;
};

export function handleRefreshAffirm({ components }) {
  const {
    ProductCollection,
    ProductCarousel,
    ShopBySpaces,
    ShopByTheme,
  } = ComponentType;

  const componentsRenderingAffirm = [ProductCollection, ProductCarousel, ShopBySpaces, ShopByTheme];

  if (haveAllElementsRendered(components, componentsRenderingAffirm)) {
    refreshAffirm();
  } else {
    const observer = new MutationObserver((_, obs) => {
      if (haveAllElementsRendered(components, componentsRenderingAffirm)) {
        refreshAffirm();
        obs.disconnect();
      }
    });

    observer.observe(document, {
      childList: true,
      subtree: true,
    });
  }
}

export function getShareCartUrl(items, hrefLang) {
  const saveCartItems = items.map((cartItem) => ({
    optionSelections: cartItem.optionValues.map((optionValue) => (
      pick(optionValue, ['optionValue', 'optionId'])
    )),
    productId: cartItem.productId,
    quantity: cartItem.quantity,
  }));
  const saveCartParams = {
    cart: JSON.stringify(saveCartItems),
    overwrite: true,
  };
  return generateUrlWithHrefLang({ hrefLang, endpoint: `${paths.SHARE_CART}?${qs.stringify(saveCartParams)}` });
}

export function getCartItemsDataForShareCart(items, hrefLang) {
  return items
    .map((item) => ({
      ...item,
      cover: item.productCardImage.url,
      price: `${getCurrencyCode(hrefLang)} ${item.listPrice}`,
      url: `${item?.seo?.url}?${qs.stringify({ sku: item.sku })}`,
    }))
    .map((item) => pick(item, [
      'name',
      'description',
      'cover',
      'url',
      'quantity',
      'price',
    ]));
}

export function getAddToCartModifierParams(slug, option, skuSplit) {
  switch (slug) {
    case 'round-tray-color':
      return {
        updateAddOnQueryParam: true,
        addOnParams: { 'round-tray': `ALRSA-TR-RD-${option.value}` },
      };
    case 'table-finish':
      return {
        updateAddOnQueryParam: true,
        addOnParams: { 'table-add-on': `FLRSTB-RG-1-${option.value}${skuSplit.legs}` },
      };
    case 'headboard-color': {
      const headboardMaterial = circaHeadboardToMaterial[option.value];
      return {
        updateAddOnQueryParam: true,
        addOnParams: { 'headboard-add-on': `FBRBS-HB-CI-${skuSplit.size}-${headboardMaterial}-${option.value}` },
      };
    }
    case 'headboard-3-color': {
      const headboardMaterial = chorusColorToMaterial[option.value];
      return {
        updateAddOnQueryParam: true,
        addOnParams: { 'headboard-3-add-on': `BRBD-HB-CI3-${skuSplit.size}-${headboardMaterial}-${option.value}` },
      };
    }
    default:
      return {
        updateAddOnQueryParam: false,
        addOnParams: { [option.label]: option.value },
      };
  }
}

const filterForMatchingFileNames = (filtered, value) => {
  const filteredFileNames = filtered.filter((img) => img.fileName.includes(value));
  return !filteredFileNames.length ? filtered : filteredFileNames;
};

export function filterDimensionAssetsByVariantSku(filteredImages, skuSplit) {
  let variantFilteredImages = filteredImages;
  if (skuSplit.collection === 'EM') { // Ember
    variantFilteredImages = filterForMatchingFileNames(variantFilteredImages, skuSplit.size);
    variantFilteredImages = variantFilteredImages.length ? variantFilteredImages : filteredImages;
  }
  return variantFilteredImages;
}

export function filterAddOnImageAssets(images, addOns, productModifiers = [], tableFinish, sku, skuParse) {
  let filteredImages = [...images];
  if (addOns?.length > 0) {
    const addOnLabels = addOns.map(({ label }) => label);
    const addToCartModifiers = productModifiers.filter(isAttachAddonItemModifier);
    const addOnModifiers = productModifiers.filter(isUpdateAddonItemVariantModifier);

    let selectedAddOnModifier;
    addOnModifiers.forEach((addOnModifier) => {
      const selected = addOnModifier.options.find((opt) => opt.selected);
      if (selected) selectedAddOnModifier = selected;
    });
    if (addToCartModifiers.length) {
      try {
        const activeOption = [];
        addToCartModifiers.forEach(({ options }) => {
          const option = options.find((opt) => addOnLabels.includes(opt.label));
          if (option) activeOption.push(option);
        });

        let filtered = filteredImages;
        activeOption.forEach((option) => {
          const {
            type,
            accessory,
            styleIdentifier,
            furnitureType,
            color,
            size,
            material,
            model,
          } = splitSku(option.value, skuParse);
          if (type === 'LRAS' && furnitureType === 'OT') {
            filtered = filterForMatchingFileNames(filtered, '-OT');
            filteredImages = filtered.length ? filtered : filteredImages;
          }
          if (type === 'NAC' || type === 'NLAC' || type === 'NVAC') {
            filtered = filterForMatchingFileNames(filtered, styleIdentifier);
            filteredImages = filtered.length ? filtered : filteredImages;
          }
          if (type === 'ALRSA' && (accessory === 'LB' || accessory === 'BT')) {
            filtered = filterForMatchingFileNames(filtered, accessory);
            filteredImages = filtered.length ? filtered : filteredImages;
          }
          if (type === 'ALRSK') {
            filtered = filterForMatchingFileNames(filtered, 'ALRSK');
            filteredImages = filtered.length ? filtered : filteredImages;
          }
          if (type === 'FLRSTB') {
            filtered = filterForMatchingFileNames(filtered, `-T${tableFinish}`);
            filteredImages = filtered.length ? filtered : filteredImages;
          }
          if (type === 'OLRST' && furnitureType === 'OT') {
            filtered = filterForMatchingFileNames(filtered, '-OT');
            filteredImages = filtered.length ? filtered : filteredImages;
          }
          if (type === 'FLRST' && (furnitureType === 'OT' ||  furnitureType === 'AO')) {
            filtered = filterForMatchingFileNames(filtered, '-OT');
            filteredImages = filtered.length ? filtered : filteredImages;
          }
          if (type === 'ODRST' && furnitureType === 'DC') {
            const quantity = option.quantity * Number(size[1]);
            filtered = filterForMatchingFileNames(filtered, `-RCDT${quantity}`);
            filteredImages = filtered.length ? filtered : filteredImages;
          }
          // Headboard add-ons can be a variant add-on (match color), parent add-ons (match woodtone), or a generic material match (dimension image)
          if (type === 'FBRBS' && model === 'HB') {
            const addOnModifierValue = selectedAddOnModifier?.value ? `-${selectedAddOnModifier?.value}` : null;
            filtered = filterForMatchingFileNames(filtered, `${addOnModifierValue || `${sku}-HB${material}` || color}`);
            filteredImages = filtered.length ? filtered : filteredImages;
          }
          if (type === 'BRBD' && model === 'HB') {
            const addOnModifierValue = selectedAddOnModifier?.value ? `-${selectedAddOnModifier?.value}` : null;
            filtered = filterForMatchingFileNames(filtered, `${sku}${addOnModifierValue}`);
            filteredImages = filtered.length ? filtered : filteredImages;
          }
          if (type === 'ODST' && furnitureType === 'OT') {
            filtered = filterForMatchingFileNames(filtered, '-OT');
            filteredImages = filtered.length ? filtered : filteredImages;
          }
          if (type === 'OLRST' && furnitureType === 'CT') {
            filtered = filterForMatchingFileNames(filtered, '-CT');
            filteredImages = filtered.length ? filtered : filteredImages;
          }
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }
  }
  return filteredImages;
}

/**
 * Returns the appropriate video related asset. Either its thumb or the video itself.
 * @param {Object}                    galleryItem
 * @param {AssetDto[]}                galleryItem.images
 * @param {'thumbnail' | 'slide'}     renderLocation
 * @returns {AssetDto}
 */
export function getAppropriateVideoAsset({ images } = {}, renderLocation = 'slide') {
  const [image] = images;

  if (images?.length === 1) return image;

  return (renderLocation === 'thumbnail'
    ? images.find(({ mimeType }) => !VIDEO_MIMETYPES.includes(mimeType))
    : images.find(({ mimeType }) => VIDEO_MIMETYPES.includes(mimeType))) || image;
}

// getAppropriateGalleryAsset should take the add ons, the add on modifiers and config them together
// remove tableFinish
/**
 * Returns appropriate asset to be displayed on either gallery slide or thumbnail.
 * @param {Object}                  galleryItem
 * @param {string}                  sku
 * @param {string}                  collection
 * @param                           galleryModifiers
 * @param {ReturnType<typeof getAddOnItems>}                  addOns
 * @param {Array<ProductModifierDto>}                          productModifiers
 * @param                           tableFinish
 * @param {unknown}                          furnitureType
 * @param {SkuParseDto}             skuParse
 * @param {'thumbnail' | 'slide'}   [renderLocation]
 * @param {Function}                [_getAppropriateVideoAsset]
 * @returns {{}|*}
 */
export function getAppropriateGalleryAsset(
  galleryItem,
  sku,
  collection,
  galleryModifiers = {},
  addOns = null,
  productModifiers = [],
  tableFinish = null,
  furnitureType = null,
  skuParse = null,
  renderLocation = 'slide',
  _getAppropriateVideoAsset = getAppropriateVideoAsset, // this helps simplify testing
) {
  if (!galleryItem.images.length) {
    return {};
  }

  // Since Video doesn't really care for addons, we can just directly return
  // the appropriate asset.
  if (galleryItem.type === 'Video') {
    return _getAppropriateVideoAsset(galleryItem, renderLocation);
  }

  let { tufted: activeTuftOption } = galleryModifiers;
  const { door: activeDoorOption } = galleryModifiers;

  let filteredImages = filterAddOnImageAssets(
    galleryItem.images,
    addOns,
    productModifiers,
    tableFinish,
    sku,
    skuParse,
  );

  if (activeTuftOption) {
    filteredImages = filteredImages.filter((img) => img.fileName.includes(activeTuftOption));
  } else {
    if (collection === 'N') {
      activeTuftOption = 'TF';
    }
    if (collection === 'NL' || collection === 'NV') {
      activeTuftOption = 'UT';
    }
  }
  if (!tableFinish) {
    filteredImages = filteredImages.filter(
      (img) => !img.fileName.includes('-TWN') && !img.fileName.includes('-TOK'),
    );
  }
  filteredImages.sort((a, b) => a.fileName.length - b.fileName.length);
  const matching = filteredImages
    && filteredImages.find((img) => {
      const [fileName] = img.fileName.split('.');
      if (activeTuftOption) {
        const [, , , , , tuft, addition] = fileName.split('-');
        // try to find an addon that includes `addition` in its SKU, e.g. "LB" in "NAC-3-LB"
        if (
          addition
          && !addOns?.find(({ product }) => product?.sku?.includes(addition))
        ) {
          return false;
        }
        return activeTuftOption === tuft && fileName.includes(sku);
      }
      if (activeDoorOption) {
        const [extendedSku] = fileName.split('_');
        const [doorType] = extendedSku.split('-').slice(sku.split('-').length);
        return activeDoorOption === doorType;
      }
      if (
        furnitureType === 'SFT'
        || furnitureType === 'OTT'
        || furnitureType === 'SLT'
      ) {
        const imageName = sku.replace(`-${tableFinish}`, `-T${tableFinish}`);
        return img.fileName.includes(imageName);
      }
      return img.fileName.includes(sku);
    });

  const skuMatchingImage = filteredImages.find(
    ({ fileName }) => fileName.split('.')[0] === sku,
  );
  const skuIncludedImage = filteredImages.find(
    ({ fileName }) => fileName.split('.')[0].includes(sku),
  );
  let asset;

  if (matching) {
    asset = matching;
  } else if (skuMatchingImage) {
    asset = skuMatchingImage;
  } else if (skuIncludedImage) {
    asset = skuIncludedImage;
  } else if (filteredImages?.length) {
    [asset] = filteredImages;
  } else {
    [asset] = galleryItem.images;
  }

  return asset;
}

/**
 *
 * @param {string= | null} sku
 * @param {string[]=} addOnItems
 * @returns {Promise<ProductDto>}
 */
export function getVariantBySKU(sku, addOnItems) {
  const addOns = Array.isArray(addOnItems) ? addOnItems.toString() : '';

  return fetch('/gql', {
    credentials: 'same-origin',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `
        query GetVariantBySKU {
          variantBySKU(sku: "${sku}"${addOnItems?.length ? `, addOnItems: "${addOns}"` : ''}) {
            ${getVariantQuery(true)}
          }
        }
      `,
    }),
  })
    .then((res) => res.json())
    .then((result) => result?.data?.variantBySKU)
    .catch((e) => {
      logError(e, getVariantBySKU.name);
      return null;
    });
}

/**
 * Get the add on details from the modifiers options for the Product Card
 * @function
 * @param {Array<string>} addOnItems an array of string(s) of the add on item SKU
 * @param {Array<ProductModifierDto>} productModifiers the product modifiers of the item
 */
export function getAddOnItems(addOnItems, productModifiers) {
  if (!Array.isArray(addOnItems) || !Array.isArray(productModifiers)) {
    return [];
  }

  const atcModifierOptions = productModifiers
    .filter(isUpdateAddonItemVariantModifier)
    .map(({ options }) => options)
    .flat();

  // Get the addOnItem by option
  const modOptions = productModifiers
    .filter((modifier) => isAttachAddonItemModifier(modifier) && modifier.options.find(({ value }) => addOnItems.find((el) => el.includes(value))))
    .map(({ slug, options }) => ({
      ...options.find(({ value }) => addOnItems.find((el) => el.includes(value))),
      slug,
    }));

  // Match the addOnItem and modifier options to create the addOnItem sku
  const newReturn = modOptions.map((option) => {
    let addOnItemMatch;
    addOnItems.forEach((addOnItem) => {
      const found = atcModifierOptions.find(({ value }) => addOnItem.split('-').includes(value));
      if (found) addOnItemMatch = addOnItem;
    });
    return {
      ...option,
      value: addOnItemMatch || option.value,
    };
  });

  return newReturn;
}

export function getProductURL(item, sku, addOnItems, options = {}) {
  const {
    url,
    productModifiers,
  } = item;
  const queryString = { sku, ...options };

  if (productModifiers && addOnItems?.length) {
    const addOns = getAddOnItems(addOnItems, item.productModifiers);
    addOns.forEach((addOn) => {
      queryString[addOn.slug] = addOn.value;
    });
  }

  return `${url}${isEmpty(omitBy(queryString, isNil)) ? '' : `?${qs.stringify(queryString, { skipNulls: true })}`}`;
}

export function generateBXProductData(sku) {
  return {
    sku,
    productUrl: `${s3ProductUrlBase}${sku}.png`,
  };
}

export function updateCollectionComponents(components = [], collections = []) {
  let newComponents = components;
  const indexOfProductCollectionComponent = components.findIndex(
    ({ type }) => type === 'ProductCollection',
  );

  newComponents = components.filter(({ type }) => type !== 'ProductCollection');
  collections.forEach((collection, i) => {
    const collectionComponent = { id: collection.id, type: 'ProductCollection', collections: [collection] };
    newComponents.splice(indexOfProductCollectionComponent + i, 0, collectionComponent);
  });

  return newComponents;
}

/**
 * return true or false if the option is active
 * returns true if no option is selected and the value is "none"
 * @param {ProductModifierOptionDto} [activeOption] the selected option
 * @param {string} value the current option
 */
export const getActiveModifierOption = (activeOption, value) => ((activeOption?.value === value) || (!activeOption && value.toLowerCase() === 'none'));

/**
 * @typedef {Object} Path
 * @property {Object?} destinationPage
 * @property {string} destinationPage.url
 */

/**
 * Looks for destinationPage.url in path. If found, append en-ca prefix to it and returns the updated path object
 * @param {Path} path
 * @returns {Path}
 */
const updateDestinationUrlWithPrefix = (path) => {
  if (path?.destinationPage?.url) {
    return {
      ...path,
      destinationPage: {
        ...path.destinationPage,
        url: `/en-ca${path.destinationPage.url}`,
      },
    };
  }
  return path;
};

/**
 * Parses a breadcrumbs object, appending en-ca prefix to all destination urls
 * @param {Breadcrumbs} breadcrumbs
 * @param {boolean} isUs
 * @returns {Breadcrumbs}
 */
export const parseBreadcrumbUrl = (breadcrumbs, isUs) => {
  if (isUs) return breadcrumbs;

  return {
    productPath: updateDestinationUrlWithPrefix(breadcrumbs.productPath),
    collectionPath: updateDestinationUrlWithPrefix(breadcrumbs.collectionPath),
  };
};

/**
 * @param {ProductModifierDto[]} modifiersList
 * @param {BaseVariant} productDetails
 * @param {ProductModifierOptionDto[]} selectedModifierOptions
 * @param {Object} [otherProps]
 */
export const getActiveOptionFromModifiers = ({
  modifiersList,
  productDetails,
  selectedModifierOptions,
  otherProps = {},
}) => {
  const { sku } = productDetails;
  /**
   * @type {ProductModifierDto}
   */
  let activeModifier;
  /**
   * @type {ProductModifierOptionDto}
   */
  let activeOption;

  modifiersList.some((modifier) => {
    const { activeStateProp, options = [], onSelectFunction } = modifier;

    activeModifier = modifier;
    activeOption = options.find(({ value, selected }) => (
      activeStateProp && value === otherProps[lowerFirst(activeStateProp)])
      || selectedModifierOptions?.find((selectedModifierOption) => selectedModifierOption.value === value)
      || selected
      || (onSelectFunction?.toLowerCase() === 'setmaterial' && sku?.includes(value)));

    return activeOption;
  });

  if (!activeOption) return {};

  return {
    activeModifier,
    activeOption,
  };
};
