const prefix = '[CART]';

export const FETCH_CART = `${prefix} FETCH_CART`;
export const FETCH_CART__REQUEST = `${prefix} FETCH_CART__REQUEST`;
export const FETCH_CART__SUCCESS = `${prefix} FETCH_CART__SUCCESS`;
export const FETCH_CART__FAILURE = `${prefix} FETCH_CART__FAILURE`;
export const ADD_TO_CART = `${prefix} ADD_TO_CART`;
export const ADD_TO_CART__REQUEST = `${prefix} ADD_TO_CART__REQUEST`;
export const ADD_TO_CART__SUCCESS = `${prefix} ADD_TO_CART__SUCCESS`;
export const ADD_TO_CART__FAILURE = `${prefix} ADD_TO_CART__FAILURE`;
export const UPDATE_CART_ITEM = `${prefix} UPDATE_CART_ITEM`;
export const UPDATE_CART_ITEM__REQUEST = `${prefix} UPDATE_CART_ITEM__REQUEST`;
export const UPDATE_CART_ITEM__SUCCESS = `${prefix} UPDATE_CART_ITEM__SUCCESS`;
export const UPDATE_CART_ITEM__FAILURE = `${prefix} UPDATE_CART_ITEM__FAILURE`;
export const UPDATE_CART_ITEM_MODIFIERS = `${prefix} UPDATE_CART_ITEM_MODIFIERS`;
export const UPDATE_CART_ITEM_MODIFIERS__REQUEST = `${prefix} UPDATE_CART_ITEM_MODIFIERS__REQUEST`;
export const UPDATE_CART_ITEM_MODIFIERS__SUCCESS = `${prefix} UPDATE_CART_ITEM_MODIFIERS__SUCCESS`;
export const UPDATE_CART_ITEM_MODIFIERS__FAILURE = `${prefix} UPDATE_CART_ITEM_MODIFIERS__FAILURE`;
export const APPLY_AUTOMATIC_DISCOUNT = `${prefix} APPLY_AUTOMATIC_DISCOUNT`;
export const ADD_COUPON_TO_CART = `${prefix} ADD_COUPON_TO_CART`;
export const ADD_COUPON_TO_CART__REQUEST = `${prefix} ADD_COUPON_TO_CART__REQUEST`;
export const ADD_COUPON_TO_CART__SUCCESS = `${prefix} ADD_COUPON_TO_CART__SUCCESS`;
export const ADD_COUPON_TO_CART__FAILURE = `${prefix} ADD_COUPON_TO_CART__FAILURE`;
export const REMOVE_COUPON_FROM_CART = `${prefix} REMOVE_COUPON_FROM_CART`;
export const REMOVE_COUPON_FROM_CART__REQUEST = `${prefix} REMOVE_COUPON_FROM_CART__REQUEST`;
export const REMOVE_COUPON_FROM_CART__SUCCESS = `${prefix} REMOVE_COUPON_FROM_CART__SUCCESS`;
export const REMOVE_COUPON_FROM_CART__FAILURE = `${prefix} ADD_COUPON_TO_CART__FAILURE`;
export const FETCH_CHECKOUT_URL_AND_REDIRECT = `${prefix} FETCH_CHECKOUT_URL_AND_REDIRECT`;
export const FETCH_CHECKOUT_URL_AND_REDIRECT__REQUEST = `${prefix} FETCH_CHECKOUT_URL_AND_REDIRECT__REQUEST`;
export const FETCH_CHECKOUT_URL_AND_REDIRECT__SUCCESS = `${prefix} FETCH_CHECKOUT_URL_AND_REDIRECT__SUCCESS`;
export const FETCH_CHECKOUT_URL_AND_REDIRECT__FAILURE = `${prefix} FETCH_CHECKOUT_URL_AND_REDIRECT__FAILURE`;
export const SET_CART_DETAILS = `${prefix} SET_CART_DETAILS`;
export const SET_TIERED_SALES = `${prefix} SET_TIERED_SALES`;
export const SAVE_CART_FOR_LATER = `${prefix} SAVE_CART_FOR_LATER`;
export const SAVE_CART_FOR_LATER_REQUEST = `${prefix} SAVE_CART_FOR_LATER_REQUEST`;
export const SAVE_CART_FOR_LATER_SUCCESS = `${prefix} SAVE_CART_FOR_LATER_SUCCESS`;
export const SAVE_CART_FOR_LATER_FAILURE = `${prefix} SAVE_CART_FOR_LATER_FAILURE`;
export const SET_SAVE_CART_MODAL_OPEN = `${prefix} SET_SAVE_CART_MODAL_OPEN`;
export const SET_SALES_TIERS_MODAL_OPEN = `${prefix} SET_SALES_TIERS_MODAL_OPEN`;
export const SET_IS_PROMO_INPUT_VISIBLE = `${prefix} SET_IS_PROMO_INPUT_VISIBLE`;
export const GET_RECOMMENDED_CART_ITEMS = `${prefix} GET_RECOMMENDED_CART_ITEMS`;
export const GET_RECOMMENDED_CART_ITEMS__REQUEST = `${prefix} GET_RECOMMENDED_CART_ITEMS__REQUEST`;
export const GET_RECOMMENDED_CART_ITEMS__SUCCESS = `${prefix} GET_RECOMMENDED_CART_ITEMS__SUCCESS`;
export const GET_RECOMMENDED_CART_ITEMS__FAILURE = `${prefix} GET_RECOMMENDED_CART_ITEMS__FAILURE`;
export const DELETE_CART = `${prefix} DELETE_CART`;
export const DELETE_CART__REQUEST = `${prefix} DELETE_CART__REQUEST`;
export const DELETE_CART__SUCCESS = `${prefix} DELETE_CART__SUCCESS`;
export const DELETE_CART__FAILURE = `${prefix} DELETE_CART__FAILURE`;
export const SET_IS_PRODUCT_EDIT_MODAL_VISIBLE = `${prefix} SET_IS_PRODUCT_EDIT_MODAL_VISIBLE`;
export const SET_IS_UPDATING_PRODUCT_DETAILS = `${prefix} SET_IS_UPDATING_PRODUCT_DETAILS`;

export const fetchCart = () => ({
  type: FETCH_CART,
});

export const fetchCartRequest = () => ({
  type: FETCH_CART__REQUEST,
});

export const fetchCartSuccess = (payload) => ({
  type: FETCH_CART__SUCCESS,
  payload,
});

export const fetchCartFailure = (payload) => ({
  type: FETCH_CART__FAILURE,
  payload,
});

/**
 * @param item
 * @param allOptions
 * @param quantity
 * @param source
 * @param addOnItems
 * @param overwrite
 * @param quantityMultiplier
 * @param modifiers
 * @param {string | null} [lineItems]
 * @param {Function | null} [onAdded]
 * @returns {{lineItems: null | string, addOnItems, item, quantity, source, type: string, modifiers: [], allOptions, overwrite: boolean, quantityMultiplier: number}}
 */
export const addToCart = (item, allOptions, quantity, source, addOnItems, overwrite = false, quantityMultiplier = 1, modifiers = [], lineItems = null, onAdded = null) => ({
  type: ADD_TO_CART,
  item,
  allOptions,
  quantity,
  source,
  addOnItems,
  overwrite,
  quantityMultiplier,
  modifiers,
  lineItems,
  onAdded,
});

export const addToCartRequest = () => ({
  type: ADD_TO_CART__REQUEST,
});

export const addToCartSuccess = (payload) => ({
  type: ADD_TO_CART__SUCCESS,
  payload,
});

export const addToCartFailure = (payload) => ({
  type: ADD_TO_CART__FAILURE,
  payload,
});

export const updateCartItem = (item, sign, event, action, label, source) => ({
  type: UPDATE_CART_ITEM,
  item,
  sign,
  event,
  action,
  label,
  source,
});

export const updateCartItemRequest = () => ({
  type: UPDATE_CART_ITEM__REQUEST,
});

export const updateCartItemSuccess = (payload) => ({
  type: UPDATE_CART_ITEM__SUCCESS,
  payload,
});

export const updateCartItemFailure = (payload) => ({
  type: UPDATE_CART_ITEM__FAILURE,
  payload,
});

export const updateCartItemModifiers = (item, optionValues, quantity) => ({
  type: UPDATE_CART_ITEM_MODIFIERS,
  item,
  optionValues,
  quantity,
});

export const updateCartItemModifiersRequest = () => ({
  type: UPDATE_CART_ITEM_MODIFIERS__REQUEST,
});

export const updateCartItemModifiersSuccess = (payload) => ({
  type: UPDATE_CART_ITEM_MODIFIERS__SUCCESS,
  payload,
});

export const updateCartItemModifiersFailure = (payload) => ({
  type: UPDATE_CART_ITEM_MODIFIERS__FAILURE,
  payload,
});

export const addCouponToCart = (cartId, couponCode) => ({
  type: ADD_COUPON_TO_CART,
  cartId,
  couponCode,
});

export const applyAutomaticDiscount = (cartId, couponCode) => ({
  type: APPLY_AUTOMATIC_DISCOUNT,
  cartId,
  couponCode,
});

export const addCouponToCartRequest = () => ({
  type: ADD_COUPON_TO_CART__REQUEST,
});

export const addCouponToCartSuccess = (payload) => ({
  type: ADD_COUPON_TO_CART__SUCCESS,
  payload,
});

export const addCouponToCartFailure = (payload) => ({
  type: ADD_COUPON_TO_CART__FAILURE,
  payload,
});

export const removeCouponFromCart = (couponCode) => ({
  type: REMOVE_COUPON_FROM_CART,
  couponCode,
});

export const removeCouponFromCartRequest = () => ({
  type: REMOVE_COUPON_FROM_CART__REQUEST,
});

export const removeCouponFromCartSuccess = (payload) => ({
  type: REMOVE_COUPON_FROM_CART__SUCCESS,
  payload,
});

export const removeCouponFromCartFailure = (payload) => ({
  type: REMOVE_COUPON_FROM_CART__FAILURE,
  payload,
});

export const fetchCheckoutUrlAndRedirect = () => ({
  type: FETCH_CHECKOUT_URL_AND_REDIRECT,
});

export const fetchCheckoutUrlAndRedirectRequest = () => ({
  type: FETCH_CHECKOUT_URL_AND_REDIRECT__REQUEST,
});

export const fetchCheckoutUrlAndRedirectSuccess = (payload) => ({
  type: FETCH_CHECKOUT_URL_AND_REDIRECT__SUCCESS,
  payload,
});

export const fetchCheckoutUrlAndRedirectFailure = (payload) => ({
  type: FETCH_CHECKOUT_URL_AND_REDIRECT__FAILURE,
  payload,
});

export const setCartDetails = (details, localStorage = true) => ({
  type: SET_CART_DETAILS,
  details,
  localStorage,
});

export const setPromotions = (payload) => ({
  type: SET_TIERED_SALES,
  payload,
});

export const saveCartForLater = (payload) => ({
  type: SAVE_CART_FOR_LATER,
  payload,
});

export const saveCartForLaterRequest = () => ({
  type: SAVE_CART_FOR_LATER_REQUEST,
});

export const saveCartForLaterSuccess = () => ({
  type: SAVE_CART_FOR_LATER_SUCCESS,
});

export const saveCartForLaterFailure = (payload) => ({
  type: SAVE_CART_FOR_LATER_FAILURE,
  payload,
});

export const setSaveCartModalOpen = (payload) => ({
  type: SET_SAVE_CART_MODAL_OPEN,
  payload,
});

export const setSalesTiersModalOpen = (payload) => ({
  type: SET_SALES_TIERS_MODAL_OPEN,
  payload,
});

export const setIsPromoInputVisible = (isPromoInputVisible) => ({
  type: SET_IS_PROMO_INPUT_VISIBLE,
  payload: {
    isPromoInputVisible,
  },
});

export const getRecommendedCartItems = () => ({
  type: GET_RECOMMENDED_CART_ITEMS,
});

export const getRecommendedCartItemsRequest = (payload) => ({
  type: GET_RECOMMENDED_CART_ITEMS__REQUEST,
  payload,
});

export const getRecommendedCartItemsSuccess = (payload) => ({
  type: GET_RECOMMENDED_CART_ITEMS__SUCCESS,
  payload,
});

export const getRecommendedCartItemsFailure = (payload) => ({
  type: GET_RECOMMENDED_CART_ITEMS__FAILURE,
  payload,
});

export const deleteCart = () => ({
  type: DELETE_CART,
});

export const deleteCartRequest = () => ({
  type: DELETE_CART__REQUEST,
});

export const deleteCartSuccess = () => ({
  type: DELETE_CART__SUCCESS,
});

export const deleteCartFailure = (payload) => ({
  type: DELETE_CART__FAILURE,
  payload,
});

export const setIsProductEditModalVisible = (isItemEditModalVisible) => ({
  type: SET_IS_PRODUCT_EDIT_MODAL_VISIBLE,
  payload: {
    isItemEditModalVisible,
  },
});

export const setIsUpdatingProductDetails = (isUpdatingProductDetails) => ({
  type: SET_IS_UPDATING_PRODUCT_DETAILS,
  payload: {
    isUpdatingProductDetails,
  },
});
