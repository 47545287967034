const prefix = '[UI]';

export const PRODUCT_LANDING_PAGE_DID_MOUNT = `${prefix} PRODUCT_LANDING_PAGE_DID_MOUNT`;
export const COLLECTION_LANDING_PAGE_DID_MOUNT = `${prefix} COLLECTION_LANDING_PAGE_DID_MOUNT`;
export const SET_WINDOW_WIDTH = `${prefix} SET_WINDOW_WIDTH`;
export const SET_HEADER_TEXT_COLOR = `${prefix} SET_HEADER_TEXT_COLOR`;
export const SET_ERROR_POPUP_MESSAGE = `${prefix} SET_ERROR_POPUP_MESSAGE`;
export const UPDATE_EXPERIMENTS_FLAGS = `${prefix} UPDATE_EXPERIMENTS_FLAGS`;
export const UPDATE_HEADER_NAVIGATION = `${prefix} UPDATE_HEADER_NAVIGATION`;
export const UPDATE_FOOTER_NAVIGATION = `${prefix} UPDATE_FOOTER_NAVIGATION`;
export const UPDATE_CX_HOURS = `${prefix} UPDATE_CX_HOURS`;
export const OPEN_CART = `${prefix} OPEN_CART`;
export const SET_IS_FILTERS_POPOUT_OPEN = `${prefix} SET_IS_FILTERS_POPOUT_OPEN`;
export const SET_IS_GOOGLE_MAPS_ACTIVE = `${prefix} SET_IS_GOOGLE_MAPS_ACTIVE`;
export const SET_NAV_STATE = `${prefix} SET_NAV_STATE`;
export const INJECT_SCRIPT = `${prefix} INJECT_SCRIPT`;
export const SCRIPT_INJECTED = `${prefix} SCRIPT_INJECTED`;
export const SET_IS_ONLINE = `${prefix} SET_IS_ONLINE`;
export const SET_DISPLAY_PRODUCT_PREVIEW = `${prefix} SET_DISPLAY_PRODUCT_PREVIEW`;
export const SET_NAV_ITEMS = `${prefix} SET_NAV_ITEMS`;
export const SET_IS_MOBILE_MENU_OPEN = `${prefix} SET_IS_MOBILE_MENU_OPEN`;
export const PUSH = `${prefix} PUSH`;
export const REPLACE = `${prefix} REPLACE`;
export const SET_IS_SEARCH_DROP_DOWN_OPEN = `${prefix} SET_IS_SEARCH_OPEN`;
export const SET_SEARCH_LAYOUT = `${prefix} SET_SEARCH_LAYOUT`;

export const productLandingPageDidMount = (payload) => ({
  payload,
  type: PRODUCT_LANDING_PAGE_DID_MOUNT,
});

export const collectionLandingPageDidMount = (payload) => ({
  payload,
  type: COLLECTION_LANDING_PAGE_DID_MOUNT,
});

export const setWindowWidth = (width) => ({
  payload: width,
  type: SET_WINDOW_WIDTH,
});

export const setHeaderTextColor = (color) => ({
  payload: color,
  type: SET_HEADER_TEXT_COLOR,
});

export const setErrorPopupMessage = (message, redirect = null, onClose) => ({
  payload: {
    message,
    redirect,
    onClose,
  },
  type: SET_ERROR_POPUP_MESSAGE,
});

export const updateExperimentsFlags = (experiments) => ({
  payload: experiments,
  type: UPDATE_EXPERIMENTS_FLAGS,
});

export const updateHeaderNavigation = (navigation) => ({
  payload: navigation,
  type: UPDATE_HEADER_NAVIGATION,
});

export const updateFooterNavigation = (navigation) => ({
  payload: navigation,
  type: UPDATE_FOOTER_NAVIGATION,
});

export const updateCXHours = (cxHours) => ({
  payload: cxHours,
  type: UPDATE_CX_HOURS,
});

export const openCart = (isOpen) => ({
  payload: isOpen,
  type: OPEN_CART,
});

export const setIsGoogleMapsActive = (isGoogleMapsActive) => ({
  payload: isGoogleMapsActive,
  type: SET_IS_GOOGLE_MAPS_ACTIVE,
});

export const setDisplayProductPreview = (payload) => ({
  payload,
  type: SET_DISPLAY_PRODUCT_PREVIEW,
});

export const setNavState = (payload) => ({
  payload,
  type: SET_NAV_STATE,
});

export const injectScript = (payload) => ({
  payload,
  type: INJECT_SCRIPT,
});

export const scriptInjected = (payload) => ({
  payload,
  type: SCRIPT_INJECTED,
});

export const setIsOnline = (payload) => ({
  payload,
  type: SET_IS_ONLINE,
});

export const setNavItems = (payload) => ({
  payload,
  type: SET_NAV_ITEMS,
});

export const setIsFiltersPopoutOpen = (isFiltersPopoutOpen) => ({
  payload: isFiltersPopoutOpen,
  type: SET_IS_FILTERS_POPOUT_OPEN,
});

export const setIsMobileMenuOpen = (isMobileMenuOpen) => ({
  payload: isMobileMenuOpen,
  type: SET_IS_MOBILE_MENU_OPEN,
});

export const setIsSearchDropdownOpen = (isSearchDropdownOpen) => ({
  payload: isSearchDropdownOpen,
  type: SET_IS_SEARCH_DROP_DOWN_OPEN,
});

export const setSearchLayout = (layout) => ({
  payload: layout,
  type: SET_SEARCH_LAYOUT,
});

export const push = (url) => ({
  payload: {
    url,
  },
  type: PUSH,
});

export const replace = (url) => ({
  payload: {
    url,
  },
  type: REPLACE,
});
