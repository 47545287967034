/**
 * @deprecated
 */
enum ColorType {
  Charcoal = 'Charcoal',
  Black = 'Black',
  White = 'White',
  Bluesteel = 'Bluesteel',
  Shiitake = 'Shiitake',
  Oat = 'Oat',
  Yellow = 'Yellow',
  Tan = 'Tan',
  Persimmon = 'Persimmon',
  Sandstone = 'Sandstone',
  Terracotta = 'Terracotta',
}

export default ColorType;
