import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { gql, useMutation } from '@apollo/client';

import ApolloClient from '../../../../Apollo/ApolloClient';

declare global {
  interface GlobalEventHandlersEventMap {
    'ftr:tokenReady': CustomEvent;
  }
}

const REFRESH_FORTER_MUTATION = gql`
  mutation RefreshForterToken($token: String!) {
    refreshForterToken(token: $token)
  }
`;

const ForterRefreshToken = () => {
  const [refreshForterToken, { error }] = useMutation(REFRESH_FORTER_MUTATION, {
    client: ApolloClient,
  });

  useEffect(() => {
    if (error) {
      Sentry.captureException(error);
    }
  }, [error]);

  useEffect(() => {
    const handleTokenReady = (event: CustomEvent) => {
      const token = event.detail;
      refreshForterToken({ variables: { token } });
    };

    document.addEventListener('ftr:tokenReady', handleTokenReady);

    return () => {
      document.removeEventListener('ftr:tokenReady', handleTokenReady);
    };
  }, []);

  return null;
};

export default ForterRefreshToken;
