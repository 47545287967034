import { createContext, useContext } from 'react';
import type { NextRouter } from 'next/router';
import type { useRouter } from 'next/navigation';
import { Location } from 'history';

interface AppContextInterface {
  location: Location | NextRouter | ReturnType<typeof useRouter>;
  nextApp?: boolean;
  ssg?: boolean;
  windowWidth: number;
}

const AppContext = createContext<AppContextInterface | Record<string, never>>({});

export const { Provider, Consumer } = AppContext;
export const useAppContext = () => useContext(AppContext);
