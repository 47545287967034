import compress from 'graphql-query-compress/lib/graphql-query-compress.browser';
import { gql } from '@apollo/client/core';

export const getLocaleAvailabilityExclusiveFilter = ({ localeAvailabilityExclusive }:{ localeAvailabilityExclusive: string }) => `
  localeAvailabilityExclusive: ${localeAvailabilityExclusive}
`;

export const getAbTestFilterCondition = (aBTestVersion?: string) => (
  aBTestVersion ? `{ OR: [{ aBTestVersion: null }, { aBTestVersion: ${aBTestVersion} }] },` : ''
);

export const styleFields = `
  property
  value
  selector
  breakPoint
`;

export const optionValuesFields = `
  optionId
  optionValue
  type
  value
  cartDescription
`;

export const skuSplitFields = `
  color
  arms
  model
  collection
  furnitureType
  attachedOttoman
  type
  legs
  size
  styleIdentifier
  legStyle
  addOnItems
  cushionColor
  brand
  partner
  tableFinish
  accessory
  material
  cover
  fabricMaterial
  woodFinish
`;

export const linkPageQuery = `
  url
  product {
    sku
    defaultSku
    skuParse {
      skuFields
    }
  }
`;

export const assetQuery = `
  alt
  fileName
  url
  handle
  mimeType
  width
  height
  externalAssetUrl
  screenSize
  assetSources {
    source
    maxWidth
    type
    order
  }
`;

export const collectionFiltersFields = `
  description
  header
  skuMatchers
  slug
  asset {
    ${assetQuery}
  }
`;

export const materialsFields = `
  name
  skuMatchers
  colorBuckets {
    name
    label
    color
    colors
    order
  }
`;

export const filterRedirectsFields = `
  page {
    url
  }
  material {
    name
  }
  collectionFilter {
    slug
  }
`;

export const filterFields = `
  id
  configurations
  collectionFilters {
    ${collectionFiltersFields}
  }
  materials {
    ${materialsFields}
  }
  filterRedirects {
    ${filterRedirectsFields}
  }
  productCollections {
    id
    productCollectionItems {
      id
      type
      sku
      filterCategories
      product {
        id
        modifiers {
          slug
          onSelectFunction
          subTitle
          options {
            value
          }
        }
        skuParse {
          skuFields
        }
      }
    }
  }
`;

export const productCardImageFields = `
  type
  images {
    ${assetQuery}
  }
`;

export const colorFields = `
  hex
  css
  rgba {
    r
    g
    b
    a
  }
`;

export const callToAction = `
  align
  badgeCopy
  backgroundColor
  textColor
  className
  verticalAlign
  color {${colorFields}}
  background {${colorFields}}
`;

export const richTextFields = `
  html
  markdown
  text
`;

export const linkQuery = `
  id
  asset {
    ${assetQuery}
  }
  text
  textColor
  className
  type
  order
  href
  anchor
  queryParams
  components {
    id
    type
  }
  color {
    ${colorFields}
  }
  buttonColor {
    ${colorFields}
  }
  richText {
    ${richTextFields}
  }
  destinationPage {
    ${linkPageQuery}
  }
  callToAction {
    ${callToAction}
  }
  horizontalAlign
  modals {
    header
    copy
    footnote
    teaserCopy
    buttonCopy
    order
    teaserIcon {
      ${assetQuery}
    }
    links {
      textColor
      type
      text
      destinationPage {
        ${linkPageQuery}
      }
    }
    points {
      anchor
      type
      header
      subHeader
      copy
      images {
        ${assetQuery}
      }
      aspectRatios
    }
    assets {
      ${assetQuery}
    }
  }
  productCollectionItems {
    sku
    tuftedSetting
    flipSetting
    addOnItems
  }
`;

export const modalFields = `
  copy
  footnote
  buttonCopy
  teaserCopy
  teaserIcon {
    ${assetQuery}
  }
  header
  links {
    ${linkQuery}
  }
  assets {
    ${assetQuery}
  }
`;

export const getProductModifierFields = (client: boolean) => `
  type
  modifierStyle
  modifierOperation
  title
  className
  slug
  showModifierValue
  onSelectFunction
  order
  bcOptionDisplayName
  activeStateProp
  shouldUpdateQueryParam
  subTitle
  requiredActiveModifierOptions {
    id
    value
  }
  options${!client ? '(orderBy: order_ASC)' : ''} {
    id
    label
    value
    description
    order
    priceAdjust
    addOnBundleDiscount
    pricing {
      originalPrice
      price
    }
    active
    quantity
    maxQuantity
    className
    groupName
    links {
      ${linkQuery}
    }
    modals {
      ${modalFields}
    }
    namePrepend
    nameAppend
    nameChange
    changeGalleryIndex
    cartDescription
    asset {
      ${assetQuery}
    }
    colors { hex }
    borderColor { hex }
  }
  modals {
    ${modalFields}
  }
`;

export const relatedProductFields = `
  id
  sku
  skuID
  optionValues {
    ${optionValuesFields}
  }
`;

export const seoFields = `
  url
`;

export const priceFields = `
  formatted
  raw
  inCents
`;

export const pricingInformation = `
  price {
    ${priceFields}
  }
  salePrice {
    ${priceFields}
  }
  originalPrice {
    ${priceFields}
  }
  variantPriceRange
  variantSaleRange
  addOnSavings
`;

export const galleryFields = `
  type
  images {
    ${assetQuery}
  }
`;

export const modifiersFields = `
  name
  optionId
  optionValues {
    price
    id
    productId
  }
`;

export const dimensionFields = `
  dimensions {
    header
    dimension
    dimensionOption
  }
  dimensionImages {
    ${assetQuery}
  }
`;

export const breadcrumbFields = `
  productPath {
    ${linkQuery}
  }
  collectionPath {
    ${linkQuery}
  }
`;

export const promotionMeta = `
  id
  type
  startDate
  endDate
  modifierOptions {
    value
  }
`;

const productStyleCommonFields = `
  id
  name
  label
  slug
  fallbackSku
  icon {
    url
    width
    height
  }
`;

export const productStyleFields = `
  ${productStyleCommonFields}

  configurations {
    ${productStyleCommonFields}
    skuPartOnSelectPairs {
      onSelectFunction
      skuPartValue
    }
  }
`;

const getVariantFields = () => `
  sku
  id
  skuID
  short
  parentSku
  url
  image
  metaImage
  optionsDescription
  type
  name
  description
  addOnSavings
  addOnSavingsCopy
  activeModifier
  brand
  productID
  productType
  category
  collection
  seatType
  sellWithoutInventory
  price
  skuParse {
    skuFields
  }
  skuSplit {
    ${skuSplitFields}
  }
  seo {
    ${seoFields}
  }
  pricing {
    ${pricingInformation}
  }
  optionValues {
    ${optionValuesFields}
  }
  gallery {
    ${galleryFields}
  }
  productCardImage {
    ${productCardImageFields}
  }
  modifiers {
    ${modifiersFields}
  }
  ${dimensionFields}
  breadcrumbs {
    ${breadcrumbFields}
  }
  relatedProducts {
    ${relatedProductFields}
  }
  promotions {
    ${promotionMeta}
  }
  filter {
    category
    order
  }
  inferredAddOn {
    quantity
    product {
      id
      skuID
      sku
      optionValues {
        id
      }
      price {
        ${priceFields}
      }
    }
  }
  productStyles {
    ${productStyleFields}
  }
`;

export const getVariantQuery = (client: boolean) => `
  ${getVariantFields()}
  productModifiers${!client ? '(orderBy: order_ASC)' : ''} {
    ${getProductModifierFields(client)}
  }
  setProducts {
    quantity
    product {
      ${getVariantFields()}
    }
    modifiers {
      ${modifiersFields}
    }
  }
`;

export const userFields = `
  id
  firstName
  lastName
  email
  burrowHouseAssociate
  tradePartner
  attributes {
    userUuid
  }
`;

export const productQuery = `
  product {
    defaultSku
    sku
    skuParse {
      skuFields
    }
  }
`;

export const productCollectionItems = (client: boolean) => compress(`
  productCollectionItems${!client ? '(orderBy: order_ASC)' : ''} {
    id
    order
    type
    sku
    filterCategories
    visibilities
    columnWidths
    ${client ? `item {
      ${getVariantQuery(client)}
    }` : ''}
    salesCopy
    lifestyleHeader
    lifestyleCopy
    columnSpan
    lifestyleCopyBackgroundColor
    lifestyleIcon {
      ${assetQuery}
    }
    alignHorizontally
    skuModifier
    addOnItems
    callToActions {
      ${callToAction}
    }
    lifestyleAsset {
      ${assetQuery}
    }
    tuftedSetting
    flipSetting
    hideColorPicker
    hideShoppingLink
    link {
      ${linkQuery}
    }
    coordinateX
    coordinateY
  }
`);

export const sitemapQuery = `
  siteMapLinks {
    group
    link
    name
    type
    sku
  }
`;

export const getPromotionFields = (client = false) => `
  id
  type
  copy
  callToAction {
    ${callToAction}
  }
  description
  footnote
  title
  ${!client ? 'localeAvailabilityExclusive' : ''}
  appliesAutomatically
  isSpecialTieredPromotion
  salesTiers${!client ? '(orderBy: level_ASC)' : ''} {
    id
    level
    discountAmount
    copy
    type
    couponCode
    copy
  }
  startDate
  teaserCopy
  enterCodeCopy
  tiersBackgroundColor {
    ${colorFields}
  }
  tiersTextColor {
    ${colorFields}
  }
  tiersHighlightColor {
    ${colorFields}
  }
  couponCode
  tierDistanceCopy
  maxSavingsCopy
  endDate
  modifierOptions {
    id
    value
  }
`;

export const getFooterNavigationItemsQuery = ({ localeAvailabilityExclusive }: { localeAvailabilityExclusive: string }) => compress(`
  navigationSections(
    orderBy: orderInTier_ASC,
    where: {
      ${getLocaleAvailabilityExclusiveFilter({ localeAvailabilityExclusive })}
    }
  ) {
    id
    header
    type
    tier
    color {${colorFields}}
    hoverColor {${colorFields}}
    orderInTier
    localeAvailabilityExclusive
    link {
      ${linkQuery}
      hoverColor {
        ${colorFields}
      }
    }
    categories(orderBy: order_ASC) {
      ${linkQuery}
      hoverColor {
        ${colorFields}
      }
      localeAvailabilityExclusive
    }
    features(orderBy: order_ASC) {
      ${linkQuery}
      hoverColor {
        ${colorFields}
      }
      localeAvailabilityExclusive
    }
    callToAction {
      ${callToAction}
    }
  }
`);

export const placeDetails = `
  placeDetails {
    address_one
    address_two
    city
    phone
    directionsUrl
    hours
  }
`;

export const showroomsQuery = (client: boolean) => `
  showrooms {
    name
    url
    type
    state
    placeId
    items
    ${client ? placeDetails : ''}
  }
`;

export const dimensionQuery = `
  header
  dimension
  dimensionOption
`;

export const collectionLayoutFields = `
  availableLayouts
  defaultLayout
  screenSize
  showMoreLimit
  subNavigationType
`;

export const componentFields = (client = false) => compress(`
  id
  order
  header
  subHeader
  type
  className
  images {
    ${assetQuery}
  }
  aspectRatios
  overlayAsset {
    ${assetQuery}
  }
  copy
  richTextCopy {
    ${richTextFields}
  }
  anchor
  align
  bodyFontSize
  backgroundColor {
    ${colorFields}
  }
  overlayColor {
    ${colorFields}
  }
  textColor {
    ${colorFields}
  }
  styles {
    ${styleFields}
  }
  verticalAlign
  collections {
    id
    header
    subHeader
    slug
    order
    ${productCollectionItems(client)}
    assets {
      ${assetQuery}
    }
    collectionLayouts {
      ${collectionLayoutFields}
    }
  }
  products
  visibilities
  columnWidths
  theme
  points {
    anchor
    type
    header
    subHeader
    copy
    markdownCopy
    headerSize
    bodyFontSize
    images {
      ${assetQuery}
    }
    aspectRatios
    align
    verticalAlign
    parallaxBoundary
    icon {
      ${assetQuery}
    }
    links {
      ${linkQuery}
    }
    styles {
      ${styleFields}
    }
  }
  ancestorImitatesLink
  links {
    ${linkQuery}
  }
  dimensions${!client ? '(orderBy: order_ASC)' : ''} {
    ${dimensionQuery}
  }
  expanded
  date
  headerSize
  disableInfiniteSlider
  autoplay
`);

export const componentsQuery = (client: boolean) => compress(`
  components${!client ? '(orderBy: order_ASC)' : ''} {
    ${componentFields(client)}
  }
`);

export const getFooterQuery = () => compress(`
  components(where: { type: Footer }) {
    ${componentFields()}
  }
`);

export const getCXOperatingHoursQuery = () => compress(`
  id
  holidays
  isClosed
  cxSmsNumber
  cxDailyHours {
    weekday
    openHour
    openMinute
    closeHour
    closeMinute
  }
`);

export const getCollectionsFields = (client: boolean) => compress(`
  description
  header
  id
  subHeader
  slug
  order
  ${productCollectionItems(client)}
  callToAction {
    ${callToAction}
  }
  assets {
    ${assetQuery}
  }
  componentsComingBefore {
    ${componentFields(client)}
  }
  collectionLayouts {
    ${collectionLayoutFields}
  }
`);

const navigationFeaturedProductCollection = `
  featuredProductCollection {
    id
    subHeader
    productCollectionItems {
      id
      order
      type
      sku
      visibilities
      lifestyleCopyBackgroundColor
      lifestyleIcon {
        ${assetQuery}
      }
      lifestyleHeader
      lifestyleCopy
      lifestyleAsset {
        ${assetQuery}
      }
    }
}`;
export const getHeaderNavigationItemsQuery = ({
  localeAvailabilityExclusive,
  aBTestVersion,
}: {
  localeAvailabilityExclusive: string;
  aBTestVersion?: string;
}) => `
  navigationItems(
    orderBy: order_ASC,
    where: {
      AND: [
        { tier_in: [Shopping, Utility, Locale] },
        { ${getLocaleAvailabilityExclusiveFilter({ localeAvailabilityExclusive })} },
        ${getAbTestFilterCondition(aBTestVersion)}
      ]
    }
  ) {
    color {${colorFields}}
    hoverColor {${colorFields}}
    navigationId: id
    header
    visibilities
    tier
    navigationType: type
    order
    localeAvailabilityExclusive
    link {
      id
      type
      text
      anchor
      destinationPage {
        ${linkPageQuery}
      }
      hoverColor {
        hex
      }
      color {
        hex
      }
    }
    points {
      type
      header
      subHeader
      copy
      markdownCopy
      headerSize
      bodyFontSize
      localeAvailability
      images {
        ${assetQuery}
      }
      aspectRatios
      align
      verticalAlign
      links {
        type
        text
        destinationPage {
          ${linkPageQuery}
        }
      }
    }
    ${navigationFeaturedProductCollection}
    expandedMobileSection
    features {
      __typename
      ... on Link {
        id
        type
        text
        anchor
        localeAvailabilityExclusive
        destinationPage {
          ${linkPageQuery}
        }
        asset {
          ${assetQuery}
        }
        color {${colorFields}}
        hoverColor {${colorFields}}
        href
      }
      ... on NavigationItem {
        navigationId: id
        header
        navigationType: type
        localeAvailabilityExclusive
        ${navigationFeaturedProductCollection}
        features {
          __typename
          ... on Link {
            id
            type
            text
            anchor
            localeAvailabilityExclusive
            callToAction {
              ${callToAction}
            }
            destinationPage {
              ${linkPageQuery}
            }
            color {${colorFields}}
            hoverColor {${colorFields}}
          }
        }
      }
    }
  }
`;

export const getPageQuery = (client: boolean) => compress(`
  id
  url
  type
  pageTitle
  pageDescription
  pageImage {
    ${assetQuery}
  }
  header
  subHeader
  bannerContent
  backgroundColor {
    ${colorFields}
  }
  mobileBannerContent
  pageTemplate {
    ${componentsQuery(client)}
  }
  ${productQuery}
  ${showroomsQuery(client)}
  ${componentsQuery(client)}
  ${sitemapQuery}
  productCollections {
    ${getCollectionsFields(client)}
  }
  filter {
    ${filterFields}
  }
  availablePages {
    url
  }
`);

export const mulberryOffer = `
  url
`;

export const trackingItems = `
  orderProductId
  productId
  quantity
`;

export const shipmentTracking = `
  trackingUrl
  trackingStatus
  trackingCopy
  trackingItems {
    ${trackingItems}
  }
`;

export const getCheckoutQuery = () => compress(`
  id
  billingAddress {
    firstName
    lastName
    email
    company
    address1
    address2
    city
    state
    zipcode
    phone
  }
  shippingConsignment {
    consignmentId
    shippingOptionId
    shippingOptionType
    address {
      firstName
      lastName
      email
      address1
      address2
      city
      state
      zipcode
      phone
      countryCode
      textUpdates
      signatureOnDelivery
    }
    lineItemIds
  }
  tax
  grandTotal
`);

export const querifyField = (fieldName: string, value: unknown, type?: string) => {
  switch (type || typeof value) {
    case 'string':
      return typeof value === 'string' ? `${fieldName}: "${value}"` : '';
    case 'number':
      return Number.isFinite(value) ? `${fieldName}: ${value}` : '';
    case 'boolean':
      return value ? `${fieldName}: ${value}` : '';
    default:
      return '';
  }
};

export const querifyFields = (fields: [string, any, string][]) => (
  compress(`{${
    fields
      .map((params) => querifyField(...params))
      .filter((querifiedField) => querifiedField)
      .join(', ')
  }}`)
);

export const querifyLineItem = ({ id, quantity }: { id: string, quantity: number }) => querifyFields([
  ['item_id', id, 'string'],
  ['quantity', quantity, 'number'],
]);

export const getFetchConsignmentQuery = () => gql`
  query GetConsigment {
    consignment: getCheckout {
      ${getCheckoutQuery()}
    }
  }
`;

export const querifyCartItems = (lineItems: Record<string, any>[]) => (
  lineItems?.length
    ? compress(`[${lineItems.map(querifyLineItem).join(', ')}]`)
    : '[]'
);

export const querifyShippingAddress = ({
  firstName,
  lastName,
  email,
  company,
  address1,
  address2,
  city,
  state,
  stateCode,
  zipcode,
  signatureOnDelivery,
  textUpdates,
  countryCode,
  phone,
}: Record<string, any>) => querifyFields([
  ['firstName', firstName, 'string'],
  ['lastName', lastName, 'string'],
  ['email', email, 'string'],
  ['company', company, 'string'],
  ['address1', address1, 'string'],
  ['address2', address2, 'string'],
  ['city', city, 'string'],
  ['state', state, 'string'],
  ['stateCode', stateCode, 'string'],
  ['zipcode', zipcode, 'string'],
  ['countryCode', countryCode, 'string'],
  ['phone', phone, 'string'],
  ['signatureOnDelivery', signatureOnDelivery, 'boolean'],
  ['textUpdates', textUpdates, 'boolean'],
]);

/**
 * @TODO:
 * https://hiburrow.atlassian.net/browse/BTR-1035
 * @param shippingAddress
 * @param items
 */
export const getCreateConsignmentQuery = (shippingAddress: Record<string, any>, items: Record<string, any>[]) => gql`
  mutation CreateConsignment {
    consignment: createConsignment(
      shippingAddress: ${querifyShippingAddress(shippingAddress)}
      items: ${querifyCartItems(items)}
    ) {
      ${getCheckoutQuery()}
    }
  }
`;

/**
 * @TODO:
 * https://hiburrow.atlassian.net/browse/BTR-1032
 * @param shippingAddress
 * @param items
 * @param consignmentId
 */
export const getUpdateConsignmentQuery = (shippingAddress: Record<string, any>, items: Record<string, any>[], consignmentId: number) => gql`
  mutation UpdateConsignment {
    consignment: updateConsignment(
      consignmentId: "${consignmentId}"
      update: {
        shippingAddress: ${querifyShippingAddress(shippingAddress)}
        items: ${querifyCartItems(items)}
      }
    ) {
      ${getCheckoutQuery()}
    }
  }
`;

export const getOrderHistorySchema = () => compress(`
  id
  dateCreated
  email
  total {
    formatted
  }
  status
  billingAddress {
    firstName
    lastName
    address1
    address2
    city
    state
    zipcode
    phone
    country
  }
  shippingAddress {
    firstName
    lastName
    address1
    address2
    city
    state
    zipcode
    phone
    country
  }
`);

export const getOrderFields = (client: boolean) => compress(`
  id
  status
  dateCreated
  paymentMethod
  email
  tax {
    raw
    formatted
  }
  discount
  subtotal {
    raw
    formatted
  }
  total {
    raw
    formatted
  }
  shippingCost {
    raw
    formatted
  }
  billingAddress {
    firstName
    lastName
  }
  items {
    id
    productId
    variantId
    name
    sku
    basePrice {
      raw
      formatted
    }
    imageUrl
    quantity
    images {
      ${productCardImageFields}
    }
    skuSplit {
      ${skuSplitFields}
    }
    productModifiers${!client ? '(orderBy: order_ASC)' : ''} {
      ${getProductModifierFields(client)}
    }
    tracking {
      ${shipmentTracking}
    }
    description
  }
  shippingAddress {
    firstName
    lastName
    address1
    address2
    city
    state
    zipcode
    phone
  }
  mulberryOffer {
    ${mulberryOffer}
  }
  salesChannel
  triggerOrderCompletedClientEvent
  textUpdates
  analytics {
    firstPurchase,
    coupon,
    discountFactor,
    numItems,
    products {
      productId,
      name,
      quantity,
      sku,
      price,
      imageUrl
    }
  }
  currency
  hrefLang
`);

export const getFilterSchema = () => compress(`
  id
  name
  configurations
  collectionFilters {
    ${collectionFiltersFields}
  }
  materials {
    ${materialsFields}
  }
  productCollections {
    id
    productCollectionItems {
      id
      sku
      filterCategories
      product {
        id
        modifiers(where: {slug_in: ["fabric", "velvet", "leather", "color"]}) {
          slug
          options {
            value
          }
        }
        skuParse {
          skuFields
        }
      }
    }
  }
  filterRedirects {
    ${filterRedirectsFields}
  }
`);

export const getCareersSchema = () => compress(`
  jobs {
    id
    applyUrl
    title
    description {
      ${richTextFields}
    }
  }
`);

export const cartFields = `
  id
  cartTotalAmount
  MSRPSubtotal
  saleSavings
  baseAmount
  taxAmount
  shippingAmount
  cartNumber
  items: lineItems {
    id
    variantId
    productId
    parentId
    sku
    name
    brand
    description
    productCardImage {
      ${assetQuery}
    }
    quantity
    listPrice
    originalPrice
    optionValues {
      ${optionValuesFields}
    }
    seo {
      url
    }
    productType
    category
  }
  coupons {
    id
    code
    name
    couponType
    discountedAmount
  }
  discounts {
    cartLevel
    bundleSaving
    coupon
  }
`;
