import {
  call, put, select, takeLatest,
} from 'redux-saga/effects';

import { gql } from '@apollo/client/core';
import {
  CREATE_OR_UPDATE_CONSIGNMENT,
  createOrUpdateConsignmentFailure,
  createOrUpdateConsignmentRequest,
  createOrUpdateConsignmentSuccess,
  FETCH_CONSIGNMENT,
  FETCH_TERMINALS,
  fetchConsignmentFailure,
  fetchConsignmentRequest,
  fetchConsignmentSuccess,
  fetchTerminalsFailure,
  fetchTerminalsRequest,
  fetchTerminalsSuccess,
  RESEND_PAYMENT_LINK,
  resendPaymentLinkFailure,
  resendPaymentLinkRequest,
  resendPaymentLinkSuccess,
  SUBMIT_CHECKOUT,
  submitCheckoutFailure,
  submitCheckoutRequest,
  submitCheckoutSuccess,
} from '../actions/checkout.actions';
import { setErrorPopupMessage } from '../actions/ui.actions';
import { fetchCartSuccess } from '../actions/cart.actions';
import { getCartDetails } from '../selectors/cart.selectors';
import { getConsignmentId } from '../selectors/checkout.selectors';
import RequestHelpers from '../../components/global/RequestHelpers';
import CheckoutHelpers from '../../components/checkout/CheckoutHelpers';
import {
  getCreateConsignmentQuery,
  getFetchConsignmentQuery,
  getUpdateConsignmentQuery,
  cartFields,
} from '../../../../apis/QueryBuilder';
import { logError } from '../../../../utils/Utils';

// FIXME: [POS] rename this function
export function* fetchTerminalsSaga() {
  yield put(fetchTerminalsRequest());
  try {
    const query = gql`query GetSalesChannels {
      salesChannels: getSalesChannels {
        displayName
        paymentLocation
        paymentMethods
        paymentTerminals {
          terminalId
        }
      }
    }`;
    const { terminals, salesChannels } = yield call(RequestHelpers.apolloGqlQuery, query);
    yield put(fetchTerminalsSuccess({ terminals, salesChannels }));
  } catch (error) {
    yield call([this, logError], error, fetchTerminalsSaga.name);
    if (error.message) {
      yield put(setErrorPopupMessage(RequestHelpers.errorMessages.generic));
    }
    yield put(fetchTerminalsFailure(error));
  }
}

export function* processCheckoutSaga({
  terminal,
  salesChannel,
  paymentMethod,
  sendPayLink,
}) {
  yield put(submitCheckoutRequest());
  try {
    const mutation = gql`mutation SubmitCheckout {
      submitCheckout(
        terminal: "${terminal}"
        salesChannel: "${salesChannel}"
        paymentMethod: "${paymentMethod}"
        sendPayLink: ${!!sendPayLink}
      ) {
        redirectURL
        orderID
        payLinkURL
      }
    }`;
    const { submitCheckout: { redirectURL, payLinkURL, orderID } } = yield call(RequestHelpers.apolloGqlMutate, mutation);
    yield put(submitCheckoutSuccess(redirectURL));
    if (payLinkURL) {
      return yield put(setErrorPopupMessage(
        CheckoutHelpers.formatPayLinkMessage(orderID, payLinkURL),
        redirectURL,
        'deleteCartOnClose',
      ));
    }
    window.location.href = redirectURL;
    return redirectURL;
  } catch (error) {
    yield call([this, logError], error, processCheckoutSaga.name);
    if (error.message) {
      yield put(setErrorPopupMessage(
        RequestHelpers.errorMessages[error.message]
          ? RequestHelpers.errorMessages[error.message]
          : RequestHelpers.errorMessages.generic,
      ));
    }
    return yield put(submitCheckoutFailure(error));
  }
}

export function* fetchConsignmentSaga() {
  try {
    yield put(fetchConsignmentRequest());
    const query = getFetchConsignmentQuery();
    const { consignment } = yield call(RequestHelpers.apolloGqlQuery, query);
    yield put(fetchConsignmentSuccess(consignment));
  } catch (error) {
    yield call([this, logError], error, fetchConsignmentSaga.name);
    yield put(setErrorPopupMessage(
      RequestHelpers.errorMessages[error.message]
        ? RequestHelpers.errorMessages[error.message]
        : RequestHelpers.errorMessages.generic,
    ));
    yield put(fetchConsignmentFailure(error));
  }
}

export function* createOrUpdateConsignmentSaga({ shippingAddress }) {
  try {
    yield put(createOrUpdateConsignmentRequest());
    const consignmentId = yield select(getConsignmentId);
    const { items } = yield select(getCartDetails);
    const mutation = consignmentId
      ? getUpdateConsignmentQuery(shippingAddress, items, consignmentId)
      : getCreateConsignmentQuery(shippingAddress, items);
    const { consignment } = yield call(RequestHelpers.apolloGqlMutate, mutation);
    yield put(createOrUpdateConsignmentSuccess(consignment));
    const query = gql`query GetCart {
      getCart {
        ${cartFields}
      }
    }`;
    const cartDetails = yield call(RequestHelpers.apolloGqlQuery, query);
    yield put(fetchCartSuccess(cartDetails.getCart));
  } catch (error) {
    yield call([this, logError], error, createOrUpdateConsignmentSaga.name);
    yield put(setErrorPopupMessage(
      RequestHelpers.errorMessages[error.message]
        ? RequestHelpers.errorMessages[error.message]
        : RequestHelpers.errorMessages.generic,
    ));
    yield put(createOrUpdateConsignmentFailure(error));
  }
}

export function* resendPaymentLinkSaga({
  phone,
  email,
  id,
  total,
  textUpdates,
}) {
  yield put(resendPaymentLinkRequest());
  try {
    const mutation = gql`mutation ResendPaymentLink {
      resendPaymentLink(
        phone: "${phone}"
        email: "${email}"
        textUpdates: ${textUpdates}
        total: ${total}
        id: "${id}"
      ) {
        orderID
        payLinkURL
      }
    }`;
    const { resendPaymentLink: { payLinkURL, orderID } } = yield call(RequestHelpers.apolloGqlMutate,  mutation);
    yield put(resendPaymentLinkSuccess(payLinkURL));
    return yield put(setErrorPopupMessage(CheckoutHelpers.formatPayLinkMessage(orderID, payLinkURL)));
  } catch (error) {
    yield call([this, logError], error, resendPaymentLinkSaga.name);
    if (error.message) {
      yield put(setErrorPopupMessage(
        RequestHelpers.errorMessages[error.message]
          ? RequestHelpers.errorMessages[error.message]
          : RequestHelpers.errorMessages.generic,
      ));
    }
    return yield put(resendPaymentLinkFailure(error));
  }
}

const checkoutSagas = [
  takeLatest(SUBMIT_CHECKOUT, processCheckoutSaga),
  takeLatest(FETCH_TERMINALS, fetchTerminalsSaga),
  takeLatest(FETCH_CONSIGNMENT, fetchConsignmentSaga),
  takeLatest(CREATE_OR_UPDATE_CONSIGNMENT, createOrUpdateConsignmentSaga),
  takeLatest(RESEND_PAYMENT_LINK, resendPaymentLinkSaga),
];

export default checkoutSagas;
