import {
  FETCH_PAGE_CONTENT__REQUEST,
  FETCH_PAGE_CONTENT__SUCCESS,
  FETCH_PAGE_CONTENT__FAILURE,
  SET_PAGE_CONTENT,
  SET_FILTERS_SELECTED,
} from '../actions/page.actions';

export const initialState = {
  isPending: false,
  error: null,
  data: {
    content: {
      components: [],
      footer: null,
      backgroundColor: { hex: '#fff' },
      filter: {},
    },
    footer: undefined,
  },
};

const updatePageContentRequest = (state) => ({
  ...state,
  isPending: true,
});

const updatePageContentFailure = (state, error) => ({
  ...state,
  isPending: false,
  error,
  data: {
    ...state.data,
    content: initialState.data.content,
  },
});

const setPageContent = (state, payload) => ({
  ...state,
  isPending: false,
  error: null,
  data: {
    ...state.data,
    content: payload || initialState.data.content,
  },
});

const setFiltersSelected = (state, filtersSelected) => ({
  ...state,
  data: {
    ...state.data,
    content: {
      ...state.data.content,
      filter: {
        ...state.data.content.filter,
        filtersSelected,
      },
    },
  },
});

const pageReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PAGE_CONTENT__REQUEST:
      return updatePageContentRequest(state);

    case FETCH_PAGE_CONTENT__FAILURE:
      return updatePageContentFailure(state, action.error);

    case SET_PAGE_CONTENT:
    case FETCH_PAGE_CONTENT__SUCCESS:
      return setPageContent(state, action.payload);

    case SET_FILTERS_SELECTED:
      return setFiltersSelected(state, action.payload);

    default:
      return state;
  }
};

export default pageReducer;
