import { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getVariantStateParams } from '../ProductCard';
import DetailsModal from '../../global/DetailsModal';
import FavoriteCardDetailsModal from './FavoriteCardDetailsModal';

import {
  generateImageAltTag,
  getAppropriateGalleryAsset,
} from '../../global/ProductHelpers';
import { ProductDto, ProductCollectionItemDto, ProductCollectionDto } from '../../../types/schema';
import { withCssModulesClassNames } from '../../../common/nextMigrationHelpers';
// eslint-disable-next-line import/no-named-default
import { default as styles } from '../../../scss/components/FavoriteCard.module.scss';
import { getUserDetails } from '../../../store/selectors/user.selectors';
import { addFavoriteItem, removeFavoriteItem } from '../../../store/slices/favorites.slice';
import { getFavoritesFrom, isFavoriteItemSelected } from '../../../store/selectors/favorites.selector';
import Analytics from '../../global/Analytics';
import Asset from '../../global/Asset';

const withCssModulesClassNamesHandler = withCssModulesClassNames(styles);

const GROUP = 'swatches';
const TRACKING_NAME = 'Favorite Card';

export interface FavoriteCardProps {
  item: ProductDto;
  modal: ProductCollectionItemDto['modal'];
  collectionHeader: ProductCollectionDto['header'];
}

const FavoriteCard = ({
  item,
  modal,
  collectionHeader,
}: FavoriteCardProps) => {
  const dispatch = useDispatch();
  const favoriteCardRef = useRef<HTMLDivElement>(null);
  const [isCardViewed, setIsCardViewed] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [state] = useState(
    () => getVariantStateParams({
      item,
      sku: item.sku,
    }),
  );
  const userDetails = useSelector(getUserDetails);
  const items = useSelector(getFavoritesFrom(GROUP));
  const isSelected = useSelector(isFavoriteItemSelected({
    group: GROUP,
    sku: item.sku,
  }));

  const handleFavoriteCardKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.currentTarget !== event.target) {
      return;
    }

    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      favoriteCardRef.current?.click();
    }
  };

  const handleDetailsModal = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setShowDetailsModal(true);
  };

  const handleAddOrRemove = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    let eventType = '';

    if (isSelected) {
      eventType = 'Removed';
      dispatch(removeFavoriteItem({
        group: GROUP,
        sku: item.sku,
      }));
    } else if (items.length < 5) {
      eventType = 'Added';

      dispatch(addFavoriteItem({
        group: GROUP,
        item: {
          name: item.name,
          sku: item.sku,
          collectionHeader,
          productID: item.productID,
          optionValues: item.optionValues,
          productCardImage: item.productCardImage,
        },
      }));
    }

    if (eventType) {
      Analytics.enqueue({
        method: 'item',
        params: {
          item: state.item,
          email: userDetails.email,
          label: `Product ${eventType} from Wishlist`,
          event: `Product ${eventType} from Wishlist`,
        },
      });
    }
  };

  const handleMarkCardAsViewed = () => {
    try {
      if (state.item && !isCardViewed) {
        setIsCardViewed(true);

        Analytics.enqueue({
          method: 'item',
          params: {
            event: `${TRACKING_NAME} Viewed`,
            item: state.item,
            label: `${TRACKING_NAME} Viewed`,
            email: userDetails.email,
            source: 'swatches',
          },
          location: Analytics.getLocation(),
        });
      }
    } catch { /* nothing to do */ }
  };

  const addRemoveBtnText = isSelected ? `Remove ${item.productType}` : `Add ${item.productType}`;
  const images = state.item.productCardImage
    .map((image) => getAppropriateGalleryAsset(
      image,
      state.item.sku,
      state.item.collection ?? '',
      { tufted: undefined, door: undefined },
      state.addOns,
      state.productModifiers,
      state.activeTableFinish,
      state.activeFurnitureType,
      state.item?.skuParse,
    ));

  useEffect(() => {
    // On prod build, the selected classname is not being applied to the div
    // so we have to do it manually
    if (isSelected && !favoriteCardRef.current?.classList.contains('selected')) {
      favoriteCardRef.current?.classList.add(...withCssModulesClassNamesHandler('favorite-card-container', {
        selected: isSelected,
      }).split(' '));
    }
  }, [isSelected]);

  return (
    <>
      {
        showDetailsModal && modal && (
          <DetailsModal
            open={showDetailsModal}
            onClose={() => setShowDetailsModal(false)}
            componentToRender={() => (
              <FavoriteCardDetailsModal
                modal={modal}
                collectionHeader={collectionHeader}
              />
            )}
          />
        )
      }
      <div
        className={withCssModulesClassNamesHandler('favorite-card__wrapper')}
      >
        {
          images[0] && (
            <button
              className={withCssModulesClassNamesHandler('favorite-card__details-button')}
              aria-label={`View details of ${item.name}`}
              onClick={handleDetailsModal}
            >
              <i className={withCssModulesClassNamesHandler('icon', 'icon-expand')} data-testid="icon-card-expand"/>
            </button>
          )
        }
        <div
          tabIndex={0}
          ref={favoriteCardRef}
          aria-label={addRemoveBtnText}
          role="button"
          data-testid="favorite-card"
          className={withCssModulesClassNamesHandler('favorite-card-container', {
            selected: isSelected,
          })}
          onClick={handleAddOrRemove}
          onKeyDown={handleFavoriteCardKeyDown}
          onMouseOver={handleMarkCardAsViewed}
        >
          <div className={withCssModulesClassNamesHandler('favorite-card-container__image-container')}>
            {
              images[0] && (
                <>
                  <Asset
                    asset={images[0]}
                    maxWidth={favoriteCardRef?.current?.offsetWidth}
                    alt={generateImageAltTag(state.item)}
                    imageClassName={withCssModulesClassNamesHandler('favorite-card-container__graph-image')}
                  />
                </>
              )
            }
          </div>
          <h5 className={withCssModulesClassNamesHandler('favorite-card-container__title')}>{item.name}</h5>
          <div
            className={withCssModulesClassNamesHandler('favorite-card-container__description')}
          >
            <p>
              {
                isSelected ? `- ${addRemoveBtnText}` : `+ ${addRemoveBtnText}`
              }
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default FavoriteCard;
