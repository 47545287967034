import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { cart as cartGlobalAPI } from '../../../mock/globalAPI';
import { getHrefLang } from '../../../store/selectors/general.selectors';
import getCurrencyPrefix from '../../../common/getCurrencyPrefix';

class Discounts extends Component {
  render() {
    const { discounts, isCartDataPending, hrefLang } = this.props;
    const { discountTypes } = cartGlobalAPI;
    const currencyPrefix = getCurrencyPrefix({ hrefLang });

    if (!discounts) {
      return null;
    }

    return discountTypes.map(
      (discount, i) => discounts[discount.type] && discounts[discount.type] !== `-${currencyPrefix}0.00` && (
        <div key={i} className={`cart-footer__item ${discount.class}`}>
          <span>{discount.title}</span>
          <span className="amount">
            {isCartDataPending ? cartGlobalAPI.calculating : discounts[discount.type]}
          </span>
        </div>
      ),
    );
  }
}

Discounts.propTypes = {
  hrefLang: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  hrefLang: getHrefLang(state),
});

export default connect(mapStateToProps, null)(Discounts);
