enum Breakpoints {
  XS = 375,
  Small = 576,
  Phone = 640,
  Medium = 768,
  Tablet = 920,
  Kit = 970,
  Large = 1024,
  Larger = 1090,
  XL = 1280,
  MaxWidth = 1320,
  FullWidth = 1440,
  Gigantic = 1600,
}

export default Breakpoints;
