/** @typedef {import('../store.types').RootState} RootState */

export const getIsUserDataPending = ({ user }) => user.isPending;
export const getUserError = ({ user }) => user.error;
/** @param {RootState} state */
export const getUserDetails = ({ user }) => user.data.details;
export const getIsUserTradePartner = ({ user }) => user?.data?.details?.tradePartner;
export const getIsUserLoggedIn = ({ user }) => user.data.isLoggedIn;
export const getNewsletterSubscribeResponse = ({ user }) => user.newsletter.response;
export const getCanUpdateProfile = ({ user }) => user.data.canUpdateProfile;
export const getCanForget = ({ user }) => user.data.canForget;
export const getForgotPasswordFormMessage = ({ user }) => user.data.forgotPasswordFormMessage;
export const getAnalytics = ({ user }) => user.analytics;
