import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class PopoutUnderlay extends Component {
  render() {
    const {
      closePopouts,
      isPopoutOpen,
      isDropdownOpen,
      isMobileMenuOpen,
      isSearchDropdownOpen,
    } = this.props;

    const classes = classNames(
      'site--underlay',
      {
        'open-cart': isPopoutOpen,
        'open-dropdown': !isPopoutOpen && (isMobileMenuOpen || isDropdownOpen),
        'open-search': isSearchDropdownOpen,
      },
    );

    return (
      <div
        tabIndex="0"
        role="button"
        aria-label="Open cart"
        data-testid="popout underlay"
        aria-haspopup="true"
        className={classes}
        onClick={closePopouts}
      />
    );
  }
}

PopoutUnderlay.propTypes = {
  closePopouts: PropTypes.func.isRequired,
  isPopoutOpen: PropTypes.bool.isRequired,
  isDropdownOpen: PropTypes.bool.isRequired,
  isMobileMenuOpen: PropTypes.bool.isRequired,
  isSearchDropdownOpen: PropTypes.bool.isRequired,
};

export default PopoutUnderlay;
