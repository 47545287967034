import { useState } from 'react';

import { FavoriteItem } from '../../../store/slices/favorites.slice';
import { withCssModulesClassNames } from '../../../common/nextMigrationHelpers';
import { default as styles } from '../../../scss/components/FavoritesBucket.module.scss';

const cssModulesClassNames = withCssModulesClassNames(styles);

export const getImageUrl = ({
  sku,
  productCardImage,
}: Pick<FavoriteItem, 'sku' | 'productCardImage'>) => (
  productCardImage?.[0].images.find((image) => image.fileName.includes(sku))?.url
    || productCardImage?.[0].images[0].url
);

type FavoritesBucketItemProps = Record<string, undefined> | Partial<FavoriteItem> & {
  removeFavoriteHandler?: (event: React.MouseEvent<HTMLDivElement>) => void;
};

const FavoritesBucketItem = ({
  sku,
  productCardImage,
  name,
  collectionHeader,
  removeFavoriteHandler,
}: FavoritesBucketItemProps) => {
  const [showItemDetails, setShowItemDetails] = useState(false);

  return (
    <div
      data-testid="swatch-favorites-item"
      className={cssModulesClassNames(
        sku
          ? 'swatch-favorites__item'
          : 'swatch-favorites__item--empty',
      )}
      onMouseEnter={() => setShowItemDetails(true)}
      onMouseLeave={() => setShowItemDetails(false)}
    >
      {sku && productCardImage && (
        <>
          <img
            src={getImageUrl({
              sku,
              productCardImage,
            })}
            alt={name}
          />
          <div
            aria-label="Remove from favorites"
            role="button"
            data-testid="swatch-favorites-item-remove-btn"
            onClick={removeFavoriteHandler}
            className={cssModulesClassNames(
              'swatch-favorites__item-remove-btn',
            )}
          >
            <i className="icon icon-contained-close" />
          </div>
          {showItemDetails && (
            <div className={cssModulesClassNames('swatch-favorites__item-details')}>
              <p>{collectionHeader}</p>
              <p>&nbsp;-&nbsp;</p>
              <p>{name}</p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default FavoritesBucketItem;
