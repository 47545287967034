import UniversalLink from '../UniversalLink';
import { LinkDto } from '../../../types/schema/models/Link.dto';
import { withCssModulesClassNames } from '../../../common/nextMigrationHelpers';
import { default as searchDropdownStyles } from '../../../scss/components/SearchDropdown.module.scss';

const withCssModulesClassNamesHandler = withCssModulesClassNames(searchDropdownStyles);

export interface SearchDropdownPopularLinksProps {
  popularLinks: Pick<LinkDto, 'id' | 'text' | 'destinationPage'>[];
}

export const SEARCH_DROPDOWN_POPULAR_LINKS_CONTAINER_TEST_ID = 'search-dropdown-popular-links-container';

const SearchDropdownPopularLinks = ({ popularLinks }: SearchDropdownPopularLinksProps) => (
  <div
    data-testid={SEARCH_DROPDOWN_POPULAR_LINKS_CONTAINER_TEST_ID}
    className={withCssModulesClassNamesHandler('search-dropdown-popular-links-container')}
  >
    <p>Popular Links</p>
    <ul>
      {
        popularLinks.map((link) => {
          const labelText = link.text || '';

          return (
            <li key={link.id} className={withCssModulesClassNamesHandler('search-dropdown-popular-link')}>
              <UniversalLink
                type="Page"
                label={labelText}
                to={link.destinationPage?.url ?? '/'}
              >
                {labelText}
              </UniversalLink>
            </li>
          );
        })
      }
    </ul>
  </div>
);

export default SearchDropdownPopularLinks;
