import InfiniteScroll from 'react-infinite-scroll-component';

import { useSelector } from 'react-redux';
import { SearchProductsResponse } from '../../../../../Product/dto/search.dto';

import ProductCardContainer, { DEFAULT_ITEM_STATE } from '../../shopping/ProductCard';

import { withCssModulesClassNames } from '../../../common/nextMigrationHelpers';
import { default as searchStyles } from '../../../scss/components/Search.module.scss';
import { getSearchLayout } from '../../../store/selectors/ui.selectors';

const withCssModulesClassNamesHandler = withCssModulesClassNames(searchStyles);

export const SEARCH_PRODUCTS_CONTAINER_TEST_ID = 'search-products-container';

interface SearchInfiniteScrollProps {
  hasNextPage: boolean;
  onLoadMoreSearchProducts: () => void;
  searchProducts: SearchProductsResponse['products'];
}

const SearchInfiniteScroll = ({
  hasNextPage,
  searchProducts,
  onLoadMoreSearchProducts,
}: SearchInfiniteScrollProps) => {
  const selectedLayout = useSelector(getSearchLayout);

  return (
  <InfiniteScroll
    loader={null}
    dataLength={searchProducts.length}
    next={onLoadMoreSearchProducts}
    hasMore={!!hasNextPage}
  >
    <div
      data-testid={SEARCH_PRODUCTS_CONTAINER_TEST_ID}
      className={withCssModulesClassNamesHandler('search-products-grid-container', selectedLayout)}
    >
    {
        searchProducts.map(({
          sku, path, name, pricing, defaultImageUrl,
        }) => (
          <ProductCardContainer
            key={`product-card-${sku}`}
            visibilityClassNames={withCssModulesClassNamesHandler('product-card')}
            item={{
              ...DEFAULT_ITEM_STATE,
              name,
              sku,
              id: sku,
              url: path,
              pricing: {
                ...pricing,
                addOnSavings: null,
                variantSaleRange: null,
                variantPriceRange: null,
              },
              productCardImage: [
                {
                  sort: 1,
                  type: 'Asset',
                  images: [
                    {
                      alt: name,
                      url: defaultImageUrl,
                      fileName: defaultImageUrl.split('/').pop() ?? '',
                      handle: '',
                      width: 0,
                      height: 0,
                      mimeType: '',
                      assetSources: [],
                      screenSize: null,
                      externalAssetUrl: null,
                    },
                  ],
                },
              ],
            }}
            source="search"
            hideColorPicker
            hideDetails={false}
            waypointProps={{}}
          />
        ))
      }
    </div>
  </InfiniteScroll>
  );
};

export default SearchInfiniteScroll;
