import { createSelector } from 'reselect';
import { getQueryParams } from './router.selectors';
import {
  filterAttachAddOnItemModifierOptions,
  filterAddOnOptionsBySize,
  getRangeTableFinishFromAddOns,
  applyModifiersToProductName,
  getSelectedModifierFromModifier,
  isAttachAddonItemModifier,
  getOptionRelatedProduct,
} from '../../components/global/ProductHelpers';
import {
  getSelectedModifierValueAndCountFromQueryParams,
  getIsOptionSelected,
  getOptionCount,
  getSelectedProductModifierOptionsCb,
  getAddOnOptionsHandler,
} from '../../common/productHelpers';
import { getHrefLang } from './general.selectors';
import getCurrencyPrefix from '../../common/getCurrencyPrefix';

/** @typedef {import('../store.types').RootState} RootState */
/** @typedef {import('../../types/schema').ProductDto} ProductDto */
/** @typedef {keyof import('../../types/schema').ProductDto} ProductDtoProperties */
/** @typedef {import('../../types/schema').ModifierOption} ModifierOption */
/** @typedef {import('../../types/schema').PricingDto} PricingDto */

/** @param {RootState} state */
export const getIsProductDataPending = ({ product }) => product.isPending;
export const getIsProductAddonModifierOptionInventoryPending = ({ product }) => product.isAddonModifierOptionInventoryPending;
/** @param {RootState} state */
export const getIsFetchProductAvailabilityPending = ({ product }) => product.isFetchProductAvailabilityPending;
/** @param {RootState} state */
export const getSelectedModifiers = ({ product }) => (product?.data?.selectedModifiers) || [];
export const getLastModifierSelection = ({ product }) => (product?.data?.lastModifierSelection) || {};
export const getAddOnModifiers = ({ product }) => (product?.data?.addOnModifiers) || {};
/** @param {RootState} state */
export const getSelectedQty = ({ product }) => (product?.data?.selectedQty) || 1;
export const getShippingEstimates = ({ product }) => product?.data?.shippingEstimates;
/** @param {RootState} state */
export const getChannelAvailability = ({ product }) => product?.data?.channelAvailability;
export const getProductPromotionsLookup = ({ product }) => product.data.promotionsLookup;
export const getProductDetails = ({ product }, property) => product?.data?.details && (
  property
    ? product.data.details[property]
    : product.data.details
);

/**
 * @description The same functionality as getProductDetails, but in a HOF format,
 * so we can avoid creating a new function everytime we need a selector for it.
 *
 * @template {keyof ProductDto} K
 * @param {K} [property]
 * @returns {(state: RootState) => K extends keyof ProductDto ? ProductDto[K] : (ProductDto | null)}
 */
export const getProductDetailsHOF = (property) => ({ product }) => {
  const { details } = product.data;

  return details && property ? details[property] : details;
};

export const getSetProducts = (state) => getProductDetails(state, 'setProducts') || [];
export const getIsSetProduct = (state) => {
  const setProducts = getSetProducts(state);
  return Array.isArray(setProducts) && !!setProducts.length;
};
export const getSetProductsIds = (state) => getSetProducts(state).map(({ product: setProduct }) => setProduct.productID);
export const getSetProductsSkus = (state) => getSetProducts(state).map(({ product: setProduct }) => setProduct.sku);
export const getProductInventory = (state) => getProductDetails(state, 'inventory');
export const getProductSku = (state) => getProductDetails(state, 'sku');
export const getProductParentSku = (state) => getProductDetails(state, 'parentSku');
export const getProductId = (state) => getProductDetails(state, 'productID');
export const getProductType = (state) => getProductDetails(state, 'productType');
export const getProductCollection = (state) => getProductDetails(state, 'collection');
export const getProductSeatType = (state) => getProductDetails(state, 'seatType');
export const getProductGallery = (state) => getProductDetails(state, 'gallery');
export const getProductBreadcrumbs = (state) => getProductDetails(state, 'breadcrumbs');
export const getProductPromotions = (state) => getProductDetails(state, 'promotions') || [];
export const getRelatedProducts = (state) => getProductDetails(state, 'relatedProducts');
export const getProductPricing = (state) => getProductDetails(state, 'pricing');
export const getProductNameRaw = (state) => getProductDetails(state, 'name');
export const getProductModifiersRaw = (state) => getProductDetails(state, 'productModifiers');
export const getModifiersRaw = (state) => getProductDetails(state, 'modifiers');
export const getProductSellWithoutInventory = (state) => getProductDetails(state, 'sellWithoutInventory');

export const getAddonSavings = createSelector(
  getProductPricing,
  (pricing) => pricing?.addOnSavings,
);

export const getProductSkuSplit = createSelector(
  getProductDetails,
  (productDetails) => productDetails?.skuSplit,
);

export const getProductSkuSplitProperty = (state, property) => {
  const skuSplit = getProductSkuSplit(state);

  return skuSplit?.[property];
};

export const getActiveLegs = createSelector(
  getProductSkuSplit,
  (skuSplit) => skuSplit?.legs,
);

export const getActiveLegStyle = createSelector(
  getProductSkuSplit,
  (skuSplit) => skuSplit?.legStyle,
);

export const getActiveSize = createSelector(
  getProductSkuSplit,
  (skuSplit) => skuSplit?.size,
);

export const getActiveColor = createSelector(
  getProductSkuSplit,
  (skuSplit) => skuSplit?.color,
);

export const getInferredAddOn = createSelector(
  getProductDetails,
  (productDetails) => productDetails?.inferredAddOn,
);

export const getProductModifiers = createSelector(
  getProductModifiersRaw,
  getQueryParams,
  getSelectedModifiers,
  getProductSkuSplit,
  getRelatedProducts,
  (productModifiersRaw, queryParams, selectedModifiers, skuSplit, relatedProducts) => {
    if (!(productModifiersRaw && Array.isArray(productModifiersRaw))) return null;

    return productModifiersRaw.map((modifier) => {
      const {
        options,
        slug,
        type,
        modifierOperation,
      } = modifier;

      let filteredOptions = options;

      if (['AttachAddonItem', 'UpdateAddonItemVariant', 'PickList', 'AddToCart', 'AddToCartModifier'].includes(modifierOperation || type) && skuSplit) {
        filteredOptions = filterAddOnOptionsBySize(skuSplit, filteredOptions);
      }

      if (isAttachAddonItemModifier(modifier)) {
        filteredOptions = filterAttachAddOnItemModifierOptions(filteredOptions, relatedProducts);
      }

      return {
        ...modifier,
        options: filteredOptions.map((option) => ({
          ...option,
          selected: getIsOptionSelected(
            slug,
            productModifiersRaw,
            queryParams,
            option,
            selectedModifiers,
            skuSplit,
          ),
          count: getOptionCount(
            slug,
            productModifiersRaw,
            queryParams,
          ),
        })),
      };
    });
  },
);

export const getSelectedProductModifierOptions = createSelector(
  getProductModifiers,
  getProductSkuSplit,
  getSelectedProductModifierOptionsCb,
);

export const getProductModifierActiveOption = (slug) => createSelector(
  getProductModifiersRaw,
  getQueryParams,
  getSelectedModifiers,
  getProductSkuSplit,
  (productModifiers, queryParams, selectedModifiers, skuSplit) => {
    if (!Array.isArray(productModifiers)) {
      return null;
    }
    const productModifier = productModifiers.find((modifier) => modifier.slug === slug);
    if (!productModifier) {
      return null;
    }
    const { type, options } = productModifier;

    const filteredOptions = type === 'PickList' && skuSplit
      ? filterAddOnOptionsBySize(skuSplit, options)
      : options;

    return filteredOptions.find((option) => (
      getIsOptionSelected(
        slug,
        productModifiers,
        queryParams,
        option,
        selectedModifiers,
        skuSplit,
      )
    ));
  },
);

export const getGalleryModifiers = createSelector(
  getProductModifierActiveOption('tufted'),
  getProductModifierActiveOption('door'),
  (tuftedOption, doorOption) => ({
    tufted: tuftedOption && tuftedOption.value,
    door: doorOption && doorOption.value,
  }),
);

export const getAddOns = createSelector(
  getProductModifiers,
  (productModifiers) => {
    if (!productModifiers) return [];

    const addOns = [];

    productModifiers
      .filter((modifier) =>  isAttachAddonItemModifier(modifier))
      .forEach((modifier) => {
        modifier.options
          .filter(({ selected }) => selected)
          .forEach((selectedOption) => addOns.push({
            ...selectedOption,
            modifier,
            slug: modifier.slug,
          }));
      });

    return addOns;
  },
);

export const getActiveTableFinish = createSelector(
  getQueryParams,
  getProductSkuSplit,
  getAddOns,
  (queryParams, skuSplit, addOns) => (
    queryParams?.['table-add-on']?.slice(-4, -2)
    || (skuSplit && getRangeTableFinishFromAddOns(skuSplit, addOns))
  ),
);

export const getSelectedAddonsList = createSelector(
  getProductModifiers,
  getAddOns,
  getQueryParams,
  getRelatedProducts,
  getActiveColor,
  getActiveLegStyle,
  getActiveLegs,
  getProductDetails,
  getInferredAddOn,
  (
    productModifiers,
    addOns,
    queryParams,
    relatedProducts,
    activeColor,
    activeLegStyle,
    activeLegs,
    productDetails,
    inferredAddOn,
  ) => {
    let selectedAddonsList = [];
    if (!Array.isArray(productModifiers)) return selectedAddonsList;

    let isCoverAddOnSelected = false;
    let isOttomanAddOnSelected = false;
    addOns.forEach(({
      label,
      priceAdjust,
      nameAppend,
      quantity,
      modifier,
      slug,
      value,
      outOfStock,
    }) => {
      const { value: queryParamsValue, count } = getSelectedModifierValueAndCountFromQueryParams(queryParams, modifier);

      if (queryParamsValue.startsWith('OLRST') || queryParamsValue.startsWith('ODST')) {
        isOttomanAddOnSelected = true;
      } else if (queryParamsValue.startsWith('AOSTCV') || queryParamsValue.startsWith('OSTCV')) {
        isCoverAddOnSelected = true;
      }

      selectedAddonsList = [...selectedAddonsList, {
        label,
        priceAdjust,
        nameAppend,
        quantity,
        selectedCount: count,
        isOnlyOption: modifier.options.length === 1,
        outOfStock,
        product: getOptionRelatedProduct({
          slug,
          relatedProducts,
          value,
          queryParamsValue,
          activeColor,
          activeLegStyle,
          activeLegs,
        }),
      }];
    });
    if ((productDetails?.skuSplit?.type === 'OLRST' || productDetails?.skuSplit?.type === 'ODST') && isCoverAddOnSelected && isOttomanAddOnSelected) {
      selectedAddonsList.push(inferredAddOn);
    }
    if (productDetails?.skuSplit?.type === 'BRBD' && selectedAddonsList?.length > 1) {
      selectedAddonsList = [selectedAddonsList?.[0]];
    }
    return selectedAddonsList;
  },
);

export const getAddOnOptions = createSelector(
  getProductModifiers,
  getAddOnOptionsHandler,
);

export const getaddOnOptionsWithMatchingRelatedProducts = createSelector(
  getAddOnOptions,
  getRelatedProducts,
  getActiveColor,
  getActiveLegStyle,
  getActiveLegs,
  getActiveSize,
  (
    addOnOptions,
    relatedProducts,
    activeColor,
    activeLegStyle,
    activeLegs,
    activeSize,
  ) => addOnOptions.map(({ value, slug }) => ({
    value,
    slug,
    product: getOptionRelatedProduct({
      slug,
      relatedProducts,
      value,
      activeColor,
      activeLegs,
      activeLegStyle,
      activeSize,
    }),
  })),
);

export const getSelectedModifiersList = createSelector(
  getProductModifiers,
  getModifiersRaw,
  (productModifiers, modifiers) =>  {
    let selectedModifiersList = [];

    if (!(Array.isArray(productModifiers) && Array.isArray(modifiers))) return selectedModifiersList;

    productModifiers
      .filter(({ options, type }) => Array.isArray(options) && type === 'PickList')
      .forEach(({ options }) => {
        options
          .filter(({ selected }) => selected)
          .forEach(({ value }) => {
            const modifier = modifiers.find(({ name }) => name.toLowerCase().includes(value.toLowerCase()));
            selectedModifiersList = modifier
              ? [
                ...selectedModifiersList,
                getSelectedModifierFromModifier(modifier),
              ]
              : selectedModifiersList;
          });
      });
    return selectedModifiersList;
  },
);

export const getProductName = createSelector(
  getSelectedAddonsList,
  getProductNameRaw,
  (selectedAddonsList, name) => {
    if (selectedAddonsList) {
      return applyModifiersToProductName(name, selectedAddonsList);
    }

    return name;
  },
);

export const getSelectedModifiersPrice = createSelector(
  getAddonSavings,
  getProductModifiers,
  getProductDetails,
  getInferredAddOn,
  /**
   * @param {PricingDto['addOnSavings']} addOnSavings
   * @param {{options: ModifierOption[]}[]} productModifiers
   * @param {{ skuSplit: ProductDto['skuSplit']} } productDetails
   * @param {ProductDto['inferredAddOn']} inferredAddOn
   * @returns {{originalPrice: number, price: number}}
   */
  (addOnSavings, productModifiers, productDetails, inferredAddOn) => {
    const selectedModifiersPricing = {
      price: 0,
      originalPrice: 0,
    };
    if (addOnSavings || !Array.isArray(productModifiers)) {
      return selectedModifiersPricing;
    }

    let isCoverModifierSelected = false;
    let isOttomanModifierSelected = false;
    productModifiers
      .forEach(({ options }) => {
        options.forEach((option) => {
          const {
            selected, count, priceAdjust, value, addOnBundleDiscount = 0, pricing,
          } = option;
          if (selected) {
            const calculatedPrice = pricing?.price || (priceAdjust - addOnBundleDiscount);
            const originalPrice = pricing?.originalPrice || priceAdjust;

            selectedModifiersPricing.price += (calculatedPrice * (count || 1));
            selectedModifiersPricing.originalPrice += (originalPrice * (count || 1));

            if (value === 'OLRST-OT-RY-S0-A0') {
              isOttomanModifierSelected = true;
            } else if (value.startsWith('AOSTCV')) {
              isCoverModifierSelected = true;
            }
          }
        });
      });

    if (productDetails?.skuSplit?.type === 'OLRST' && isCoverModifierSelected && isOttomanModifierSelected) {
      selectedModifiersPricing.price += inferredAddOn?.product?.price?.raw || 0;
      selectedModifiersPricing.originalPrice += inferredAddOn?.product?.originalPrice?.raw || 0;
    }
    return selectedModifiersPricing;
  },
);

export const getProductCurrentPrice = createSelector(
  getProductPricing,
  getSelectedModifiersPrice,
  getHrefLang,
  (pricing, { price: addOnsPrice }, hrefLang) => {
    if (!(pricing && pricing.price)) {
      return null;
    }

    const price = pricing.price.raw;
    const currencyPrefix = getCurrencyPrefix({
      hrefLang,
      verbose: true,
    });

    return `${currencyPrefix}${(+price + addOnsPrice).toFixed(0)}`;
  },
);

export const getProductPriceWithSelectedModifiersInCents = createSelector(
  getProductPricing,
  getSelectedModifiersPrice,
  (pricing, { price: addOnsPrice }) => {
    if (!(pricing && pricing.price)) {
      return null;
    }

    const price = pricing.price.inCents;

    return Math.round(price + (addOnsPrice * 100));
  },
);

export const getProductSalePrice = createSelector(
  getProductPricing,
  getSelectedModifiersPrice,
  getHrefLang,
  (pricing, { price: addOnsSalePrice }, hrefLang) => {
    if (!(pricing && pricing.salePrice)) {
      return null;
    }

    const salePrice = pricing.salePrice.raw;
    const currencyPrefix = getCurrencyPrefix({ hrefLang });

    return `${currencyPrefix}${(+salePrice + addOnsSalePrice).toFixed(0)}`;
  },
);

export const getProductOriginalPrice = createSelector(
  getProductPricing,
  getSelectedModifiersPrice,
  getHrefLang,
  (pricing, { originalPrice: addOnsOriginalPrice }, hrefLang) => {
    if (!(pricing && pricing.originalPrice)) {
      return null;
    }

    const originalPrice = pricing.originalPrice.raw;
    const currencyPrefix = getCurrencyPrefix({
      hrefLang,
      verbose: true,
    });

    return `${currencyPrefix}${(+originalPrice + addOnsOriginalPrice).toFixed(0)}`;
  },
);

export const getIsProductSoldOut = (state) => getProductInventory(state) === 0 && !getProductSellWithoutInventory(state);

export const getIsProductInventoryChecked = (state) => typeof getProductInventory(state) === 'number';

export const getProductError = ({ product }) => product.error;
