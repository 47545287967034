import PropTypes from 'prop-types';
import Nullable from '../../../../../shared/nullable';
import AccountLink from './AccountLink';
import CartButton from '../Cart/CartButton';
import { SearchButton, usePopularLinks } from '../Search';

export type UserSectionProps = {
  cartNumber: number;
  isLoggedIn: boolean;
  closeMenu: (config?: { preventMobile?: Nullable<boolean> }) => void
  experiments?: { [key: string]: boolean }
  openCart: (booleanValue: boolean) => void
};

const UserSection = ({
  isLoggedIn,
  closeMenu,
  cartNumber,
  experiments,
  openCart,
}: UserSectionProps) => {
  usePopularLinks();

  return (
    <>
      <SearchButton closeMenu={closeMenu}/>
      <AccountLink
        isLoggedIn={isLoggedIn}
        onClick={closeMenu}
      />
      <CartButton
        cartNumber={cartNumber}
        experiments={experiments}
        openCart={openCart}
      />
    </>
  );
};

UserSection.defaultProps = {
  experiments: {},
  cartNumber: 0,
};

UserSection.propTypes = {
  cartNumber: PropTypes.number,
  closeMenu: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  experiments: PropTypes.shape({}),
  openCart: PropTypes.func.isRequired,
};

export default UserSection;
