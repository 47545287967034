import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Waypoint } from 'react-waypoint';
import { productCollectionLayoutPropTypes } from '../../common/productCollection.propTypes';
import { callToActionPropTypes } from '../../common/callToAction.propTypes';
import { setAvailableFilters } from '../../store/actions/collections.actions';
import {
  getActiveFilters,
  getActiveLayout,
  getActiveLayoutGroup,
  getGridContainerClassNames,
  getSelectedLayout,
} from '../../store/selectors/collections.selectors';
import { getBackgroundColor } from '../../store/selectors/page.selectors';
import { getUserDetails } from '../../store/selectors/user.selectors';

import ProductCollectionItem from './ProductCollectionItem';
import CallToAction from '../global/CallToAction';
import { productCollection as productCollectionGlobalAPI } from '../../mock/globalAPI';
import Analytics from '../global/Analytics';
import CollectionCarousel from './CollectionCarousel';
import { Nullable } from '../../types/common';
import { ProductCollectionItemDto, ProductCollectionDto, ProductCollectionLayoutDto } from '../../types/schema';
import { withCssModulesClassNames } from '../../common/nextMigrationHelpers';
import { default as styles } from '../../scss/pages/_plp.module.scss';

const withCssModulesClassNamesHandler = withCssModulesClassNames(styles);

interface ProductCollectionProps {
  source?: string;
  activeLayout?: 'Carousel' | 'SingleGrid' | 'DoubleGrid' | 'TripleGrid';
  collection: ProductCollectionDto & {
    gridContainerClassNames?: string[];
    activeCollectionLayout?: Nullable<ProductCollectionLayoutDto>;
  };
}

const ProductCollection = ({
  source,
  collection,
  activeLayout,
}: ProductCollectionProps) => {
  const activeFilters: string[] = useSelector(getActiveFilters);
  const activeLayoutFromRedux = useSelector(getActiveLayout);
  const activeLayoutGroup = useSelector(getActiveLayoutGroup);
  const userDetails = useSelector(getUserDetails);
  const backgroundColor = useSelector(getBackgroundColor);
  const selectedLayout = useSelector(getSelectedLayout);

  const dispatch = useDispatch();

  const viewedEvents: { [key: string]: boolean } = {};
  const [showMoreClicked, setShowMoreClicked] = useState(false);
  const getColumnsCount = () => {
    switch (selectedLayout || activeLayout || activeLayoutFromRedux) {
      case 'SingleGrid': return 1;
      case 'DoubleGrid': return 2;
      case 'TripleGrid': return 3;
      default: return 0;
    }
  };

  const filterCollectionByCategory = (
    // eslint-disable-next-line @typescript-eslint/default-param-last
    productCollectionItems: ProductCollectionItemDto['productCollectionItems'] = [],
    activeFiltersProp: typeof activeFilters,
  ) => {
    if (!activeFiltersProp.length) {
      return productCollectionItems;
    }
    const availableFilters: { [key: string]: boolean } = {};

    const products = productCollectionItems.filter(({ filterCategories = [] }) => {
      if (activeFiltersProp.every((activeFilter) => filterCategories.includes(activeFilter))) {
        filterCategories.forEach((category) => {
          availableFilters[category] = true;
        });
        return true;
      }
      return false;
    });

    if (Object.keys(availableFilters).length) dispatch(setAvailableFilters(availableFilters));
    return products;
  };

  const productCollectionEvent = (eventName: string, eventSource: string) => {
    if (eventSource === 'Clicked') {
      setShowMoreClicked(true);
    }
    if (eventSource === 'Viewed' && viewedEvents[eventName]) {
      return;
    }
    try {
      const {
        subHeader,
        activeCollectionLayout,
        productCollectionItems = [],
      } = collection;
      const { email = '' } = userDetails;

      const products = productCollectionItems?.filter(({ type }) => type === 'ProductCard');
      const showMoreLimit = activeCollectionLayout && activeCollectionLayout.showMoreLimit;

      Analytics.enqueue({
        method: 'collection',
        params: {
          event: eventName,
          action: source,
          email,
          category: subHeader,
          listId: window && window.location.pathname.replace('/', ''),
          showMoreLimit,
          totalProducts: products && products.length,
          activeLayoutGroup,
          activeFilters,
        },
        location: Analytics.getLocation(),
      });
      if (source === 'Viewed') {
        viewedEvents[eventName] = true;
      }
    } catch (e) { /* nothing to do */ }
  };

  const renderCollectionGrid = (filteredCollectionItems: ProductCollectionItemDto['productCollectionItems'] = []) => {
    const {
      header,
      activeCollectionLayout,
      subHeader,
      gridContainerClassNames = [],
      collectionLayouts,
    } = collection;

    const gridContainerClassNamesOverride = gridContainerClassNames.length === 0
      ? getGridContainerClassNames({ collectionLayouts, selectedLayout })
      : [];

    const products = filteredCollectionItems.filter(({ type }) => type === 'ProductCard');
    const showMoreLimit = activeCollectionLayout && activeCollectionLayout.showMoreLimit;
    const columnsCount = getColumnsCount();

    const showMoreVisible = (
      showMoreLimit
      && showMoreLimit + columnsCount < products.length
      && !showMoreClicked
    );

    const items = showMoreVisible
      ? products.slice(0, showMoreLimit)
      : filteredCollectionItems;

    const itemsBelowShowMore = showMoreVisible
      ? products.slice(showMoreLimit, showMoreLimit + columnsCount)
      : null;
    return items.length && (
      <div className={withCssModulesClassNamesHandler('collection-grid-wrapper')}>
        <div className={ withCssModulesClassNamesHandler(
          'collection-grid-container',
          ...gridContainerClassNames,
          ...gridContainerClassNamesOverride,
        )}
        data-testid="collection-grid-container"
        >
          {items.map((collectionItem) => (
            <ProductCollectionItem
              key={collectionItem.id}
              source={source}
              collectionHeader={header}
              collectionItem={collectionItem}
            />
          ))}
        </div>
        {showMoreVisible && (
          <div className={ withCssModulesClassNamesHandler(
            'collection-grid-container',
            ...gridContainerClassNames,
            ...gridContainerClassNamesOverride,
          )}
          data-testid="collection-grid-container"
          >
            {itemsBelowShowMore?.map((collectionItem) => (
              <ProductCollectionItem
                hideDetails
                key={`${collectionItem.id}-${collectionItem?.item?.sku}`}
                collectionItem={collectionItem}
                source={source}
                tabIndex={-1}
                collectionHeader={header}
              />
            ))}
            <Waypoint
              onEnter={() => productCollectionEvent('Product Collection Show More', 'Viewed')}
            >
              <div
                className={withCssModulesClassNamesHandler('collection-show-more')}
                data-testid="collection-show-more"
                style={{
                  background: `linear-gradient(
                    to bottom,
                    rgba(255, 255, 255, 0.5),
                    ${backgroundColor.hex} 100%,
                    ${backgroundColor.hex} 100%
                  )`,
                }}
              >
                <button
                  className={withCssModulesClassNamesHandler('collection-show-more__button')}
                  onClick={() => productCollectionEvent('Product Collection Show More', 'Clicked')}
                  data-testid="collection-show-more__button"
                >
                  <div className={withCssModulesClassNamesHandler('collection-show-more__title')}>
                    {productCollectionGlobalAPI.show_more}
                  </div>
                  <div
                    className={withCssModulesClassNamesHandler('collection-show-more__subtitle')}
                  >
                    {subHeader}
                  </div>
                </button>
              </div>
            </Waypoint>
          </div>
        )}
      </div>
    );
  };

  const {
    productCollectionItems,
    id,
    subHeader,
    callToAction,
  } = collection;
  const filteredCollection = filterCollectionByCategory(productCollectionItems || [], activeFilters);

  return Array.isArray(filteredCollection) && filteredCollection.length ? (
    <div className={withCssModulesClassNamesHandler('collection')}
      key={id}
      data-testid="collection"
    >
      <Waypoint
        key={id}
        onEnter={() => productCollectionEvent('Product List Viewed', 'Viewed')}
      >
        <div>
          {subHeader
            && (
              <div className={withCssModulesClassNamesHandler('collection-title')}>
                <h3>{subHeader}</h3>
                {callToAction && <CallToAction callToAction={callToAction}/>}
              </div>
            )
          }
        </div>
      </Waypoint>
      {filteredCollection && (
        activeLayoutGroup === 'GRID'
          ? renderCollectionGrid(filteredCollection)
          : <CollectionCarousel collection={filteredCollection} />
      )}
    </div>
  ) : null;
};

ProductCollection.defaultProps = {
  source: undefined,
  activeLayout: undefined,
};

ProductCollection.propTypes = {
  source: PropTypes.string,
  activeLayout: PropTypes.oneOf(
    ['Carousel', 'SingleGrid', 'DoubleGrid', 'TripleGrid'],
  ),
  collection: PropTypes.shape({
    subHeader: PropTypes.string,
    id: PropTypes.string.isRequired,
    slug: PropTypes.string,
    callToAction: PropTypes.shape(callToActionPropTypes),
    gridContainerClassNames: PropTypes.arrayOf(PropTypes.string),
    activeCollectionLayout: PropTypes.shape(productCollectionLayoutPropTypes),
    collectionLayouts: PropTypes.arrayOf(
      PropTypes.shape(productCollectionLayoutPropTypes),
    ).isRequired,
    productCollectionItems: PropTypes.arrayOf(PropTypes.shape({
      tabIndex: PropTypes.number,
      skuModifier: PropTypes.string,
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      filterCategories: PropTypes.arrayOf(PropTypes.string).isRequired,
      item: PropTypes.shape({
        sku: PropTypes.string,
      }),
    })).isRequired,
  }).isRequired,
};

export default ProductCollection;
