import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import Analytics from './Analytics';
import { convertDateToTimezone } from '../../../../utils/Utils';
import { default as styles } from '../../scss/components/SMS.module.scss';
import { CXHours, UserDetails } from './footer/footer.types';
import HrefLang from '../../../../shared/localization/hrefLang';
import { withCssModulesClassNames } from '../../common/nextMigrationHelpers';

const withCssModulesClassNamesHandler = withCssModulesClassNames(styles);

interface SMSProps {
  cxHours: Omit<CXHours, '__typename' | 'id'>;
  hrefLang?: `${HrefLang}`;
  icon?: boolean;
  copy?: string;
  className?: string;
  userDetails?: UserDetails;
  isFavoritesBucketVisible?: boolean;
}

export const isTodayAHoliday = (date: Date, holidaysArray: CXHours['holidays'] = []) => holidaysArray.find((i) => {
  const holiday = new Date(i);

  return !!(
    holiday.getUTCFullYear() === date.getUTCFullYear()
      && holiday.getUTCMonth() === date.getUTCMonth()
      && holiday.getUTCDate() === date.getUTCDate()
  );
});

export const isCXOpenNow = (date: Date, cxDailyHoursArray: CXHours['cxDailyHours'] = []) => {
  // convert the users time to eastern and compare against CX hours (in EST)
  const easternTime = convertDateToTimezone(date, 'America/New_York');
  const currentHour = easternTime.getHours();
  const currentWeekDay = cxDailyHoursArray.find((daily) => daily.weekday === easternTime.getDay());

  return !!(
    currentWeekDay
    && currentWeekDay.openHour <= currentHour
    && currentWeekDay.closeHour > currentHour
  );
};

const SMS = (props: SMSProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    cxHours: {
      cxDailyHours,
      holidays,
      isClosed,
      cxSmsNumber,
    },
    hrefLang,
    icon,
    copy,
    className,
    userDetails,
    isFavoritesBucketVisible,
  } = props;

  useEffect(() => {
    const currentDate = new Date();
    const isHoliday = isTodayAHoliday(currentDate, holidays);
    const isWithinCXHours = isCXOpenNow(currentDate, cxDailyHours);

    setIsOpen(!isClosed && !isHoliday && isWithinCXHours);
  }, [cxDailyHours, holidays, isClosed]);

  const triggerAnalytics = () => {
    try {
      Analytics.enqueue({
        method: 'track',
        params: {
          event: 'SMS conversation',
          properties: {
            ...userDetails,
            hrefLang,
          },
        },
      });
    } catch (e) {
      /* nothing to do */
    }
  };

  const smsClassName = `sms${icon ? '-icon' : ''}`;

  return isOpen ? (
    <a className={withCssModulesClassNamesHandler(smsClassName, className, {
      'favorites-bucket-sms': isFavoritesBucketVisible,
    })}
      onClick={triggerAnalytics}
      href={`sms:${cxSmsNumber};?&body=Hi Burrow!`}
    >
      {icon ? <i className={withCssModulesClassNamesHandler('icon', 'icon-sms')} /> : copy}
    </a>
  ) : null;
};

export default SMS;

SMS.defaultProps = {
  copy: 'Text us',
  cxHours: {
    cxDailyHours: [],
    cxSmsNumber: '2246287769',
    holidays: [],
    isClosed: false,
  },
  icon: true,
  className: '',
  userDetails: {},
  hrefLang: 'en-us',
};

SMS.propTypes = {
  icon: PropTypes.bool,
  copy: PropTypes.string,
  cxHours: PropTypes.shape({
    cxDailyHours: PropTypes.array.isRequired,
    holidays: PropTypes.array.isRequired,
    isClosed: PropTypes.bool.isRequired,
    cxSmsNumber: PropTypes.string.isRequired,
  }).isRequired,
  hrefLang: PropTypes.string,
  className: PropTypes.string,
  userDetails: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    burrowHouseAssociate: PropTypes.bool,
    tradePartner: PropTypes.bool,
  }),
};
