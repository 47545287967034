const prefix = '[COLLECTIONS]';

export const INITIALIZE_COLLECTIONS_DATA = `${prefix} INITIALIZE_COLLECTIONS_DATA`;
export const FETCH_COLLECTIONS = `${prefix} FETCH_COLLECTIONS`;
export const FETCH_COLLECTIONS_REQUEST = `${prefix} FETCH_COLLECTIONS_REQUEST`;
export const FETCH_COLLECTIONS_SUCCESS = `${prefix} FETCH_COLLECTIONS_SUCCESS`;
export const FETCH_COLLECTIONS_FAILURE = `${prefix} FETCH_COLLECTIONS_FAILURE`;
export const SET_SELECTED_LAYOUT_GROUP = `${prefix} SET_SELECTED_LAYOUT_GROUP`;
export const SET_ACTIVE_FILTER = `${prefix} SET_ACTIVE_FILTER`;
export const SET_SELECTED_LAYOUT = `${prefix} SET_SELECTED_LAYOUT`;
export const SET_AVAILABLE_FILTERS = `${prefix} SET_AVAILABLE_FILTERS`;
export const AUTO_SELECT_MOBILE_LAYOUT = `${prefix} AUTO_SELECT_MOBILE_LAYOUT`;

export const initializeCollectionsData = (collections) => ({
  type: INITIALIZE_COLLECTIONS_DATA,
  payload: collections,
});

export const setSelectedLayoutGroup = (payload) => ({
  type: SET_SELECTED_LAYOUT_GROUP,
  payload,
});

export const setActiveFilter = (payload) => ({
  type: SET_ACTIVE_FILTER,
  payload,
});

export const setAvailableFilters = (payload) => ({
  type: SET_AVAILABLE_FILTERS,
  payload,
});

export const setSelectedLayout = (payload) => ({
  type: SET_SELECTED_LAYOUT,
  payload,
});

export const autoSelectMobileLayout = () => ({
  type: AUTO_SELECT_MOBILE_LAYOUT,
});
