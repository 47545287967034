import { withCssModulesClassNames } from '../../../common/nextMigrationHelpers';
import { default as styles } from '../../../scss/components/Header.module.scss';
import { CloseMenuParams, NavigationItem } from './header.types';
import Menu from './Menu';

const withCssModulesClassNamesHandler = withCssModulesClassNames(styles);

export type MobileMenuProps = {
  visibleGalleryID?: string,
  isLoggedIn: boolean,
  isMobileMenuOpen: boolean,
  navigation: NavigationItem[],
  openCart: (booleanValue: boolean) => void,
  closeMenu: (config?: CloseMenuParams) => void,
  dropdownStates: { [key: string]: boolean },
  toggleCurrentDropdown: (id: string, header: string, neighbors: string[]) => void,
  experiments?: Record<string, boolean>,
};

const MobileMenu = ({
  closeMenu,
  isLoggedIn,
  isMobileMenuOpen,
  navigation,
  openCart,
  experiments,
  dropdownStates,
  toggleCurrentDropdown,
  visibleGalleryID = '',
}: MobileMenuProps) => (
    <div className={withCssModulesClassNamesHandler(
      'header-menu-mobile',
      isMobileMenuOpen ? 'is-open' : 'is-closed',
    )}
    >
      <div className={withCssModulesClassNamesHandler('header-menu-mobile-scroll')}>
        <Menu
          isLoggedIn={isLoggedIn}
          openCart={openCart}
          navigation={navigation}
          experiments={experiments}
          closeMenu={closeMenu}
          dropdownStates={dropdownStates}
          toggleCurrentDropdown={toggleCurrentDropdown}
          isMobile
          visibleGalleryID={visibleGalleryID}
          data-cy="mobileNav"
        />
      </div>
    </div>
);

export default MobileMenu;
