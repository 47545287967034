import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { getRecommendedCartItems } from '../../../store/actions/cart.actions';
import CartRecommendedItems from './CartRecommendedItems';
import { getCartDetailsFromState } from './CartHelpers';

import { withCssModulesClassNames } from '../../../common/nextMigrationHelpers';
import { default as styles } from '../../../scss/components/RecommendedProducts.module.scss';

const withCssModulesClassNamesHandler = withCssModulesClassNames(styles);

const RecommendedProducts = () => {
  const cartRecommendedItems = useSelector((state) => getCartDetailsFromState(state));
  const { cartDetails, recommendedItems } = cartRecommendedItems;
  const dispatch = useDispatch();

  useEffect(() => {
    if (cartDetails && typeof cartDetails === 'object' && Object.keys(cartDetails).length > 0) {
      dispatch(getRecommendedCartItems());
    }
  }, [cartDetails, dispatch]);

  return recommendedItems.length > 0 ? (
    <div data-testid="cart_recommended-wrapper" className={withCssModulesClassNamesHandler('cart_recommended-wrapper')}>
      <div className={withCssModulesClassNamesHandler('cart__recommended-header')}>Recommended based on your cart</div>
      {recommendedItems && (
        <CartRecommendedItems
          recommendedItems={recommendedItems}
        />
      )}
    </div>
  ) : null;
};

RecommendedProducts.propTypes = {
  recommendedItems: PropTypes.arrayOf(PropTypes.shape({
    __typename: PropTypes.string,
    image: PropTypes.string,
    name: PropTypes.string,
    path: PropTypes.string,
    pricing: PropTypes.number,
    sku: PropTypes.string,
    variantSku: PropTypes.string,
  })),
};

RecommendedProducts.defaultProps = {
  recommendedItems: [],
};

export default RecommendedProducts;
