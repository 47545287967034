enum ComponentType {
  Accordion = 'Accordion',
  AnchorNav = 'AnchorNav',
  Card = 'Card',
  CollectionHero = 'CollectionHero',
  ContentBreak = 'ContentBreak',
  Countdown = 'Countdown',
  DesignFeatures = 'DesignFeatures',
  Details = 'Details',
  Dimensions = 'Dimensions',
  DownloadDropdown = 'DownloadDropdown',
  Newsletter = 'Newsletter',
  NewsletterEmail = 'NewsletterEmail',
  FeaturedProducts = 'FeaturedProducts',
  FilterNav = 'FilterNav',
  Footer = 'Footer',
  FullWidthVideo = 'FullWidthVideo',
  HeroGeneral = 'HeroGeneral',
  Images = 'Images',
  InfoCard = 'InfoCard',
  InfoHighlights = 'InfoHighlights',
  KeyFeatures = 'KeyFeatures',
  Margin = 'Margin',
  MaxWidthCard = 'MaxWidthCard',
  NarrowImages = 'NarrowImages',
  NarrowStoryCard = 'NarrowStoryCard',
  OurApproach = 'OurApproach',
  PlainText = 'PlainText',
  PLPHero = 'PLPHero',
  PLPNav = 'PLPNav',
  PLPToolbar = 'PLPToolbar',
  ProductAttributeSet = 'ProductAttributeSet',
  ProductCarousel = 'ProductCarousel',
  ProductCategoryCard = 'ProductCategoryCard',
  ProductCollection = 'ProductCollection',
  ProductCollections = 'ProductCollections',
  RecruiteeJobs = 'RecruiteeJobs',
  RestockNotifications = 'RestockNotifications',
  RestockNotificationsButton = 'RestockNotificationsButton',
  Reviews = 'Reviews',
  RichText = 'RichText',
  SalesTiers = 'SalesTiers',
  SelectedReviews = 'SelectedReviews',
  ShopBySpaces = 'ShopBySpaces',
  ShopByTheme = 'ShopByTheme',
  SiteMap = 'SiteMap',
  SpotlightCard = 'SpotlightCard',
  StoryCard = 'StoryCard',
  SwatchForm = 'SwatchForm',
  SwatchHero = 'SwatchHero',
  Testimonials = 'Testimonials',
  ThreePointBox = 'ThreePointBox',
  TypeformWidget = 'TypeformWidget',
  Values = 'Values',
  Welcome = 'Welcome',
  WideStoryCard = 'WideStoryCard',
  YoutubeVideo = 'YoutubeVideo',
  SwatchesDropdown = 'SwatchesDropdown',
  CompareCollection = 'CompareCollection',
}

export default ComponentType;
