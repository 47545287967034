import {
  CSSProperties,
  useEffect,
  useMemo,
  useRef,
  useState,
  VideoHTMLAttributes,
} from 'react';
import { Asset as AssetType } from './header/header.types';
import { calculateAssetPaddingBottom } from '../../../../utils/Utils';
import { withCssModulesClassNames } from '../../common/nextMigrationHelpers';
import {
  addListeners,
  MetadataState,
  onPlay,
  onViewportObserver,
  slideChangeObserver,
  toggleControls,
  togglePlayHandler,
} from './VideoTag.helpers';

// eslint-disable-next-line import/no-named-default -- non-issue
import { default as styles } from '../../scss/components/VideoTag.module.scss';
import VideoTagControls from './VideoTag.Controls';

const cssModulesClassNames = withCssModulesClassNames(styles);

interface Source {
  source: string;
  type: string;
  order: number;
}

interface DeprecatedVideoProps {
  onMediaPlay?: () => void;
  onMediaClick?: () => void;
  sources?: Source[];
  responsive?: Source;
  src?: string;
}

interface VideoTagProps extends VideoHTMLAttributes<HTMLVideoElement> {
  asset?: AssetType;
  blurryPlaceholder?: string;
  autoPaddingBottom?: boolean;
  onSetMetadata?: (metadata: MetadataState) => void;
  containerClassName?: string;
  deprecatedProps?: DeprecatedVideoProps;
}

function VideoTag({
  asset,
  blurryPlaceholder,
  autoPaddingBottom,
  autoPlay = true,
  onSetMetadata,
  className,
  containerClassName,
  deprecatedProps: {
    onMediaPlay,
    onMediaClick,
    sources = [],
    responsive,
    src,
  } = {},
  ...videoAttributes
}: VideoTagProps & DeprecatedVideoProps) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showControls, setShowControls] = useState(true);
  const [metadata, setMetadata] = useState<MetadataState>({
    videoWidth: 0,
    videoHeight: 0,
    duration: 0,
  });

  const videoContainerStyle = useMemo(() => {
    const style: CSSProperties = {};
    if (blurryPlaceholder) {
      style.backgroundImage = `url(${blurryPlaceholder})`;
    }
    if (autoPaddingBottom) {
      style.paddingBottom = calculateAssetPaddingBottom(metadata.videoWidth, metadata.videoHeight);
    }

    return style;
  }, [blurryPlaceholder, autoPaddingBottom, metadata]);

  const togglePlay = useMemo(() => (
    togglePlayHandler(videoRef, isPlaying, setIsPlaying)
  ), [isPlaying]);

  useEffect(() => {
    toggleControls({ metadata, setShowControls });
  }, [metadata]);

  useEffect(() => {
    const video = videoRef.current;

    const observers = [
      slideChangeObserver({ video, setIsPlaying }),
      onViewportObserver({ video, setIsPlaying, autoPlay }),
    ];

    const eventListeners = addListeners(video, onMediaPlay, onMediaClick);

    return () => {
      observers.forEach((o) => {
        o?.disconnect();
      });

      eventListeners.forEach(([eventName, cbFunction]) => {
        video?.removeEventListener(eventName, cbFunction);
      });
    };
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- should only run once */
  }, []);

  return asset ? (
    <div
      data-testid={'video-component'}
      className={cssModulesClassNames(
        'video-tag-container',
        containerClassName,
      )}
      style={videoContainerStyle}
      id={`video-tag-${asset.handle}`}
      onClick={togglePlay}
    >
      <video
        data-testid={'video-tag'}
        className={className}
        ref={videoRef}
        loop
        playsInline
        muted
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...videoAttributes}
        onPlay={onPlay(setMetadata, onSetMetadata)}
        tabIndex={0}
      >
        {sources.length ? (
          sources.map(({ source, type, order }) => (
            <source
              key={`source-${order}-${asset.handle}`}
              src={source}
              type={type}
            />
          ))
        ) : (
          <source
            src={responsive ? responsive.source : src || asset.url}
            type={asset?.mimeType}
          />
        )}
      </video>
      {showControls && (
        <VideoTagControls
          asset={asset}
          isPlaying={isPlaying}
          togglePlay={togglePlay}
        />
      )}
    </div>
  ) : null;
}

export default VideoTag;
