import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ColorPicker from '../../shopping/ColorPicker';

class ColorPickerRadio extends Component {
  render() {
    const {
      name, label, onChange, id, value, checked, color,
    } = this.props;

    return (
      <div className="color-option">
        <label className="color-picker-radio" htmlFor={name}>
          <span>{label}</span>
          <input
            checked={checked}
            type="radio"
            value={value}
            onChange={onChange}
            name={name}
            id={id}
          />
          <ColorPicker
            active={checked}
            description={label}
            value={color}
            onClick={() => null}
            hasBorder
          />
        </label>
      </div>
    );
  }
}

ColorPickerRadio.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  color: PropTypes.string,
};

export default ColorPickerRadio;
