import { Nullable } from '../../common';
import {
  Align,
  ColorType,
} from '../enums';
import {
  AssetDto,
  CallToActionDto,
  ColorDto,
  Component,
  ModalDto,
  ProductCollectionItemDto,
  DestinationPageDto,
  ModifierOptionDto,
  RichTextDto,
} from './index';

export enum LinkType {
  Page = 'Page',
  Product = 'Product',
  SMS = 'SMS',
  External = 'External',
  UI = 'UI',
  Mailto = 'Mailto',
  Phone = 'Phone',
  Affirm = 'Affirm',
  YouTube = 'YouTube',
  Shipping = 'Shipping',
}

export interface LinkDto {
  id: Nullable<string>;
  sku: Nullable<string>;
  localeAvailabilityExclusive: Nullable<string>;
  type: Nullable<`${LinkType}`>;
  anchor: Nullable<string>;
  asset: Nullable<AssetDto>;
  buttonColor: Nullable<ColorDto>;
  callToAction: Nullable<CallToActionDto>;
  className: Nullable<string>;
  color: Nullable<ColorDto>;
  components?: Nullable<Component[]>;
  destinationPage: Nullable<DestinationPageDto>;
  horizontalAlign: Nullable<Align>;
  href: Nullable<string>;
  modals: Nullable<ModalDto[]>;
  modifierOptions?: Nullable<ModifierOptionDto[]>;
  order: Nullable<number>;
  productCollectionItems: Nullable<Partial<ProductCollectionItemDto>[]>;
  queryParams?: Nullable<string>;
  richText: Nullable<RichTextDto>;
  text: Nullable<string>;
  textColor: Nullable<ColorType> /* deprecate */;
}
