enum ColumnWidth {
  desktop_1 = 'desktop_1',
  desktop_10 = 'desktop_10',
  desktop_11 = 'desktop_11',
  desktop_12 = 'desktop_12',
  desktop_2 = 'desktop_2',
  desktop_3 = 'desktop_3',
  desktop_4 = 'desktop_4',
  desktop_5 = 'desktop_5',
  desktop_6 = 'desktop_6',
  desktop_7 = 'desktop_7',
  desktop_8 = 'desktop_8',
  desktop_9 = 'desktop_9',
  mobile_1 = 'mobile_1',
  mobile_10 = 'mobile_10',
  mobile_11 = 'mobile_11',
  mobile_12 = 'mobile_12',
  mobile_2 = 'mobile_2',
  mobile_3 = 'mobile_3',
  mobile_4 = 'mobile_4',
  mobile_5 = 'mobile_5',
  mobile_6 = 'mobile_6',
  mobile_7 = 'mobile_7',
  mobile_8 = 'mobile_8',
  mobile_9 = 'mobile_9',
  tablet_1 = 'tablet_1',
  tablet_10 = 'tablet_10',
  tablet_11 = 'tablet_11',
  tablet_12 = 'tablet_12',
  tablet_2 = 'tablet_2',
  tablet_3 = 'tablet_3',
  tablet_4 = 'tablet_4',
  tablet_5 = 'tablet_5',
  tablet_6 = 'tablet_6',
  tablet_7 = 'tablet_7',
  tablet_8 = 'tablet_8',
  tablet_9 = 'tablet_9',
}

export default ColumnWidth;
