import { captureException, withScope } from '@sentry/browser';
import kebabCase from 'lodash/kebabCase';
import groupBy from 'lodash/groupBy';
import flatten from 'lodash/flatten';
import { NextRouter } from 'next/router';
import { Location } from 'history';
import { SkuParseDto } from '../shared/graphCms/skuParse.dto';
import VariantModifierDto from '../Product/dto/variantModifier.dto';
import SkuSplit from '../shared/skuSplit';
import ModifierDto, { ModifierOptionDto } from '../Product/dto/modifier.dto';
import AssetDto from '../Product/dto/asset.dto';
import OrderAnalyticsProduct from '../Order/orderAnalyticsProduct';
import getCurrencyPrefix from '../template/src/common/getCurrencyPrefix';
import generateUrlWithHrefLang from '../template/src/common/generateUrlWithHrefLang';
import { getMaterialFromSku } from '../template/src/common/handleSetMaterialColor';
import HrefLang from '../shared/localization/hrefLang';
import GalleryItemDto from '../shared/graphCms/galleryItem.dto';
import PriceDto from '../Order/dto/price.dto';
import { Color, Nullable } from '../template/src/types/common';
import PricingObjectDto from '../Product/dto/pricingObject.dto';
import BigCommerceProductDto from '../shared/bigCommerce/bigCommerceProduct.dto';
import { countries } from '../template/src/mock/Address.constants';
import {
  Countries, ColorDto, Visibility, Breakpoints,
} from '../template/src/types/schema';
import Variant from '../Product/Variant';
import { PricingEndpointProductPayload } from '../Product/dto/airflowProduct.dto';
import { Asset } from '../template/src/components/global/header/header.types';
import { includesSleepkitSku, sleepkitSkusByUrl } from './sleepkit.utils';

const PRODUCT_SET_SKU_REGEX = /(O(L|D)R?SET)/;
export const PATH_LOCALE_GROUP_REGEX = /^\/*(?<locale>[a-zA-Z]{2}-[a-zA-Z]{2})*(?<pathWithoutLocale>\/.*)/;
export const nomadRegExp = /^(N|N3)?(V|L)?(SF|SC|SO|SL|SLO|SLC|SLCO|SU|SCC|SCO|OT|CS|CR|ST|LG|AR)$/;

export function formatStringToDollar({
  value,
  minimumFractionDigits = 2,
  haveComma = false,
  hrefLang,
  verbose,
}: {
  value: number | string,
  minimumFractionDigits?: number,
  hrefLang: HrefLang,
  haveComma?: boolean,
  verbose?: boolean,
}) {
  const parseValue = parseFloat(value?.toString());
  const priceValue = parseValue.toLocaleString('en-US', { minimumFractionDigits });

  const price = getCurrencyPrefix({
    hrefLang,
    verbose,
  }) + priceValue;

  if (haveComma) {
    return price;
  }

  return price.replace(',', '');
}

export function formatDiscountStringToDollar({
  value,
  hrefLang,
}: {
  value: number,
  hrefLang: HrefLang
}): string {
  return `-${formatStringToDollar({
    value, minimumFractionDigits: 2, hrefLang,
  })}`;
}

export function shouldHideInLocale(
  hrefLang: HrefLang,
  localesToHideIn: HrefLang[],
) {
  return localesToHideIn.includes(hrefLang);
}

export function formatCurrencyByCountry(country: Countries): string {
  const [matchingCountry] = countries?.filter(({ fullName }) => fullName === country) || [];

  return matchingCountry ? getCurrencyPrefix({ hrefLang: matchingCountry?.hrefLang as HrefLang, verbose: true }) : '';
}

export function getPriceRange(variants: BigCommerceProductDto[], type: keyof BigCommerceProductDto, hrefLang: HrefLang): Nullable<string> {
  if (hrefLang !== HrefLang.EN_US) {
    // since requests to BC like getProductByID() return US priced variants only,
    // we return null here to prevent range price on non-US sites
    return null;
  }
  const sortedVariants = variants.sort((a, b) => +a[type] - +b[type]);

  const currencyPrefix = getCurrencyPrefix({ hrefLang });
  const lowest: unknown = sortedVariants[0][type];
  const highest: unknown = sortedVariants[sortedVariants.length - 1][type];

  if (typeof lowest === 'number' && typeof highest === 'number' && lowest !== highest) {
    return `${currencyPrefix}${lowest} - ${currencyPrefix}${highest}`;
  }

  return null;
}

function isAnyParameterSleepKit(queryParams: Record<string, any>) {
  return Object.entries(queryParams).some(
    ([slug, value]) => includesSleepkitSku(value) && slug !== 'sku',
  );
}

export function getAddOnsParentSkusFromQueryParams(queryParams: Record<string, any> = {}, path: string) {
  const addOnItems = [];

  // @ts-ignore
  if (isAnyParameterSleepKit(queryParams) && sleepkitSkusByUrl[path]) {
    // @ts-ignore
    addOnItems.push(sleepkitSkusByUrl[path]);
  }
  if (queryParams['ottoman-add-on']) {
    addOnItems.push(queryParams['ottoman-add-on']);
  }
  if (queryParams.pickAPillow) {
    addOnItems.push(queryParams.pickAPillow);
  }
  if (queryParams['table-add-on']) {
    addOnItems.push(queryParams['table-add-on'].slice(0, -5));
  }
  if (queryParams['round-tray']) {
    addOnItems.push(queryParams['round-tray'].slice(0, -3));
  }
  return addOnItems;
}

export function imageFilter(fileName: string, skuSplit: SkuSplit) {
  const {
    color, arms, legs, legStyle, size, type, tableFinish,
  } = skuSplit;

  const colorRegEx = /^((?!-BR|-IV|-NB|-CH|-CG|-SL|-CN|-WN|-OK|-TN|-WT|-WNBZ|-OKBM|-FT|-JD|-DK|-MN|-BH|-HC|-SG|-MG|-OA|-FG|-CB).)*$/;
  const armsRegEx = /^((?!-LO|-HI|-MD).)*$/;
  const sizeRegEx = /^((?!-2|-3|-4|-5|-6|-7|-8|-1|-FL|-QN|-KG).)*$/;
  const legStyleRegEx = /^((?!-SG|-HP).)*$/;
  const typeRegEx = /^((?!NSF-|NSO-|NSC-|NLSF-|NLSO-|NLSC-|NSCC-|NLSCC-|NLSCO-|NSCO-|NLSL-|NLSLO-|NLSLC-|NLSLCO-|NSL-|NSLO-|NSLC-|NSLCO-|NLSU-|NSU-).)*$/;
  const legsRegEx = /^((?!-DW|-LW|-BW|-BM|-CM|-BZ).)*$/;
  const tableFinishRegex = /^((?!-WN|-OK).)*$/;
  const colorMatch = color ? (colorRegEx.test(fileName) || fileName.includes(`-${color}`)) : true;
  const armsMatch = arms ? armsRegEx.test(fileName) || fileName.includes(`-${arms}`) : true;
  const legsMatch = legs ? legsRegEx.test(fileName) || fileName.includes(`-${legs}`) : true;
  const sizeMatch = size ? sizeRegEx.test(fileName) || fileName.includes(`-${size}`) : true;
  const typeMatch = type ? typeRegEx.test(fileName) || fileName.includes(`${type}-`) : true;
  const legStyleMatch = legStyle ? legStyleRegEx.test(fileName) || fileName.includes(`-${legStyle}`) : true;
  const tableFinishMatch = tableFinish ? tableFinishRegex.test(fileName) || fileName.includes(`-T${tableFinish}`) : true;
  return colorMatch && armsMatch && legsMatch && legStyleMatch && sizeMatch && typeMatch && tableFinishMatch;
}

export function findNameModifierOption(skuSplit = {}, modifiers: ModifierDto[] = []) {
  const modifiersToUpdateName: ModifierOptionDto[] = [];
  const activeModifiers = Object.values(skuSplit);
  Object.entries(modifiers).forEach(([, modifier]) => {
    const modifiersWithNameAdjusters = modifier?.options?.filter((i) => {
      const flattened = flatten(activeModifiers);
      return (i.namePrepend || i.nameAppend || i.nameChange)
        && flattened.includes(i.value)
        // Tables and Ottoman addOn names are appended on the FE
        && i.label !== 'table-add-on'
        && i.label !== 'headboard-add-on'
        && i.label !== 'headboard-3-add-on'
        && i.label !== 'ottoman-add-on';
    });
    if (modifiersWithNameAdjusters?.length) {
      modifiersToUpdateName.push(...modifiersWithNameAdjusters);
    }
  });
  return modifiersToUpdateName;
}

export function generatePriceObject({
  price = 0,
  useFormatString = false,
  fractionDigits = 0,
  hrefLang,
  verbose,
}: {
  hrefLang: HrefLang
  price?: number,
  useFormatString?: boolean,
  fractionDigits?: number,
  verbose?: boolean,
}): PriceDto {
  const num = +price;
  const currencyPrefix = getCurrencyPrefix({ hrefLang, verbose });

  return {
    raw: num,
    inCents: +(num * 100).toFixed(0),
    formatted: useFormatString ? `${formatStringToDollar({
      value: num,
      minimumFractionDigits: fractionDigits,
      haveComma: true,
      hrefLang,
    })}` : `${currencyPrefix}${num.toFixed(0)}`,
  };
}

export const generateLocalizedPriceObject = ({
  price,
  hrefLang,
}: {
  price?: Nullable<number>;
  hrefLang: HrefLang;
}) => (price ? generatePriceObject({ price, hrefLang, verbose: true }) : null);

export function priceToPricingObject({
  variants,
  modifiers = [],
  activeModifier,
  calculatedPrice,
  salePrice,
  price,
  addOnSavings,
  hrefLang,
}: {
  price: number,
  salePrice: number,
  calculatedPrice: number,
  modifiers: VariantModifierDto[],
  variants: BigCommerceProductDto[],
  activeModifier: string;
  addOnSavings: number,
  hrefLang: HrefLang,
}): PricingObjectDto {
  let originalRange = null;
  let saleRange = null;
  let calculated = calculatedPrice;
  let sale = salePrice;
  let display = price;
  // If there are more than one variant, generate price ranges;
  if (variants && variants.length > 1) {
    originalRange = getPriceRange(variants, 'price', hrefLang);
    saleRange = getPriceRange(variants, 'sale_price', hrefLang);
  }

  const mod = modifiers.find((modifier) => modifier.name === activeModifier);
  if (mod) {
    const { optionValues: [{ price: modifierPrice }] } = mod;
    calculated += modifierPrice;
  }

  if (addOnSavings) {
    sale = calculatedPrice;
    display = calculatedPrice + addOnSavings;
  }

  return {
    price: Number.isFinite(+calculated) ? generatePriceObject({ price: calculated, hrefLang }) : null,
    salePrice: sale ? generatePriceObject({ price: sale, hrefLang }) : null,
    originalPrice: (salePrice && display && (salePrice !== 0)) ? generatePriceObject({ price: display, hrefLang }) : null,
    variantPriceRange: originalRange,
    variantSaleRange: saleRange,
    addOnSavings,
  };
}

export function getFirstImage(images: GalleryItemDto[]): AssetDto {
  try {
    const [{ images: [img] }] = images;
    return img;
  } catch (e) { /* nothing to do */ }
  return {} as AssetDto;
}

export function detectIE() {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ');
  const trident = ua.indexOf('Trident/');

  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  }

  if (trident > 0) {
    // IE 11 => return version number
    const rv = ua.indexOf('rv:');
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }

  // other browser
  return false;
}

export function detectFBBrowser() {
  // @ts-ignore
  const ua = window.navigator.userAgent || window.navigator.vendor || window.opera;
  if ((ua.indexOf('FBAN') > -1) || (ua.indexOf('FBAV') > -1)) {
    return true;
  }
  return false;
}

export function getScrollingElement() {
  return document.scrollingElement || document.body;
}

export function getContainerStyles(backgroundColor?: Nullable<Color>, textColor?: Nullable<Color>) {
  const style: {
    color?: string,
    backgroundColor?: string,
  } = {};

  if (textColor) {
    style.color = textColor.hex || textColor.css;
  }
  if (backgroundColor) {
    style.backgroundColor = backgroundColor.hex || backgroundColor.css;
  }

  return style;
}

export function getModifierOptionBackground(colors: ColorDto[] = []) {
  switch (colors.length) {
    case 0:
      return undefined;
    case 1:
      return colors[0].hex;
    default:
      return `linear-gradient(-45deg, ${colors[1].hex} 0%, ${colors[1].hex} 50%, ${colors[0].hex} 50%, ${colors[0].hex} 100%)`;
  }
}

export function isLoading(props: { url: string, location: { pathname: string }, match: { isExact: boolean, path: string } }) {
  try {
    const { url, location: { pathname }, match: { isExact, path } = {} } = props;

    if (!url && isExact) {
      return path.toLowerCase() !== pathname.toLowerCase();
    }
    if (url && pathname) {
      return url.toLowerCase() !== pathname.toLowerCase();
    }
  } catch (e) { /* nothing to do */ }
  return true;
}

export function groupImagesByScreenSize(images: Asset[], sort: boolean) {
  const imagesArray = [...images];
  if (sort) {
    imagesArray.sort((a, b) => ((a.fileName > b.fileName) ? 1 : -1));
  }

  const assetsByScreenSize = groupBy(imagesArray, ({ screenSize }) => screenSize);
  const screenSizes = Object.keys(assetsByScreenSize);
  return {
    assetsByScreenSize,
    screenSizes,
  };
}

export function formatDate(date: Date | string) {
  const parsedDate = new Date(date);
  const month = `0${parsedDate.getUTCMonth() + 1}`.slice(-2);
  const day = `0${parsedDate.getUTCDate()}`.slice(-2);
  return `${month}/${day}`;
}

export function querySelector(query: string) {
  try {
    return document.querySelector(query);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return null;
  }
}

export const getHeaderHeight = () => [
  '.header-navigation-content',
  '.header-nav__bx',
  '.anchor-nav',
].reduce((sum, el) => (
  // @ts-ignore
  sum + (querySelector(el)?.offsetHeight || 0)
), 0);

export function parseColumnWidths(columnWidths: string[] = []) {
  let desktopWidth = 12;
  let tabletWidth = 8;
  let mobileWidth = 4;

  if (Array.isArray(columnWidths)) {
    columnWidths.forEach((columnWidth) => {
      const width = parseInt(columnWidth.split('_')[1], 10);

      if (columnWidth.includes('mobile')) {
        mobileWidth = width;
      } else if (columnWidth.includes('tablet')) {
        tabletWidth = width;
      } else {
        desktopWidth = width;
      }
    });
  }

  return [desktopWidth, tabletWidth, mobileWidth];
}

// generates classes that center the element within a grid
// and span a certain number of columns for 3 main breakpoints
// e.g. grid-column: 3 / span 8;
export function generateCenteredColumnSpanClassNames(columnWidths: string[] = []) {
  const [desktopWidth, tabletWidth, mobileWidth] = parseColumnWidths(columnWidths);

  return `col-centered-mobile-${mobileWidth} col-centered-tablet-${tabletWidth} col-centered-desktop-${desktopWidth}`;
}

// generates classes that span a certain number of columns for 3 main breakpoints
// e.g. grid-column: span 8;
export function generateColumnSpanClassNames(columnWidths: string[] = []) {
  const [desktopWidth, tabletWidth, mobileWidth] = parseColumnWidths(columnWidths);

  return `col-span-mobile-${mobileWidth} col-span-tablet-${tabletWidth} col-span-desktop-${desktopWidth}`;
}

export const generateVisibilityClassNames = (visibilities: string[] = []) => visibilities?.map(
  (vis) => kebabCase(vis),
).join(' ');

export const convertDateToTimezone = (date: Date | string, timezone = 'America/New_York') => new Date(
  (typeof date === 'string'
    ? new Date(date)
    : date
  ).toLocaleString('en-US', {
    timeZone: timezone,
  }),
);

export function generatePreloadImageLink(images: AssetDto[]) {
  // predict the same responsive sizes generated in GraphImg
  const responsiveSizes = (size: number) => [
    size / 4,
    size / 2,
    size,
    size * 1.5,
    size * 2,
    size * 3,
  ];

  return images.map((asset) => {
    const sizes = responsiveSizes(asset.width).filter((size) => size < asset.width);
    const widths = [...sizes, asset.width];
    const srcSets = widths.map(
      (width) => `${[`https://media.graphassets.com/resize=w:${Math.floor(width)},fit:crop/output=format:webp/compress/${asset.handle}`]} ${Math.floor(width)}w`,
    ).join(',\n');
    return `<link rel="preload" as="image" srcset="${srcSets}" href="https://media.graphassets.com/resize=w:${asset.width},fit:crop/output=format:webp/compress/${asset.handle}">`;
  });
}

export function parseMarkdownLineBreaks(markdown: string) {
  return typeof markdown === 'string'
    ? markdown.replace(/&lt;br&gt;/gm, '<br>').replace(/&lt;br>/gm, '<br>')
    : null;
}

export const generateOrderConfirmationUrl = (email: string, orderID: string) => `/order?email=${email}&orderId=${orderID}`;

export const generateOrderConfirmationUrlWithHrefLang = (email: string, orderID: string, hrefLang: HrefLang) => generateUrlWithHrefLang({ endpoint: generateOrderConfirmationUrl(email, orderID), hrefLang });

export function logError(error: any, functionName: string) {
  let err: any;
  if (error instanceof Error) {
    err = error;
  } else if (typeof error === 'string') {
    err = new Error(error);
  } else if (typeof error === 'object' && error.message) {
    err = new Error(error.message);
  }

  if (err) {
    withScope((scope) => {
      scope.setTag('function_name', functionName);
      captureException(err);
    });
    // eslint-disable-next-line no-console
    console.error(functionName, err);
  }
}

// format the item analytics for both cart and order items
export function formatCartItemAnalytics(item: Record<string, any>, hrefLang: HrefLang) {
  const currencyPrefix = getCurrencyPrefix({ hrefLang });
  let price;
  let image;
  let name;
  let id;
  let url;
  const { category,  quantity } = item;

  try {
    // If the item is a variant then we want to use the skuId instead of the id.
    // We need to keep both because for accessories we use id and for sofas we use
    // skuId.
    id = item.skuID || item.productID?.toString() || item.variantId || item.productId || item.product_id;
    price = item.pricing?.price?.raw || item.listPrice || item.price;
    image = item.image || item.imageUrl || item.productCardImage?.url;
    name = item.name || item.short;
    url = item.seo?.url || item.url;

    if (typeof price === 'string' && price.includes(currencyPrefix)) {
      price = parseInt(price.replace(currencyPrefix, ''), 10);
    }
  } catch (e) { /* nothing to do */ }
  return {
    id,
    price,
    image,
    name,
    url,
    category,
    quantity,
  };
}

export function formatProductAnalytics({
  items,
  hrefLang,
}: {
  items: OrderAnalyticsProduct[];
  hrefLang: HrefLang;
}) {
  return items.map((item) => {
    const {
      price,
      image,
      name: productName,
      url,
      quantity,
    } = formatCartItemAnalytics(item, hrefLang);
    return {
      product_id: item.sku,
      sku: item.sku,
      name: productName,
      category: item.category,
      price,
      imageUrl: image,
      url,
      quantity,
    };
  });
}

export function calculateAssetPaddingBottom(assetWidth: number, assetHeight: number) {
  return assetWidth > 0 && assetHeight > 0
    ? `${(assetHeight / assetWidth) * 100}%`
    : undefined;
}

// Pillow, throw and insert skus can have three structures
export function handlePillowThrowSplitSku(sku: string, skuType: string, restOfSkuArr: string[]) {
  let response;
  switch (restOfSkuArr.length) {
    // sku structure for partner brand
    case 5: {
      const [model, partner, brand, styleIdentifier, color] = restOfSkuArr;
      response = {
        sku,
        type: skuType,
        model,
        partner,
        brand,
        styleIdentifier,
        color,
      };
      break;
    }
    // New Burrow sku to account for pillow and throw variants
    case 2:
    case 3: {
      const [model, styleIdentifier, color] = restOfSkuArr;
      response = {
        sku,
        type: skuType,
        model,
        styleIdentifier,
        color,
      };
      break;
    }
    // OG structure with no variants
    case 1: {
      const [styleIdentifier] = restOfSkuArr;
      response = {
        sku,
        type: skuType,
        styleIdentifier,
      };
      break;
    }
    default:
      break;
  }
  return response;
}
export function getNomadSKUModifiers(sku: string, type: string, rest: string[], modifiers: SkuSplit) {
  const subType = type.match(nomadRegExp)[3];

  switch (subType) {
    case 'SC':
    case 'SF':
    case 'SCO':
    case 'SO':
    case 'SL':
    case 'SLC':
    case 'SLO':
    case 'SLCO':
    case 'SU':
    case 'SCC': {
      const [color, size, arms, legs] = rest;
      modifiers.color = color;
      modifiers.legs = legs;
      modifiers.arms = arms;
      modifiers.size = size;
      break;
    }
    case 'ST':
    case 'CR':
    case 'CS':
    case 'OT': {
      const [color, legs] = rest;
      modifiers.color = color;
      modifiers.legs = legs;
      break;
    }
    case 'AR': {
      const [color, arms, legs] = rest;
      modifiers.color = color;
      modifiers.arms = arms;
      modifiers.legs = legs;
      break;
    }
    case 'LG': {
      const [size, legs] = rest;
      modifiers.size = size;
      modifiers.legs = legs;
      break;
    }
    default:
      break;
  }
  return modifiers;
}

export function returnResponseFromHygraphSkuParse(keys: string[], values: string[]) {
  const response = {};
  keys.forEach((key, index) => {
    response[key] = index < values.length ? values[index] : undefined;
  });
  return response;
}

export function splitSku(sku: string, skuParse?: SkuParseDto) {
  let response: SkuSplit = {};

  const [skuType, ...rest] = sku.split('-');
  const isNomadFurniture = skuType.startsWith('NLS') || skuType.startsWith('NS') || skuType.startsWith('NVS');

  const skuParseArray = sku.split('-');
  if (skuParse) {
    const skuParseResponse: {
      [k: string]: string | undefined;
      sku?: string;
    } = returnResponseFromHygraphSkuParse(skuParse.skuFields, skuParseArray);
    skuParseResponse.sku = sku;

    return skuParseResponse;
  }

  if (skuType.match(/^(N|N3)(V|L)?(OT|ST|CR|CS)$/)) {
    const [color, legs] = rest;
    response = {
      sku,
      type: skuType,
      color,
      legs,
    };
  } else if (skuType.match(/^(N|N3)(V|L)?(AR)$/)) {
    const [color, arms, legs] = rest;
    response = {
      sku,
      type: skuType,
      color,
      arms,
      legs,
    };
  } else if (isNomadFurniture || (['SF', 'SC']).includes(skuType)) {
    const [color, size, arms, legs] = rest;
    response = {
      sku,
      type: skuType,
      color,
      legs,
      arms,
      size,
      fabricMaterial: getMaterialFromSku(response, 'nomadSofa'),
    };
  } else if (skuType.match(/^(N|N3)(LG)$/)) {
    const [size, legs] = rest;
    response = {
      sku,
      type: skuType,
      size,
      legs,
    };
  } else if (skuType === 'ODCU') {
    const [furnitureType, collection] = rest;
    response = {
      type: skuType, furnitureType, collection,
    };
  } else if (skuType === 'ODSET') {
    if (rest[1] === 'SCT') { // Scout chair bundle
      const [furnitureType, collection, woodFinish, color] = rest;
      response = {
        type: skuType, furnitureType, collection, woodFinish, color,
      };
    } else if (rest[2] === 'DN') { // Dunes dining bundle
      if (rest[0] === 'DT') { // Dining bundles with table and chairs
        const [furnitureType, size, collection, color] = rest;
        response = {
          type: skuType, furnitureType, size, collection, color,
        };
      } else { // Dunes Chair Bundle
        const [furnitureType, size, collection, woodFinish, color] = rest;
        response = {
          type: skuType, furnitureType, size, collection, woodFinish, color,
        };
      }
    } else { // All other Dunes sets
      const [furnitureType, size, collection, woodFinish, color] = rest;
      response = {
        type: skuType, furnitureType, size, collection, woodFinish, color,
      };
    }
  } else if (skuType === 'FLRST') { // living room seating
    switch (rest[0]) {
      case 'SF':
      case 'SL':
      case 'SC':
      case 'OT':
      case 'SU':
      case 'OTT':
      case 'SFT':
      case 'SLT':
      case 'ST':
      case 'CR':
      case 'BP':
      case 'AP': {
        const [furniture, collection, size, arms, attachedOttoman, color, legs, tableFinish] = rest;
        response = {
          sku,
          type: skuType,
          collection,
          furnitureType: furniture,
          size,
          arms,
          attachedOttoman,
          color,
          legs,
          tableFinish,
        };
        break;
      }
      case 'SPLG':
      case 'APLG':
      case 'CRLG':
      case 'CNLG': {
        const [furniture, collection, legs] = rest;
        response = {
          sku,
          type: skuType,
          collection,
          furnitureType: furniture,
          legs,
        };
        break;
      }
      case 'STL':
      case 'BN': {
        const [furniture, collection, color, cushionColor, legStyle, legs] = rest;
        response = {
          sku,
          type: skuType,
          furnitureType: furniture,
          collection,
          color,
          cushionColor,
          legStyle,
          legs,
        };
        break;
      }
      case 'AC':
      case 'AO': {
        if (sku.startsWith('FLRST-AC-VP') || sku.startsWith('FLRST-AO-VP')) { // Vesper
          const [furniture, collection, material, cover, color, legs] = rest;
          response = {
            sku,
            type: skuType,
            furnitureType: furniture,
            collection,
            material,
            cover,
            color,
            legs,
          };
        } else { // Lodge and Pica
          const [furniture, collection, material, color, legs] = rest;
          response = {
            sku,
            type: skuType,
            furnitureType: furniture,
            collection,
            material,
            color,
            legs,
          };
        }
        break;
      }
      default:
        break;
    }

    response.fabricMaterial = getMaterialFromSku(response, 'fieldSeating');
  } else if (skuType === 'OLRST') {
    if (rest[0] === 'AC') {
      const [furnitureType, collection, color, legs] = rest;
      response = {
        type: skuType, furnitureType, collection, color, legs,
      };
    } else if (rest[0] === 'CT') { // Dune Coffee Table
      const [furnitureType, collection, woodFinish] = rest;
      response = {
        type: skuType, furnitureType, collection, woodFinish,
      };
    } else {
      const [furnitureType, collection, size, arms, color] = rest;
      response = {
        type: skuType, furnitureType, collection, size, arms, color,
      };
    }
  } else if (skuType === 'OLRTB' || skuType === 'ODRTB') {
    const [furnitureType, collection, color] = rest;
    response = {
      type: skuType, furnitureType, collection, color,
    };
  } else if (skuType === 'ODRST') { // Dune Dining Bench
    if (rest[1] === 'DN') {
      const [furnitureType, collection, legs] = rest;
      response = {
        type: skuType, furnitureType, collection, legs,
      };
    } else { // Relay outdoor dining chairs, set of 2
      const [furnitureType, collection, size, color] = rest;
      response = {
        type: skuType, furnitureType, collection, size, color,
      };
    }
  } else if (skuType === 'AOSTCV') {
    const [furnitureType, collection, partner, brand, size, direction] = rest;
    response = {
      type: skuType, furnitureType, collection, partner, brand, size, direction,
    };
  } else if (skuType === 'ODST') { // Dunes Seating
    const [furnitureType, collection, size, arms, attachedOttoman, color, woodFinish] = rest;
    response = {
      type: skuType, furnitureType, collection, size, arms, attachedOttoman, color, woodFinish,
    };
  } else if (skuType === 'ODAS') {
    if (rest[1] === 'SCT') { // Scout Folding Chair
      const [furnitureType, collection, color, woodFinish] = rest;
      response = {
        type: skuType, furnitureType, collection, color, woodFinish,
      };
    } else { // Dunes Sun Lounger
      const [furnitureType, collection, color, woodFinish] = rest;
      response = {
        type: skuType, furnitureType, collection, color, woodFinish,
      };
    }
  } else if (skuType === 'OSTCV') { // Dunes Seating Cover
    const [furnitureType, collection, partner, brand, size] = rest;
    response = {
      type: skuType, furnitureType, collection, partner, brand, size,
    };
  } else if (skuType === 'ODOD') {
    if (rest[2] === 'S2') { // Dunes Teak Chairs Set of 2
      const [furnitureType, collection, size, woodFinish] = rest;
      response = {
        type: skuType, furnitureType, collection, size, woodFinish,
      };
    } else {  // Dunes Dining Table
      const [furnitureType, collection, woodFinish] = rest;
      response = {
        type: skuType, furnitureType, collection, woodFinish,
      };
    }
  } else if (skuType === 'FLRSTB') { // living room seat table
    const [collection, size, color] = rest;
    response = {
      sku,
      type: skuType,
      collection,
      size,
      color,
    };
  } else if (skuType.includes('LG')) { // legs
    const [legs, size] = rest;
    response = {
      sku,
      type: skuType,
      legs,
      size,
    };
  } else if (skuType === 'ALRRG') { // rugs
    const [size, styleIdentifier, color] = rest;
    response = {
      sku,
      type: skuType,
      styleIdentifier,
      size,
      color,
    };
  } else if (skuType === 'LRST') { // new seating sku
    // TODO: think about a better/broader approach that doesn't require code change for upcoming new skus
    const collection = rest[1];

    if (collection === 'BT') { // carta
      const [furnitureType, , cushionColor, color, legStyle, legs] = rest;

      response = {
        sku,
        type: skuType,
        furnitureType,
        collection,
        cushionColor,
        color,
        legStyle,
        legs,
      };
    }
  } else if (skuType === 'FLRTB') { // table
    const collection = rest[1];

    if (collection === 'BT') { // carta
      const [model, , color, legStyle, legs] = rest;
      response = {
        sku,
        type: skuType,
        model,
        collection,
        color,
        legStyle,
        legs,
      };
    } else { // kettle/signal coffee/side table
      const [model, , color, legs] = rest;

      response = {
        sku,
        type: skuType,
        model,
        collection,
        color,
        legs,
      };
    }
  } else if (skuType === 'FLRHW') { // bento legs
    const [hardware, legStyle, legs] = rest;
    response = {
      sku,
      type: skuType,
      hardware,
      legs,
      legStyle,
    };
  } else if (skuType === 'FLRSR') { // storage (credenzas)
    const [model, collection, color, legStyle, legs] = rest;
    response = {
      sku,
      type: skuType,
      model,
      collection,
      color,
      legStyle,
      legs,
    };

    // cannon collection
    if (model === 'CNN') {
      const [canonCollection, canonSize, canonColor, canonLegs] = rest;
      response = {
        sku,
        type: skuType,
        size: canonSize,
        collection: canonCollection,
        color: canonColor,
        legs: canonLegs,
      };
    }
  } else if (skuType === 'IX') { // index shelves
    const [type, size, color] = rest;
    response = {
      sku,
      type,
      collection: skuType,
      size,
      color,
    };
  } else if (skuType === 'ALRLT') { // lighting
    const [model, partner, brand, styleIdentifier, color, size] = rest;
    response = {
      sku,
      type: skuType,
      model,
      partner,
      brand,
      styleIdentifier,
      color,
      size,
    };
  } else if (['ALRPL', 'ALPRL', 'ALRTW'].includes(skuType)) { // pillow covers and throws share the same basic sku ALRFL
    response = handlePillowThrowSplitSku(sku, skuType, rest);
  } else if (['NAC', 'NLAC', 'NVAC'].includes(skuType)) { // nomad pillows
    const [size, styleIdentifier, color] = rest;

    response = {
      sku,
      type: skuType,
      size,
      styleIdentifier,
      color,
    };
  } else if (skuType === 'PT') { // sets
    const [styleIdentifier, size] = rest;

    response = {
      sku,
      type: skuType,
      size,
      styleIdentifier,
    };
  } else if (skuType === 'ALRSA') { // seating accessories
    const [accessory, collection, color, size] = rest;

    response = {
      sku,
      type: skuType,
      accessory,
      collection,
      color,
      size,
    };
  } else if (['ALRSK', 'ALRFL'].includes(skuType)) { // sleep kit or pillow insert share the same skuSplit values
    let [styleIdentifier] = rest;
    let collection;
    if (rest.length > 1) {
      ([collection, styleIdentifier] = rest);
    }

    response = {
      sku,
      type: skuType,
      collection,
      styleIdentifier,
    };
  } else if (skuType === 'FHOTB') {
    const [model, collection, color, legs] = rest;

    response = {
      sku,
      type: skuType,
      collection,
      model,
      color,
    };
    if (model === 'SST') {
      response.legs = legs;
    }
  } else if (skuType === 'FBRBS') {
    const [model, collection, size, material, color, legs] = rest;

    response = {
      sku,
      type: skuType,
      model,
      collection,
      size,
      material,
      color,
      legs,
    };
  } else if (skuType === 'BRBD') { // Chorus 3.0 Frames and Headboard;
    const [model] = rest;
    if (model === 'BF') { // If bed frame
      const [hbModel, collection, size, material, legStyle, color, legs] = rest;
      response = {
        sku,
        type: skuType,
        model: hbModel,
        collection,
        size,
        material,
        legStyle,
        color,
        legs,
      };
    } else if (model === 'HB') { // If headboard
      const [hbModel, collection, size, material, color] = rest;
      response = {
        sku,
        type: skuType,
        model: hbModel,
        collection,
        size,
        material,
        color,
      };
    }
  } else if (skuType === 'FBRSR') { // Prospect and Heist collections
    if (rest[0] === 'NS') {
      const [model, collection, color, legs] = rest;

      response = {
        sku,
        type: skuType,
        model,
        collection,
        color,
        legs,
      };
    } else if (rest[0] === 'DR') {
      const [model, collection, size, color, legs] = rest;

      response = {
        sku,
        type: skuType,
        model,
        collection,
        size,
        color,
        legs,
      };
    }
  } else if (skuType === 'FBRMT') {
    const [model, collection, size] = rest;
    response = {
      sku,
      type: skuType,
      model,
      collection,
      size,
    };
  } else if (skuType === 'FDRST') { // Haiku and Alto dining collections
    const [model, collection, size, color, legs] = rest;

    response = {
      sku,
      type: skuType,
      model,
      collection,
      size,
      color,
      legs,
    };
  } else if (skuType === 'FDRTB') { // Serif Dining Table
    const [model, collection, color] = rest;

    response = {
      sku,
      type: skuType,
      model,
      collection,
      color,
    };
  } else if (skuType === 'SAMP') {
    const [model, collection, material] = rest;

    response = {
      sku,
      type: skuType,
      model,
      collection,
      material,
    };
  } else if (['ABRSB', 'ABRFL', 'ABRPL'].includes(skuType)) {
    const [model, material, partner, brand, size, color] = rest;

    response = {
      sku,
      type: skuType,
      model,
      partner,
      material,
      brand,
      size,
      color,
    };
  } else if (skuType === 'FHOST') {
    const [model, partner, brand, styleIdentifier, color] = rest;

    response = {
      sku,
      type: skuType,
      model,
      partner,
      brand,
      styleIdentifier,
      color,
    };
  } else if (['N3STNST', 'N3CSNCS', 'N3CRNCR'].includes(skuType)) {
    const [color, legs] = rest;

    response = {
      sku,
      type: skuType,
      color,
      legs,
    };
  } else if (skuType === 'OLRSET') {
    const [furnitureType, collection, arms, cover, color] = rest;
    response = {
      type: skuType, furnitureType, collection, arms, cover, color,
    };
  } else if (skuType === 'ODRSET') {
    const [furnitureType, size, collection, color] = rest;
    response = {
      type: skuType, furnitureType, collection, size, color,
    };
  }

  return response;
}

export function getSKUModifiers(sku: string) {
  const [type, ...rest] = sku.split(/\s*-\s*/g);
  const modifiers: SkuSplit = {
    type,
  };

  if (type.match(nomadRegExp)) {
    return getNomadSKUModifiers(sku, type, rest, modifiers);
  }
  switch (type) {
    case 'FLRST': {
      if (rest[0] === 'BN') {
        const [, , color, , legStyle] = rest;
        modifiers.color = color;
        modifiers.legStyle = legStyle;
      } else if (rest[0] === 'AO' || rest[0] === 'AC') {
        const [, , , , color, legs] = rest;
        modifiers.color = color;
        modifiers.legs = legs;
      } else {
        const [, , , , , color, legs, tableFinish] = rest;
        modifiers.color = color;
        modifiers.legs = legs;
        modifiers.tableFinish = tableFinish;
      }
      break;
    }
    case 'FLRSTB': {
      const [, , color] = rest;
      modifiers.color = color;
      break;
    }
    case 'ALRTW':
    case 'ALRFL':
    case 'ALPRL':
    case 'ALRPL': {
      if (rest.length === 5) {
        const [, , , , color] = rest;
        modifiers.color = color;
      } else if (rest.length === 3) {
        const [, , color] = rest;
        modifiers.color = color;
      }
      break;
    }
    case 'ALRLT': {
      const [model, partner, brand, styleIdentifier, color, size] = rest;
      modifiers.model = model;
      modifiers.partner = partner;
      modifiers.brand = brand;
      modifiers.styleIdentifier = styleIdentifier;
      modifiers.color = color;
      modifiers.size = size;
      break;
    }
    case 'FLRTB': {
      const [, , color] = rest;
      modifiers.color = color;
      break;
    }
    case 'ALRRG': {
      const [size] = rest;
      modifiers.size = size;
      break;
    }
    case 'ALRSA': {
      const [, , color, size] = rest;
      modifiers.color = color;
      modifiers.size = size;
      break;
    }
    case 'FBRBS': {
      const [, , , , color, legs] = rest;
      modifiers.color = color;
      modifiers.legs = legs;
      break;
    }
    case 'FBRMT': {
      const [, , size] = rest;
      modifiers.size = size;
      break;
    }
    case 'FBRSR': {
      if (rest[0] === 'DR') {
        const [, , , color, legs] = rest;
        modifiers.color = color;
        modifiers.legs = legs;
      } else {
        const [, , color, legs] = rest;
        modifiers.color = color;
        modifiers.legs = legs;
      }
      break;
    }
    case 'SAMP': {
      const [, , material] = rest;
      modifiers.material = material;
      break;
    }
    case 'ABRPL':
    case 'ABRFL':
    case 'ABRSB': {
      const [, , , , size, color] = rest;
      modifiers.size = size;
      modifiers.color = color;
      break;
    }
    case 'FHOST': {
      const [, , , , color] = rest;
      modifiers.color = color;
      break;
    }
    default:
      break;
  }
  return modifiers;
}

export const isServer = !(
  typeof window !== 'undefined'
  && window.document
  && window.document.createElement
);

export const isProduction = process.env.STORE === 'production';

export const getRegexGroupByLocalePath = (path: string): { [key: string]: string; } => path.match(PATH_LOCALE_GROUP_REGEX)?.groups || {};

export const parseSSGPath = (path: string) => {
  // clean up for PLP paths
  const cleanPath = path.replace(/\/(plp|pdp|landing)/, '');

  return cleanPath;
};

export const getLocationFields = (location?: Location | NextRouter) => {
  let hash = '';
  let search = '';
  let pathname = '';

  if (location && 'asPath' in location) {
    // clean up url to match ssg x client
    const asPath = parseSSGPath(location.asPath);

    ([pathname, hash = ''] = asPath.split('#'));
    [pathname, search = ''] = pathname.split('?');

    if (hash) {
      hash = `#${hash}`;
    }

    if (search) {
      search = `?${search}`;
    }
  } else if (location) {
    ({ hash, search, pathname } = location);
  }

  return {
    hash,
    search,
    pathname,
  };
};

export const handleBigCommerceGetVariantBySKUError = (sku: string): (error: Error) => { id: null; product_id: null; } => (
  (error) => {
    // if the current sku is a product set and the error is not found, return an empty variant
    if (PRODUCT_SET_SKU_REGEX.test(sku) && error.message.includes('Variant not found with SKU:')) {
      return {
        sku,
        id: null,
        variants: [],
        product_id: null,
      };
    }

    throw error;
  }
);

export const generatePricingPayloadFromSet = (
  setProducts: NonNullable<Variant['setProducts']>,
): PricingEndpointProductPayload[] => (
  setProducts.map(({ quantity, product: { productID, id } }) => ({
    product_id: productID,
    variant_id: typeof id === 'string' ? parseInt(id, 10) : id,
    quantity,
  }))
);

export const shouldHideByScreenSize = ({ windowWidth, visibilities }: { windowWidth: number, visibilities: Visibility[] }) => (
  // Hide component if the window width is 1280px or larger and the component is set to be hidden on desktops
  (windowWidth >= Breakpoints.XL && visibilities?.includes(Visibility.HiddenDesktop))
  // Hide component if the window width is less than 640px and the component is set to be hidden on mobile devices
  || (windowWidth < Breakpoints.Phone && visibilities?.includes(Visibility.HiddenMobile))
  // Hide component if the window width is between 640px and less than 1280px and the component is set to be hidden on tablets
  || (windowWidth >= Breakpoints.Phone && windowWidth < Breakpoints.XL && visibilities?.includes(Visibility.HiddenTablet))
);

export const lowerFirst = <T extends string>(str: T) => `${str.charAt(0).toLowerCase()}${str.slice(1)}` as Uncapitalize<T>;

export const capitalize = <T extends string>(str: T) => `${str.charAt(0).toUpperCase()}${str.slice(1)}` as Capitalize<T>;

export const typedObjectKeys = <{ <T>(o: T): Array<keyof T> }>Object.keys;

type NoUndefinedOrNull<T> = { [K in keyof T]: T[K] extends null | undefined ? never : K };
type CleanObject<T> = Pick<T, NoUndefinedOrNull<T>[keyof T]>;

export const cleanObject = <T extends object>(o: T) => (
  typedObjectKeys(o).reduce((acc, key) => {
    if (o[key] !== undefined && o[key] !== null) {
      // eslint-disable-next-line no-param-reassign
      acc[key] = o[key];
    }
    return acc;
  }, {} as T) as CleanObject<T>
);
