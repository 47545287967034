import React, { Component } from 'react';
import loadable from '@loadable/component';

import Item from './CartItem';
import { cart as cartGlobalAPI } from '../../../mock/globalAPI';

const ProductEditModal = loadable(
  () => import('./ProductEditModal'),
);

class CartItems extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isEditModalOpen: false,
      currentItemCartId: null,
    };

    this.loadAndCloseCart = this.loadAndCloseCart.bind(this);
    this.closeEditModal = this.closeEditModal.bind(this);
    this.openEditModal = this.openEditModal.bind(this);
  }

  closeEditModal() {
    this.props.setIsProductEditModalVisibleAction(false);
  }

  openEditModal(item) {
    const {
      fetchEditModalProductDetailsAction,
      setIsProductEditModalVisibleAction,
    } = this.props;

    fetchEditModalProductDetailsAction({ sku: item.sku }, false);
    setIsProductEditModalVisibleAction(true);
    this.setState({
      currentItemCartId: item.id,
    });
  }

  loadAndCloseCart({ sku }) {
    this.props.fetchProductDetailsAction({ sku });
    this.props.openCartAction(false);
  }

  render() {
    const {
      cartDetails,
      cartHasItems,
      isCartDataPending,
      isCartOpen,
      isCheckoutCart,
      isFetchingProduct,
      isItemEditModalVisible,
      updateCartItemAction,
      updateCartItemModifiersAction,
    } = this.props;

    if (cartHasItems) {
      return (
        <>
          {isCheckoutCart && (
            <ProductEditModal
              isFetchingProduct={isFetchingProduct}
              isCartDataPending={isCartDataPending}
              item={cartDetails.items.find(({ id }) => this.state.currentItemCartId === id)}
              onClose={this.closeEditModal}
              open={isItemEditModalVisible}
              updateCartItemModifiersAction={updateCartItemModifiersAction}
              updateCartItemQuantityAction={updateCartItemAction}
            />
          )}
          {cartDetails.items.map((item) => {
            const boundIncrease = updateCartItemAction.bind(this, item, 1, 'Product', 'Added', 'Increased', 'Cart');
            const boundDecrease = updateCartItemAction.bind(this, item, -1, 'Product', 'Removed', 'Decreased', 'Cart');
            const boundDestroy = updateCartItemAction.bind(this, item, null, 'Product', 'Removed', 'Removed', 'Cart');

            return (
              <Item
                key={item.id}
                item={item}
                editable={true}
                calculating={isCartDataPending}
                increaseQty={boundIncrease}
                decreaseQty={boundDecrease}
                remove={boundDestroy}
                options={true}
                fetchProductShippingEstimate={this.props.fetchProductShippingEstimateSuccessAction}
                shippingEstimates={this.props.shippingEstimates}
                isCartOpen={isCartOpen}
                loadAndCloseCart={this.loadAndCloseCart}
                isCheckoutCart={isCheckoutCart}
                openEditModal={this.openEditModal}
              />
            );
          })}
        </>
      );
    }

    // If there are no items in the cart
    return (
      <div className="cart-empty">
        <p className="center">{cartGlobalAPI.empty}</p>
      </div>
    );
  }
}

export default CartItems;
