import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getIsOnline } from '../../../store/selectors/ui.selectors';
import { setErrorPopupMessage } from '../../../store/actions/ui.actions';
import { popup as popupGlobalAPI } from '../../../mock/globalAPI';
import { deleteCart } from '../../../store/actions/cart.actions';

class Popup extends Component {
  constructor(props) {
    super(props);

    this.redirect = this.redirect.bind(this);
    this.togglePopUp = this.togglePopUp.bind(this);
  }

  componentWillUnmount() {
    const {
      onClose,
      deleteCartAction,
    } =  this.props;
    if (onClose === 'deleteCartOnClose') {
      deleteCartAction();
    }
  }

  redirect(e) {
    e.preventDefault();
    this.props.setErrorPopupMessageAction(false);
    this.props.history.push(this.props.redirect);
  }

  togglePopUp(e) {
    e.preventDefault();
    this.props.setErrorPopupMessageAction(false);
  }

  render() {
    return this.props.message ? (
      <div className="popup">
        <div className="popup-message">
          <h2>{this.props.header ? this.props.header : 'Oops!'}</h2>
          <p className="message">{this.props.message}</p>
          {this.props.isOnline === false && (<p className="message">{popupGlobalAPI.offline_message}</p>)}
          <button className="btn btn-charcoal" onClick={!this.props.redirect ? this.togglePopUp : this.redirect }>Okay</button>
        </div>
      </div>
    ) : null;
  }
}

Popup.propTypes = {
  header: PropTypes.string,
  redirect: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.string,
};

const mapStateToProps = (state) => ({
  isOnline: getIsOnline(state),
});

const mapDispatchToProps = {
  setErrorPopupMessageAction: setErrorPopupMessage,
  deleteCartAction: deleteCart,
};

export default connect(mapStateToProps, mapDispatchToProps)(Popup);
