import {
  createListenerMiddleware,
  addListener,
  isAnyOf,
} from '@reduxjs/toolkit';
import type { TypedStartListening, TypedAddListener } from '@reduxjs/toolkit';
import { RootState, AppDispatch } from './store.types';

import {
  FAVORITES_STORAGE_KEY,
  addFavoriteItem,
  removeFavoriteItem,
  clearFavorites,
} from './slices/favorites.slice';

const listenerMiddleware = createListenerMiddleware();

export type AppStartListening = TypedStartListening<RootState, AppDispatch>;
export const startAppListening = listenerMiddleware.startListening as AppStartListening;

export const addAppListener = addListener as TypedAddListener<
RootState,
AppDispatch
>;

startAppListening({
  matcher: isAnyOf(addFavoriteItem, removeFavoriteItem, clearFavorites),
  effect: (_, listenerApi) => {
    listenerApi.cancelActiveListeners();

    const { favorites } = listenerApi.getState();

    try {
      const serializedFavorites = JSON.stringify(favorites.data);
      localStorage.setItem(FAVORITES_STORAGE_KEY, serializedFavorites);
    } catch {
      // do nothing
    }
  },
});

export default listenerMiddleware;
